
// MAIN FUNCTION THAT CONVERTS STRING INTO DATE...

const CreateLocalDate = (date) => {
    var dateObject = '';
    if (date !== '') {
        var dateParts = date.split("-");
        dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    }

    return dateObject;
}

export default CreateLocalDate;