import no_image from '../Images/no_image.png'

// MAIN FUNCTION THAT CHECKS HTTP IMAGE FOR NO SUCH DATA...

const CheckImage = (prop) => {
    let image = no_image;

    if (prop !== 'no_such_data') {
        image = prop;
    }

    return image;
}

export default CheckImage;