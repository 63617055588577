import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import GetIndex from '../Parent/GetIndex';
import FormatDate from '../Parent/FormatDate';
import CreateDate from '../Parent/CreateDate';
import { useHistory } from 'react-router-dom';
import CreateLocalDate from '../Parent/CreateLocalDate';
import Get from '../HTTPRequest/Get';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';
import FormatLocalDate from '../Parent/FormatLocalDate';

const UpdateLedgerForm = (props) => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const party_types = ['Vendor', 'Client', 'Head', 'Staff', 'Partner'];
    const vch_types =
        [
            'Purchase',
            'Sales',
            'Credit Note',
            'Debit Note',
            'Payment',
            'Receipt',
            'Delivery Note',
            'Receipt Note',
            'Opening Balance',
            'Cheque Return',
            'TDS',
            'TCS',            
            'Others'
        ];
    const initialValues =
    {
        id: data_context.update_data['id'],
        party_type: party_types[GetIndex(party_types, data_context.update_data['party_type'])],
        party_id: data_context.update_data['party_id'],
        party: '',
        date: CreateLocalDate(data_context.update_data['date']),
        particulars: data_context.update_data['particulars'],
        vch_type: vch_types[GetIndex(vch_types, data_context.update_data['vch_type'])],
        vch_no: data_context.update_data['vch_no'],
        credit: (data_context.update_data['credit'] !== null) ? data_context.update_data['credit'] : '',
        debit: (data_context.update_data['debit']) ? data_context.update_data['debit'] : ''
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({ 0: '' });
    const [loading, setLoading] = useState({ 0: false });
    const [vendor, setVendor] = useState([]);
    const [client, setClient] = useState([]);
    const [head, setHead] = useState([]);
    const [staff, setStaff] = useState([]);
    const [partner, setPartner] = useState([]);
    const [item, setItem] = useState([]);
    const history = useHistory();

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=ledger';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let vendor = response.response_payload[0] || [];
                        let client = response.response_payload[1] || [];
                        let head = response.response_payload[2] || [];
                        let staff = response.response_payload[3] || [];
                        let partner = response.response_payload[4] || [];
                        setVendor(vendor);
                        setClient(client);
                        setHead(head);
                        setStaff(staff);
                        setPartner(partner);

                        let party_type = data_context.update_data['party_type'];
                        let party_id = data_context.update_data['party_id'];
                        if (party_type === 'Vendor') {
                            setValues({ ...values, party: vendor.name[GetIndex(vendor.id, party_id)] });
                            const newItems = vendor.id.map((_, i) => ({
                                id: vendor.id[i],
                                name: vendor.name[i] 
                            }));
                        
                            setItem(newItems);
                        }
                        if (party_type === 'Client') {
                            setValues({ ...values, party: client.address[GetIndex(client.id, party_id)] });
                            const newItems = client.id.map((_, i) => ({
                                id: client.id[i],
                                name: client.address[i] 
                            }));
                        
                            setItem(newItems);
                        }
                        if (party_type === 'Head') {
                            setValues({ ...values, party: head.name[GetIndex(head.id, party_id)] });
                            const newItems = head.id.map((_, i) => ({
                                id: head.id[i],
                                name: head.name[i] 
                            }));
                        
                            setItem(newItems);
                        }
                        if (party_type === 'Staff') {
                            setValues({ ...values, party: staff.name[GetIndex(staff.id, party_id)] });
                            const newItems = staff.id.map((_, i) => ({
                                id: staff.id[i],
                                name: staff.name[i] 
                            }));
                        
                            setItem(newItems);
                        }
                        if (party_type === 'Partner') {
                            setValues({ ...values, party: partner.name[GetIndex(partner.id, party_id)] });
                            const newItems = partner.id.map((_, i) => ({
                                id: partner.id[i],
                                name: partner.name[i] 
                            }));
                        
                            setItem(newItems);
                        }
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // REGISTERING TYPE CHANGE...

    useEffect(() => {
        setErrors({});
        setItem([]);

        if (values.party_type === 'Vendor' && vendor.id && vendor.id.length !== 0) {
            const newItems = vendor.id.map((_, i) => ({
                id: vendor.id[i],
                name: vendor.name[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Client' && client.id && client.id.length !== 0) {
            const newItems = client.id.map((_, i) => ({
                id: client.id[i],
                name: client.address[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Head' && head.id && head.id.length !== 0) {
            const newItems = head.id.map((_, i) => ({
                id: head.id[i],
                name: head.name[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Staff' && staff.id && staff.id.length !== 0) {
            const newItems = staff.id.map((_, i) => ({
                id: staff.id[i],
                name: staff.name[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Partner' && partner.id && partner.id.length !== 0) {
            const newItems = partner.id.map((_, i) => ({
                id: partner.id[i],
                name: partner.name[i] 
            }));
        
            setItem(newItems);
        }

        //eslint-disable-next-line
    }, [values.party_type]);

    // ON SELECTION...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, party_id: item.id, party: item.name });
        }        
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});

        if (Object.keys(errors).length === 0) {
            setLoading({...loading, 0: true});
            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/ledger', payload, headers).then(response => {
                setLoading({...loading, 0: false});
                setMessage({...message, 0: response.message});

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = 
                        { 
                            id: values.id,
                            party_type: values.party_type,
                            party_id: values.party_id,
                            party: values.party,
                            date: FormatDate(values.date),
                            particulars: values.particulars,
                            vch_type: values.vch_type,
                            vch_no: values.vch_no,
                            credit: values.credit || null,
                            debit: values.debit || null,
                        };
                        let data = data_context.view_data.table_rows;
                        let index = GetKey(data, payload); 

                        payload = { ...payload, sno: data[index]['sno'] };
                        payload = { ...payload, date: FormatLocalDate(CreateDate(payload['date'])) };
                        payload = { ...payload, entry_exists: data[index]['entry_exists']};
                        payload = { ...payload, docs_url: data[index]['docs_url']};
                        data = ({ ...data, [index]: payload });
                        const row_values = Object.values(data);
                        let view_data = data_context.view_data;
                        view_data = ({ ...view_data, table_rows: row_values });
                        props.handleBack(view_data);
                    }
                }
            });
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("/view_ledger");
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_decimal = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

        if (!values.id) {
            errors.id = "*Ledger ID is required";
        }

        if (!values.party_id) {
            errors.party_id = "*Party is required";
        }

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.particulars) {
            errors.particulars = "*Particulars is required";
        }

        if (!values.vch_no) {
            errors.vch_no = "*Vch Number is required";
        }

        if (!values.credit && !values.debit) {

            errors.credit = "*Either Credit or Debit is required";
            errors.debit = "*Either Credit or Debit is required";
        }
        else if (values.credit && values.debit) {

            errors.credit = "*Both Credit & Debit are not allowed";
            errors.debit = "*Both Credit & Debit are not allowed";
        }
        else if (values.credit) {
            if (!regex_decimal.test(values.credit)) {
                errors.credit = "*Only decimals allowed";
            }
        }
        else if (values.debit) {
            if (!regex_decimal.test(values.debit)) {
                errors.debit = "*Only decimals allowed";
            }
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            party_type: values.party_type,
            party_id: values.party_id,
            date: FormatDate(values.date),
            particulars: values.particulars,
            vch_type: values.vch_type,
            vch_no: values.vch_no,
            credit: values.credit || null,
            debit: values.debit || null,
            fiscal: data_context['fiscal']
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleBack, handleSelect, 
        values, errors, message, loading, party_types, vch_types, item
    };


}

export default UpdateLedgerForm;