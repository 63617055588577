import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import EncodeBase64 from '../Parent/EncodeBase64';
import CreateFileName from '../Parent/CreateFileName';
import no_image from '../Images/no_image.png';
import CreateBlob from '../Parent/CreateBlob';
import Upload from '../HTTPRequest/Upload';
import { useHistory } from 'react-router-dom';
import CheckImage from '../Parent/CheckImage';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';

const UpdateCategoryForm = (props) => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const initialValues =
    {
        id: data_context.update_data['id'],
        name: data_context.update_data['name'],
        image_url: data_context.update_data['image_url']
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({ 0: '', 1: '' });
    const [loading, setLoading] = useState({ 0: false, 1: false });
    let placeholder_image = no_image;
    const [image, setImage] = useState(CheckImage(data_context.update_data['image']));
    const [image_errors, setImageErrors] = useState();
    const [progress, setProgress] = useState({ 0: 0 });
    const [dimension, setDimension] = useState({ width: '', height: '' });
    const [dialog, setDialog] = useState(false);
    const history = useHistory();

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // HANDLER FOR UPLOAD DIALOG...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // GETTING DIMENSIONS OF AN IMAGE...

    useEffect(() => {
        if(image !== 'no_such_data'){
            var blob = CreateBlob(image.split(",").pop());
            var _URL = window.URL || window.webkitURL;
            let img = new Image();
            var objectUrl = _URL.createObjectURL(blob);
            img.onload = function () {
                setDimension({ width: this.width, height: this.height });
                _URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
        }      
        //eslint-disable-next-line    
    }, [image]);

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'image_file') {
                setProgress({ ...progress, 0: 0 });

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setImage(base64);
                    }
                    setValues({ ...values, image_url: e.target.files[0].name });
                    setImageErrors();
                    setMessage({ ...message, 1: '' });

                }).catch(() => {
                    setImageErrors();
                    setMessage({ ...message, 1: "File conversion error" });
                });
            }
        }
    }

    // CLEARING FIELDS...

    const handleClear = () => {
        setImage(placeholder_image);
        setValues({ ...values, image_url: '' });
        setImageErrors();
        setMessage({ ...message, 1: '' });
        setProgress({ ...progress, 0: 0 });

    }

    // VALIDATING UPLOADS...

    const validateUpload = () => {
        let errors = '';

        if (!values.image_url) {
            errors = "*No image selected";
            setImageErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.image_url)[1];

            if (ext !== "jpg" && ext !== "jpeg" && ext !== "JPG" && ext !== "JPEG") {
                errors = "*Image is not a valid JPG file";
                setImageErrors(errors);
            }
            else {
                if (image === placeholder_image) {
                    errors = "*No image preview found";
                    setImageErrors(errors);
                }
            }
        }

        return errors;
    }

    // UPLOADING IMAGES...

    const handleUpload = async () => {
        if (message[1] === '') {
            setProgress({ ...progress, 0: 0 });
            setMessage({ ...message, 1: '' });
            let errors = validateUpload();

            if (Object.keys(errors).length === 0) {
                setLoading({ ...loading, 1: true });
                const options = {
                    headers:
                    {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${user_context.user_data[1]}`
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress({ ...progress, 0: percent });
                    }
                }

                if (image !== null) {
                    let file_name = CreateFileName(values.image_url);
                    const payload = { file_name: file_name, encoded_string: image };

                    await Upload(user_context.url + '/upload', payload, options).then(response => {
                        setMessage({ ...message, 1: response.message });
                        setLoading({ ...loading, 1: false });
                        if (response['status'] === 1) {
                            setValues({ ...values, image_url: file_name });
                        }
                    });
                }
            }
        } else {
            setMessage({ ...message, 1: "File already uploaded. Clear the field to upload again" });
        }
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Put(user_context.url + '/category', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage({ ...message, 0: response.message });
                if(response.status === 1){
                    if (response.response_payload['result'] === 'success') {
                        let data = data_context.view_data.table_rows;
                        let index = GetKey(data, payload);
                        payload = { ...payload, sno: data[index]['sno'] };
                        data = ({ ...data, [index]: payload });
                        const row_values = Object.values(data);
    
                        let view_data = data_context.view_data;
                        view_data = ({ ...view_data, table_rows: row_values });
                        props.handleBack(view_data);
                    }
                }
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.id) {
            errors.id = "*Category ID is required";
        }

        if (!values.name) {
            errors.name = "*Category Name is required";
        }

        if (!values.image_url) {
            errors.image_url = "*Image is required";
        }

        return errors;
    }

    const handleBack = () => {
        history.push("view_category");
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            name: values.name,
            image_url: values.image_url
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleBrowse, handleClear,
        handleUpload, handleDialog, handleBack, values, errors,
        message, loading, progress, image, image_errors,
        placeholder_image, dimension, dialog
    };

}

export default UpdateCategoryForm;