import React, { useState, useEffect, useContext } from "react";
import UserContext from "../Parent/UserContext";
import DataContext from "../Parent/DataContext";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import Get from "../HTTPRequest/Get";
import CustomAutocomplete from "../Components/CustomAutoComplete";
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CameraswitchOutlinedIcon from '@mui/icons-material/CameraswitchOutlined';

const ShowServiceInvoice = () => {

    // STATES...

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const invoice_types = ['Purchase', 'Sales'];
    const [invoice_type, setInvoiceType] = useState(invoice_types[0]);
    const defaultColumnProperties = { sortable: false, resizable: true }; 
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
         { key: 'agreement', name: 'Agreement', width: '15%' },
         { key: 'april', name: 'April', width: '10%' },
         { key: 'may', name: 'May', width: '10%' },
         { key: 'june', name: 'June', width: '10%' },
         { key: 'july', name: 'July', width: '10%' },
         { key: 'august', name: 'August', width: '10%' },
         { key: 'september', name: 'Septmber', width: '10%' },
         { key: 'october', name: 'October', width: '10%' },
         { key: 'november', name: 'November', width: '10%' },
         { key: 'december', name: 'December', width: '10%' },
         { key: 'january', name: 'January', width: '10%' },
         { key: 'february', name: 'February', width: '10%' },
         { key: 'march', name: 'March', width: '10%' },
    ].map(c => ({ ...c, ...defaultColumnProperties }));
    const [table_details, setTableDetails] = useState([]);
    const [table_rows, setTableRows] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [client, setClient] = useState([]);
    const [item, setItem] = useState([]);
    const [party_id, setPartyID] = useState();
    const [disable, setDisable] = useState(false);
    const [agreement, setAgreement] = useState([]);
    const [contract, setContract] = useState([]);
    const [enable_all, setEnableAll] = useState(false);

    // SETTING UP TABLE...

    useEffect(() => {
        const setParty = async () => {
            let endpoint = user_context.url + '/dropdown?route=invoice';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                    

                    let vendor = response.response_payload[0] || [];
                    let client = response.response_payload[1] || [];                   
                    
                    setVendor(vendor);
                    setClient(client);

                    const newItems = vendor.id.map((_, i) => ({
                        id: vendor.id[i],
                        name: vendor.name[i] 
                    }));
                
                    setItem(newItems);
                    setDisable(false);
                }
            });
        }

        const setTable = async () => {
            let endpoint = user_context.url + '/invoice?route=get_service_invoice&fiscal=' + data_context['fiscal'];
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                    
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                    }
                    setTableDetails(data);
                }
            });
        }

        const fetchAgreement = async () => {
            let endpoint = user_context.url + '/dropdown?route=license';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {          
                if ((response.status === 1) && (response.response_payload['result'] === 'success') ) {        
                              
                    let contract = response.response_payload[0] || [];
                    let agreement = response.response_payload[2] || [];                  

                    const new_contract = contract.id.map((_, i) => ({
                        id: contract.id[i],
                        name: contract.contract_no[i], 
                        party_id: contract.vendor_id[i]
                    }));
                
                    setContract(new_contract);

                    const new_agreement = agreement.id.map((_, i) => ({
                        id: agreement.id[i],
                        name: agreement.agreement_no[i],
                        party_id: agreement.client_id[i]
                    }));
                
                    setAgreement(new_agreement);                   
                }
            });
        }

        setParty();
        setTable();
        fetchAgreement();

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

     // ENABLE OR DISABLE VIEW ALL...

     useEffect(() => {

        if(invoice_type === 'Purchase') {
            setEnableAll(false);
        } else {
            setEnableAll(true);
        }

        //eslint-disable-next-line
    }, [invoice_type]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { value } = e.target;
        setInvoiceType(value);
    }

    // REGISTERING TYPE CHANGE...

    useEffect(() => {

        setItem([]);
        setPartyID();

        if (invoice_type === 'Purchase' && vendor.id && vendor.id.length !== 0) {
            const newItems = vendor.id.map((_, i) => ({
                id: vendor.id[i],
                name: vendor.name[i] 
            }));
        
            setItem(newItems);
        }

        if (invoice_type === 'Sales' && client.id && client.id.length !== 0) {
            const newItems = client.id.map((_, i) => ({
                id: client.id[i],
                name: client.address[i] 
            }));
        
            setItem(newItems);
        }

        //eslint-disable-next-line
    }, [invoice_type]);

    // ON SELECTION...

    const handleSelect = (item) => {
        if(item){
            setPartyID(item.id);
        }        
    } 

    // ON SHOW...

    const handleShow = () => {
        if(party_id) {
            let agreement_details;

            if(invoice_type === 'Purchase') {                   
                agreement_details = contract.filter(item => item.party_id === party_id);                    
            } else {
                agreement_details = agreement.filter(item => item.party_id === party_id);
            }          

            const current_table_details = table_details.filter(item => item.invoice_type === invoice_type && item.party_id === party_id);
            
            const data = agreement_details.map((item, index) => {
                const rowData = {
                    sno: index + 1,
                    agreement: item.name,
                    april: "",
                    may: "",
                    june: "",
                    july: "",
                    august: "",
                    september: "",
                    october: "",
                    november: "",
                    december: "",
                    january: "",
                    february: "",
                    march: "",
                };
                
                const agreement_id = item.id;
                const filtered_table_details = current_table_details.filter(item => item.agreement_id === agreement_id);                   

                filtered_table_details.forEach(table_detail => {
                    switch (table_detail.month) {
                    case 'April':
                        rowData.april = table_detail.invoice_no;
                        break;
                    case 'May':
                        rowData.may = table_detail.invoice_no;
                        break;
                    case 'June':
                        rowData.june = table_detail.invoice_no;
                        break;
                    case 'July':
                        rowData.july = table_detail.invoice_no;
                        break;
                    case 'August':
                        rowData.august = table_detail.invoice_no;
                        break;
                    case 'September':
                        rowData.september = table_detail.invoice_no;
                        break;
                    case 'October':
                        rowData.october = table_detail.invoice_no;
                        break;
                    case 'November':
                        rowData.november = table_detail.invoice_no;
                        break;
                    case 'December':
                        rowData.december = table_detail.invoice_no;
                        break;
                    case 'January':
                        rowData.january = table_detail.invoice_no;
                        break;
                    case 'February':
                        rowData.february = table_detail.invoice_no;
                        break;
                    case 'March':
                        rowData.march = table_detail.invoice_no;
                        break;
                    default:
                        break;
                    }
                });
                
                    return rowData;
                });
            setTableRows(data);
        } else {
            toast.error("No party selected");
        }
    }

    // ON VIEW ALL...

    const handleViewAll= () => {

        const current_table_details = table_details.filter(item => item.invoice_type === invoice_type);

        const data = agreement.map((item, index) => {
            const rowData = {
                sno: index + 1,
                agreement: item.name,
                april: "",
                may: "",
                june: "",
                july: "",
                august: "",
                september: "",
                october: "",
                november: "",
                december: "",
                january: "",
                february: "",
                march: "",
            };
            
            const agreement_id = item.id;
            const filtered_table_details = current_table_details.filter(item => item.agreement_id === agreement_id);                   

            filtered_table_details.forEach(table_detail => {
                switch (table_detail.month) {
                case 'April':
                    rowData.april = table_detail.invoice_no;
                    break;
                case 'May':
                    rowData.may = table_detail.invoice_no;
                    break;
                case 'June':
                    rowData.june = table_detail.invoice_no;
                    break;
                case 'July':
                    rowData.july = table_detail.invoice_no;
                    break;
                case 'August':
                    rowData.august = table_detail.invoice_no;
                    break;
                case 'September':
                    rowData.september = table_detail.invoice_no;
                    break;
                case 'October':
                    rowData.october = table_detail.invoice_no;
                    break;
                case 'November':
                    rowData.november = table_detail.invoice_no;
                    break;
                case 'December':
                    rowData.december = table_detail.invoice_no;
                    break;
                case 'January':
                    rowData.january = table_detail.invoice_no;
                    break;
                case 'February':
                    rowData.february = table_detail.invoice_no;
                    break;
                case 'March':
                    rowData.march = table_detail.invoice_no;
                    break;
                default:
                    break;
                }
            });
            
                return rowData;
            });

        setTableRows(data);
    }

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="location-wrapper">
                <div className="view-form">
                    <div className="view-formGroup">
                        <h1 className="form-head">SHOW&nbsp;SERVICE&nbsp;INVOICES</h1>
                    </div>

                    <div className="view-formGroup">
                        <label className="view-label">Invoice Type:</label>                        
                        <select className="view-select" name="selected_column" value={invoice_type} onChange={handleChange} disabled={disable}>
                            {invoice_types.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>  

                        <label className="view-label" style={{marginRight: '10px', marginLeft: '10px'}}>Party:</label>  
                        <div style={{width: '20em'}}>
                            <CustomAutocomplete
                                data={item}
                                placeholder="Search Party..."
                                selectValue={handleSelect}
                                selected_value={party_id}
                            />
                        </div>  
                        <button className="view-button" type="button" onClick={handleShow}><VisibilityOutlinedIcon/>&nbsp;Show</button>           
                        <button className="view-button" type="button" onClick={handleViewAll} disabled={!enable_all}><CameraswitchOutlinedIcon/>&nbsp;View&nbsp;All</button>                                               
                    </div>                    
                </div>

                {/* DATA GRID */}

                <div className="long-table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={table_rows}
                        enableCellSelect={false}
                    />
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default ShowServiceInvoice;