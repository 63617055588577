
import axios from 'axios';

const Post = async (endpoint, request_payload, headers) => {
    try {
        const response = await axios.post(endpoint, request_payload, { headers, timeout: 30000 });
        
        if (response.status === 200) {
            return { status: 1, message: response.data.message, response_payload: response.data };
        }
        else {
            return { status: 0, message: `Unexpected response status: ${response.status}` };
        }
    }
    catch (error) {
        return { status: 0, message: error.message };
    }

}

export default Post;