import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import FormatDate from '../Parent/FormatDate';
import Get from '../HTTPRequest/Get';
import Post from '../HTTPRequest/Post';

const AddLedgerForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const party_types = ['Vendor', 'Client', 'Head', 'Staff', 'Partner'];
    const vch_types =
        [
            'Purchase',
            'Sales',
            'Credit Note',
            'Debit Note',
            'Payment',
            'Receipt',
            'Delivery Note',
            'Receipt Note',
            'Opening Balance',
            'Cheque Return',
            'TDS',
            'TCS',
            'Others'
        ];
    const initialValues =
    {
        party_type: party_types[0],
        party_id: '',
        date: '',
        particulars: '',
        vch_type: vch_types[0],
        vch_no: '',
        credit: '',
        debit: ''
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({ 0: '' });
    const [loading, setLoading] = useState({ 0: false });
    const [vendor, setVendor] = useState([]);
    const [client, setClient] = useState([]);
    const [head, setHead] = useState([]);
    const [staff, setStaff] = useState([]);
    const [partner, setPartner] = useState([]);
    const [item, setItem] = useState([]);
    const [disable, setDisable] = useState(true); 

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=ledger';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let vendor = response.response_payload[0] || [];
                        let client = response.response_payload[1] || [];
                        let head = response.response_payload[2] || [];
                        let staff = response.response_payload[3] || [];
                        let partner = response.response_payload[4] || [];
                        setVendor(vendor);
                        setClient(client);
                        setHead(head);  
                        setStaff(staff); 
                        setPartner(partner);                     

                        const newItems = vendor.id.map((_, i) => ({
                            id: vendor.id[i],
                            name: vendor.name[i] 
                        }));
                    
                        setItem(newItems);
                        setDisable(false);
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // REGISTERING TYPE CHANGE...

    useEffect(() => {
        setErrors({});
        setItem([]);

        if (values.party_type === 'Vendor' && vendor.id && vendor.id.length !== 0) {
            const newItems = vendor.id.map((_, i) => ({
                id: vendor.id[i],
                name: vendor.name[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Client' && client.id && client.id.length !== 0) {
            const newItems = client.id.map((_, i) => ({
                id: client.id[i],
                name: client.address[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Head' && head.id && head.id.length !== 0) {
            const newItems = head.id.map((_, i) => ({
                id: head.id[i],
                name: head.name[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Staff' && staff.id && staff.id.length !== 0) {
            const newItems = staff.id.map((_, i) => ({
                id: staff.id[i],
                name: staff.name[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Partner' && partner.id && partner.id.length !== 0) {
            const newItems = partner.id.map((_, i) => ({
                id: partner.id[i],
                name: partner.name[i] 
            }));
        
            setItem(newItems);
        }
        //eslint-disable-next-line
    }, [values.party_type]);

    // ON SELECTION...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, party_id: item.id });
        }        
    }   

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Post(user_context.url + '/ledger', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage({ ...message, 0: response.message });
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_decimal = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

        if (!values.party_id) {
            errors.party_id = "*Party is required";
        }

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.particulars) {
            errors.particulars = "*Particulars is required";
        }

        if (!values.vch_no) {
            errors.vch_no = "*Vch Number is required";
        }

        if (!values.credit && !values.debit) {

            errors.credit = "*Either Credit or Debit is required";
            errors.debit = "*Either Credit or Debit is required";
        }
        else if (values.credit && values.debit) {

            errors.credit = "*Both Credit & Debit are not allowed";
            errors.debit = "*Both Credit & Debit are not allowed";
        }
        else if (values.credit) {
            if (!regex_decimal.test(values.credit)) {
                errors.credit = "*Only decimals allowed";
            }
        }
        else if (values.debit) {
            if (!regex_decimal.test(values.debit)) {
                errors.debit = "*Only decimals allowed";
            }
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            party_type: values.party_type,
            party_id: values.party_id,
            date: FormatDate(values.date),
            particulars: values.particulars,
            vch_type: values.vch_type,
            vch_no: values.vch_no,
            credit: values.credit || null,
            debit: values.debit || null,
            fiscal: data_context['fiscal']
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleSelect, 
        values, errors, message, loading, party_types, 
        vch_types, item, disable
    };
}

export default AddLedgerForm;