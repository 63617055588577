import React from "react";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { CSVLink } from "react-csv";
import StockSummaryForm from "./StockSummaryForm";
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

const StockSummary = () =>{
    // DECLARING STATES...

    const { ...destruct } = StockSummaryForm();
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'name', name: 'Item', width: '20%' },
        { key: 'model', name: 'Model', width: '15%'},
        { key: 'manufacturer', name: 'Manufacturer', width: '15%'},
        { key: 'category', name: 'Category', width: '15%'},
        { key: 'qty_in', name: 'Qty In', width: '10%'},
        { key: 'qty_out', name: 'Qty Out', width: '10%'},
        { key: 'balance', name: 'Balance', width: '10%'}
    ].map(c => ({ ...c, ...defaultColumnProperties }));
    const headers = ['S.No', 'Item', 'Model', 'Manufacturer', 'Category', 'Qty In', 'Qty Out', 'Balance'];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">
                {/* SEARCH BOX */}

                <form className="view-form">
                    <div className="view-formGroup">
                        <h1 className="form-head">STOCK&nbsp;SUMMARY</h1>
                    </div>                   

                    <div className="daily-formGroup">  
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="view-input" 
                            name="key" 
                            value={destruct.key} 
                            onChange={destruct.handleChange}
                        />                     
                        <CSVLink 
                            className="view-button" 
                            data={destruct.table_rows.map(item => Object.values(item))} 
                            headers={headers} 
                            filename={'BALANCE_SUMMARY.csv'} >
                            <TableViewOutlinedIcon />&nbsp;Export
                        </CSVLink>

                        <button 
                            className="view-button" 
                            type="button" onClick={destruct.generatePDF}>
                            <PictureAsPdfOutlinedIcon/>
                            &nbsp;PDF
                        </button>                        
                    </div>

                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* DATA GRID */}

                <div className="long-table-container">
                    {destruct.loading ? (
                        <div 
                            style={{
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center', 
                                height: '100%', 
                                fontSize: '1.5em', 
                                color: '#555'
                            }}
                        >
                            Loading...
                        </div>
                    ) : (
                        <DataGrid
                            className="rdg-light"
                            style={{width: '100%', height: '100%'}}
                            columns={table_columns}
                            rows={destruct.table_rows}
                            rowRenderer={destruct.rowRenderer}
                            enableCellSelect={false}
                        />
                    )}
                </div>
            </div>
        </div>)
}

export default StockSummary;