import React, { useState, useEffect, useContext } from "react";
import UserContext from "../Parent/UserContext";
import DataContext from "../Parent/DataContext";
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import * as XLSX from 'xlsx';
import Get from "../HTTPRequest/Get";
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import jsPDF from 'jspdf';
import FormatLocalDate from "../Parent/FormatLocalDate";
import { CSVLink } from "react-csv";
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';

const SalaryRequest = () => {

    // STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const initialValues =
    {
        docs_url: '',
        ref_no: '',
        date: '',
        subject: 'Salary payment for the month of ',
        cheque_no: ''
    };
    const [values, setValues] = useState(initialValues);
    const [data, setData] = useState([]);
    const [table_rows, setTableRows] = useState([]);
    const table_columns = [
        { field: 'sno', headerName: 'S.No', width: 5 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'father', headerName: 'Father', width: 200 },
        { field: 'account', headerName: 'Account No', width: 150 },
        {
            field: 'salary', headerName: 'Salary', width: 150,
            renderCell: ({ value, row }) => (
                <input
                    autoComplete='off'
                    className="input"
                    value={value}
                    type="number"
                    onChange={(event) => handleSalary(event, row.id)}
                />
            )
        },
        { field: 'exported_name', headerName: 'Exported Name', width: 250 },
        { field: 'exported_salary', headerName: 'Exported Salary', width: 150 }
    ];
    const [dialog, setDialog] = useState(false);
    const [errors, setErrors] = useState({});
    const headers = ['S.No', 'Name', 'Father', 'Account No', 'Salary'];

    // SETTING UP DATAGRID...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/attendance?route=salary_list';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                    
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                        data[i] = { ...payload[i], id: i+1 };
                    }
                    const row_values = Object.values(data);
                    setTableRows(row_values);               
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // HANDLING SALARY CHANGE...

    const handleSalary = (event, id) => {
        const newValue = event.target.value;
        const updatedRows = table_rows.map((row) => {
            if (row.id === id) {
                return { ...row, salary: newValue };
            }
            return row;
        });
        setTableRows(updatedRows);
    };

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'xls_file') {
                setValues({ ...values, docs_url: e.target.files[0].name });
                const file = e.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const data = new Uint8Array(e.target.result);
                      const workbook = XLSX.read(data, { type: 'array' });
                      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                      setData(jsonData);
                    };
                    reader.readAsArrayBuffer(file);
                }
            }
        }
    }    

    // SUBMITTING...

    const handleSubmit = () => {        
       if (data.length > 0) {
            const updatedRows = table_rows.map(row => {
                const combinedName = `${row.name}`;
                const dataRow = data.find(d => containsSubstringIgnoreCase(d[1], combinedName));
                if (dataRow) {
                    return {
                        ...row,
                        exported_name: dataRow[1],
                        exported_salary: dataRow[2]
                    };
                }
                return row;
            });
            setTableRows(updatedRows);
        } else {
            toast.error("No file selected");
        }
    }

    // CHECKING IF NAME CONTAINS IN THE EXPORTED DATA...

    const containsSubstringIgnoreCase = (str, substr) => {
        return str.toLowerCase().includes(substr.toLowerCase());
    };

    // OPENING DETAILS DIALOG...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // GENEREATING SALARY SHEET...

    const handleGenerate = () => {
        let errors = validateForm(values);
        setErrors(errors);        

        if (Object.keys(errors).length === 0) {
            if(table_rows.length > 0) {
                generatePDF();
            } else {
                toast.error("No salary details found");
            }
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.ref_no) {
            errors.ref_no = "*Ref No is required";
        }

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.subject) {
            errors.subject = "*Subject is required";
        }

        if (!values.cheque_no) {
            errors.cheque_no = "*Cheque No is required";
        }

        return errors;
    }

    // GENERATING PDF...

    const generatePDF = () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'A4',
        });
    
        doc.setFontSize(11);
        const yPosition = 70; 

        const ref_no = "Ref.No: " + values.ref_no;             
        doc.text(ref_no, 15, yPosition);
        const date = "Date: " + FormatLocalDate(values.date);             
        doc.text(date, 15, yPosition+10);             
        doc.text("To,", 15, yPosition+20);
        doc.text("The Manager", 20, yPosition+30);
        doc.text("The Federal Bank Ltd", 20, yPosition+40);
        doc.text("Jalandhar", 20, yPosition+50);
        doc.text("Pincode: 144040", 20, yPosition+60);

        doc.text("Subject: " + values.subject, 15, yPosition+70);
        doc.text("Respected Sir / Madam,", 15, yPosition+80);
        doc.text("I kindly request you to credit the salaries of Softech Smart Solutions’ employees debiting from ", 20, yPosition+90);
        doc.text("the Account No:  16150200001936 using Cheque No: " + values.cheque_no + ". The details are given below.", 20, yPosition+100);

        const headers = ['SNo', 'Name', 'Father', 'Account No', 'Salary'];     
        const totalSalary = table_rows.reduce((total, row) => total + Number(row.salary), 0);
        const totalRow = [{ content: 'Total', colSpan: 4, styles: { halign: 'center' } }, totalSalary.toFixed(2)]; 

        doc.autoTable({
            startY: yPosition + 110,
            theme: 'grid',
            head: [headers],
            body: [
                ...table_rows.map(row => Object.values(row)),
                totalRow
            ],
            rowPageBreak: 'avoid',
            styles: {
                cellPadding: 2,
                cellWidth: 'auto',
                valign: 'middle',
                halign: 'center', 
                lineWidth: 0.1,
                lineColor: [0, 0, 0], 
            },  
            headStyles: {
                fillColor: [48, 51, 78],
                textColor: [255, 255, 255], 
            },
            bodyStyles: {
                fillColor: [255, 255, 255], 
                textColor: [0, 0, 0]
            },
            
        });

        
        doc.save(`SALARY_LIST.pdf`);
    };

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="daily-wrapper">
                <form className="view-form">
                    <div className="location-formGroup">
                        <h1 className="form-head">SALARY&nbsp;REQUEST</h1>
                    </div>

                    <div className="view-formGroup">
                        <label className="view-label">File:</label>
                        <input
                            type="text"
                            autoComplete="off"
                            className="view-input"
                            name="key"
                            readOnly
                            value={values.docs_url}
                        />

                        <input 
                            id="xls_file" 
                            type="file" 
                            accept=".xlsx, .xls"
                            onChange={handleBrowse} 
                            onClick={(event) => { event.target.value = null }} 
                        />
        
                        <label 
                            htmlFor="xls_file" 
                            className="view-button">
                            <ImageSearchOutlinedIcon />&nbsp;Browse
                        </label>

                        <button 
                            className="view-button" 
                            type="button"
                            onClick={handleSubmit} 
                        ><TroubleshootOutlinedIcon/>&nbsp;Find
                        </button>

                        <button 
                            className="view-button" 
                            type="button"
                            onClick={handleDialog} 
                        ><PictureAsPdfOutlinedIcon/>&nbsp;Generate
                        </button>

                        <CSVLink 
                            className="view-button" 
                            data={table_rows.map(item => Object.values(item))} 
                            headers={headers} filename={'SALARY_LIST.csv'} ><TableViewOutlinedIcon />&nbsp;Export
                        </CSVLink>
                    </div>
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <div style={{ width: '100%', height: '100%', backgroundColor: '#FFFF' }}>
                        <DataGrid
                            editMode="cell"
                            rows={table_rows}
                            columns={table_columns}
                            disableSelectionOnClick
                        />
                    </div>
                </div>
            </div>
            
            {dialog && <ReactDialogBox
                closeBox={handleDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Ref No:</label>
                        <input type="text" autoComplete='off' className="input" name="ref_no" value={values.ref_no} onChange={handleChange}></input>
                        {errors.ref_no && <p className="error">{errors.ref_no}</p>}
                    </div> 

                    <div className="location-formGroup">
                        <label className="label">Date:</label>
                        <div className="datepicker" style={{ zIndex: 2 }}>
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={values.date}
                                    onChange={date => handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {errors.date && <p className="error">{errors.date}</p>}
                    </div>   

                    <div className="location-formGroup">
                        <label className="label">Subject:</label>
                        <input type="text" autoComplete='off' className="input" name="subject" value={values.subject} onChange={handleChange}></input>
                        {errors.subject && <p className="error">{errors.subject}</p>}
                    </div>  

                    <div className="location-formGroup">
                        <label className="label">Cheque No:</label>
                        <input type="text" autoComplete='off' className="input" name="cheque_no" value={values.cheque_no} onChange={handleChange}></input>
                        {errors.cheque_no && <p className="error">{errors.cheque_no}</p>}
                    </div>  

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={handleGenerate}>Submit</button>                       
                    </div>         
     
                </div>
            </ReactDialogBox>}
            <ToastContainer/>  
        </div>
    );
}

export default SalaryRequest;