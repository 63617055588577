import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Get from '../HTTPRequest/Get';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';

const DashboardForm = () => {

    // DECLARING STATES...    
    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const user = user_context.user_data[0];
    const [loading, setLoading] = useState({ 0: false });
    const [task, setTask] = useState([]);
    const [attendance, setAttendance] = useState([]);
    const [business, setBusiness] = useState([]);
    const [expense, setExpense] = useState([]);
    const [birthday, setBirthday] = useState([]);
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/dashboard');
    const base_url = user_context.url;
    const url = base_url.replace("/smarterpweb-api", "");
    const [isAnimationEnabled, setIsAnimationEnabled] = useState(false);
    const [pending, setPending] = useState([]);

    // REGISTERING DATABASE CHANGE...

    useEffect(() => {
        const fetchDashboard = async () => {
            setLoading({ ...loading, 0: true });
            let endpoint = user_context.url + '/dashboard?fiscal=' + data_context.fiscal;
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Get(endpoint, headers).then(response => {
                setLoading({ ...loading, 0: false });
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        // TASK DATA...

                        let task = response.response_payload['task'];
                        let data_length = (Object.keys(task).length);
                        let task_data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            let date = CreateDate(task[i]['date']);
                            date = FormatLocalDate(date);
                            task[i]['date'] = date;
                            task_data[i] = task[i];
                        }
                        setTask(task_data);

                        // PENDING TASK...

                        let pending = response.response_payload['pending_task'];
                        let pending_length = (Object.keys(pending).length);
                        let pending_data = ([]);
                        
                        for (let i = 0; i < pending_length; i++) {                            
                            pending_data[i] = pending[i];
                        }
                        setPending(pending_data);

                        // ATTENDANCE DATA...

                        let attendance = response.response_payload['attendance'][0];
                        setAttendance(attendance);

                        // BUSINESS DATA...

                        let business = response.response_payload['business'][0];
                        setBusiness(business);

                        // EXPENSE DATA...

                        let expense = response.response_payload['expense'];
                        setExpense(expense);

                        // BIRTHDAY DATA...

                        let birthday = response.response_payload['birthday'];
                        setBirthday(birthday);
                    }
                }
            });
        }
        if (data_context && data_context.fiscal !== undefined) {
            fetchDashboard();
        }
        //eslint-disable-next-line 
    }, [data_context.fiscal]);

    // HANDLE CARD CLICK TO STOP ANIMATIONS...

    const handleCardClick = () => {
        setIsAnimationEnabled(!isAnimationEnabled);
    };

    // RETURNING VALUES...

    return { loading, permissions, user, task, 
        pending, attendance, business, expense, 
        url, birthday, isAnimationEnabled, 
        handleCardClick };
}

export default DashboardForm;