import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import GetIndex from '../Parent/GetIndex';
import EncodeBase64 from '../Parent/EncodeBase64';
import CreateBlob from '../Parent/CreateBlob';
import CreateFileName from '../Parent/CreateFileName';
import FormatDate from '../Parent/FormatDate';
import CreateDate from '../Parent/CreateDate';
import { useHistory } from 'react-router-dom';
import Get from '../HTTPRequest/Get';
import Upload from '../HTTPRequest/Upload';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';
import FormatLocalDate from '../Parent/FormatLocalDate';
import CreateLocalDate from '../Parent/CreateLocalDate';

const UpdateAgreementForm = (props) => {
    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const types = ['Hardware & Service', 'Service', 'Content Service', 'Professional Service', 'Others'];
    const statuses = ['Active', 'Expired', 'Terminated'];
    const initialValues =
    {
        id: data_context.update_data['id'],
        client_id: data_context.update_data['client_id'],
        client: '',
        agreement_no: data_context.update_data['agreement_no'],
        status: statuses[GetIndex(statuses, data_context.update_data['status'])],
        type: types[GetIndex(types, data_context.update_data['type'])],
        signatory_1: data_context.update_data['signatory_1'],
        signatory_2: data_context.update_data['signatory_2'],
        signatory_3: data_context.update_data['signatory_3'],
        witness_1: data_context.update_data['witness_1'],
        witness_2: data_context.update_data['witness_2'],
        start_date: CreateLocalDate(data_context.update_data['start_date']),
        end_date: CreateLocalDate(data_context.update_data['end_date']),
        tenure: data_context.update_data['tenure'],
        consideration: data_context.update_data['consideration'],
        classes: data_context.update_data['classes'],
        docs_url: data_context.update_data['docs_url']
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({ 0: '', 1: '' });
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [client_id, setClientID] = useState([]);
    const [client, setClient] = useState([]);
    const [progress, setProgress] = useState(0);
    const [docs_errors, setDocsErrors] = useState();
    const [docs, setDocs] = useState(data_context.update_data['docs']);
    const history = useHistory();
    const [dialog, setDialog] = useState(false);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=client';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let client_id = response.response_payload[0]['id'] || [];
                        let client = response.response_payload[0]['address'] || [];

                        setClientID(client_id);
                        setClient(client);
                        setValues({
                            ...values, client_id: client_id[GetIndex(client_id, data_context.update_data['client_id'])],
                            client: client[GetIndex(client_id, data_context.update_data['client_id'])]
                        });
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);


    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });

        if (e.target.name === 'client_id') {
            setValues({ ...values, client_id: value, client: client[GetIndex(client_id, e.target.value)] });
        } GetIndex(client_id, data_context.update_data['client_id'])
    }

    // HANDLER FOR UPLOAD DIALOG...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'pdf_file') {

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setDocs(base64);
                    }

                    setValues({ ...values, docs_url: e.target.files[0].name });
                    setDocsErrors();
                    setMessage({ ...message, 1: '' });

                }).catch(() => {
                    setDocsErrors();
                    setMessage({ ...message, 1: "File conversion error" });
                });
            }
        }
    }

    // VIEWING PDF...

    const viewPDF = () => {
        setLoading({ ...loading, 1: true });
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 1: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 1: false });
                }
            }
        } else {
            setLoading({ ...loading, 1: false });
        }
    }

    // VALIDATING UPLOADS...

    const validateDocs = () => {
        let errors = '';
        if (!values.docs_url) {
            errors = "*No pdf selected";
            setDocsErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.docs_url)[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors = "*Document is not a valid PDF file";
                setDocsErrors(errors);
            }
            else {
                if (docs === 'no_such_data' || !docs) {
                    errors = "*No pdf preview found";
                    setDocsErrors(errors);
                    setLoading({ ...loading, 1: false });
                }
            }
        }
        return errors;
    }

    // CLEARING FIELDS...

    const handleClear = () => {
        setDocs();
        setValues({ ...values, docs_url: '' });
        setDocsErrors();
        setMessage({ ...message, 1: '' });
        setProgress(0);
    }

    // UPLOADING IMAGES AND DOCS...

    const handleUpload = async () => {
        if (message[1] === '') {
            setProgress(0);
            setMessage({ ...message, 1: '' });
            let errors = validateDocs();

            if (Object.keys(errors).length === 0) {
                setLoading({ ...loading, 2: true });
                const options = {
                    headers: 
                    { 
                        'Accept': 'application/json', 
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${user_context.user_data[1]}` 
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress(percent);
                    }
                }

                if (docs !== null) {
                    let file_name = CreateFileName(values.docs_url);
                    const payload = 
                    { 
                        file_name: file_name, 
                        encoded_string: docs,
                        old_file: data_context.update_data['docs_url'] 
                    };

                    await Upload(user_context.url + '/upload', payload, options).then(response => {
                        setMessage({ ...message, 1: response.message });
                        setLoading({ ...loading, 2: false });
                        if (response['status'] === 1) {
                            setValues({ ...values, docs_url: file_name });
                        }
                    });
                }
            }
        } else {
            setMessage({ ...message, 1: "File already uploaded. Clear the field to upload again" });
        }
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateForm(values);
        setErrors(errors);
        setMessage({ ...message, 0: '' });

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/agreement', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage({ 0: response.message });
                
                if(response.status === 1){
                    if (response.response_payload['result'] === 'success') {
                        let data = data_context.view_data.table_rows;
                        let index = GetKey(data, payload);
                        payload = { ...payload, sno: data[index]['sno'] };
                        payload = { ...payload, start_date: FormatLocalDate(CreateDate(payload['start_date'])) };
                        payload = { ...payload, end_date: FormatLocalDate(CreateDate(payload['end_date'])) };
                        data = ({ ...data, [index]: payload });
                        const row_values = Object.values(data);

                        let view_data = data_context.view_data;
                        view_data = ({ ...view_data, table_rows: row_values });
                        props.handleBack(view_data);
                    }
                }
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        const regex_number = /^[0-9]*$/;
        const regex_decimal = /^[-+]?\d*\.?\d+$/;


        if (!values.id) {
            errors.id = "*Agreement ID is required";
        }

        if (!values.client_id) {
            errors.client_id = "*Client ID is required";
        }

        if (!values.agreement_no) {
            errors.agreement_no = "*Agreement Number is required";
        }

        if (!values.signatory_1) {
            errors.signatory_1 = "*Signatory 1 is required";
        }

        if (!values.signatory_2) {
            errors.signatory_2 = "*Signatory 2 is required";
        }

        if (!values.signatory_3) {
            errors.signatory_3 = "*Signatory 3 is required";
        }

        if (!values.witness_1) {
            errors.witness_1 = "*Witness 1 is required";
        }

        if (!values.witness_2) {
            errors.witness_2 = "*Witness 2 is required";
        }

        if (!values.start_date) {
            errors.start_date = "*Start Date is required";
        }

        if (!values.end_date) {
            errors.end_date = "*End Date is required";
        }

        if (values.start_date && values.end_date) {
            if (values.start_date >= values.end_date) {
                errors.end_date = "*End Date cannot be smaller than Start Date";
            }
        }

        if (!values.tenure) {
            errors.tenure = "*Tenure is required";
        }

        if (!values.consideration) {
            errors.consideration = "*Consideration is required";
        }
        else if (!regex_decimal.test(values.consideration)) {
            errors.consideration = "*Only decimals allowed";
        }

        if (!values.classes) {
            errors.classes = "*Classes is required";
        }
        else if (!regex_number.test(values.classes)) {
            errors.classes = "*Only digits allowed";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            client_id: values.client_id,
            agreement_no: values.agreement_no,
            status: values.status,
            type: values.type,
            signatory_1: values.signatory_1,
            signatory_2: values.signatory_2,
            signatory_3: values.signatory_3,
            witness_1: values.witness_1,
            witness_2: values.witness_2,
            tenure: values.tenure,
            start_date: FormatDate(values.start_date),
            end_date: FormatDate(values.end_date),
            consideration: values.consideration,
            classes: values.classes,
            docs_url: values.docs_url
        };

        return data;
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_agreement");
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleBrowse, viewPDF, handleClear, handleUpload, handleBack, handleDialog,
        values, errors, message, loading, client_id, types, progress, docs_errors, statuses, dialog
    };
}

export default UpdateAgreementForm;