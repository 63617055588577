import { useState, useEffect, useContext, useRef } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import { Row } from 'react-data-grid';
import { useHistory } from 'react-router-dom';
import Get from '../HTTPRequest/Get';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import Delete from '../HTTPRequest/Delete';
import Download from '../HTTPRequest/Download';
import CreateBlob from '../Parent/CreateBlob';

const ViewLedgerForm = (props) => {
    // DECLARING STATES...   

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const [columns] = useState(['All', ...user_context.user_data[3]['ledger']]);
    const [values, setValues] = useState({ key: data_context.view_data['key'] || '', selected_column: data_context.view_data['selected_column'] || 'All' });
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [table_rows, setTableRows] = useState(data_context.view_data['table_rows'] || []);
    const [selected_row, setSelectedRow] = useState
    (
        data_context.view_data['selected_row'] !== undefined && 
        data_context.view_data['selected_row'] !== null 
        ? data_context.view_data['selected_row'] : -1
    );
    const [dialog, setDialog] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const history = useHistory();
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/view_ledger');
    const [progress, setProgress] = useState({ 0: 0 });
    const [errors, setErrors] = useState({ 0: '' });
    const gridRef = useRef(null); 
    const [up, setUp] = useState(true);   

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }  

    // SCROLLING UP OR DOWN...
    
    const scroll = () => {
            if (gridRef.current ) {                
                if(up) {
                    const lastIndex = table_rows.length - 1;
                    gridRef.current.scrollToRow(lastIndex);
                } else {
                    gridRef.current.scrollToRow(0);
            }            
        }

        setUp(!up);
    };
    
    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        setMessage();
        setErrors({});
        setTableRows([]);
        setSelectedRow();
        setLoading({ ...loading, 0: true });
        setUp(true);

        let endpoint = '';
        values.key.length === 0
            ? endpoint = user_context.url + '/ledgers?fiscal=' + data_context['fiscal']
            : endpoint = user_context.url + '/ledger?filter_key=' + values.key + '&field=' + values.selected_column + '&fiscal=' + data_context['fiscal'];
        const headers =
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${user_context.user_data[1]}`
        };

        await Get(endpoint, headers).then(response => {
            setLoading({ ...loading, 0: false });
            setMessage(response.message);
            if (response.status === 1) {
                if (response.response_payload['result'] === 'success') {
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        let date = CreateDate(payload[i]['date']);
                        date = FormatLocalDate(date);
                        payload[i]['date'] = date;
                        data[i] = payload[i];
                    }
                    setTableRows(data);
                }
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && (props.row['entry_exists'] === '1') && <Row {...props} isRowSelected={true} className='black'/>}
                {(props.rowIdx === selected_row) && (props.row['entry_exists'] === '0') && <Row {...props} isRowSelected={true} className='red'/>}
                {(props.rowIdx !== selected_row) && (props.row['entry_exists'] === '1') && <Row {...props} isRowSelected={false} className='black'/>}
                {(props.rowIdx !== selected_row) && (props.row['entry_exists'] === '0') && <Row {...props} isRowSelected={false} className='red'/>}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // HANDLER FOR UPDATE BUTTON...

    const handleUpdate = () => {
        if (selected_row > -1) {
            let view_data =
            {
                key: values.key,
                selected_column: values.selected_column,
                selected_row: selected_row,
                table_rows: table_rows
            };
            props.handleBack(view_data);
            props.handleUpdate(table_rows[selected_row]);
            history.push("/update_ledger");
        }
        else {
            setMessage("No row selected");
        }
    }

    // HANDLER FOR DELETE BUTTON...

    const handleDelete = () => {
        setMessage();
        setDialog(true);
    }

    // HANDLER FOR DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // HANDLER FOR CONFIRM DELETE...

    const handleConfirm = () => {
        setDialog(!dialog);
        setConfirm(true);
    }

    // PERFORMING DELETE ACTION...

    useEffect(() => {
        const deleteRow = async () => {
            if (confirm) {
                setConfirm(false);

                if (selected_row > -1) {
                    setLoading({ ...loading, 1: true });
                    const headers =
                    {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${user_context.user_data[1]}`
                    };
                    await Delete(user_context.url + '/ledger?id=' + table_rows[selected_row]['id'], headers).then(response => {
                        setLoading(false);
                        setMessage(response.message);
                        const new_table_rows = table_rows.filter(item => item !== table_rows[selected_row]);
                        setSelectedRow(-1);
                        setTableRows(new_table_rows);
                    });
                }
                else {
                    setMessage("No row selected");
                }
            }
        }

        deleteRow();
        //eslint-disable-next-line    
    }, [confirm]);

    // HANDLER FOR DETAILS BUTTON...

    const handleDetails = () => {
        if (selected_row > -1) {
            let view_data =
            {
                key: values.key,
                selected_column: values.selected_column,
                selected_row: selected_row,
                table_rows: table_rows
            };
            props.handleBack(view_data);
            props.handleUpdate(table_rows[selected_row]);

            switch (table_rows[selected_row]['vch_type']) {
                case 'Purchase':
                    history.push("/add_purchase");
                    break;
                case 'Sales':
                    history.push("/add_sales");
                    break;
                case 'Credit Note':
                    history.push("/add_credit_note");
                    break;
                case 'Debit Note':
                    history.push("/add_debit_note");
                    break;
                case 'Payment':
                    history.push("/add_payment");
                    break;
                case 'Receipt':
                    history.push("/add_receipt");
                    break;
                case 'Delivery Note':
                    history.push("/add_delivery_note");
                    break;
                case 'Receipt Note':
                    history.push("/add_receipt_note");
                    break;
                case 'Others':
                    history.push("/add_others");
                    break;               
                default:
                    setMessage("Invalid option");
            }

        }
        else {
            setMessage("No row selected");
        }
    }     

    // DOWNLOADING ATTACHMENT...
    
    const handleAttachment = async () =>{
        if (selected_row > -1) {
            setMessage();            
            setLoading({ ...loading, 2: true });
            const options = {
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${user_context.user_data[1]}`
                },
                onDownloadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress({ ...progress, 0: percent });
                }
            }

            await Download(user_context.url + '/download?file_docs=' + table_rows[selected_row]['docs_url'], options).then(response => {
                setMessage(response['message'] );
                setLoading({ ...loading, 2: false });
                if (response['status'] === 1 && response.response_payload['result'] === 'success') {
                    viewPDF(response.response_payload['docs']);
                }
            });
        } else {
            setMessage("No row selected");
        }
    }

    // VIEWING PDF...

    const viewPDF = (docs) => {
        setLoading({ ...loading, 2: true });
        let errors = validateDocs(docs);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 2: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 2: false });
                }
            }
        } else {
            setLoading({ ...loading, 2: false });
        }
    }

    // // VALIDATING UPLOADS...

    const validateDocs = (docs) => {
        let errors = {};
        if (!table_rows[selected_row]['docs_url']) {
            errors[0] = "*No attachment found";
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(table_rows[selected_row]['docs_url'])[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors[0] = "*Document is not a valid PDF file";
            }
            else {
                if (docs === 'no_such_data' || !docs) {
                    errors[0] = "*No pdf preview found";
                    setLoading({ ...loading, 2: false });
                }
            }
        }
        return errors;
    }

    const handleStock = () => {
        if (selected_row > -1) {
            if (
                table_rows[selected_row]['vch_type'] === 'Purchase' || 
                table_rows[selected_row]['vch_type'] === 'Sales' || 
                table_rows[selected_row]['vch_type'] === 'Credit Note' || 
                table_rows[selected_row]['vch_type'] === 'Debit Note' || 
                table_rows[selected_row]['vch_type'] === 'Delivery Note' || 
                table_rows[selected_row]['vch_type'] === 'Receipt Note') 
                {
                    let view_data =
                    {
                        key: values.key,
                        selected_column: values.selected_column,
                        selected_row: selected_row,
                        table_rows: table_rows
                    };
                    props.handleBack(view_data);
                    props.handleUpdate(table_rows[selected_row]);
                    history.push("/add_stock", { from_page: "/view_ledger" });
            }
            else {
                setMessage("Selected row doesn't contain the required Vch Type");
            }
        } else {
            setMessage("No row selected");
        }
    }

    // RETURNING VALUES...

    return { handleSubmit, handleChange, onRowClick, rowRenderer, 
            handleDialog, handleConfirm, handleDelete, handleUpdate, 
            handleDetails, handleStock,  handleAttachment, scroll, 
            columns, message, values, loading, table_rows, dialog, 
            permissions, progress, errors, gridRef, up };
}

export default ViewLedgerForm;