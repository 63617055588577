
// MAIN FUNCTION THAT CONVERTS STRING INTO DATE...

const CreateDate = (date) => {
    var dateObject = '';
    if (date !== '') {
        var dateParts = date.split("-");
        dateObject = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
    }

    return dateObject;
}

export default CreateDate;