import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import GetIndex from '../Parent/GetIndex';
import EncodeBase64 from '../Parent/EncodeBase64';
import CreateBlob from '../Parent/CreateBlob';
import CreateFileName from '../Parent/CreateFileName';
import FormatDate from '../Parent/FormatDate';
import Upload from '../HTTPRequest/Upload';
import Post from '../HTTPRequest/Post';
import Get from '../HTTPRequest/Get';

const AddContractForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [vendor_id, setVendorID] = useState([]);
    const [vendor, setVendor] = useState([]);
    const types = ['Service', 'Content Service', 'Professional Service', 'Others'];
    const statuses = ['Active', 'Expired', 'Terminated'];
    const initialValues =
    {
        vendor_id: '',
        vendor: '',
        contract_no: '',
        status: statuses[0],
        license: '',
        start_date: '',
        end_date: '',
        tenure: '',
        fee: '',
        type: types[0],
        docs_url: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({ 0: '', 1: '' });
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false, 3: false });
    const [progress, setProgress] = useState(0);
    const [docs_errors, setDocsErrors] = useState();
    const [docs, setDocs] = useState();
    const [dialog, setDialog] = useState(false);
    const [contract_dialog, setContractDialog] = useState(false);
    const [contract, setContract] = useState([]);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=vendor';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let vendor_id = response.response_payload[0]['id'] || [];
                        let vendor = response.response_payload[0]['name'] || [];

                        setVendorID(vendor_id);
                        setVendor(vendor);
                        setValues({ ...values, vendor_id: vendor_id[0], vendor: vendor[0] });
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });

        if (e.target.name === 'vendor_id') {
            setValues({ ...values, vendor_id: value, vendor: vendor[GetIndex(vendor_id, e.target.value)] });
        }
    }

    // HANDLER FOR UPLOAD DIALOG...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // HANDLER FOR CONTRACT DIALOG...

    const handleContractDialog = () => {
        setContractDialog(!contract_dialog);
    }

    // HANDLER FOR GETTING CONTRACT DETAILS...

    const handleContract = async () =>{
        setLoading({...loading, 3: true});
        let endpoint = user_context.url + '/dropdown?route=contract&id='+values.vendor_id;
        const headers = 
        { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${user_context.user_data[1]}` 
        };
        await Get(endpoint, headers).then(response => {         
            if (response.status === 1) {
                if (response.response_payload['result'] === 'success') {
                    setLoading({...loading, 3: false}); 
                    setContract(response.response_payload[0]['contract_no']);                  
                    setContractDialog(true);
                }
            }
        });
    }

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'pdf_file') {

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setDocs(base64);
                    }

                    setValues({ ...values, docs_url: e.target.files[0].name });
                    setDocsErrors();
                    setMessage({ ...message, 1: '' });

                }).catch(() => {
                    setDocsErrors();
                    setMessage({ ...message, 1: "File conversion error" });
                });
            }
        }
    }

    // VIEWING PDF...

    const viewPDF = () => {
        setLoading({ ...loading, 1: true });
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 1: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 1: false });
                }
            }
        } else {
            setLoading({ ...loading, 1: false });
        }
    }

    // VALIDATING UPLOADS...

    const validateDocs = () => {
        let errors = '';

        if (!values.docs_url) {
            errors = "*No pdf selected";
            setDocsErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.docs_url)[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors = "*Document is not a valid PDF file";
                setDocsErrors(errors);
            }
        }
        return errors;
    }

    // CLEARING FIELDS...

    const handleClear = () => {
        setDocs();
        setValues({ ...values, docs_url: '' });
        setDocsErrors();
        setMessage({ ...message, 1: '' });
        setProgress(0);
    }

    // UPLOADING DOCS...

    const handleUpload = async () => {
        if (message[1] === '') {
            setProgress(0);
            setMessage({ ...message, 1: '' });
            let errors = validateDocs();

            if (Object.keys(errors).length === 0) {
                setLoading({ ...loading, 2: true });
                const options = {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress(percent);
                    }
                }

                if (docs !== null) {
                    let file_name = CreateFileName(values.docs_url);
                    const payload = { file_name: file_name, encoded_string: docs };

                    await Upload(user_context.url + '/upload', payload, options).then(response => {
                        setMessage({ ...message, 1: response.message });
                        setLoading({ ...loading, 2: false });
                        if (response['status'] === 1) {
                            setValues({ ...values, docs_url: file_name });
                        }
                    });
                }
            }
        } else {
            setMessage({ ...message, 1: "File already uploaded. Clear the field to upload again" });
        }
    }


    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateForm(values);
        setErrors(errors);
        setMessage({ ...message, 0: '' });

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Post(user_context.url + '/contract', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage({ 0: response.message });
            });
        }else {
            setMessage({ 0: "Validation failed. Page up to check for errors" });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_number = /^[0-9]*$/;
        const regex_decimal = /^[-+]?\d*\.?\d+$/;

        if (!values.vendor_id) {
            errors.vendor_id = "*Vendor ID is required";
        }

        if (!values.contract_no) {
            errors.contract_no = "*Contract Number is required";
        }

        if (!values.license) {
            errors.license = "*License is required";
        }
        else if (!regex_number.test(values.license)) {
            errors.license = "*Only digits allowed";
        }

        if (!values.start_date) {
            errors.start_date = "*Start Date is required";
        }

        if (!values.end_date) {
            errors.end_date = "*End Date is required";
        }

        if (values.start_date && values.end_date) {
            if (values.start_date >= values.end_date) {
                errors.end_date = "*End Date cannot be smaller than Start Date";
            }
        }

        if (!values.tenure) {
            errors.tenure = "*Tenure is required";
        }

        if (!values.fee) {
            errors.fee = "*Fee is required";
        }
        else if (!regex_decimal.test(values.fee)) {
            errors.fee = "*Only decimals allowed";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            vendor_id: values.vendor_id,
            contract_no: values.contract_no,
            status: values.status,
            license: values.license,
            start_date: FormatDate(values.start_date),
            end_date: FormatDate(values.end_date),
            tenure: values.tenure,
            fee: values.fee,
            type: values.type,
            docs_url: values.docs_url
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleBrowse, viewPDF, handleClear, 
        handleUpload, handleDialog, handleContractDialog, handleContract,
        values, errors, message, loading, vendor_id, types, progress, 
        docs_errors, statuses, dialog, contract_dialog, contract
    };
}

export default AddContractForm;