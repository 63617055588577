import React from 'react';
import "./login.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from '../Images/login_logo.png';
import LoginForm from './LoginForm';
import { useMediaQuery } from 'react-responsive';

const Login = (props) => {

    // DECLARING STATES...  

    const { ...destruct } = LoginForm(props);
    const isMobile = useMediaQuery({ query: `(max-width: 480px)` });

    // MAIN FUNCTION...

    return (
        <div className="login">

            {/* LOGO AND TITLE... */}

            <div className="login-logo">
                <LazyLoadImage className="login-logoIcon" src={Logo} alt="Not found" />                
            </div>

            {/* FORM FOR LOGIN CREDENTIALS... */}

            <form className="login-form" onSubmit={destruct.handleSubmit}>

                <div className="login-formGroup">
                    <label className="label" htmlFor="username">Username:</label>
                    <input autoFocus type="text" autoComplete='off' className="input" id="username" name="username" value={destruct.values.username} onChange={destruct.handleChange}></input>
                    {destruct.errors.username && <p className="error">{destruct.errors.username}</p>}
                </div>

                <div className="login-formGroup">
                    <label className="label" htmlFor="password">Password:</label>
                    <input type="password" autoComplete='off' className="input" id="password" name="password" value={destruct.values.password} onChange={destruct.handleChange}></input>
                    {destruct.errors.password && <p className="error">{destruct.errors.password}</p>}
                </div>

                <div className="login-formGroup">
                    {!destruct.loading && <button className="button">Submit</button>}
                    {destruct.loading && <button className="button" disabled >Submit</button>}
                    {destruct.message && <p className="message">{destruct.message}</p>}
                </div>

            </form>

            {/* FOOTER FOR DEVELOPER DETAILS... */}

            <div className="login-footer">
            <span className="login-logoTitle">Smart ERP 4.0</span>
                <span>Powered by</span>
                <span>Softech Smart Solutions</span>
                {!isMobile && <span>Shop No 4, Silver Kunj, Jalandhar Bypass, Ludhiana, Punjab</span>}
                {isMobile && <span>Ludhiana, Punjab</span>}
                {!isMobile && <span>+919592134440</span>}
            </div>
        </div>
    );
}

export default Login;