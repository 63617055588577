import { useState, useEffect, useContext } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import Get from '../HTTPRequest/Get';
import Delete from '../HTTPRequest/Delete';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-data-grid';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';

const ViewPurchaseOrderForm = (props) => {
    // DECLARING STATES...   

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const [columns] = useState(['All', ...user_context.user_data[3]['purchase_order']]);
    const [values, setValues] = useState(
        {
            key: data_context.view_data['key'] || '',
            selected_column: data_context.view_data['selected_column'] || 'All'
        }
    );
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [table_rows, setTableRows] = useState(data_context.view_data['table_rows'] || []);
    const [selected_row, setSelectedRow] = useState
        (
            data_context.view_data['selected_row'] !== undefined &&
                data_context.view_data['selected_row'] !== null
                ? data_context.view_data['selected_row'] : -1
        );
    const history = useHistory();
    const [dialog, setDialog] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/view_purchase_order');
    const [vendor, setVendor] = useState([]);

    // SETTING UP DETAILS...

    useEffect(() => {
        const fetchVendor = async () => {
            let endpoint = user_context.url + '/dropdown?route=vendor_details';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let vendor = response.response_payload[0] || [];

                        const newItems = vendor.id.map((_, i) => ({
                            id: vendor.id[i],
                            name: vendor.name[i],
                            address: vendor.address[i],
                            district: vendor.district[i],
                            state: vendor.state[i],
                            pincode: vendor.pincode[i]
                        }));

                        setVendor(newItems);
                    }
                }
            });
        }

        fetchVendor();

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        setTableRows([]);
        setSelectedRow();
        setLoading({ ...loading, 0: true });

        let endpoint = '';
        values.key.length === 0
            ? endpoint = user_context.url + '/purchase_orders?fiscal=' + data_context['fiscal']
            : endpoint = user_context.url + '/purchase_order?filter_key=' + values.key +
            '&field=' + values.selected_column + '&fiscal=' + data_context['fiscal'];
        const headers =
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${user_context.user_data[1]}`
        };

        await Get(endpoint, headers).then(response => {
            setLoading({ ...loading, 0: false });
            if (response.status === 1 && response.response_payload['result'] === 'success') {
                toast.success(response?.message);
                let payload = response.response_payload;
                let data_length = (Object.keys(payload).length) - 1;

                let data = ([]);
                for (let i = 0; i < data_length; i++) {
                    let date = CreateDate(payload[i]['date']);
                    let deadline = CreateDate(payload[i]['deadline']);
                    date = FormatLocalDate(date);
                    deadline = FormatLocalDate(deadline);
                    payload[i]['date'] = date;
                    payload[i]['deadline'] = deadline;
                    data[i] = payload[i];
                }
                setTableRows(data);
            } else {
                toast.error(response?.message);
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // HANDLER FOR UPDATE BUTTON...

    const handleUpdate = () => {
        if (selected_row > -1) {
            let view_data =
            {
                key: values.key,
                selected_column: values.selected_column,
                selected_row: selected_row,
                table_rows: table_rows
            };
            props.handleBack(view_data);
            props.handleUpdate(table_rows[selected_row]);
            history.push("/update_purchase_order");
        }
        else {
            toast.error("No row selected");
        }
    }

    // HANDLER FOR DELETE BUTTON...

    const handleDelete = () => {
        setDialog(true);
    }

    // HANDLER FOR DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // HANDLER FOR CONFIRM DELETE...

    const handleConfirm = () => {
        setDialog(!dialog);
        setConfirm(true);
    }

    // PERFORMING DELETE ACTION...

    useEffect(() => {
        const deleteRow = async () => {
            if (confirm) {
                setConfirm(false);

                if (selected_row > -1) {
                    setLoading({ ...loading, 1: true });
                    const headers =
                    {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${user_context.user_data[1]}`
                    };
                    await Delete(user_context.url + '/purchase_order?id=' + table_rows[selected_row]['id'], headers).then(response => {
                        setLoading({ ...loading, 1: false });
                        if (response?.status === 1) {
                            toast.success(response?.message);
                            const new_table_rows = table_rows.filter(item => item !== table_rows[selected_row]);
                            setSelectedRow(-1);
                            setTableRows(new_table_rows);
                        } else {
                            toast.error(response?.message);
                        }
                    });
                }
                else {
                    toast.error("No row selected");
                }
            }
        }

        deleteRow();
        //eslint-disable-next-line    
    }, [confirm]);

    // DOWNLOADING PO PDF...

    const handleDownload = async () => {
        if (selected_row > -1) {
            setLoading({ ...loading, 2: true });
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            let endpoint = user_context.url + '/purchase_order?route=download&id=' + table_rows[selected_row]['id'];

            await Get(endpoint, headers).then(response => {                
                setLoading({ ...loading, 0: false });
                if (response.status === 1 && response.response_payload['result'] === 'success') {
                    toast.success(response?.message);
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 2;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                    }
                    generatePDF(data);
                } else {
                    toast.error(response?.message);
                }
            });
        }
        else {
            toast.error("No row selected");
        }
    }

    // GENERATING PDF...

    const generatePDF = (items) => {       
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'A4',
        });        

        doc.setFontSize(11);
        const yPosition = 20;
        doc.text('Ref No: ' + table_rows[selected_row]['ref_no'], 10, yPosition + 45);
        doc.text('Date: ' + table_rows[selected_row]['date'], 160, yPosition + 45);

        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");

        const titleText = 'PURCHASE ORDER';
        const textWidth = doc.getStringUnitWidth(titleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const xPosition = (doc.internal.pageSize.width - textWidth) / 2;

        doc.text(titleText, xPosition, yPosition + 60);

        const lineHeight = 0.1;
        const lineY = yPosition + 61;
        doc.setLineWidth(lineHeight);
        doc.line(xPosition, lineY, xPosition + textWidth, lineY);

        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        const vendor_details = table_rows[selected_row]['vendor_id'] ? vendor.find(vendorItem => vendorItem.id.includes(table_rows[selected_row]['vendor_id'])) : null;
        doc.text('Vendor: ' + vendor_details?.name, 10, yPosition + 70);
        doc.text('' + vendor_details?.address, 23, yPosition + 75);
        const address = vendor_details?.district + ', ' + vendor_details?.state;
        doc.text('' + address, 23, yPosition + 80);
        doc.text('' + vendor_details?.pincode, 23, yPosition + 85);

        if (table_rows[selected_row]['bill_to'] === 'Ludhiana') {
            doc.text('Bill To: Softech Smart Solutions', 140, yPosition + 70);
            doc.text('Shop No 4, Silver Kunj', 152, yPosition + 75);
            doc.text('Near Jalandhar Byepass', 152, yPosition + 80);
            doc.text('Ludhiana, Punjab, 141008', 152, yPosition + 85);
            doc.text('GSTIN: 03ADLFS5708D1Z8', 152, yPosition + 90);
        } else {
            doc.text('Bill To: Softech Smart Solutions', 140, yPosition + 70);
            doc.text('Flat No GF 4', 152, yPosition + 75);
            doc.text('Guru Gobind Singh Avenue', 152, yPosition + 80);
            doc.text('Chogtti, Jalandhar, 144009', 152, yPosition + 85);
            doc.text('GSTIN: 03ADLFS5708D1Z8', 152, yPosition + 90);
        }

        doc.text('Ship To: ' + table_rows[selected_row]['ship_to'], 10, yPosition + 100);

        doc.setFontSize(11);
        doc.setFont("helvetica", "italic");
        doc.text('We are pleased to place an order for the following materials/services. Kindly deliver the materials/services', 15, yPosition + 110);
        doc.text('on or before ' + FormatLocalDate(values.deadline) + '. The order will be deemed cancelled, if the delivery is delayed.', 15, yPosition + 115);

        const headers = ['SNo', 'Name', 'Manufacturer', 'Model', 'Quantity', 'Unit'];
        const data = items.map(obj => {
            const newObj = { ...obj };
            delete newObj.item_id;
            return newObj;
        });
        
        doc.autoTable({
            startY: yPosition + 125,
            head: [headers],
            body: data.map(row => Object.values(row)),
            rowPageBreak: 'avoid',
            margin: {bottom: 50},
            styles: {
                cellPadding: 2,
                cellWidth: 'auto',
                valign: 'middle',
                halign: 'center', 
                lineWidth: 0.1,
                lineColor: [0, 0, 0], 
            },  
            headStyles: {
                fillColor: [48, 51, 78],
                textColor: [255, 255, 255], 
            },
            bodyStyles: {
                fillColor: [255, 255, 255], 
                textColor: [0, 0, 0]
            }
        });

        doc.save(`${table_rows[selected_row]['vendor']}.pdf`);
    };

    // RETURNING VALUES...

    return {
        handleSubmit,
        handleChange,
        onRowClick,
        handleDelete,
        handleUpdate,
        handleDialog,
        handleConfirm,
        rowRenderer,
        handleDownload,
        columns,
        values,
        loading,
        table_rows,
        dialog,
        selected_row,
        permissions
    };
}

export default ViewPurchaseOrderForm;