import { useState, useEffect, useContext } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-data-grid';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import Get from '../HTTPRequest/Get';
import Delete from '../HTTPRequest/Delete';

const ViewEntryForm = (props) => {
    // DECLARING STATES...   

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const [columns] = useState(['All', ...user_context.user_data[3]['expense']]);
    const [values, setValues] = useState({ key: data_context.view_data['key'] || '', selected_column: data_context.view_data['selected_column'] || 'All' });
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false, 1: false });
    const [table_rows, setTableRows] = useState(data_context.view_data['table_rows'] || []);
    const [selected_row, setSelectedRow] = useState
        (
            data_context.view_data['selected_row'] !== undefined &&
                data_context.view_data['selected_row'] !== null
                ? data_context.view_data['selected_row'] : -1
        );
    const history = useHistory();
    const [dialog, setDialog] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [recipient_id, setRecipientID] = useState([]);
    const [recipient, setRecipient] = useState([]);
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/view_entry');

    // SETTING UP TOOLTIPS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=recipient';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let recipient_id = response.response_payload[0]['id'] || [];
                        let recipient = response.response_payload[0]['name'] || [];

                        setRecipientID(recipient_id);
                        setRecipient(recipient);
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        setMessage();
        setTableRows([]);
        setSelectedRow();
        setLoading({ ...loading, 0: true });

        let endpoint = '';
        values.key.length === 0 ? endpoint = user_context.url + '/expenses?fiscal=' + data_context['fiscal']
            : endpoint = user_context.url + '/expense?filter_key=' + values.key + '&field=' + values.selected_column + '&fiscal=' + data_context['fiscal'];
        const headers =
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${user_context.user_data[1]}`
        };

        await Get(endpoint, headers).then(response => {
            setLoading(false);
            setMessage(response.message);
            if (response.status === 1) {
                if (response.response_payload['result'] === 'success') {
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;
                    let credit = 0, debit = 0;
                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        let date = CreateDate(payload[i]['date']);
                        date = FormatLocalDate(date);
                        payload[i]['date'] = date;
                        credit += parseFloat(payload[i]['credit'] || 0);
                        debit += parseFloat(payload[i]['debit'] || 0);
                        data[i] = payload[i];
                    }

                    credit = parseFloat(credit.toFixed(2));
                    debit = parseFloat(debit.toFixed(2));

                    let footer = 
                    { 
                        sno: '', 
                        id: '', 
                        date: '', 
                        recipient_id: '', 
                        account_id: '', 
                        particulars: 'TOTAL', 
                        credit: credit, 
                        debit: debit 
                    };
                    data.push(footer);
                    setTableRows(data);
                }
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {

        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // HANDLER FOR UPDATE BUTTON...

    const handleUpdate = () => {
        if (selected_row > -1 && table_rows[selected_row]['id'] !== '') {
            let view_data = 
            { 
                key: values.key, 
                selected_column: values.selected_column, 
                selected_row: selected_row, 
                table_rows: table_rows 
            };
            props.handleBack(view_data);
            props.handleUpdate(table_rows[selected_row]);
            history.push("/update_entry");
        }
        else {
            setMessage("No row selected");
        }
    }

    // HANDLER FOR DELETE BUTTON...

    const handleDelete = () => {
        setMessage();
        setDialog(true);
    }

    // HANDLER FOR DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // HANDLER FOR CONFIRM DELETE...

    const handleConfirm = () => {
        setDialog(!dialog);
        setConfirm(true);
    }

    // PERFORMING DELETE ACTION...

    useEffect(() => {
        const deleteRow = async () => {
            if (confirm) {
                setConfirm(false);

                if (selected_row > -1 && table_rows[selected_row]['id'] !== '') {
                    setLoading({ ...loading, 1: true });
                    const headers = 
                    { 
                        'Accept': 'application/json', 
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${user_context.user_data[1]}` 
                    };
                    await Delete(user_context.url + '/expense?id=' + table_rows[selected_row]['id'], headers).then(response => {
                        setLoading(false);
                        setMessage(response.message);
                        const new_table_rows = table_rows.filter(item => item !== table_rows[selected_row]);
                        setSelectedRow(-1);
                        setTableRows(new_table_rows);
                    });
                }
                else {
                    setMessage("No row selected");
                }
            }
        }

        deleteRow();
        //eslint-disable-next-line    
    }, [confirm]);

    // RETURNING VALUES...

    return {
        handleSubmit, handleChange, onRowClick, handleDelete,
        handleUpdate, handleDialog, handleConfirm, rowRenderer,
        columns, message, values, loading, table_rows, dialog,
        recipient_id, recipient, permissions
    };
}

export default ViewEntryForm;