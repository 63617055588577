import React, { useState, useContext } from "react";
import UserContext from "./UserContext";
import "./menu.css";
import { Link } from 'react-router-dom';
import Icon from "../Parent/Icon";
import { useMediaQuery } from 'react-responsive';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';

const Menu = (props) => {

    // STATES...
    
    const user_context = useContext(UserContext);
    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });
    const [flag, setFlag] = useState(false);

    // HANDLES MENU CLICK EVENT...

    const handleClick = (e, permission, link) => {
        if (!permission) {
            e.preventDefault();
        }

        if (isMobile) {
            props.setOpen(false);
        }

        props.onMenuItemClick(link);
    }

    // SETS A MENU OPEN OR CLOSED...

    const handleTree = () => {
        setFlag(!flag);
    }

    // MAIN FUNCITON....

    return (
        <div>
            {!isMobile && (
                <div>
                    {!flag ? (
                        <h3 className="menu-title" onClick={handleTree}>
                            <Icon icon_name={props.title} />&nbsp;{props.title}&nbsp;<ArrowDropDownOutlinedIcon />
                        </h3>
                    ) : (
                        <h3 className="menu-title" onClick={handleTree}>
                            <Icon icon_name={props.title} />&nbsp;{props.title}&nbsp;<ArrowDropUpOutlinedIcon />
                        </h3>
                    )}
                    {flag && (
                        <ul className="menu-list">
                            {props.menu_item.map((item, index) => {
                                const page_exists = user_context.user_data[4].some((array) => array.includes(item.link));
                                return (
                                    <div key={index}>
                                        {page_exists && (
                                            <Link to={item.link} className="menu-link" onClick={event => handleClick(event, true, item.link)}>
                                                <li className={`menu-listItem ${props.selected === item.link ? 'menu-listItem-selected' : ''}`}>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <Icon icon_name={item.icon} />
                                                    {item.name}
                                                </li>
                                            </Link>
                                        )}
                                    </div>
                                );
                            })}
                        </ul>
                    )}
                </div>
            )}

            {isMobile && (
                <div>
                    <h3 className="menu-title">{props.title}</h3>
                    <ul className="menu-list">
                        {props.menu_item.map((item, index) => {
                            const page_exists = user_context.user_data[4].some((array) => array.includes(item.link));
                            return (
                                <div key={index}>
                                    {page_exists && (
                                        <Link to={item.link} className="menu-link" onClick={event => handleClick(event, true, item.link)}>
                                            <li className={`menu-listItem ${props.selected === item.link ? 'menu-listItem-selected' : ''}`}>
                                                &nbsp;&nbsp;&nbsp;
                                                <Icon icon_name={item.icon} />
                                                {item.name}
                                            </li>
                                        </Link>
                                    )}
                                </div>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Menu;
