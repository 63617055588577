import React from "react";
import AddDutyForm from "./AddDutyForm";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DatePicker from "react-datepicker";
import CustomAutocomplete from "../Components/CustomAutoComplete";
import { ToastContainer } from 'react-toastify';

const AddDuty = () => {

    // DECLARING STATES...    

    const { ...destruct } = AddDutyForm();

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR DUTY DETAILS... */}
                
                <form className="location-form" onSubmit={destruct.handleSubmit}>
                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;DUTY</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Staff:</label>
                        {destruct.item &&
                            <CustomAutocomplete
                                data={destruct.item}
                                placeholder="Search Staff..."
                                selectValue={destruct.handleSelect}
                                selected_value={destruct.values.staff_id}
                            />}
                        {destruct.errors.staff_id && <p className="error">{destruct.errors.staff_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Ticket ID:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="ticket_id" 
                            value={destruct.values.ticket_id} 
                            onChange={destruct.handleChange}
                            disabled
                        ></input>
                        {destruct.errors.ticket_id && <p className="error">{destruct.errors.ticket_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Distance (In KM):</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="distance" 
                            value={destruct.values.distance} 
                            onChange={destruct.handleChange}
                        ></input>
                        {destruct.errors.distance && <p className="error">{destruct.errors.distance}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Status:</label>
                        <select className="select" name="status" value={destruct.values.status} onChange={destruct.handleChange} >
                            {destruct.statuses.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.status && <p className="error">{destruct.errors.status}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading[0]}>Save</button>                         
                    </div>
                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>                     
                    </div>

                </form>
            </div>
            <ToastContainer/>
        </div>
    );
}   

export default AddDuty;