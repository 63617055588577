import React from "react";
import AddAttendanceForm from "./AddAttendanceForm";
import './attendance.css';
import DatePicker from "react-datepicker";
import { useMediaQuery } from 'react-responsive';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const AddAttendance = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddAttendanceForm();
    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="attendance-wrapper">

                {/* NORMAL VIEW ON A COMPUTER BROWSER... */}

                {!isMobile && <div className="attendance-form">

                    <div className="attendance-header">
                        <div className="location-formGroup">
                            <h1 className="form-head">ADD&nbsp;ATTENDANCE</h1>
                        </div>
                    </div>

                    <div className="attendance-body">
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Date:</label>
                                <div className="datepicker">
                                    <CalendarMonthOutlinedIcon />
                                    <div className='datepicker-component'>
                                        <DatePicker
                                            className='datepicker-inner-component'
                                            dateFormat="dd-MM-yyyy"
                                            selected={destruct.values.date}
                                            onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                            fixedHeight
                                            todayButton="TODAY"
                                        />
                                    </div>
                                </div>
                                {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Staff ID:</label>
                                <select className="select" name="staff_id" value={destruct.values.staff_id} onChange={destruct.handleChange}>
                                    {destruct.staff_id && destruct.staff_id.length
                                        ? (destruct.staff_id.map((item, i) => (<option key={i}>{item}</option>)))
                                        : (<option value="">Loading...</option>)}
                                </select>
                                {destruct.errors.staff_id && <p className="error">{destruct.errors.staff_id}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Name:</label>
                                <input type="text" autoComplete='off' className="input" name="name" value={destruct.values.name} onChange={destruct.handleChange} disabled></input>
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Type:</label>
                                <select className="select" name="type" value={destruct.values.type} onChange={destruct.handleChange}>
                                    {destruct.types.map((item, i) => (<option key={i}>{item}</option>))}
                                </select>
                                {destruct.errors.type && <p className="error">{destruct.errors.type}</p>}
                            </div>

                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Location ID:</label>
                                <select className="select" name="location_id" value={destruct.values.location_id} onChange={destruct.handleChange} disabled={destruct.disable[1]}>
                                    {destruct.location_id && destruct.location_id.length
                                        ? (destruct.location_id.map((item, i) => (<option key={i}>{item}</option>)))
                                        : (<option value="">Loading...</option>)}
                                </select>
                                {destruct.errors.location_id && <p className="error">{destruct.errors.location_id}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Address:</label>
                                <input type="text" autoComplete='off' className="input" name="address" value={destruct.values.address} onChange={destruct.handleChange} disabled></input>
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Reason:</label>
                                <input type="text" autoComplete='off' className="input" name="reason" value={destruct.values.reason} onChange={destruct.handleChange} disabled={destruct.disable[0]}></input>
                                {destruct.errors.reason && <p className="error">{destruct.errors.reason}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Sign In:</label>
                                <input type="time" autoComplete='off' className="input" name="signin" value={destruct.values.signin} onChange={destruct.handleChange} disabled={destruct.disable[2]}></input>
                                {destruct.errors.signin && <p className="error">{destruct.errors.signin}</p>}
                            </div>

                        </div>

                        <div className="attendance-innerbox">

                            <div className="location-formGroup">
                                <label className="label">Sign Out:</label>
                                <input type="time" autoComplete='off' className="input" name="signout" value={destruct.values.signout} onChange={destruct.handleChange} disabled={destruct.disable[3]}></input>
                                {destruct.errors.signout && <p className="error">{destruct.errors.signout}</p>}
                            </div>

                            <div className="location-formGroup">
                                <button className="button" type="button" onClick={destruct.handleToggle}>&nbsp;Sign-Out</button>
                                <button className="button" type="button" onClick={destruct.handleNoInfo} disabled={destruct.loading[1]}>Show&nbsp;No-Info</button>
                                <button className="button" type="button" onClick={destruct.handleShowAll}>Show&nbsp;All</button>
                            </div>

                        </div>
                    </div>

                    <div className="attendance-footer">
                        <div className="location-formGroup">
                            <button className="short-button" type="button" onClick={destruct.handleSubmit} disabled={destruct.loading[0]}>Save</button>
                            {destruct.message && <p className="message">{destruct.message}</p>}
                        </div>
                    </div>

                </div>}

                {/* MEDIA QUERY VIEW ON MOBILE DEVICE... */}

                {isMobile && <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;ATTENDANCE</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Staff ID:</label>
                        <select className="select" name="staff_id" value={destruct.values.staff_id} onChange={destruct.handleChange}>
                            {destruct.staff_id && destruct.staff_id.length
                                ? (destruct.staff_id.map((item, i) => (<option key={i}>{item}</option>)))
                                : (<option value="">Loading...</option>)}
                        </select>
                        {destruct.errors.staff_id && <p className="error">{destruct.errors.staff_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Name:</label>
                        <input type="text" autoComplete='off' className="input" name="name" value={destruct.values.name} onChange={destruct.handleChange} disabled></input>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Type:</label>
                        <select className="select" name="type" value={destruct.values.type} onChange={destruct.handleChange}>
                            {destruct.types.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.type && <p className="error">{destruct.errors.type}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Location ID:</label>
                        <select className="select" name="location_id" value={destruct.values.location_id} onChange={destruct.handleChange} disabled={destruct.disable[1]}>
                            {destruct.location_id && destruct.location_id.length
                                ? (destruct.location_id.map((item, i) => (<option key={i}>{item}</option>)))
                                : (<option value="">Loading...</option>)}
                        </select>
                        {destruct.errors.location_id && <p className="error">{destruct.errors.location_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Address:</label>
                        <input type="text" autoComplete='off' className="input" name="address" value={destruct.values.address} onChange={destruct.handleChange} disabled></input>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Reason:</label>
                        <input type="text" autoComplete='off' className="input" name="reason" value={destruct.values.reason} onChange={destruct.handleChange} disabled={destruct.disable[0]}></input>
                        {destruct.errors.reason && <p className="error">{destruct.errors.reason}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Sign In:</label>
                        <input type="time" autoComplete='off' className="input" name="signin" value={destruct.values.signin} onChange={destruct.handleChange} disabled={destruct.disable[2]}></input>
                        {destruct.errors.signin && <p className="error">{destruct.errors.signin}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Sign Out:</label>
                        <input type="time" autoComplete='off' className="input" name="signout" value={destruct.values.signout} onChange={destruct.handleChange} disabled={destruct.disable[3]}></input>
                        {destruct.errors.signout && <p className="error">{destruct.errors.signout}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleToggle}>Toggle&nbsp;Sign&nbsp;Out</button>
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleSubmit} disabled={destruct.loading[0]}>Save</button>
                        {destruct.message && <p className="message">{destruct.message}</p>}
                    </div>

                </form>}

            </div>
        </div>
    );
}

export default AddAttendance;