import { useState, useContext, useEffect } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Post from '../HTTPRequest/Post';
import Get from '../HTTPRequest/Get';
import md5 from "md5";

const AddUserForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const initialValues =
    {
        username: '',
        password: '',
        staff: '',
        role: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [staff, setStaff] = useState();
    const [role, setRoles] = useState();

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=user';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let staff = response.response_payload[0] || [];
                        let role = response.response_payload[1] || [];
                        setStaff(staff);
                        setRoles(role);
                        setValues({ ...values, staff: staff.id[0], role: role.id[0] });
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage();

        if (Object.keys(errors).length === 0) {
            setLoading(true);

            let payload = createPayload(values);
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Post(user_context.url + '/user', payload, headers).then(response => {
                setLoading(false);
                setMessage(response.message);
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.username) {
            errors.username = "*Username is required";
        }

        if (!values.password) {
            errors.password = "*Password is required";
        }

        if (!values.staff) {
            errors.staff = "*Staff is required";
        }

        if (!values.role) {
            errors.role = "*Role is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const payload =
        {
            username: values.username,
            password: md5(values.password),
            role_id: values.role,
            staff_id: values.staff,
            partner_id: null,
            status: null
        };

        return payload;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, values, errors, message, loading, staff, role };

}

export default AddUserForm;