import { useState, useContext } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';
import { useHistory } from 'react-router-dom';

const UpdateRecipientForm = (props) => {
    // DECLARING STATES...

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const initialValues =
    {
        id: data_context.update_data['id'],
        name: data_context.update_data['name'],
        address: data_context.update_data['address'],
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const history = useHistory();


    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }


    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage();

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            let payload = createPayload(values);
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Put(user_context.url + '/recipient', payload, headers).then(response => {
                setLoading(false);
                setMessage(response.message);

                if(response.status === 1){
                    if (response.response_payload['result'] === 'success') {
                        let data = data_context.view_data.table_rows;
                        let index = GetKey(data, payload);
                        payload = { ...payload, sno: data[index]['sno'] };
                        data = ({ ...data, [index]: payload });
                        const row_values = Object.values(data);

                        let view_data = data_context.view_data;
                        view_data = ({ ...view_data, table_rows: row_values });
                        props.handleBack(view_data);
                    }
                }
            });
        }
    }


    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_recipient");
    }


    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.id) {
            errors.id = "*Recipient ID is required";
        }

        if (!values.name) {
            errors.name = "*Name is required";
        }

        if (!values.address) {
            errors.address = "*Address is required";
        }

        return errors;
    }


    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            name: values.name,
            address: values.address
        };

        return data;
    }


    // RETURNING VALUES...

    return { handleChange, handleSubmit, handleBack, values, errors, message, loading };

}

export default UpdateRecipientForm;