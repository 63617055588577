import React, { useState, useEffect, useContext } from "react";
import UserContext from '../Parent/UserContext';
import DataContext from "../Parent/DataContext";
import "./home.css";
import Topbar from './Topbar';
import Sidebar from "./Sidebar";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Dashboard from './Dashboard';
import ProtectedRoute from '../Parent/ProtectedRoute';
import { useMediaQuery } from 'react-responsive';

import AddLocation from "../Location/AddLocation";
import ViewLocation from "../Location/ViewLocation";
import UpdateLocation from "../Location/UpdateLocation";

import AddStaff from "../Staff/AddStaff";
import ViewStaff from "../Staff/ViewStaff";
import UpdateStaff from "../Staff/UpdateStaff";
import MergePDF from "../Staff/MergePDF";
import ViewProfile from "../Staff/ViewProfile";

import AddPartner from "../Partner/AddPartner";
import ViewPartner from "../Partner/ViewPartner";
import UpdatePartner from "../Partner/UpdatePartner";

import AddWork from "../Work/AddWork";
import ViewWork from "../Work/ViewWork";
import UpdateWork from "../Work/UpdateWork";
import AddMap from "../Work/AddMap";
import ViewMap from "../Work/ViewMap";
import UpdateMap from "../Work/UpdateMap";

import AddAttendance from "../Attendance/AddAttendance";
import ViewAttendance from "../Attendance/ViewAttendance";
import UpdateAttendance from "../Attendance/UpdateAttendance";
import DailyAttendance from "../Attendance/DailyAttendance";
import MonthlyAttendance from "../Attendance/MonthlyAttendance";
import YearlySummary from "../Attendance/YearlySummary";
import AddHoliday from "../Attendance/AddHoliday";
import ViewHoliday from "../Attendance/ViewHoliday";
import UpdateHoliday from "../Attendance/UpdateHoliday";
import LeaveList from "../Attendance/LeaveList";

import AddRecipient from "../Expense/AddRecipient";
import ViewRecipient from "../Expense/ViewRecipient";
import UpdateRecipient from "../Expense/UpdateRecipient";
import AddAccount from "../Expense/AddAccount";
import MakeEntry from "../Expense/MakeEntry";
import ViewEntry from "../Expense/ViewEntry";
import UpdateEntry from "../Expense/UpdateEntry";
import ViewStatement from "../Expense/ViewStatement";
import MiniStatement from "../Expense/MiniStatement";
import ExpenseSheet from "../Expense/ExpenseSheet";
import BalanceSummary from "../Expense/BalanceSummary";

import AddVendor from "../Vendor/AddVendor";
import ViewVendor from "../Vendor/ViewVendor";
import UpdateVendor from "../Vendor/UpdateVendor";
import AddContract from "../Vendor/AddContract";
import ViewContract from "../Vendor/ViewContract";
import UpdateContract from "../Vendor/UpdateContract";
import AddLicense from "../Vendor/AddLicense";
import ViewLicense from "../Vendor/ViewLicense";
import UpdateLicense from "../Vendor/UpdateLicense";

import AddClient from "../Client/AddClient";
import ViewClient from "../Client/ViewClient";
import UpdateClient from "../Client/UpdateClient";
import AddAgreement from "../Client/AddAgreement";
import ViewAgreement from "../Client/ViewAgreement";
import UpdateAgreement from "../Client/UpdateAgreement";
import AddStrength from "../Client/AddStrength";
import ViewStrength from "../Client/ViewStrength";
import UpdateStrength from "../Client/UpdateStrength";
import ShowClass from "../Client/ShowClass";

import AddHead from "../Head/AddHead";
import ViewHead from "../Head/ViewHead";
import UpdateHead from "../Head/UpdateHead";

import AddTicket from "../Ticket/AddTicket";
import ViewTicket from "../Ticket/ViewTicket";
import UpdateTicket from "../Ticket/UpdateTicket";
import ShowTicket from "../Ticket/ShowTicket";

import AddLedger from "../Ledger/AddLedger";
import ViewLedger from "../Ledger/ViewLedger";
import UpdateLedger from "../Ledger/UpdateLedger";
import AddPurchase from "../Ledger/Purchase/AddPurchase";
import ViewPurchase from "../Ledger/Purchase/ViewPurchase";
import UpdatePurchase from "../Ledger/Purchase/UpdatePurchase";
import AddSales from "../Ledger/Sales/AddSales";
import ViewSales from "../Ledger/Sales/ViewSales";
import UpdateSales from "../Ledger/Sales/UpdateSales";
import AddCreditNote from "../Ledger/CreditNote/AddCreditNote";
import ViewCreditNote from "../Ledger/CreditNote/ViewCreditNote";
import UpdateCreditNote from "../Ledger/CreditNote/UpdateCreditNote";
import AddDebitNote from "../Ledger/DebitNote/AddDebitNote";
import ViewDebitNote from "../Ledger/DebitNote/ViewDebitNote";
import UpdateDebitNote from "../Ledger/DebitNote/UpdateDebitNote";
import AddPayment from "../Ledger/Payment/AddPayment";
import ViewPayment from "../Ledger/Payment/ViewPayment";
import UpdatePayment from "../Ledger/Payment/UpdatePayment";
import AddReceipt from "../Ledger/Receipt/AddReceipt";
import ViewReceipt from "../Ledger/Receipt/ViewReceipt";
import UpdateReceipt from "../Ledger/Receipt/UpdateReceipt";
import AddDeliveryNote from "../Ledger/DeliveryNote/AddDeliveryNote";
import ViewDeliveryNote from "../Ledger/DeliveryNote/ViewDeliveryNote";
import UpdateDeliveryNote from "../Ledger/DeliveryNote/UpdateDeliveryNote";
import AddReceiptNote from "../Ledger/ReceiptNote/AddReceiptNote";
import ViewReceiptNote from "../Ledger/ReceiptNote/ViewReceiptNote";
import UpdateReceiptNote from "../Ledger/ReceiptNote/UpdateReceiptNote";
import AddOthers from "../Ledger/Others/AddOthers";
import ViewOthers from "../Ledger/Others/ViewOthers";
import UpdateOthers from "../Ledger/Others/UpdateOthers";
import ShowLedger from "../Ledger/Statistics/ShowLedger";

import AddCategory from "../Item/AddCategory";
import ViewCategory from "../Item/ViewCategory";
import UpdateCategory from "../Item/UpdateCategory";
import AddItem from "../Item/AddItem";
import ViewItem from "../Item/ViewItem";
import UpdateItem from "../Item/UpdateItem";

import AddStock from "../Stock/AddStock";
import ViewStock from "../Stock/ViewStock";
import UpdateStock from "../Stock/UpdateStock";
import StockSummary from "../Stock/StockSummary";

import AddPurchaseOrder from "../Letters/AddPurchaseOrder";
import ViewPurchaseOrder from "../Letters/ViewPurchaseOrder";
import UpdatePurchaseOrder from "../Letters/UpdatePurchaseOrder";
import AddProposalTemplate from "../Letters/AddProposalTemplate";
import AddProposal from "../Letters/AddProposal";
import ViewProposal from "../Letters/ViewProposal";
import UpdateProposal from "../Letters/UpdateProposal";
import AddQuotation from "../Letters/AddQuotation";
import ViewQuotation from "../Letters/ViewQuotation";
import UpdateQuotation from "../Letters/UpdateQuotation";
import ViewQuotationItems from "../Letters/ViewQuotationItems"; 
import UpdateQuotationItems from "../Letters/UpdateQuotationItems";
import AddLetterDespatch from "../Letters/AddLetterDespatch";
import ViewLetterDespatch from "../Letters/ViewLetterDespatch";
import AddLetterReceived from "../Letters/AddLetterReceived";
import ViewLetterReceived from "../Letters/ViewLetterReceived";
import UpdateLetterReceived from "../Letters/UpdateLetterReceived";
import ShowTemplate from "../Letters/ShowTemplate";

import ViewCheque from "../Cheque/ViewCheque";

import AddTask from "../Task/AddTask";
import ViewTask from "../Task/ViewTask";
import UpdateTask from "../Task/UpdateTask";
import StickyNote from "../Task/StickyNote";

import AddServiceInvoice from "../Invoice/AddServiceInvoice";
import ViewServiceInvoice from "../Invoice/ViewServiceInvoice";
import ShowServiceInvoice from "../Invoice/ShowServiceInvoice";

import AddDuty from "../Duty/AddDuty";
import ViewDuty from "../Duty/ViewDuty";
import UpdateDuty from "../Duty/UpdateDuty";
import ShowDuty from "../Duty/ShowDuty";
import DutySummary from "../Duty/DutySummary";

import BillGoods from "../Billings/BillGoods";
import BillService from "../Billings/BillService";

import Documents from "../Office/Documents";

import AddUser from "../Settings/AddUser";
import Backup from "../Settings/Backup";
import FiscalSetup from "../Settings/FiscalSetup";
import Permissions from "../Settings/Permissions";
import UpdateLetterDespatch from "../Letters/UpdateLetterDespatch";

import SalaryList from "../Salary/SalaryList";
import SalaryRequest from "../Salary/SalaryRequest";

const Home = () => {
    // DECLARING STATES... 

    const user_context = useContext(UserContext);
    const [database, setDatabase] = useState([]);
    const [default_db, setDefaultDB] = useState();
    const [update_data, setUpdateData] = useState({});
    const [view_data, setViewData] = useState({});
    const [open, setOpen] = useState(true);
    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });
    let data_context = { update_data: update_data, view_data: view_data, fiscal: default_db };

    // REGISTERING SELECTED VALUE RETURNED BY TOPBAR COMPONENT...

    const setSelectedDB = (selected_db) => {
        setDefaultDB(selected_db);
    }

    // SETTING UP ARRAY FOR TOPBAR SELECT...

    useEffect(() => {
        setDatabase([]);
        let data_length = (Object.keys(user_context.user_data[2]).length);
        let temp_data = ([]);
        let j = 0;

        for (let i = 0; i < data_length; i++) {
            temp_data[j] = user_context.user_data[2][i];
            j++;

        }

        let last_index = (Object.keys(temp_data).length) - 1;
        setDatabase(temp_data);
        setDefaultDB(temp_data[last_index]);

        if (isMobile) {
            setOpen(false);
        }

        //eslint-disable-next-line    
    }, []);

    // CHECKING IF SCREEN WIDTH CHANGES...

    useEffect(() => {
        if (isMobile) {
            setOpen(false);
        }
        else {
            setOpen(true);
        }

    }, [isMobile]);

    // SETTING UP DATA FOR UPDATE REQUEST...

    const handleUpdate = (update_data) => {
        setUpdateData(update_data);
    }

    // SETTING UP DATA FOR GO BACK REQUEST...

    const handleBack = (view_data) => {
        setViewData(view_data);
    }

    // DISMISSES SIDEBAR FROM CLICK ANYWHERE IF OPEN...

    const handleClick = () => {
        if (isMobile && open) {
            setOpen(false);
        }
    }

    // MAIN FUNCTION...

    return (
        <DataContext.Provider value={data_context}>
            <div className="home" onClick={handleClick}>
                <Router>
                    <Topbar database={database} open={open} setSelectedDB={setSelectedDB} setOpen={setOpen} default_db={default_db} />
                    <div className="homeWrapper">
                        {open && <Sidebar open={open} setOpen={setOpen} handleBack={handleBack} handleUpdate={handleUpdate} />}
                        <Switch>
                            <ProtectedRoute path="/home" component={Dashboard} />
                            <ProtectedRoute path="/dashboard" component={Dashboard} />

                            <ProtectedRoute path="/add_location" component={AddLocation} />
                            <ProtectedRoute path="/view_location" component={ViewLocation} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_location" component={UpdateLocation} handleBack={handleBack} />

                            <ProtectedRoute path="/add_staff" component={AddStaff} />
                            <ProtectedRoute path="/view_staff" component={ViewStaff} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_staff" component={UpdateStaff} handleBack={handleBack} />
                            <ProtectedRoute path="/merge_pdf" component={MergePDF} />
                            <ProtectedRoute path="/view_profile" component={ViewProfile} />

                            <ProtectedRoute path="/add_partner" component={AddPartner} />
                            <ProtectedRoute path="/view_partner" component={ViewPartner} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_partner" component={UpdatePartner} handleBack={handleBack} />

                            <ProtectedRoute path="/add_work" component={AddWork} />
                            <ProtectedRoute path="/view_work" component={ViewWork} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_work" component={UpdateWork} handleBack={handleBack} />
                            <ProtectedRoute path="/add_map" component={AddMap} />
                            <ProtectedRoute path="/view_map" component={ViewMap} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_map" component={UpdateMap} handleBack={handleBack} />

                            <ProtectedRoute path="/add_attendance" component={AddAttendance} />
                            <ProtectedRoute path="/view_attendance" component={ViewAttendance} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_attendance" component={UpdateAttendance} handleBack={handleBack} />
                            <ProtectedRoute path="/daily_attendance" component={DailyAttendance} />
                            <ProtectedRoute path="/monthly_attendance" component={MonthlyAttendance} />
                            <ProtectedRoute path="/yearly_summary" component={YearlySummary} />
                            <ProtectedRoute path="/add_holiday" component={AddHoliday} />
                            <ProtectedRoute path="/view_holiday" component={ViewHoliday} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_holiday" component={UpdateHoliday} handleBack={handleBack} />
                            <ProtectedRoute path = "/leave_list" component={LeaveList}/>                             

                            <ProtectedRoute path="/add_recipient" component={AddRecipient} />
                            <ProtectedRoute path="/view_recipient" component={ViewRecipient} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_recipient" component={UpdateRecipient} handleBack={handleBack} />
                            <ProtectedRoute path="/add_account" component={AddAccount} />
                            <ProtectedRoute path="/make_entry" component={MakeEntry} />
                            <ProtectedRoute path="/view_entry" component={ViewEntry} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_entry" component={UpdateEntry} handleBack={handleBack} />
                            <ProtectedRoute path="/view_statement" component={ViewStatement} />
                            <ProtectedRoute path="/mini_statement" component={MiniStatement} />
                            <ProtectedRoute path="/expense_sheet" component={ExpenseSheet} />
                            <ProtectedRoute path="/balance_summary" component={BalanceSummary} />

                            <ProtectedRoute path="/add_vendor" component={AddVendor} />
                            <ProtectedRoute path="/view_vendor" component={ViewVendor} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_vendor" component={UpdateVendor} handleBack={handleBack} />
                            <ProtectedRoute path="/add_contract" component={AddContract} />
                            <ProtectedRoute path="/view_contract" component={ViewContract} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_contract" component={UpdateContract} handleBack={handleBack} />
                            <ProtectedRoute path="/add_license" component={AddLicense} />
                            <ProtectedRoute path="/view_license" component={ViewLicense} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_license" component={UpdateLicense} handleBack={handleBack} />

                            <ProtectedRoute path="/add_client" component={AddClient} />
                            <ProtectedRoute path="/view_client" component={ViewClient} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_client" component={UpdateClient} handleBack={handleBack} />
                            <ProtectedRoute path="/add_agreement" component={AddAgreement} />
                            <ProtectedRoute path="/view_agreement" component={ViewAgreement} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_agreement" component={UpdateAgreement} handleBack={handleBack} />
                            <ProtectedRoute path="/add_strength" component={AddStrength} />
                            <ProtectedRoute path="/view_strength" component={ViewStrength} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_strength" component={UpdateStrength} handleBack={handleBack} />
                            <ProtectedRoute path="/show_class" component={ShowClass} />

                            <ProtectedRoute path="/add_ticket" component={AddTicket} />
                            <ProtectedRoute path="/view_ticket" component={ViewTicket} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_ticket" component={UpdateTicket} handleBack={handleBack} />
                            <ProtectedRoute path="/show_ticket" component={ShowTicket} />

                            <ProtectedRoute path="/add_head" component={AddHead} />
                            <ProtectedRoute path="/view_head" component={ViewHead} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_head" component={UpdateHead} handleBack={handleBack} />
                            <ProtectedRoute path="/add_ledger" component={AddLedger} />
                            <ProtectedRoute path="/view_ledger" component={ViewLedger} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_ledger" component={UpdateLedger} handleBack={handleBack} />
                            <ProtectedRoute path="/add_purchase" component={AddPurchase} />
                            <ProtectedRoute path="/view_purchase" component={ViewPurchase} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_purchase" component={UpdatePurchase} handleBack={handleBack} />
                            <ProtectedRoute path="/add_sales" component={AddSales} />
                            <ProtectedRoute path="/view_sales" component={ViewSales} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_sales" component={UpdateSales} handleBack={handleBack} />
                            <ProtectedRoute path="/add_credit_note" component={AddCreditNote} />
                            <ProtectedRoute path="/view_credit_note" component={ViewCreditNote} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_credit_note" component={UpdateCreditNote} handleBack={handleBack} />
                            <ProtectedRoute path="/add_debit_note" component={AddDebitNote} />
                            <ProtectedRoute path="/view_debit_note" component={ViewDebitNote} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_debit_note" component={UpdateDebitNote} handleBack={handleBack} />
                            <ProtectedRoute path="/add_payment" component={AddPayment}/>
                            <ProtectedRoute path="/view_payment" component={ViewPayment} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_payment" component={UpdatePayment} handleBack={handleBack} />
                            <ProtectedRoute path="/add_receipt" component={AddReceipt}/>
                            <ProtectedRoute path="/view_receipt" component={ViewReceipt} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_receipt" component={UpdateReceipt} handleBack={handleBack} />
                            <ProtectedRoute path="/add_delivery_note" component={AddDeliveryNote} />
                            <ProtectedRoute path="/view_delivery_note" component={ViewDeliveryNote} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_delivery_note" component={UpdateDeliveryNote} handleBack={handleBack} />
                            <ProtectedRoute path="/add_receipt_note" component={AddReceiptNote} />
                            <ProtectedRoute path="/view_receipt_note" component={ViewReceiptNote} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_receipt_note" component={UpdateReceiptNote} handleBack={handleBack} />
                            <ProtectedRoute path="/add_others" component={AddOthers} />
                            <ProtectedRoute path="/view_others" component={ViewOthers} handleUpdate={handleUpdate} handleBack={handleBack} />
                            <ProtectedRoute path="/update_others" component={UpdateOthers} handleBack={handleBack} />
                            <ProtectedRoute path="/show_ledger" component={ShowLedger} handleUpdate={handleUpdate} handleBack={handleBack} />
                            
                            <ProtectedRoute path = "/add_category" component={AddCategory}/>
                            <ProtectedRoute path = "/view_category" component={ViewCategory} handleUpdate = {handleUpdate} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/update_category" component={UpdateCategory} handleBack = {handleBack}/>  
                            <ProtectedRoute path = "/add_item" component={AddItem}/>       
                            <ProtectedRoute path = "/view_item" component={ViewItem} handleUpdate = {handleUpdate} handleBack = {handleBack}/>  
                            <ProtectedRoute path = "/update_item" component={UpdateItem} handleBack = {handleBack}/> 

                            <ProtectedRoute path = "/add_stock" component={AddStock}/>     
                            <ProtectedRoute path = "/view_stock" component={ViewStock} handleUpdate = {handleUpdate} handleBack = {handleBack}/> 
                            <ProtectedRoute path = "/update_stock" component={UpdateStock} handleBack = {handleBack}/> 
                            <ProtectedRoute path = "/stock_summary" component={StockSummary} handleUpdate = {handleUpdate} handleBack = {handleBack}/> 

                            <ProtectedRoute path = "/add_purchase_order" component={AddPurchaseOrder}/>
                            <ProtectedRoute path = "/view_purchase_order" component={ViewPurchaseOrder} handleUpdate = {handleUpdate} handleBack = {handleBack}/> 
                            <ProtectedRoute path = "/update_purchase_order" component={UpdatePurchaseOrder} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/add_proposal_template" component={AddProposalTemplate}/>
                            <ProtectedRoute path = "/add_proposal" component={AddProposal}/>
                            <ProtectedRoute path = "/view_proposal" component={ViewProposal} handleUpdate = {handleUpdate} handleBack = {handleBack}/> 
                            <ProtectedRoute path = "/update_proposal" component={UpdateProposal} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/add_quotation" component={AddQuotation}/>
                            <ProtectedRoute path = "/view_quotation" component={ViewQuotation} handleUpdate = {handleUpdate} handleBack = {handleBack}/> 
                            <ProtectedRoute path = "/update_quotation" component={UpdateQuotation} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/view_quotation_items" component={ViewQuotationItems} handleUpdate = {handleUpdate} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/update_quotation_items" component={UpdateQuotationItems} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/add_letter_despatch" component={AddLetterDespatch}/>
                            <ProtectedRoute path = "/view_letter_despatch" component={ViewLetterDespatch} handleUpdate = {handleUpdate} handleBack = {handleBack}/> 
                            <ProtectedRoute path = "/update_letter_despatch" component={UpdateLetterDespatch} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/add_letter_received" component={AddLetterReceived}/>
                            <ProtectedRoute path = "/view_letter_received" component={ViewLetterReceived} handleUpdate = {handleUpdate} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/update_letter_received" component={UpdateLetterReceived} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/show_template" component={ShowTemplate}/>

                            <ProtectedRoute path = "/view_cheque" component={ViewCheque}/> 

                            <ProtectedRoute path = "/add_task" component={AddTask}/>
                            <ProtectedRoute path = "/view_task" component={ViewTask} handleUpdate = {handleUpdate} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/update_task" component={UpdateTask} handleBack = {handleBack}/> 
                            <ProtectedRoute path = "/sticky_note" component={StickyNote}/>

                            <ProtectedRoute path = "/add_service_invoice" component={AddServiceInvoice}/>
                            <ProtectedRoute path = "/view_service_invoice" component={ViewServiceInvoice} handleUpdate = {handleUpdate} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/show_service_invoice" component={ShowServiceInvoice}/>

                            <ProtectedRoute path = "/add_duty" component={AddDuty}/>
                            <ProtectedRoute path = "/view_duty" component={ViewDuty} handleUpdate = {handleUpdate} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/update_duty" component={UpdateDuty} handleBack = {handleBack}/>
                            <ProtectedRoute path = "/show_duty" component={ShowDuty}/>
                            <ProtectedRoute path = "/duty_summary" component={DutySummary}/>

                            <ProtectedRoute path = "/bill_goods" component={BillGoods}/>
                            <ProtectedRoute path = "/bill_service" component={BillService}/>

                            <ProtectedRoute path = "/documents" component={Documents}/>

                            <ProtectedRoute path="/add_user" component={AddUser} />  
                            <ProtectedRoute path="/backup" component={Backup} /> 
                            <ProtectedRoute path = "/fiscal_setup" component={FiscalSetup}/>     
                            <ProtectedRoute path = "/permission" component={Permissions}/> 

                            <ProtectedRoute path = "/salary_list" component={SalaryList}/> 
                            <ProtectedRoute path = "/salary_request" component={SalaryRequest}/>            
                        </Switch>
                    </div>
                </Router>
            </div>
        </DataContext.Provider>
    );
}

export default Home;
