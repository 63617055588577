import { useState, useEffect, useContext } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import GetIndex from '../Parent/GetIndex';
import { useHistory } from 'react-router-dom';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';
import Get from '../HTTPRequest/Get';
import { toast } from 'react-toastify';

const UpdateWorkForm = (props) => {
    // DECLARING STATES...        

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [staff, setStaff] = useState([]);
    const [location, setLocation] = useState([]);
    const initialValues =
    {
        id: data_context.update_data['id'],
        staff: '',
        location: ''
    };
    const [values, setValues] = useState(initialValues);
    const history = useHistory();

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=work';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {
                    let staff = response.response_payload[0] || [];
                    let location = response.response_payload[1] || [];

                    setStaff(staff);;
                    setLocation(location);

                    setValues({
                        ...values, staff: data_context.update_data['staff_id'],
                        location: data_context.update_data['location_id']
                    });

                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);

            const payload = { id: values.id, staff_id: values.staff, location_id: values.location };
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/work', payload, headers).then(response => {
                setLoading(false);

                if((response.status === 1) && (response.response_payload['result'] === 'success')){
                    toast.success(response?.message);
                    let payload = { id: values.id, staff_id: values.staff, name: staff.name[GetIndex(staff.id, values.staff)], 
                                    location_id: values.location, address: location.address[GetIndex(location.id, values.location)] };
                    let data = data_context.view_data.table_rows;
                    let index = GetKey(data, payload);
                    payload = { ...payload, sno: data[index]['sno'] };
                    data = ({ ...data, [index]: payload });
                    const row_values = Object.values(data);

                    let view_data = data_context.view_data;
                    view_data = ({ ...view_data, table_rows: row_values });
                    props.handleBack(view_data);
                } else {
                    toast.error(response?.message);
                }
            });
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_work");
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.id) {
            errors.id = "*Work ID is required";
        }

        if (!values.staff) {
            errors.staff = "*Staff is required";
        }

        if (!values.location) {
            errors.location = "*Location is required";
        }

        return errors;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, handleBack, values, errors, loading, staff, location };

}

export default UpdateWorkForm;