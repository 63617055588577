import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import { Row } from 'react-data-grid';
import Get from '../HTTPRequest/Get';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ViewStatementForm = () => {
    // DECLARING STATES...   

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [item, setItem] = useState([]);
    const initialValues = { recipient_id: '', name: '' };
    const [values, setValues] = useState(initialValues);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false, 1: false });
    const [table_rows, setTableRows] = useState([]);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=recipient';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let recipient = response.response_payload[0] || [];
                        const newItems = recipient.id.map((_, i) => ({
                            id: recipient.id[i],
                            name: recipient.name[i]
                        }));
                    
                        setItem(newItems);
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // ON SELECTION...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, recipient_id: item.id, name: item.name });
        }        
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        setMessage();
        setTableRows([]);

        if (values.recipient_id) {
            setLoading({ ...loading, 0: true });
            let endpoint = user_context.url + '/expense?id=' 
                            + values.recipient_id + '&fiscal=' 
                            + data_context['fiscal'] 
                            + '&filter_key=unsorted&route=view_statement';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Get(endpoint, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage(response.message);

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;

                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            if (payload[i]['date']) {
                                let date = CreateDate(payload[i]['date']);
                                date = FormatLocalDate(date);
                                payload[i]['date'] = date;
                            }
                            data[i] = payload[i];
                        }

                        setTableRows(data);
                    }
                }
            });
        }
        else {
            setMessage("Select a recipient");
        }
    }

    // SORTING...

    const handleSort = async () => {
        setMessage();
        setTableRows([]);

        if (values.recipient_id) {
            setLoading({ ...loading, 1: true });
            let endpoint = user_context.url + '/expense?id=' 
                            + values.recipient_id + '&fiscal=' 
                            + data_context['fiscal'] 
                            + '&filter_key=sorted&route=view_statement';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Get(endpoint, headers).then(response => {
                setLoading({ ...loading, 1: false });
                setMessage(response.message);

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;

                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            if (payload[i]['date']) {
                                let date = CreateDate(payload[i]['date']);
                                date = FormatLocalDate(date);
                                payload[i]['date'] = date;
                            }
                            data[i] = payload[i];
                        }

                        setTableRows(data);
                    }
                }
            });
        }
        else {
            setMessage("Select a recipient");
        }
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {

        return (
            <div>
                <Row {...props} />
            </div>

        );
    }

    // GENERATING PDF FROM ARRAY OF OBJECTS...

    const generatePDF = () =>{
        const doc = new jsPDF();
        const headers = [ 'S.No', 'Date', 'Particulars', 'Credit', 'Debit'];
        const tableData = table_rows.map(obj => Object.values(obj));
        doc.autoTable({
            head: [headers],
            body: tableData,
        });        
        doc.save(`${values.name}.pdf`);
    }

    // RETURNING VALUES...

    return {
        handleSubmit, handleChange, rowRenderer, handleSort,
        handleSelect, generatePDF,message, values, loading, 
        table_rows, item, 
    };
}

export default ViewStatementForm;