import { useState, useContext, useEffect } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import { useHistory } from 'react-router-dom';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';
import Get from '../HTTPRequest/Get';
import { toast } from 'react-toastify';

const UpdateLocationForm = (props) => {
    // DECLARING STATES...

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    let initialValues =
    {
        id: data_context.update_data['id'],
        latitude: data_context.update_data['latitude'],
        longitude: data_context.update_data['longitude'],
        name: data_context.update_data['name'],
        address: data_context.update_data['address'],
        time_in: data_context.update_data['time_in'] || '',
        time_out: data_context.update_data['time_out'] || '',
        client_id: data_context.update_data['client_id'] || ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [item, setItem] = useState([]);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=ledger';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) &&(response.response_payload['result'] === 'success')) {                    
                        
                    let client = response.response_payload[1] || []; 
                    const newItems = client.id.map((_, i) => ({
                        id: client.id[i],
                        name: client.address[i] 
                    }));
                
                    setItem(newItems);                                          
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }


    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/location', payload, headers).then(response => {
                setLoading(false); 
            
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {
                    toast.success(response?.message);  
                    let data = data_context.view_data.table_rows;
                    let index = GetKey(data, payload);
                    payload = { ...payload, sno: data[index]['sno'] };
                    data = ({ ...data, [index]: payload });
                    const row_values = Object.values(data);

                    let view_data = data_context.view_data;
                    view_data = ({ ...view_data, table_rows: row_values });
                    props.handleBack(view_data);
                } else {
                    toast.error(response?.message);
                }
               
            });
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_location");
    }

    // ON SELECTION...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, client_id: item.id });
        }        
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex = /^\d+\.\d{2}$/;

        if (!values.id) {
            errors.id = "*Location ID is required";
        }

        if (!values.latitude) {
            errors.latitude = "*Latitude is required";
        }
        else if (!regex.test(values.latitude)) {
            errors.latitude = "*Decimal with 2 places is required";
        }

        if (!values.longitude) {
            errors.longitude = "*Longitude is required";
        }
        else if (!regex.test(values.longitude)) {
            errors.longitude = "*Decimal with 2 places is required";
        }

        if (!values.name) {
            errors.name = "*Name is required";
        }

        if (!values.address) {
            errors.address = "*Address is required";
        }

        if (!values.time_in) {
            errors.time_in = "*Time In is required";
        }

        if (!values.time_out) {
            errors.time_out = "*Time Out is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            latitude: values.latitude,
            longitude: values.longitude,
            name: values.name,
            address: values.address,
            time_in: values.time_in,
            time_out: values.time_out,
            client_id: values.client_id || null
        };

        return data;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, handleBack, handleSelect, values, errors, loading, item };

}

export default UpdateLocationForm;