import React from "react";
import UpdateItemForm from "./UpdateItemForm";
import ItemImage from "./ItemImage";
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const UpdateItem = (props) => {
    // DECLARING STATES...    

    const { ...destruct } = UpdateItemForm(props);

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR ITEM DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;ITEM</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Item ID:</label>
                        <input type="text" autoComplete='off' className="input" name="id" value={destruct.values.id} onChange={destruct.handleChange} disabled></input>
                        {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Category:</label>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={destruct.item}
                            getOptionLabel={(option) => option ? option.name : ''} 
                            isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Search Party..." />                                       
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                        <br />
                                        <span>{`${option.name}`}</span>
                                    </div>
                                </li>
                            )}
                            value={destruct.item.find(option => option.name === destruct.values.category) || null}
                            onChange={(event, value) => destruct.handleSelect(value)}
                            sx={{
                                width: '90%',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                "& .MuiInputBase-root": {
                                    fontFamily: 'sans-serif',
                                    height: 45,
                                    fontSize: '15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignContent: 'center'
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: 0
                                },
                                "&:hover fieldset": {
                                    border: 0
                                },
                                backgroundColor: '#FFFFFF',
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: '1px solid #9ecaed',
                                    boxShadow: '0 0 10px #9ecaed',
                                },
                            }}
                        />
                        {destruct.errors.category_id && <p className="error">{destruct.errors.category_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Name:</label>
                        <input type="text" autoComplete='off' className="input" name="name" value={destruct.values.name} onChange={destruct.handleChange} ></input>
                        {destruct.errors.name && <p className="error">{destruct.errors.name}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Manufacturer:</label>
                        <input type="text" autoComplete='off' className="input" name="manufacturer" value={destruct.values.manufacturer} onChange={destruct.handleChange} ></input>
                        {destruct.errors.manufacturer && <p className="error">{destruct.errors.manufacturer}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Model:</label>
                        <input type="text" autoComplete='off' className="input" name="model" value={destruct.values.model} onChange={destruct.handleChange} ></input>
                        {destruct.errors.model && <p className="error">{destruct.errors.model}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">HSN:</label>
                        <input type="text" autoComplete='off' className="input" name="hsn" value={destruct.values.hsn} onChange={destruct.handleChange} ></input>
                        {destruct.errors.hsn && <p className="error">{destruct.errors.hsn}</p>}
                    </div>

                    <div className="location-formGroup">
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="tax-input-long" name="cgst" value={destruct.values.cgst} placeholder="CGST%" onChange={destruct.handleChange}></input>
                            <input type="text" autoComplete='off' className="tax-input-long" name="sgst" value={destruct.values.sgst} placeholder="SGST%" onChange={destruct.handleChange}></input>
                            <input type="text" autoComplete='off' className="tax-input-long" name="igst" value={destruct.values.igst} placeholder="IGST%" onChange={destruct.handleChange}></input>
                        </div>
                        {destruct.errors.tax && <p className="error">{destruct.errors.tax}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Unit:</label>
                        <input type="text" autoComplete='off' className="input" name="unit" value={destruct.values.unit} onChange={destruct.handleChange} ></input>
                        {destruct.errors.unit && <p className="error">{destruct.errors.unit}</p>}
                    </div>

                    <div className="item-viewGroup">
                        <ItemImage image={destruct.image} placeholder={destruct.placeholder_image} width={destruct.dimension.width} height={destruct.dimension.height} />
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Image:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name='image_url' value={destruct.values.image_url} readOnly></input>
                            <button className="tax-button" type="button" onClick={destruct.handleDialog}><CloudUploadOutlinedIcon /></button>
                        </div>
                        {destruct.errors.image_url && <p className="error">{destruct.errors.image_url}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading[0]}>Update</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>

                </form>
            </div>
            {/* UPLOAD DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Image:</label>
                        <input type="text" autoComplete='off' className="input" name='image_url' value={destruct.values.image_url} readOnly></input>
                        <progress className="form-progress" value={destruct.progress[0]} max="100" />
                        <span className="progressText">{destruct.progress[0] + "%"}</span>
                        {destruct.image_errors && <p className="message">{destruct.image_errors}</p>}
                        {destruct.message[1] && <p className="message">{destruct.message[1]}</p>}

                        <input id="image_file" type="file" accept="image/*" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="image_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={destruct.handleUpload} disabled={destruct.loading[1]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleClearImage}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                    </div>
                </div>
            </ReactDialogBox>}
        </div>
    );
}

export default UpdateItem;