import React, { useContext } from "react";
import UserContext from '../Parent/UserContext';
import "./topbar.css";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useMediaQuery } from 'react-responsive';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from '../Images/topbar-logo.png';

const Topbar = (props) => {

    // DECLARING STATES...    

    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });
    const user_context = useContext(UserContext);
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/topbar');
    

    // REGISTERING AND SENDING SELECT VALUES...

    const handleChange = e => {
        props.setSelectedDB(e.target.value);
    }

    // MAIN FUNCTION...

    return (

        // TOPBAR COMPONENT...

        <div className="topbar">
            <div className="topbar-wrapper">

                {/* TOPBAR LEFT... */}

                <div className="topbar-left">
                    <div className="topbar-logo">
                        {!isMobile && <LazyLoadImage className="topbar-logoIcon" src={Logo} alt="Not found" />}
                        {isMobile && <MenuOutlinedIcon className="topbar-logoIcon" onClick={() => props.setOpen(!props.open)} />}
                        <span className="topbar-logoTitle">Smart&nbsp;ERP&nbsp;4.0</span>
                    </div>
                </div>

                {/* TOPBAR RIGHT... */}

                <div className="topbar-right">
                    <div className="topbar-formGroup">
                        {!isMobile && <label className="topbar-label">Financial&nbsp;Year:</label>}
                        <select 
                            className="topbar-select" 
                            value={props.default_db} 
                            onChange={handleChange} 
                            disabled={permissions && permissions[2] === '1' ? false : true}
                        >
                            {props.database.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Topbar;