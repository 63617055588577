import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import Post from '../HTTPRequest/Post';

const AddClientForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const states = ['Andaman and Nicobar', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh', 'Chhattisgarh',
        'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh',
        'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Lakshadweep', 'Madhya Pradesh', 'Maharashtra',
        'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Orissa', 'Puducherry', 'Punjab', 'Rajasthan', 'Sikkim',
        'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'];
    const initialValues =
    {
        name: '',
        address1: '',
        address2: '',
        district: '',
        state: states[27],
        pincode: '',
        principal: '',
        director: '',
        phone: '',
        email: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateForm(values);
        setErrors(errors);
        setMessage();

        if (Object.keys(errors).length === 0) {
            setLoading(true);

            let payload = createPayload(values);
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Post(user_context.url + '/client', payload, headers).then(response => {
                setLoading(false);
                setMessage(response.message);
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_number = /^[0-9]*$/;
        let regex_mail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

        if (!values.name) {
            errors.name = "*Name is required";
        }

        if (!values.address2) {
            errors.address2 = "*Address Line 2 is required";
        }

        if (!values.district) {
            errors.district = "*District is required";
        }

        if (!values.pincode) {
            errors.pincode = "*Pincode is required";
        }

        if (!values.principal) {
            errors.principal = "*Principal is required";
        }

        if (!values.phone) {
            errors.phone = "*Phone Number is required";
        }
        else if (!regex_number.test(values.phone)) {
            errors.phone = "*Only digits allowed";
        }

        if (!values.email) {
            errors.email = "*Email is required";
        }
        else if (!regex_mail.test(values.email)) {
            errors.email = "*Not a valid email id";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            name: values.name,
            address1: values.address1,
            address2: values.address2,
            district: values.district,
            state: values.state,
            pincode: values.pincode,
            principal: values.principal,
            director: values.director,
            phone: values.phone,
            email: values.email
        };

        return data;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, values, errors, message, loading, states };


}

export default AddClientForm;