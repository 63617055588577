import { useState, useEffect, useContext } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import Get from '../HTTPRequest/Get';
import Delete from '../HTTPRequest/Delete';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-data-grid';
import Download from '../HTTPRequest/Download';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import CreateBlob from '../Parent/CreateBlob';
import GetIndex from '../Parent/GetIndex';
import { toast } from 'react-toastify';

const ViewStaffForm = (props) => {
    // DECLARING STATES...  
 
    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);    
    const [columns] = useState(['All', ...user_context.user_data[3]['staff']]);
    const [values, setValues] = useState({ key: data_context.view_data['key'] || '', selected_column: data_context.view_data['selected_column'] || 'All' });
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false, 3: false});
    const [table_rows, setTableRows] = useState(data_context.view_data['table_rows'] || []);
    const [selected_row, setSelectedRow] = useState
    (
        data_context.view_data['selected_row'] !== undefined && 
        data_context.view_data['selected_row'] !== null 
        ? data_context.view_data['selected_row'] : -1
    );
    const [progress, setProgress] = useState({ 0: 0 });
    const history = useHistory();
    const [checked, setChecked] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [card_dialog, setCardDialog] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [errors, setErrors] = useState({ 0: '' });
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/view_staff');
    const base_url = user_context.url;
    const url = base_url.replace("/smarterpweb-api", "");
    const [designation, setDesignation] = useState([]);
    const [card_data, setCardData] = useState({});

    // FETCHING DESIGNATIONS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=designation';
            const headers = { 
                                'Accept': 'application/json', 
                                'Content-Type': 'application/json;charset=UTF-8', 
                                'Authorization': `Bearer ${user_context.user_data[1]}` 
                            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                   
                    let designation = response.response_payload[0] || [];                       
                    setDesignation(designation);                     
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        setTableRows([]);
        setSelectedRow();
        setLoading({ ...loading, 0: true });

        let endpoint = '';
        values.key.length === 0 
            ? endpoint = user_context.url + '/staffs' 
            : endpoint = user_context.url + '/staff?filter_key=' + values.key + '&field=' + values.selected_column;
        const headers = 
        { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${user_context.user_data[1]}` 
        };

        await Get(endpoint, headers).then(response => {
            setLoading(false);

            if ((response.status === 1) && (response.response_payload['result'] === 'success')) {             
                toast.success(response?.message);   
                let payload = response.response_payload;
                let data_length = (Object.keys(payload).length) - 1;

                let data = ([]);
                for (let i = 0; i < data_length; i++) {
                    let dob = CreateDate(payload[i]['dob']);
                    dob = FormatLocalDate(dob);
                    payload[i]['dob'] = dob;
                    let doj = CreateDate(payload[i]['doj']);
                    doj = FormatLocalDate(doj);
                    payload[i]['doj'] = doj;

                    if (payload[i]['dor'] !== null && payload[i]['dor'] !== '0000-00-00') {
                        let dor = CreateDate(payload[i]['dor']);
                        dor = FormatLocalDate(dor);
                        payload[i]['dor'] = dor;
                    }
                    else {
                        payload[i]['dor'] = null;
                    }

                    data[i] = payload[i];
                }
                setTableRows(data);                
            } else {
                toast.error(response?.message);
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {

        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // HANDLER FOR UPDATE BUTTON...

    const handleUpdate = async () => {
        if (selected_row > -1) {           
            if (checked) {
                setLoading({ ...loading, 2: true });
                setProgress({ ...progress, 0: 0 });
                const options = {
                    headers: 
                    { 
                        'Accept': 'application/json', 
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${user_context.user_data[1]}` 
                    },
                    onDownloadProgress: (progressEvent) => {                        
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress({ ...progress, 0: percent });
                    }
                }

                await Download(user_context.url + '/download?file_image=' + table_rows[selected_row]['photo_url'] + '&file_docs=' + table_rows[selected_row]['docs_url'], options).then(response => {
                    setLoading({ ...loading, 2: false });
                    if (response?.status === 1) {
                        toast.success(response?.message);
                        let view_data = { key: values.key, selected_column: values.selected_column, selected_row: selected_row, table_rows: table_rows };
                        props.handleBack(view_data);
                        let update_data = table_rows[selected_row];
                        update_data['photo'] = response['response_payload']['image'];
                        update_data['docs'] = response['response_payload']['docs'];
                        props.handleUpdate(update_data);
                        history.push("/update_staff");
                    } else {
                        toast.error(response?.message);
                    }
                });
            }
            else {
                let view_data = { key: values.key, selected_column: values.selected_column, selected_row: selected_row, table_rows: table_rows };
                props.handleBack(view_data);
                let update_data = table_rows[selected_row];
                update_data['photo'] = 'no_such_data';
                update_data['docs'] = 'no_such_data';
                props.handleUpdate(update_data);
                history.push("/update_staff");
            }
        }else{
            toast.error("No row selected");
        }    
    }

    // HANDLER FOR DELETE BUTTON...

    const handleDelete = () => {
        setDialog(true);
    }

    // HANDLER FOR DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // HANDLER FOR CONFIRM DELETE...

    const handleConfirm = () => {
        setDialog(!dialog);
        setConfirm(true);
    }

    // PERFORMING DELETE ACTION...

    useEffect(() => {
        const deleteRow = async () => {
            if (confirm) {
                setConfirm(false);

                if (selected_row > -1) {
                    setLoading({ ...loading, 1: true });
                    const headers = { 
                        'Accept': 'application/json', 
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${user_context.user_data[1]}`
                    };
                    await Delete(user_context.url + '/staff?id=' + table_rows[selected_row]['id'], headers).then(response => {
                        setLoading(false);
                        if(response?.status === 1) {
                            toast.success(response?.message);
                            const new_table_rows = table_rows.filter(item => item !== table_rows[selected_row]);
                            setSelectedRow(-1);
                            setTableRows(new_table_rows);
                        } else {
                            toast.error(response?.message);
                        }                       
                    });
                }
                else {
                    toast.error("No row selected");
                }
            }
        }

        deleteRow();

        //eslint-disable-next-line    
    }, [confirm]);

    // HANDLER FOR MERGE BUTTON...

    const handleMerge = () => {
        if (selected_row > -1) {
            let view_data = { key: values.key, selected_column: values.selected_column, selected_row: selected_row, table_rows: table_rows };
            props.handleBack(view_data);
            props.handleUpdate(table_rows[selected_row]);
            history.push("/merge_pdf");
        }
        else {
            toast.error("No row selected");
        }
    }

    // SHOWING ATTACHMENT...

    const handleAttachment = async () =>{
        if (selected_row > -1) {
            setLoading({ ...loading, 3: true });

            const options = {
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${user_context.user_data[1]}`
                },
                onDownloadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress({ ...progress, 0: percent });
                }
            }

            await Download(user_context.url + '/download?file_docs=' + table_rows[selected_row]['docs_url'], options).then(response => {
                setLoading({ ...loading, 3: false });
                if (response?.status === 1 ) {
                    toast.success(response?.message);
                    viewPDF(response.response_payload['docs']);
                } else {
                    toast.error(response?.message);
                }
            });
        } else {
            toast.error("No row selected");
        }
    }

    // VIEWING PDF...

    const viewPDF = (docs) => {
        setLoading({ ...loading, 3: true });
        let errors = validateDocs(docs);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {            
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 3: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 3: false });
                }
            }
        } else {            
            setLoading({ ...loading, 3: false });
        }
    }

    // VALIDATING UPLOADS...

    const validateDocs = (docs) => {
        let errors = {};
        if (!table_rows[selected_row]['docs_url']) {
            errors[0] = "*No attachment found";
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(table_rows[selected_row]['docs_url'])[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors[0] = "*Document is not a valid PDF file";
            }
            else {
                if (docs === 'no_such_data' || !docs) {
                    errors[0] = "*No pdf preview found";
                    setLoading({ ...loading, 3: false });
                }
            }
        }
        return errors;
    }

    // PRINT ID CARD...

    const handleIDCard = () =>{
        if (selected_row > -1) {
            let designation_id = table_rows[selected_row]['designation'];
            let index = GetIndex(designation['id'], designation_id);            

            let values = {
                photo_url: `${url}/assets/${table_rows[selected_row]['photo_url']}`,
                name: table_rows[selected_row]['name'],
                designation: designation['name'][index],
                father: table_rows[selected_row]['father'],
                dob: table_rows[selected_row]['dob'],
                bloodgroup: table_rows[selected_row]['bloodgroup'],
                phone: table_rows[selected_row]['phone'],
                email: table_rows[selected_row]['email'],
                address: table_rows[selected_row]['address1']  +', '+ 
                            table_rows[selected_row]['address2'] +', '+ 
                            table_rows[selected_row]['district'] +', '+
                            table_rows[selected_row]['state'] +', '+  
                            table_rows[selected_row]['pincode']
            }
            
            setCardData(values);
            handleCardDialog();
        }else {
            toast.error("No row selected");
        }
    }

    // HANDLER FOR DIALOG BUTTON...

    const handleCardDialog = () => {
        setCardDialog(!card_dialog);
    }   

    // RETURNING VALUES...

    return { 
        handleSubmit, handleChange, onRowClick, handleDelete, 
        handleUpdate, setChecked, handleDialog, handleConfirm, 
        handleMerge, rowRenderer, handleAttachment, handleIDCard,
        handleCardDialog, columns, values, loading, 
        table_rows, progress, checked, dialog, errors, permissions, 
        card_dialog, card_data};
}

export default ViewStaffForm;