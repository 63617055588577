import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Get from '../HTTPRequest/Get';
import { Row } from 'react-data-grid';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import FormatLocalDate from '../Parent/FormatLocalDate';

const BillGoodsForm = () => {

    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const current = new Date();
    const initialValues =
    {
        date: '',
        client_id: '',
        transportation: '',
        driver: '',
        departure: current.toLocaleTimeString("en-US", { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" }),
        item_id: '',
        quantity: '',
        rate: '',  
        type: 'Goods Invoice Request'   
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [item, setItem] = useState([]);
    const [client, setClient] = useState([]);
    const [selected_row, setSelectedRow] = useState();
    const [table_rows, setTableRows] = useState([]);

    // SETTING UP DROPDOWNS...

    useEffect(() => { 

        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=bill_goods';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                console.log(response);
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {

                    let item = response.response_payload[0] || [];

                    let newItems = item.id.map((_, i) => ({
                        id: item.id[i],
                        name: item.name[i],
                        manufacturer: item.manufacturer[i],
                        model: item.model[i],
                        unit: item.unit[i],
                        hsn: item.hsn[i],
                        rate: item.rate[i]
                    }));

                    setItem(newItems);

                    let client = response.response_payload[1] || [];
                    newItems = client.id.map((_, i) => ({
                        id: client.id[i],
                        name: client.address[i],
                        institution: client.institution[i]
                    }));

                    setClient(newItems);
                }
            });
        }

        setSelect();

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // ON SELECTION OF CLIENT...

    const selectClient = (item) => {
        if (item) {
            setValues({ ...values, client_id: item.id });
        } else {
            setValues({ ...values, client_id: '' });
        }
    }

    // ON SELECTION OF ITEM...

    const selectItem = (item) => {
        if (item) {
            setValues({ ...values, item_id: item.id });
        } else {
            setValues({ ...values, item_id: '' });
        }
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
  
        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            if(table_rows && table_rows.length > 0) {
                generatePDF();
            } else {
                toast.error("No Item added");
            }
        }
    }

    // VALIDATE FORM...

    const validateForm = () => {
        let errors = {};

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.transportation) {
            errors.transportation = "*Transportation is required";
        }

        if (!values.departure) {
            errors.departure = "*Departure is required";
        }

        if (!values.client_id) {
            errors.client_id = "*Client is required";
        }
        
        if (!values.driver) {
            errors.driver = "*Driver is required";
        }

        if (!values.type) {
            errors.type = "*Type is required";
        }

        return errors;
    }

    // GENERATING PDF...

    const generatePDF = (ref_no) => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'A5',
        });
    
        doc.setFontSize(11);
        const yPosition = 10; 

        
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");

        const titleText = values.type.toUpperCase();
        const textWidth = doc.getStringUnitWidth(titleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const xPosition = (doc.internal.pageSize.width - textWidth) / 2;         

        doc.text(titleText, xPosition, yPosition);

        const lineHeight = 0.1; 
        const lineY = yPosition + 1; 
        doc.setLineWidth(lineHeight);
        doc.line(xPosition, lineY, xPosition + textWidth, lineY);

        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");  
        
        const clientData = client.find(element => element.id === values.client_id);
        doc.text('Party: ' + clientData?.institution + ', ' +clientData?.name, 15, yPosition+10); 
        doc.text('Date of Delivery: ' + FormatLocalDate(values.date), 15, yPosition+15);
        doc.text('Departure: ' + formatTime(values.departure), 15, yPosition+20);
        doc.text('Transportation: ' + values.transportation, 15, yPosition+25);
        doc.text('Driver: ' + values.driver, 15, yPosition+30);

        const headers = ['SNo', 'Name', 'HSN', 'Manufacturer', 'Model', 'Quantity', 'Rate','Unit'];
        const data = table_rows.map(obj => {
            const newObj = { ...obj }; 
            delete newObj.id; 
            return newObj; 
        });
        doc.autoTable({
            startY: yPosition + 35,
            theme: 'grid',
            head: [headers],
            body: data.map(row => Object.values(row)),
            rowPageBreak: 'avoid',
            styles: {
                cellPadding: 2,
                cellWidth: 'auto',
                valign: 'middle',
                halign: 'center', 
                lineWidth: 0.1,
                lineColor: [0, 0, 0], 
              },  

              headStyles: {
                fillColor: [48, 51, 78],
                textColor: [255, 255, 255], 
              },
              bodyStyles: {
                fillColor: [255, 255, 255], 
                textColor: [0, 0, 0]
              },
        });
        
        doc.save(`${clientData?.name}.pdf`);
    };

    // FUNCTION TO FORMAT TIME...

    const formatTime = (time24) => {
        const [hours, minutes] = time24.split(':');
        let formattedTime = '';
    
        if (+hours === 0) {
          formattedTime = `12:${minutes} AM`;
        } else if (+hours === 12) {
          formattedTime = `12:${minutes} PM`;
        } else if (+hours > 12) {
          formattedTime = `${+hours - 12}:${minutes} PM`;
        } else {
          formattedTime = `${+hours}:${minutes} AM`;
        }
    
        return formattedTime;
    };

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>
        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // ADDING ITEM TO DATAGRID...

    const addItem = () => {
        let errors = validateItem(values);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            const data = item.find(data => data.id === values.item_id);
            const sno = table_rows.length + 1;
            const new_row_data =
            {
                sno: sno,
                id: values.item_id,
                name: data.name,
                hsn: data.hsn,
                manufacturer: data.manufacturer,
                model: data.model,
                quantity: values.quantity,
                rate: values.rate,
                unit: data.unit
            }
            const updated_rows = [...table_rows, new_row_data];
            setTableRows(updated_rows);
        }
    }

    
    const validateItem = (values) => {
        let errors = {};
        let regex_number = /^[0-9]*$/;
        let regex_decimal = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

        if (!values.item_id) {
            errors.item_id = "*Item ID is required";
        }

        if (!values.quantity) {
            errors.quantity = "*Quantity is required";
        } else if (!regex_number.test(values.quantity)) {
            errors.quantity = "*Only digits allowed";
        }

        if(!values.rate) {
            errors.rate = "*Rate is required";
        } else if (!regex_decimal.test(values.rate)) {
            errors.quantity = "*Only decimals allowed";
        }

        return errors;
    }

    // DELETING ITEM FROM DATAGRID...

    const handleDelete = () => {
        if (selected_row > -1) {
            const updatedRows = [...table_rows];
            updatedRows.splice(selected_row, 1);
            setTableRows(updatedRows);
            reorderArray(updatedRows);
        } else {
            toast.error("No row selected");
        }
    }

    // FUNCTION TO REARRANGE SNO AFTER DELETION...

    const reorderArray = (rows) => {
        rows.forEach((row, index) => {
            row.sno = index + 1;
        });
        setTableRows([...rows]);
    };

    // RETURNING VALUES...

    return {
        handleChange,  
        selectClient,  
        handleSubmit,  
        onRowClick,
        rowRenderer, 
        selectItem,
        addItem,
        handleDelete,
        values,
        errors,
        client,
        table_rows,
        item
    };

}   

export default BillGoodsForm;