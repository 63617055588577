import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const CustomAutocomplete = ({data, placeholder, selectValue, disabled, selected_value }) =>{ 
    return(
        <>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={data}
                getOptionLabel={(option) => option ? option.name : ''} 
                isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                renderInput={(params) => (
                    <TextField {...params} placeholder={placeholder} />                                       
                )}
                renderOption={(props, option) => (
                    <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                            <br />
                            <span>{`Name: ${option.name}`}</span>                                        
                        </div>
                    </li>
                )}
                value={data.find(option => option.id === selected_value) || null}
                onChange={(event, value) => selectValue(value)}                
                sx={{
                    width: '90%',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    "& .MuiInputBase-root": {
                        fontFamily: 'sans-serif',
                        height: 45,
                        fontSize: '15px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center'
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: 0
                    },
                    "&:hover fieldset": {
                        border: 0
                    },
                    backgroundColor: '#FFFFFF',
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: '1px solid #9ecaed',
                        boxShadow: '0 0 10px #9ecaed',
                    },
                }}
                disabled={disabled}
            />
        </>
    );
}

export default CustomAutocomplete;