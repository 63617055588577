import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Get from '../HTTPRequest/Get';
import { toast } from 'react-toastify';
import FormatDate from '../Parent/FormatDate';
import Post from '../HTTPRequest/Post';

const AddLetterDespatchForm = () => {

    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);

    const initialValues =
    {
        date: '',
        letter_type: '',
        party: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({ 0: false });   
    const [letter, setLetter] = useState(); 

    // SETTING UP DROPDOWNS...

    useEffect(() => {

        const fetchLetterType = async () => {
            let endpoint = user_context.url + '/dropdown?route=letter_type';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {    

                    let data = response?.response_payload[0];
                    setLetter(data);
                    setValues({ ...values, letter_type: data?.id[0]});
                }
            });
        }

        fetchLetterType();

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {        
       
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Post(user_context.url + '/letter_despatch', payload, headers).then(response => {    
                setLoading({ ...loading, 0: false });
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                    toast.success(response?.message);                                        
                } else {
                    toast.error(response?.message);
                }
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if(!values.letter_type) {
            errors.letter_type = "*Letter Type is required";
        }

        if(!values.party) {
            errors.party = "*Party is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const payload =
        {
            date: FormatDate(values.date),
            letter_type: values.letter_type,
            party: values.party,
            fiscal: data_context['fiscal']
        };

        return payload;
    }

    // RETURNING VALUES...

    return {
        handleChange,
        handleSubmit,       
        values,
        errors,
        loading,
        letter
    };

}

export default AddLetterDespatchForm;