import { useState, useContext, useEffect } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Put from '../HTTPRequest/Put';
import Get from '../HTTPRequest/Get';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import FormatDate from '../Parent/FormatDate';
import CreateLocalDate from '../Parent/CreateLocalDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import CreateDate from '../Parent/CreateDate';
import GetKey from '../Parent/GetKey';

const UpdateDutyForm = (props) => {

    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const statuses = ['Active','Cancelled'];
    const initialValues = 
    { 
        id: data_context.update_data['id'],
        date: CreateLocalDate(data_context.update_data['date']),
        staff_id: data_context.update_data['staff_id'],
        ticket_id: data_context.update_data['ticket_id'],
        distance: data_context.update_data['distance'],
        status: data_context.update_data['status'],
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({ 0: false });   
    const [item, setItem] = useState([]);
    const history = useHistory();

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=staff';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) &&(response.response_payload['result'] === 'success')) {                    
                        
                    let staff = response.response_payload[0] || []; 
                    const newItems = staff.id.map((_, i) => ({
                        id: staff.id[i],
                        name: staff.name[i] 
                    }));
                
                    setItem(newItems);                                          
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

     // ON SELECTION...

     const handleSelect = (item) => {
        if(item){
            setValues({ ...values, staff_id: item.id });
        } else {
            setValues({ ...values, staff_id: '' });
        }        
    } 

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();        
        let errors = validateForm(values);
        setErrors(errors);        

        if (Object.keys(errors).length === 0) {
            setLoading({...loading, 0 : true});

            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/duty', payload, headers).then(response => {
                setLoading(false);
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                    toast.success(response?.message); 
                    let payload = 
                        { 
                            id: values.id,
                            date: FormatDate(values.date),
                            staff_id: values.staff_id,
                            ticket_id: values.ticket_id,
                            distance: values.distance,
                            status: values.status,
                            fiscal: data_context['fiscal']
                        };
                        let data = data_context.view_data.table_rows;
                        let index = GetKey(data, payload); 
                        const filtered_staff = item.filter(item => item.id === values.staff_id);

                        payload = { ...payload, sno: data[index]['sno'] };
                        payload = { ...payload, date: FormatLocalDate(CreateDate(payload['date'])) };
                        payload = { ...payload, name: filtered_staff[0]['name'] };

                        data = ({ ...data, [index]: payload });
                        const row_values = Object.values(data);
                        let view_data = data_context.view_data;
                        view_data = ({ ...view_data, table_rows: row_values });
                        props.handleBack(view_data);                       
                } else {
                    toast.error(response?.message);
                }                
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        const regex_decimal = /^[-+]?\d*\.?\d+$/;        

        if (!values.id) {
            errors.id = "*ID is required";
        }

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.staff_id) {
            errors.staff_id = "*Staff is required";
        }

        if (!values.ticket_id) {
            errors.ticket_id = "*Ticket ID is required";
        }

        if (!values.distance) {
            errors.distance = "*Distance is required";
        } else if (!regex_decimal.test(values.distance)) {
            errors.distance = "*Only decimals allowed";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const payload =
        {
            id: values.id,
            date: FormatDate(values.date),
            staff_id: values.staff_id,
            ticket_id: values.ticket_id,
            distance: values.distance,
            status: values.status,
            fiscal: data_context['fiscal']
        };

        return payload;
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("/view_duty");
    }

    // RETURNING VALUES...

    return { 
        handleChange, 
        handleSubmit, 
        handleSelect, 
        handleBack,
        values, 
        errors, 
        loading, 
        item,
        statuses 
    };
}

export default UpdateDutyForm;