import React from 'react'
import "./row.css";
import { useMediaQuery } from 'react-responsive';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const Row = (props) => {

    // DECLARING STATES...

    const isMobile = useMediaQuery({ query: `(max-width: 480px)` });

    // MAIN FUNCTION...

    return (
        <div>

            {/* ----------------------------------------------------------------------------------------- */}

            {!isMobile &&
                <div className="row-rowWrapper">
                    {props.structure.map((item, index) => {
                        return (
                            <div className="row-outerGroup" key={index}>

                                {item.type === "text" &&
                                    <div key={index} className="row-formGroup">
                                        <label className="label">{item.label}</label>
                                        {!item.disabled && <input type={item.type} autoComplete='off' className="input" name={item.name} value={item.value} onChange={item.onChange}></input>}
                                        {item.disabled && <input type={item.type} autoComplete='off' className="input" name={item.name} value={item.value} onChange={item.onChange} disabled></input>}
                                        {item.errors && <p className="error">{item.errors}</p>}
                                    </div>}

                                {item.type === "select" &&
                                    <div key={index} className="row-formGroup">
                                        <label className="label">{item.label}</label>
                                        <select className="select" name={item.name} value={item.value} onChange={item.onChange}>                                            
                                            {item.values && item.values.length
                                                ? (item.values.map((item, i) => (<option key={i}>{item}</option>))) 
                                                : (<option>Loading...</option>)}
                                        </select>
                                        {item.errors && <p className="error">{item.errors}</p>}
                                    </div>}

                                {item.type === "date" &&
                                    <div key={index} className="row-formGroup">
                                        <label className="label">{item.label}</label>
                                        <div className="datepicker">
                                            <CalendarMonthOutlinedIcon />
                                            <div className='datepicker-component'>
                                                <DatePicker
                                                    className='datepicker-inner-component'
                                                    dateFormat="dd-MM-yyyy"
                                                    selected={item.value}
                                                    onChange={date => item.onChange({ target: { value: date, name: item.name } })}
                                                    fixedHeight
                                                    todayButton="TODAY"
                                                />
                                            </div>
                                        </div>
                                        {item.errors && <p className="error">{item.errors}</p>}
                                    </div>}

                                {props.single && <div className="row-formGroup"></div>}

                            </div>
                        )
                    })
                    }
                </div>
            }

            {/* ----------------------------------------------------------------------------------------- */}

            {isMobile &&
                <div>
                    {props.structure.map((item, index) => {
                        return (
                            <div key={index} className="row-rowWrapper">

                                {item.type === "text" &&
                                    <div key={index} className="row-formGroup">
                                        <label className="label">{item.label}</label>
                                        <input type={item.type} autoComplete='off' className="input" name={item.name} value={item.value} onChange={item.onChange}></input>
                                        {item.errors && <p className="error">{item.errors}</p>}
                                    </div>}

                                {item.type === "select" &&
                                    <div key={index} className="row-formGroup">
                                        <label className="label">{item.label}</label>
                                        <select className="select" name={item.name} value={item.value} onChange={item.onChange}>
                                            {item.values.map((item, i) => (<option key={i}>{item}</option>))}
                                        </select>
                                        {item.errors && <p className="error">{item.errors}</p>}
                                    </div>}

                                {item.type === "date" &&
                                    <div key={index} className="row-formGroup">
                                        <label className="label">{item.label}</label>
                                        <div className="datepicker">
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={item.value}
                                                onChange={date => item.onChange({ target: { value: date, name: item.name } })}
                                                fixedHeight
                                            />
                                        </div>
                                        {item.errors && <p className="error">{item.errors}</p>}
                                    </div>}

                            </div>
                        )
                    })
                    }
                </div>
            }

            {/* ----------------------------------------------------------------------------------------- */}

        </div>
    )

}

export default Row;