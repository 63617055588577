import React from "react";
import AddLedgerForm from "./AddLedgerForm";
import DatePicker from "react-datepicker";
import { useMediaQuery } from 'react-responsive';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const AddLedger = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddLedgerForm();
    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="attendance-wrapper">

                {/* NORMAL VIEW ON A COMPUTER BROWSER... */}

                {!isMobile && <div className="attendance-form">

                    <div className="attendance-header">
                        <div className="location-formGroup">
                            <h1 className="form-head">ADD&nbsp;LEDGER</h1>
                        </div>
                    </div>

                    <div className="attendance-body">

                        <div className="attendance-innerbox">

                            <div className="location-formGroup">
                                <label className="label">Party Type:</label>
                                <select className="select" name="party_type" value={destruct.values.party_type} onChange={destruct.handleChange} disabled={destruct.disable}>
                                    {destruct.party_types.map((item, i) => (<option key={i}>{item}</option>))}
                                </select>
                                {destruct.errors.party_type && <p className="error">{destruct.errors.party_type}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Party:</label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={destruct.item}
                                    getOptionLabel={(option) => option ? option.name : ''} 
                                    isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Search Party..." />                                       
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                                <br />
                                                <span>{`${option.name}`}</span>
                                            </div>
                                        </li>
                                    )}
                                    onChange={(event, value) => destruct.handleSelect(value)}
                                    sx={{
                                        width: '90%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        "& .MuiInputBase-root": {
                                            fontFamily: 'sans-serif',
                                            height: 45,
                                            fontSize: '15px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0
                                        },
                                        "&:hover fieldset": {
                                            border: 0
                                        },
                                        backgroundColor: '#FFFFFF',
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: '1px solid #9ecaed',
                                            boxShadow: '0 0 10px #9ecaed',
                                        },
                                    }}
                                />
                                {destruct.errors.party_id && <p className="error">{destruct.errors.party_id}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Date:</label>
                                <div className="datepicker">
                                    <CalendarMonthOutlinedIcon />
                                    <div className='datepicker-component'>
                                        <DatePicker
                                            className='datepicker-inner-component'
                                            dateFormat="dd-MM-yyyy"
                                            selected={destruct.values.date}
                                            onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                            fixedHeight
                                            todayButton="TODAY"
                                        />
                                    </div>
                                </div>
                                {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Particulars:</label>
                                <input type="text" autoComplete='off' className="input" name="particulars" value={destruct.values.particulars} onChange={destruct.handleChange}></input>
                                {destruct.errors.particulars && <p className="error">{destruct.errors.particulars}</p>}
                            </div>

                        </div>

                        <div className="attendance-innerbox">

                            <div className="location-formGroup">
                                <label className="label">Vch Type:</label>
                                <select className="select" name="vch_type" value={destruct.values.vch_type} onChange={destruct.handleChange} >
                                    {destruct.vch_types.map((item, i) => (<option key={i}>{item}</option>))}
                                </select>
                                {destruct.errors.vch_type && <p className="error">{destruct.errors.vch_type}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Vch Number:</label>
                                <input type="text" autoComplete='off' className="input" name="vch_no" value={destruct.values.vch_no} onChange={destruct.handleChange}></input>
                                {destruct.errors.vch_no && <p className="error">{destruct.errors.vch_no}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Credit:</label>
                                <input type="text" autoComplete='off' className="input" name="credit" value={destruct.values.credit} onChange={destruct.handleChange}></input>
                                {destruct.errors.credit && <p className="error">{destruct.errors.credit}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Debit:</label>
                                <input type="text" autoComplete='off' className="input" name="debit" value={destruct.values.debit} onChange={destruct.handleChange}></input>
                                {destruct.errors.debit && <p className="error">{destruct.errors.debit}</p>}
                            </div>
                        </div>
                    </div>

                    <div className="location-formGroup">
                        <button className="short-button" type="button" onClick={destruct.handleSubmit} disabled={destruct.loading[0]}>Save</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                    </div>

                </div>}

                {/* MEDIA QUERY VIEW ON MOBILE DEVICE... */}

                {isMobile && <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;LEDGER</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Party Type:</label>
                        <select className="select" name="party_type" value={destruct.values.party_type} onChange={destruct.handleChange} >
                            {destruct.party_types.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.party_type && <p className="error">{destruct.errors.party_type}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Party:</label>
                        <ReactSearchAutocomplete className="search-input"
                            items={destruct.item}
                            onSelect={destruct.handleSelect}
                            formatResult={destruct.formatResult}
                            styling={{ borderRadius: "4px", boxShadow: "0 0 10px #9ecaed" }}
                            placeholder="Search Party..."
                            onClear={destruct.handleClear}
                        />
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Particulars:</label>
                        <input type="text" autoComplete='off' className="input" name="particulars" value={destruct.values.particulars} onChange={destruct.handleChange}></input>
                        {destruct.errors.particulars && <p className="error">{destruct.errors.particulars}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Vch Type:</label>
                        <select className="select" name="vch_type" value={destruct.values.vch_type} onChange={destruct.handleChange} >
                            {destruct.vch_types.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.vch_type && <p className="error">{destruct.errors.vch_type}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Vch Number:</label>
                        <input type="text" autoComplete='off' className="input" name="vch_no" value={destruct.values.vch_no} onChange={destruct.handleChange}></input>
                        {destruct.errors.vch_no && <p className="error">{destruct.errors.vch_no}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Credit:</label>
                        <input type="text" autoComplete='off' className="input" name="credit" value={destruct.values.credit} onChange={destruct.handleChange}></input>
                        {destruct.errors.credit && <p className="error">{destruct.errors.credit}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Debit:</label>
                        <input type="text" autoComplete='off' className="input" name="debit" value={destruct.values.debit} onChange={destruct.handleChange}></input>
                        {destruct.errors.debit && <p className="error">{destruct.errors.debit}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading[0]}>Save</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                    </div>

                </form>}

            </div>
        </div>
    );
}

export default AddLedger;