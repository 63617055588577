import { useState, useContext, useEffect } from 'react';
import UserContext from '../Parent/UserContext';
import Post from '../HTTPRequest/Post';
import no_image from '../Images/no_image.png';
import CreateBlob from '../Parent/CreateBlob';
import EncodeBase64 from '../Parent/EncodeBase64';
import CreateFileName from '../Parent/CreateFileName';
import Upload from '../HTTPRequest/Upload';
import { toast } from 'react-toastify';
import FormatDate from '../Parent/FormatDate';

const AddPartnerForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const states = ['Andaman and Nicobar', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh', 'Chhattisgarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi',
        'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Lakshadweep', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya',
        'Mizoram', 'Nagaland', 'Orissa', 'Puducherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'];
    const statuses = ['Working', 'Retired', 'Terminated'];
    const initialValues =
    {
        name: '',
        address1: '',
        address2: '',
        district: '',
        state: states[27],
        country: '',
        email: '',
        phone: '',
        dob: '',
        status: statuses[0],
        photo_url: '',
        docs_url: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [dimension, setDimension] = useState({ width: '', height: '' });
    let placeholder_image = no_image;
    const [image, setImage] = useState(placeholder_image);
    const [image_errors, setImageErrors] = useState();
    const [docs_errors, setDocsErrors] = useState();
    const [docs, setDocs] = useState();
    const [dialog, setDialog] = useState({ 0: false, 1: false });
    const [progress, setProgress] = useState({ 0: 0, 1: 0 });

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // GETTING DIMENSIONS OF AN IMAGE...

    useEffect(() => {
        var blob = CreateBlob(image.split(",").pop());
        var _URL = window.URL || window.webkitURL;
        let img = new Image();
        var objectUrl = _URL.createObjectURL(blob);
        img.onload = function () {
            setDimension({ width: this.width, height: this.height });
            _URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;

        //eslint-disable-next-line    
    }, [image]);

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Post(user_context.url + '/partner', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {
                    toast.success(response?.message);
                } else {
                    toast.error(response?.message);
                }
            });
        } else {
            toast.error("Validation failed");
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_mail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

        if (!values.name) {
            errors.name = "*Name is required";
        }

        if (!values.address1) {
            errors.address1 = "*Address Line 1 is required";
        }

        if (!values.address2) {
            errors.address2 = "*Address Line 2 is required";
        }

        if (!values.district) {
            errors.district = "*District is required";
        }

        if (!values.country) {
            errors.country = "*Country is required";
        }

        if (!values.email) {
            errors.email = "*Email is required";
        } else if (!regex_mail.test(values.email)) {
            errors.email = "*Not a valid email id";
        }

        if (!values.phone) {
            errors.phone = "*Phone is required";
        }

        if (!values.dob) {
            errors.dob = "*Date of birth is required";
        }

        if (!values.photo_url) {
            errors.photo_url = "*Photo is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const payload =
        {
            name: values.name,
            address1: values.address1,
            address2: values.address2,
            district: values.district,
            state: values.state,
            country: values.country,
            email: values.email,
            phone: values.phone,
            dob: FormatDate(values.dob),
            status: values.status,
            photo_url: values.photo_url,
            docs_url: values.docs_url
        };

        return payload;
    }

    // HANDLING DIALOG OF IMAGE UPLOAD...

    const handleImage = () => {
        setDialog({ ...dialog, 0: !dialog[0] });
    }

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'image_file') {
                setProgress({ ...progress, 0: 0 });

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setImage(base64);
                    }
                    setValues({ ...values, photo_url: e.target.files[0].name });
                    setImageErrors();

                }).catch(() => {
                    setImageErrors();
                    toast.error("File conversion error");
                });
            }

            if (e.target.id === 'pdf_file') {

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setDocs(base64);
                    }

                    setValues({ ...values, docs_url: e.target.files[0].name });
                    setDocsErrors();

                }).catch(() => {
                    setDocsErrors();
                    toast.error("File conversion error");
                });
            }
        }
    }

    // CLEARING IMAGE...

    const handleClearImage = () => {
        setImage(placeholder_image);
        setValues({ ...values, photo_url: '' });
        setImageErrors();
        setProgress({ ...progress, 0: 0 });

    }

    // VALIDATING IMAGE UPLOADS...

    const validateImage = () => {
        let errors = '';

        if (!values.photo_url) {
            errors = "*No image selected";
            setImageErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.photo_url)[1];

            if (ext !== "jpg" && ext !== "jpeg" && ext !== "JPG" && ext !== "JPEG") {
                errors = "*Image is not a valid JPG file";
                setImageErrors(errors);
            }
        }

        return errors;
    }

    // UPLOADING IMAGES...

    const handleImageUpload = async () => {

        setProgress({ ...progress, 0: 0 });
        let errors = validateImage();

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 1: true });
            const options = {
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${user_context.user_data[1]}`
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress({ ...progress, 0: percent });
                }
            }

            if (image !== null) {
                let file_name = CreateFileName(values.photo_url);
                const payload = { file_name: file_name, encoded_string: image };

                await Upload(user_context.url + '/upload', payload, options).then(response => {
                    setLoading({ ...loading, 1: false });
                    if (response['status'] === 1) {
                        toast.success(response?.message);
                        setValues({ ...values, photo_url: file_name });
                    } else {
                        toast.error(response?.message);
                    }
                });
            }
        }
    }

    // HANDLING DIALOG OF DOCS UPLOAD...

    const handleDocs = () => {
        setDialog({ ...dialog, 1: !dialog[1] });
    }

    // VIEWING PDF...

    const viewPDF = () => {
        setLoading({ ...loading, 2: true });
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 2: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 2: false });
                }
            }
        } else {
            setLoading({ ...loading, 2: false });
        }
    }

    // CLEARING DOCS...

    const handleClearDocs = () => {
        setDocs();
        setValues({ ...values, docs_url: '' });
        setDocsErrors();
        setProgress({ ...progress, 1: 0 });
    }

    // VALIDATING DOCS UPLOADS...

    const validateDocs = () => {
        let errors = '';
        if (!values.docs_url) {
            errors = "*No pdf selected";
            setDocsErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.docs_url)[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors = "*Document is not a valid PDF file";
                setDocsErrors(errors);
            }
            else {
                if (docs === 'no_such_data' || !docs) {
                    errors = "*No pdf preview found";
                    setDocsErrors(errors);
                    setLoading({ ...loading, 1: false });
                }
            }
        }
        return errors;
    }

    // UPLOADING DOCS...

    const handleDocsUpload = async () => {
        setProgress({ ...progress, 1: 0 });
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 2: true });
            const options = {
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${user_context.user_data[1]}`
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress({ ...progress, 1: percent });
                }
            }

            if (docs !== null) {
                let file_name = CreateFileName(values.docs_url);
                const payload = { file_name: file_name, encoded_string: docs };

                await Upload(user_context.url + '/upload', payload, options).then(response => {
                    setLoading({ ...loading, 2: false });
                    if (response['status'] === 1) {
                        toast.success(response?.message);
                        setValues({ ...values, docs_url: file_name });
                    } else {
                        toast.error(response?.message);
                    }
                });
            }
        }
    }

    // RETURNING VALUES...

    return {
        handleChange,
        handleSubmit,
        handleImage,
        handleBrowse,
        handleClearImage,
        handleImageUpload,
        handleDocs,
        viewPDF,
        handleDocsUpload,
        handleClearDocs,
        values,
        errors,
        loading,
        states,
        statuses,
        image,
        image_errors,
        placeholder_image,
        dimension,
        docs,
        docs_errors,
        progress,
        dialog
    };

}

export default AddPartnerForm;