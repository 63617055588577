import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Post from '../HTTPRequest/Post';
import GetIndex from '../Parent/GetIndex';
import FormatDate from '../Parent/FormatDate';
import Get from '../HTTPRequest/Get';

const MakeEntryForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const types = ['Issue Cash', 'Receive Voucher'];
    const [account, setAccount] = useState([]);
    const [particulars, setParticulars] = useState([]);
    const [recipient_id, setRecipientID] = useState([]);
    const [item, setItem] = useState([]);
    const initialValues = 
    { 
        recipient_id: '', 
        name: '', 
        account: '', 
        date: '', 
        particulars: '', 
        type: types[0], 
        credit: '', 
        debit: '' 
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState({ 0: false, 1: false });

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=expense';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let recipient_id = response.response_payload[1]['id'] || [];
                        let recipient = response.response_payload[1]['name'] || [];                        
                        let account = response.response_payload[0] || [];
                 
                        setRecipientID(recipient_id);            
                        setAccount(account);  
                        setParticulars(account['particulars']);            
                        setValues({...values, account: account['id'][0]});

                        const newItems = recipient_id.map((_, i) => ({
                            id: recipient_id[i],
                            name: recipient[i]
                        }));
                        
                        setItem(newItems);
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // REGISTERING TYPE CHANGE...

    useEffect(() => {
        setErrors({});

        if (values.type === 'Issue Cash') {
            setDisable({ 0: true, 1: false });
        }

        if (values.type === 'Receive Voucher') {
            setDisable({ 0: false, 1: true });
        }

        //eslint-disable-next-line
    }, [values.type]);

    // REGISTERING ACCOUNT CHANGE...

    useEffect(() => {
        setErrors({});

        if (values.account !== '' && values.name !== '') {
            let particulars_received = particulars[GetIndex(account['id'], values.account)];
            let result = particulars_received.replace("[name]", values.name);

            setValues({ ...values, particulars: result });
        }

        //eslint-disable-next-line
    }, [values.account, values.recipient_id]);

    // ON SELECTION...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, recipient_id: item.id, name: item.name });
        }        
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage();

        if (Object.keys(errors).length === 0) {
            setLoading(true);

            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Post(user_context.url + '/expense', payload, headers).then(response => {
                setLoading(false);
                setMessage(response.message);
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        const regex_decimal = /^[-+]?\d*\.?\d+$/;

        if (!values.recipient_id) {
            errors.recipient_id = "*Recipient is required";
        }

        if (!values.account) {
            errors.account = "*Account is required";
        }

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.particulars) {
            errors.particulars = "*Particulars is required";
        }

        if (disable[0] === true) {
            if (values.credit) {
                errors.credit = "*Credit is not allowed";
            }

            if (!values.debit) {
                errors.debit = "*Debit is required";
            }
            else if (!regex_decimal.test(values.debit)) {
                errors.debit = "*Only decimals allowed";
            }
        }

        if (disable[1] === true) {
            if (values.debit) {
                errors.debit = "*Debit is not allowed";
            }

            if (!values.credit) {
                errors.credit = "*Credit is required";
            }
            else if (!regex_decimal.test(values.credit)) {
                errors.credit = "*Only decimals allowed";
            }
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            date: FormatDate(values.date),
            recipient_id: values.recipient_id,
            account_id: values.account,
            particulars: values.particulars,
            credit: values.credit || null,
            debit: values.debit || null,
            fiscal: data_context['fiscal']
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleSelect, 
        types, values, errors, message, loading, 
        disable, recipient_id, item, account
    };
}

export default MakeEntryForm;