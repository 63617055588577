import React from "react";
import UpdateHeadForm from "./UpdateHeadForm";

const UpdateHead = (props) => {
    // DECLARING STATES...    

    const { ...destruct } = UpdateHeadForm(props);

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR HEAD DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;HEAD</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Head ID:</label>
                        <input type="text" autoComplete='off' className="input" name="id" value={destruct.values.id} onChange={destruct.handleChange} disabled ></input>
                        {destruct.errors.id && <p className="error">{destruct.errors.head_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Head Name:</label>
                        <input type="text" autoComplete='off' className="input" name="name" value={destruct.values.name} onChange={destruct.handleChange} ></input>
                        {destruct.errors.name && <p className="error">{destruct.errors.name}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Address:</label>
                        <input type="text" autoComplete='off' className="input" name="address" value={destruct.values.address} onChange={destruct.handleChange} ></input>
                        {destruct.errors.address && <p className="error">{destruct.errors.address}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Contact:</label>
                        <input type="text" autoComplete='off' className="input" name="contact" value={destruct.values.contact} onChange={destruct.handleChange} ></input>
                        {destruct.errors.contact && <p className="error">{destruct.errors.contact}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading[0]}>Update</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>

                </form>

            </div>
        </div>
    );
}

export default UpdateHead;