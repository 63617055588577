import React from "react";
import DatePicker from "react-datepicker";
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import UpdateContractForm from "./UpdateContractForm";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import '../Ledger/Purchase/tax.css';

const UpdateContract = (props) => {
    // DECLARING STATES...    

    const { ...destruct } = UpdateContractForm(props);

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR CONTRACT DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;CONTRACT</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Contract ID:</label>
                        <input type="text" autoComplete='off' className="input" name="id" value={destruct.values.id} onChange={destruct.handleChange} disabled></input>
                        {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Vendor&nbsp;ID:</label>
                        <select className="select" name="vendor_id" value={destruct.values.vendor_id} onChange={destruct.handleChange}>
                            {destruct.vendor_id && destruct.vendor_id.length
                                ? (destruct.vendor_id.map((item, i) => (<option key={i}>{item}</option>)))
                                : (<option value="">Loading...</option>)}
                        </select>
                        {destruct.errors.vendor_id && <p className="error">{destruct.errors.vendor_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Vendor:</label>
                        <input type="text" autoComplete='off' className="input" name="vendor" value={destruct.values.vendor} disabled></input>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Contract Number:</label>
                        <input type="text" autoComplete='off' className="input" name="contract_no" value={destruct.values.contract_no} onChange={destruct.handleChange}></input>
                        {destruct.errors.contract_no && <p className="error">{destruct.errors.contract_no}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Status:</label>
                        <select className="select" name="status" value={destruct.values.status} onChange={destruct.handleChange}>
                            {destruct.statuses.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.status && <p className="error">{destruct.errors.status}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">License:</label>
                        <input type="text" autoComplete='off' className="input" name="license" value={destruct.values.license} onChange={destruct.handleChange}></input>
                        {destruct.errors.license && <p className="error">{destruct.errors.license}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Start&nbsp;Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.start_date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'start_date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.start_date && <p className="error">{destruct.errors.start_date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">End&nbsp;Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.end_date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'end_date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.end_date && <p className="error">{destruct.errors.end_date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Tenure:</label>
                        <input type="text" autoComplete='off' className="input" name="tenure" value={destruct.values.tenure} onChange={destruct.handleChange}></input>
                        {destruct.errors.tenure && <p className="error">{destruct.errors.tenure}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Fee:</label>
                        <input type="text" autoComplete='off' className="input" name="fee" value={destruct.values.fee} onChange={destruct.handleChange}></input>
                        {destruct.errors.fee && <p className="error">{destruct.errors.fee}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Type:</label>
                        <select className="select" name="type" value={destruct.values.type} onChange={destruct.handleChange} >
                            {destruct.types.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.type && <p className="error">{destruct.errors.type}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Documents:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                            <button className="tax-button" type="button" onClick={destruct.handleDialog}><CloudUploadOutlinedIcon /></button>
                        </div>
                        {destruct.errors.docs_url && <p className="error">{destruct.errors.docs_url}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleSubmit} disabled={destruct.loading[0]}>Update</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>
                </form>
            </div>
            {/* UPLOAD DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>                        
                        <progress className="form-progress" value={destruct.progress} max="100" />
                        <span className="progressText">{destruct.progress + "%"}</span>
                        {destruct.docs_errors && <p className="message">{destruct.docs_errors}</p>}
                        {destruct.message[1] && <p className="message">{destruct.message[1]}</p>}

                        <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={destruct.viewPDF} disabled={destruct.loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={destruct.handleUpload} disabled={destruct.loading[2]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>                        
                    </div>
                </div>
            </ReactDialogBox>}
        </div>
    );
}

export default UpdateContract;