import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import Post from '../HTTPRequest/Post';
import { toast } from 'react-toastify';

const AddVendorForm = () => {
    
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const initialValues =
    {
        name: '',
        address1: '',
        address2: '',
        district: '',
        state: 'Punjab',
        country: 'India',
        pincode: '',
        gstin: '',
        pan: '',
        tan: '',
        phone: '',
        contact_person: '',
        email: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);

            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 
                'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Post(user_context.url + '/vendor', payload, headers).then(response => {
                setLoading(false);
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                    toast.success(response?.message);                        
                } else {
                    toast.error(response?.message);
                }
            });           
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_mail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

        if (!values.name) {
            errors.name = "*Name is required";
        }

        if (!values.address2) {
            errors.address2 = "*Address Line 2 is required";
        }

        if (!values.district) {
            errors.district = "*District is required";
        }

        if (!values.state) {
            errors.state = "*State is required";
        }

        if (!values.country) {
            errors.country = "*Country is required";
        }

        if (!values.pincode) {
            errors.pincode = "*Pincode is required";
        }

        if (!values.gstin) {
            errors.gstin = "*GSTIN is required";
        }

        if (!values.pan) {
            errors.pan = "*PAN is required";
        }

        if (!values.phone) {
            errors.phone = "*Phone is required";
        }

        if (!values.contact_person) {
            errors.contact_person = "*Contact Person is required";
        }

        if (!values.email) {
            errors.email = "*Email is required";
        }
        else if (!regex_mail.test(values.email)) {
            errors.email = "*Not a valid email id";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            name: values.name,
            address1: values.address1,
            address2: values.address2,
            district: values.district,
            state: values.state,
            country: values.country,
            pincode: values.pincode,
            gstin: values.gstin,
            pan: values.pan,
            tan: values.tan,
            phone: values.phone,
            contact_person: values.contact_person,
            email: values.email
        };

        return data;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, values, errors, loading };

}

export default AddVendorForm;