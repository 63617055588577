import React from "react";
import AddOthersForm from "./AddOthersForm";
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

const AddOthers = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddOthersForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;OTHERS</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Ledger ID:</label>
                        <input type="text" autoComplete='off' className="input" name="ledger_id" value={destruct.values.ledger_id} onChange={destruct.handleChange} disabled></input>
                        {destruct.errors.ledger_id && <p className="error">{destruct.errors.ledger_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Documents:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                            <button className="tax-button" type="button" onClick={destruct.handleUploadDialog}><CloudUploadOutlinedIcon /></button>
                        </div>
                        {destruct.errors.docs_url && <p className="error">{destruct.errors.docs_url}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleSubmit} disabled={destruct.loading[0]}>Save</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>
                </form>
            </div>

            {/* UPLOAD DIALOG BOX... */}

            {destruct.upload_dialog && <ReactDialogBox
                closeBox={destruct.handleUploadDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'>

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                        <progress className="form-progress" value={destruct.progress} max="100" />
                        <span className="progressText">{destruct.progress + "%"}</span>
                        {destruct.docs_errors && <p className="message">{destruct.docs_errors}</p>}
                        {destruct.message[1] && <p className="message">{destruct.message[1]}</p>}

                        <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={destruct.viewPDF} disabled={destruct.loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={destruct.handleUpload} disabled={destruct.loading[2]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                    </div>
                </div>
            </ReactDialogBox>}
        </div>
    );
}

export default AddOthers;