import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Get from '../HTTPRequest/Get';
import { Row } from 'react-data-grid';
import jsPDF from 'jspdf';
import Logo from '../Images/logo.png';

const YearlySummaryForm = () => {
    // DECLARING STATES...  

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [table_rows, setTableRows] = useState([]);
    const [progress, setProgress] = useState(0);
    const [selected_row, setSelectedRow] = useState();

    // ROW RENDERER...

    const rowRenderer = (props) => {

        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        setMessage();
        setTableRows([]);
        setLoading(true);
        setProgress();

        let endpoint = user_context.url + '/attendance?fiscal=' + data_context['fiscal'] + '&route=yearly_attendance';
        const headers = 
        { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${user_context.user_data[1]}` 
        };

        await Get(endpoint, headers).then(response => {
            setLoading(false);
            setMessage(response.message);
            setProgress(0);

            if (response.status === 1) {
                if (response.response_payload['result'] === 'success') {
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                    }
                    const row_values = Object.values(data);
                    setTableRows(row_values);
                }
            }
        });

    }

    // GENERATING PDF FOR THE ROW CLICKED...

    const generatePDF = () => {
        if (selected_row > -1) {
            const doc = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'letter',
            });
            doc.addImage(Logo, 'PNG', 20, 20, 30, 20);
            doc.setFontSize(18);
            doc.text('SOFTECH SMART SOLUTIONS', 60, 30);
            doc.setFontSize(12);
            doc.text('Yearly Attendance Summary', 60, 40);

            const rowData = { ...table_rows[selected_row] }; 
            const keysToRemove = ['sno'];
            keysToRemove.forEach(key => delete rowData[key]);

            const keys = Object.keys(rowData);
            const rowHeight = 10;
            const totalRows = keys.length;

            for (let i = 0; i < totalRows; i++) {
                const isGrayRow = i % 2 !== 0;
                if (isGrayRow) {
                    doc.setFillColor(240);
                    doc.rect(20, 50 + i * rowHeight, 180, rowHeight, 'F');
                }

                const key = keys[i].toUpperCase();
                const value = rowData[keys[i]] || '';
                const text = `${key}: ${value}`;

                doc.setTextColor(isGrayRow ? 0 : 0);
                doc.text(text, 25, 55 + i * rowHeight);
            }

            doc.save('YEARLY SUMMARY.pdf');
        } else {
            setMessage('No row selected');
        }
    };

    // RETURNING VALUES...

    return {
        handleSubmit, rowRenderer, onRowClick, generatePDF,
        message, loading, table_rows, progress
    };
}

export default YearlySummaryForm;