import React, { useState, useContext, useEffect } from "react";
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DatePicker from "react-datepicker";
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Get from "../HTTPRequest/Get";
import FormatDate from "../Parent/FormatDate";
import { DataGrid } from '@mui/x-data-grid';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import jsPDF from 'jspdf';
import letter_head from '../Images/letter_head.png';
import FormatLocalDate from '../Parent/FormatLocalDate';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import FaceIcon from '@mui/icons-material/Face';

const ShowDuty = () => {

    // STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const initialValues = 
    { 
        date: ''
    };    
    const [values, setValues] = useState(initialValues);
    const [loading, setLoading] = useState({ 0: false });
    const [table_rows, setTableRows] = useState([]);
    const [technician, setTechnician] = useState([]);
    const [pending, setPending] = useState([]);
    const [dialog, setDialog] = useState(false);
    const table_columns = [
        { field: 'sno', headerName: 'SNo', width: 150 },        
        { field: 'ticket_id', headerName: 'Ticket ID', width: 150 },
        { field: 'staff_id', headerName: 'Staff ID', width: 150 },
        { field: 'name', headerName: 'Name', width: 250 },
        { field: 'client', headerName: 'Client', width: 250 },        
        { field: 'distance', headerName: 'Distance (KM)', width: 150 }
    ];  
    
    // FETCHING TECHNICIANS DETAILS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=technician';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) &&(response.response_payload['result'] === 'success')) {                    
                        
                    let staff = response.response_payload[0] || []; 
                    const newItems = staff.id.map((_, i) => ({
                        id: staff.id[i],
                        name: staff.name[i] 
                    }));
                
                    setTechnician(newItems);                                          
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        setTableRows([]);
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // HANDLER FOR DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // ON SUBMIT...

    const handleSubmit = async e => {
        e.preventDefault();
        if(values.date) {
            setLoading({ ...loading, 0: true });
            setTableRows([]);

            let endpoint = user_context.url + '/duty?route=show_duty&filter_key=' + FormatDate(values.date);
    
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };
            
            await Get(endpoint, headers).then(response => {
                setLoading({ ...loading, 0: false });            
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) { 
                    toast.success(response?.message);     
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;
    
                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                    }
                    
                    setTableRows(data);
                } else {
                    toast.error(response?.message);
                }
            });
        } else {
            toast.error("No date selected");
        }        
    }    
 
    // GENERATING PDF...

     const generatePDF = () => {
        if (table_rows && table_rows.length > 0) {
            const doc = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'A4',
            });

            const imgWidth = 210; 
            const imgHeight = (imgWidth * 40) / 190; 
            doc.addImage(letter_head, 'PNG', 0, 10, imgWidth, imgHeight)
           
            doc.setFontSize(12);
            const titleText = 'DUTY';
            const textWidth = doc.getStringUnitWidth(titleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const xPosition = (doc.internal.pageSize.width - textWidth) / 2;
            doc.text(titleText, xPosition, 60);

            doc.setFontSize(10);
            const subtitleText = FormatLocalDate(values.date);
            const subtitleWidth = doc.getStringUnitWidth(subtitleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const subtitlePosition = (doc.internal.pageSize.width - subtitleWidth) / 2;
            doc.text(subtitleText, subtitlePosition, 65);

            const updatedRows = table_rows.map(({ id, client_id, ...rest }) => rest);   
            const data = updatedRows.map(row => ({
                sno: row.sno,
                ticket_id: row.ticket_id,
                staff_id: row.staff_id,
                name: row.name,
                client: row.client,
                distance: row.distance,
            }));
            const headers = ['SNo', 'Ticket ID', 'Staff ID', 'Name', 'Client', 'Distance (KM)'];        
            
            doc.autoTable({
                startY: 70,
                head: [headers],
                body:  data.map(row => Object.values(row)),
                rowPageBreak: 'avoid',
                styles: {
                    cellPadding: 2,
                    cellWidth: 'auto',
                    valign: 'middle',
                    halign: 'center', 
                    lineWidth: 0.1,
                    lineColor: [0, 0, 0], 
                },  
                headStyles: {
                    fillColor: [48, 51, 78],
                    textColor: [255, 255, 255], 
                },
                bodyStyles: {
                    fillColor: [255, 255, 255], 
                    textColor: [0, 0, 0]
                }
            });       
        
            doc.save(`${FormatLocalDate(values.date)}.pdf`);
        } else {
            toast.error('No data found');
        }
    };

    // FETCHING TECHNICIAN WITH NO DUTY...

    const handleTechnician = () => {
        const tableRowIds = new Set(table_rows?.map(row => row.staff_id));
        const updatedTechnician = technician.filter(tech => !tableRowIds.has(tech.id));
        setPending(updatedTechnician);
        if(updatedTechnician && updatedTechnician.length > 0){
            handleDialog();
        } else {
            toast.success("No duty is pending");
        }        
    }

    // MAIN FUNCTION...

    return(
        <div className="page">
             <div className="daily-wrapper">
                {/* SEARCH BOX */}

                <form className="daily-form" onSubmit={handleSubmit}>
                    <div className="daily-formGroup">
                        <h1 className="form-head">SHOW&nbsp;DUTY</h1>
                    </div>

                    <div className="daily-formGroup">
                        <label className="daily-label">Date:</label>
                        <div className="view-datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={values.date}
                                    onChange={date => handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        <button className="view-button" type="submit" disabled={loading[0]}>View&nbsp;All</button>       
                        <button className="view-button" type="button" onClick={generatePDF}><PictureAsPdfOutlinedIcon/>&nbsp;PDF</button>      
                        <button className="view-button" type="button" onClick={handleTechnician}><PersonPinOutlinedIcon/></button>             
                    </div>
                </form>

                {/* DATA GRID */}

                <div className="daily-container">
                    <div style={{ width: '100%', height: '100%', backgroundColor: '#FFFF' }}>
                        <DataGrid
                            editMode="cell"
                            rows={table_rows}
                            columns={table_columns}
                            disableSelectionOnClick
                        />
                    </div>
                </div>

             </div>

             {/* DIALOG BOX... */}

            {dialog && <ReactDialogBox
                closeBox={handleDialog}
                modalWidth='20%'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'
            >
                
                <div className="dialog-container">
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1
                        }}
                        >
                        {pending?.map((item, index) => (
                            <Chip
                                key={index}
                                label={item.name}                                
                                icon={<FaceIcon />}
                            />
                        ))}
                    </Box>
                </div>
            </ReactDialogBox>}
             <ToastContainer/>
        </div>
    );
}

export default ShowDuty;