import React from 'react';
import styles from './About.module.css';

const About = () => {
  //**************************************************** */
  // MAIN FUNCTION...

  return (
    <>
      <div className="About">
        {/* ********************************Hero About Section******************************** */}
        <div className={styles.heroSection}>
          <div className={styles.heroImage} />
          <div className={styles.heroText}>
            <div className={styles.title}>About Us</div>
            <div className={styles.description}>We are passionate about transforming education through technology. With a focus on innovation, security, and efficiency, we aim to create a positive impact on learning environments in schools.<br /><br />Our team comprises experienced professionals dedicated to tailoring software and hardware solutions to meet the unique needs of each school. We take pride in delivering high-quality products and services that empower educators and students alike.</div>
          </div>
        </div>

        {/* ********************************Partner Section******************************** */}
        <div className={styles.about}>

  <div className={styles.aboutText}>
    <h1>Why Choose Us?</h1>
  </div>

  <div className={styles.aboutSection}>
    {['Expertise', 'Quality', 'Customer Service', 'Innovation', 'Efficiency', 'Security'].map((title, index) => (
      <div className={styles.aboutBox} key={index}>
        <div className={`${styles.icon} ${styles[`icon${index + 1}`]}`}></div>
        <h2>{title}</h2>
        <p>{[
          "With years of Experience we are experts in providing hardware Solutions tailored to School’s unique needs.",
          "We offer top-of-the-line Products & Services ensuring reliability and long term performance.",
          "Our dedicated team provides exceptional support and assistance throughout your journey with us.",
          "We stay at the forefront of educational technology, providing cutting-edge solutions for your school.",
          "Our ERP solutions streamline administrative tasks, saving time and resources.",
          "Our CCTV systems and smart class tech prioritize safety, creating a secure learning environment."
        ][index] /* Insert the respective text here, accessed via the current index */}
        </p>
      </div>
    ))}
</div>


  {/* <div className={styles.aboutText2}>
    <h1>Partners</h1>
  </div>

  <div className={styles.aboutSection2}>
    {['Fr. James Ullatil', 'Fr. Dins P. John',  'Fr. Terej Agniparty', 'Fr. Denis Thomas'].map((name, index) => (
      <div className={styles.aboutBox2} key={index}>
        <div className={`${styles.dp} ${styles[`dp${index + 1}`]}`}></div>
        <h3>{name}</h3>
      </div>
    ))}
  </div> */}

</div>


</div>


  





        
    

    </>
  );
}

export default About;