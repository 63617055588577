import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Put from '../HTTPRequest/Put'
import { useHistory } from 'react-router-dom';
import GetIndex from '../Parent/GetIndex';
import FormatDate from '../Parent/FormatDate';
import CreateLocalDate from '../Parent/CreateLocalDate';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import GetKey from '../Parent/GetKey';
import Get from '../HTTPRequest/Get';

const UpdateEntryForm = (props) => {

    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [account, setAccount] = useState([]);
    const [recipient_id, setRecipientID] = useState([]);
    const [item, setItem] = useState([]);
    const types = ['Issue Cash', 'Receive Voucher'];
    let type_index = -1;
    if (data_context.update_data['credit'] === null || data_context.update_data['credit'] === '') {
        type_index = 0;
    }
    else {
        type_index = 1;
    }
    const initialValues =
    {
        id: data_context.update_data['id'],
        recipient_id: data_context.update_data['recipient_id'],
        name: '',
        account: '',
        date: CreateLocalDate(data_context.update_data['date']),
        particulars: data_context.update_data['particulars'],
        type: types[type_index],
        credit: (data_context.update_data['credit'] !== null) ? data_context.update_data['credit'] : '',
        debit: (data_context.update_data['debit']) ? data_context.update_data['debit'] : ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState({ 0: false, 1: false });
    const history = useHistory();

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=expense';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let recipient_id = response.response_payload[1]['id'] || [];
                        let recipient = response.response_payload[1]['name'] || [];
                        let account = response.response_payload[0] || [];

                        setRecipientID(recipient_id);
                        setAccount(account);

                        setValues({
                            ...values,
                            name: recipient[GetIndex(recipient_id, data_context.update_data['recipient_id'])],
                            account: account['id'][GetIndex(account['id'], data_context.update_data['account_id'])]
                        });

                        const newItems = recipient_id.map((_, i) => ({
                            id: recipient_id[i],
                            name: recipient[i]
                        }));
                    
                        setItem(newItems);
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);


    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // REGISTERING TYPE CHANGE...

    useEffect(() => {
        setErrors({});

        if (values.type === 'Issue Cash') {
            setDisable({ 0: true, 1: false });
        }

        if (values.type === 'Receive Voucher') {
            setDisable({ 0: false, 1: true });
        }

        //eslint-disable-next-line
    }, [values.type]);

    // ON SELECTION...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, recipient_id: item.id, name: item.name });
        }        
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage();

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/expense', payload, headers).then(response => {
                setLoading(false);
                setMessage(response.message);

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let data = data_context.view_data.table_rows;
                        let index = GetKey(data, payload);
                        payload = { ...payload, sno: data[index]['sno'] };
                        payload = { ...payload, date: FormatLocalDate(CreateDate(payload['date'])) };
                        data = ({ ...data, [index]: payload });
                        const row_values = Object.values(data);

                        let view_data = data_context.view_data;
                        view_data = ({ ...view_data, table_rows: row_values });
                        props.handleBack(view_data);
                    }
                }
            });
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_entry");
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        const regex_decimal = /^[-+]?\d*\.?\d+$/;

        if (!values.id) {
            errors.id = "*Expense ID is required";
        }

        if (!values.recipient_id) {
            errors.recipient_id = "*Recipient is required";
        }

        if (!values.account) {
            errors.account = "*Account is required";
        }

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.particulars) {
            errors.particulars = "*Particulars is required";
        }

        if (disable[0] === true) {
            if (values.credit) {
                errors.credit = "*Credit is not allowed";
            }

            if (!values.debit) {
                errors.debit = "*Debit is required";
            }
            else if (!regex_decimal.test(values.debit)) {
                errors.debit = "*Only decimals allowed";
            }
        }

        if (disable[1] === true) {
            if (values.debit) {
                errors.debit = "*Debit is not allowed";
            }

            if (!values.credit) {
                errors.credit = "*Credit is required";
            }
            else if (!regex_decimal.test(values.credit)) {
                errors.credit = "*Only decimals allowed";
            }
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            date: FormatDate(values.date),
            recipient_id: values.recipient_id,
            account_id: values.account,
            particulars: values.particulars,
            credit: values.credit || null,
            debit: values.debit || null,
            fiscal: data_context['fiscal']
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleBack, handleSelect, 
        types, values, errors, message, loading, disable, recipient_id, account, item
    };

}

export default UpdateEntryForm;