import React from "react";
import UpdatePartnerForm from "./UpdatePartnerForm";
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ItemImage from "../Item/ItemImage";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import { ToastContainer } from 'react-toastify';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const UpdatePartner = (props) => {
    // DECLARING STATES...    

    const { ...destruct } = UpdatePartnerForm(props);

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">
                {/* FORM FOR PARTNER DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;PARTNER</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">ID:</label>
                        <input type="text" autoComplete='off' className="input" name="id" value={destruct.values.id} onChange={destruct.handleChange} disabled></input>
                        {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Name:</label>
                        <input type="text" autoComplete='off' className="input" name="name" value={destruct.values.name} onChange={destruct.handleChange}></input>
                        {destruct.errors.name && <p className="error">{destruct.errors.name}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Address&nbsp;Line&nbsp;1:</label>
                        <input type="text" autoComplete='off' className="input" name="address1" value={destruct.values.address1} onChange={destruct.handleChange}></input>
                        {destruct.errors.address1 && <p className="error">{destruct.errors.address1}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Address&nbsp;Line&nbsp;2:</label>
                        <input type="text" autoComplete='off' className="input" name="address2" value={destruct.values.address2} onChange={destruct.handleChange}></input>
                        {destruct.errors.address2 && <p className="error">{destruct.errors.address2}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">District:</label>
                        <input type="text" autoComplete='off' className="input" name="district" value={destruct.values.district} onChange={destruct.handleChange}></input>
                        {destruct.errors.district && <p className="error">{destruct.errors.district}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">State:</label>
                        <select className="select" name="state" value={destruct.values.state} onChange={destruct.handleChange} >
                            {destruct.states.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.state && <p className="error">{destruct.errors.state}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Country:</label>
                        <input type="text" autoComplete='off' className="input" name="country" value={destruct.values.country} onChange={destruct.handleChange}></input>
                        {destruct.errors.country && <p className="error">{destruct.errors.country}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Email:</label>
                        <input type="text" autoComplete='off' className="input" name="email" value={destruct.values.email} onChange={destruct.handleChange}></input>
                        {destruct.errors.email && <p className="error">{destruct.errors.email}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Phone:</label>
                        <input type="text" autoComplete='off' className="input" name="phone" value={destruct.values.phone} onChange={destruct.handleChange}></input>
                        {destruct.errors.phone && <p className="error">{destruct.errors.phone}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">DOB:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.dob}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'dob' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.dob && <p className="error">{destruct.errors.dob}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Status:</label>
                        <select className="select" name="status" value={destruct.values.status} onChange={destruct.handleChange} >
                            {destruct.statuses.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.status && <p className="error">{destruct.errors.status}</p>}
                    </div>

                    <div className="item-viewGroup">
                        <ItemImage image={destruct.image} placeholder={destruct.placeholder_image} width={destruct.dimension.width} height={destruct.dimension.height} />
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Image:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name='photo_url' value={destruct.values.photo_url} readOnly></input>
                            <button className="tax-button" type="button" onClick={destruct.handleImage}><CloudUploadOutlinedIcon /></button>
                        </div>
                        {destruct.errors.photo_url && <p className="error">{destruct.errors.photo_url}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Documents:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                            <button className="tax-button" type="button" onClick={destruct.handleDocs}><CloudUploadOutlinedIcon /></button>
                        </div>
                        {destruct.errors.docs_url && <p className="error">{destruct.errors.docs_url}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleSubmit} disabled={destruct.loading[0]}>Update</button>
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>

                </form>
            </div>

            {/* UPLOAD IMAGE DIALOG BOX... */}

            {destruct.dialog[0] && <ReactDialogBox
                closeBox={destruct.handleImage}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Image:</label>
                        <input type="text" autoComplete='off' className="input" name='photo_url' value={destruct.values.photo_url} readOnly></input>
                        <progress className="form-progress" value={destruct.progress[0]} max="100" />
                        <span className="progressText">{destruct.progress[0] + "%"}</span>
                        {destruct.image_errors && <p className="message">{destruct.image_errors}</p>}

                        <input id="image_file" type="file" accept="image/*" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="image_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={destruct.handleImageUpload} disabled={destruct.loading[1]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleClearImage}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                    </div>
                </div>
            </ReactDialogBox>}

            {/* UPLOAD DOCS DIALOG BOX... */}

            {destruct.dialog[1] && <ReactDialogBox
                closeBox={destruct.handleDocs}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                        <progress className="form-progress" value={destruct.progress[1]} max="100" />
                        <span className="progressText">{destruct.progress[1] + "%"}</span>
                        {destruct.docs_errors && <p className="message">{destruct.docs_errors}</p>}

                        <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={destruct.viewPDF} disabled={destruct.loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={destruct.handleDocsUpload} disabled={destruct.loading[2]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleClearDocs}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                    </div>
                </div>
            </ReactDialogBox>}

            <ToastContainer />
        </div>
    );
}

export default UpdatePartner;