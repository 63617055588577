import React from "react";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import ExpenseSheetForm from "./ExpenseSheetForm";
import { useMediaQuery } from 'react-responsive';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const ExpenseSheet = () => {

    // DECLARING STATES...

    const { ...destruct } = ExpenseSheetForm();
    const defaultColumnProperties = { sortable: false, resizable: true };

    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'date', name: 'Date', width: '10%' },
        { key: 'particulars', name: 'Particulars', width: '50%' },
        { key: 'amount', name: 'Amount', width: '15%' },
        { key: 'account', name: 'Account', width: '20%' }

    ].map(c => ({ ...c, ...defaultColumnProperties }));

    const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
    const headers = ['S.No', 'Date', 'Particulars', 'Amount', 'Account'];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">EXPENSE&nbsp;SHEET</h1>
                    </div>

                    <div className="daily-formGroup">
                        {!isMobile && <>
                            <label className="daily-label">From:</label>
                            <div className="datepicker">
                                <CalendarMonthOutlinedIcon />
                                <div className='datepicker-component'>
                                    <DatePicker
                                        className='datepicker-inner-component'
                                        dateFormat="dd-MM-yyyy"
                                        selected={destruct.values.from}
                                        onChange={date => destruct.handleChange({ target: { value: date, name: 'from' } })}
                                        fixedHeight
                                        todayButton="TODAY"
                                    />
                                </div>
                            </div>

                            <label className="daily-label">To:</label>
                            <div className="datepicker">
                                <CalendarMonthOutlinedIcon />
                                <div className='datepicker-component'>
                                    <DatePicker
                                        className='datepicker-inner-component'
                                        dateFormat="dd-MM-yyyy"
                                        selected={destruct.values.to}
                                        onChange={date => destruct.handleChange({ target: { value: date, name: 'to' } })}
                                        fixedHeight
                                        todayButton="TODAY"
                                    />
                                </div>
                            </div>
                        </>}
                        <button className="view-button" type="submit" disabled={destruct.loading[0]}><RemoveRedEyeOutlinedIcon />&nbsp;Show</button>
                        <CSVLink className="view-button" data={destruct.table_rows.map(item => Object.values(item))} headers={headers} filename={'EXPENSE_SHEET.csv'} ><TableViewOutlinedIcon />&nbsp;Export</CSVLink>
                    </div>

                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

            </div>
        </div>
    );
}

export default ExpenseSheet;