import React from "react";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import ViewStatementForm from "./ViewStatementForm";
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { CSVLink } from "react-csv";
import SortByAlphaOutlinedIcon from '@mui/icons-material/SortByAlphaOutlined';
import { useMediaQuery } from 'react-responsive';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const ViewStatement = () => {
    // DECLARING STATES...

    const { ...destruct } = ViewStatementForm();
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'date', name: 'Date', width: '10%' },
        { key: 'particulars', name: 'Particulars', width: '50%' },
        { key: 'debit', name: 'Credit', width: '20%' },
        { key: 'credit', name: 'Debit', width: '20%' }
    ].map(c => ({ ...c, ...defaultColumnProperties }));
    const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
    const headers = ['S.No', 'Date', 'Particulars', 'Credit', 'Debit'];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">VIEW&nbsp;STATEMENT</h1>
                    </div>

                    <div className="daily-formGroup">
                        <label className="daily-label">Recipient:</label>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={destruct.item}
                            getOptionLabel={(option) => option ? option.name : ''} 
                            isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Search Party..." />                                       
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                        <br />
                                        <span>{`${option.name}`}</span>
                                    </div>
                                </li>
                            )}
                            onChange={(event, value) => destruct.handleSelect(value)}
                            sx={{
                                width: '90%',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                "& .MuiInputBase-root": {
                                    fontFamily: 'sans-serif',
                                    height: 45,
                                    fontSize: '15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignContent: 'center'
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: 0
                                },
                                "&:hover fieldset": {
                                    border: 0
                                },
                                backgroundColor: '#FFFFFF',
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: '1px solid #9ecaed',
                                    boxShadow: '0 0 10px #9ecaed',
                                },
                            }}
                        />

                        <button className="view-button" type="button" onClick={destruct.handleSort} disabled={destruct.loading[1]}><SortByAlphaOutlinedIcon />&nbsp;Sort</button>
                        <button className="view-button" type="submit" disabled={destruct.loading[0]}><RemoveRedEyeOutlinedIcon/>&nbsp;Show</button>
                        {!isMobile && <CSVLink className="view-button" data={destruct.table_rows.map(item => Object.values(item))} headers={headers} filename={destruct.values.name + '.csv'} ><TableViewOutlinedIcon />&nbsp;Export</CSVLink>}
                        {!isMobile && <button className="view-button" type="button" onClick={destruct.generatePDF}><PictureAsPdfOutlinedIcon/>&nbsp;PDF</button>}
                    </div>

                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

            </div>
        </div>
    );
}

export default ViewStatement;