import React from "react";  
import BillGoodsForm from "./BillGoodsForm";
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DataGrid from 'react-data-grid';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import { ToastContainer } from 'react-toastify';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

const BillGoods = () => {

    // DECLARING STATES...    

    const { ...destruct } = BillGoodsForm();
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'ID', width: '5%' },
        { key: 'name', name: 'Name', width: '20%' },
        { key: 'hsn', name: 'HSN', width: '10%' },
        { key: 'manufacturer', name: 'Manufacturer', width: '15%' },
        { key: 'model', name: 'Model', width: '20%' },
        { key: 'quantity', name: 'QTY', width: '5%' },
        { key: 'rate', name: 'Rate', width: '10%' },
        { key: 'unit', name: 'Unit', width: '10%' },
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="view-wrapper">
                <div className="view-form">
                    <div className="view-formGroup">
                        <h1 className="form-head">BILL&nbsp;GOODS</h1>
                    </div>

                    <div className="attendance-body">

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Date:</label>
                                <div className="datepicker">
                                    <CalendarMonthOutlinedIcon />
                                    <div className='datepicker-component'>
                                        <DatePicker
                                            className='datepicker-inner-component'
                                            dateFormat="dd-MM-yyyy"
                                            selected={destruct.values.date}
                                            onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                            fixedHeight
                                            todayButton="TODAY"
                                        />
                                    </div>
                                </div>
                                {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Client:</label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={destruct.client}
                                    getOptionLabel={(option) => option ? option.name : ''}
                                    isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Search Client..." />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <span style={{ color: 'red' }}>{`ID: ${option.id}`}</span>
                                                <br />
                                                <span>{`Name: ${option.name}`}</span>
                                            </div>
                                        </li>
                                    )}
                                    onChange={(event, value) => destruct.selectClient(value)}
                                    sx={{
                                        width: '90%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        "& .MuiInputBase-root": {
                                            fontFamily: 'sans-serif',
                                            height: 45,
                                            fontSize: '15px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0
                                        },
                                        "&:hover fieldset": {
                                            border: 0
                                        },
                                        backgroundColor: '#FFFFFF',
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: '1px solid #9ecaed',
                                            boxShadow: '0 0 10px #9ecaed',
                                        },
                                    }}
                                />
                                {destruct.errors.client_id && <p className="error">{destruct.errors.client_id}</p>}
                            </div>                           
                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Transportation:</label>
                                <input 
                                    type="text" 
                                    autoComplete='off' 
                                    className="input" 
                                    name="transportation" 
                                    value={destruct.values.transportation} 
                                    onChange={destruct.handleChange}
                                />
                                {destruct.errors.transportation && <p className="error">{destruct.errors.transportation}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Driver:</label>
                                <input 
                                    type="text" 
                                    autoComplete='off' 
                                    className="input" 
                                    name="driver" 
                                    value={destruct.values.driver} 
                                    onChange={destruct.handleChange}
                                />
                                {destruct.errors.driver && <p className="error">{destruct.errors.driver}</p>}
                            </div>
                        </div>   

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Departure:</label>
                                <input 
                                    type="time" 
                                    autoComplete='off' 
                                    className="input" 
                                    name="departure" 
                                    value={destruct.values.departure} 
                                    onChange={destruct.handleChange}
                                />
                                {destruct.errors.departure && <p className="error">{destruct.errors.departure}</p>}
                            </div>   

                            <div className="location-formGroup">
                                <label className="label">Type:</label>
                                <input 
                                    type="text" 
                                    autoComplete='off' 
                                    className="input" 
                                    name="type" 
                                    value={destruct.values.type} 
                                    onChange={destruct.handleChange}
                                />
                                {destruct.errors.type && <p className="error">{destruct.errors.type}</p>}
                            </div>                         
                        </div>      
                        
                    </div>                   
                </div>

                <div className="view-form">
                    <div className="attendance-body">
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Item:</label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={destruct.item}
                                    getOptionLabel={(option) => option ? option.name : ''}
                                    isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Search Item..." />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <span style={{ color: 'red' }}>{`ID: ${option.id}`}</span>
                                                <br />
                                                <span>{`Name: ${option.name}`}</span>
                                                <br />
                                                <span>{`Model: ${option.model}`}</span>
                                                <br />
                                                <span>{`Manufacturer: ${option.manufacturer}`}</span>
                                                <br />
                                                <span>{`Rate: ${option.rate}`}</span>
                                            </div>
                                        </li>
                                    )}
                                    onChange={(event, value) => destruct.selectItem(value)}
                                    sx={{
                                        width: '90%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        "& .MuiInputBase-root": {
                                            fontFamily: 'sans-serif',
                                            height: 45,
                                            fontSize: '15px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0
                                        },
                                        "&:hover fieldset": {
                                            border: 0
                                        },
                                        backgroundColor: '#FFFFFF',
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: '1px solid #9ecaed',
                                            boxShadow: '0 0 10px #9ecaed',
                                        },
                                    }}
                                />
                                {destruct.errors.item_id && <p className="error">{destruct.errors.item_id}</p>}
                            </div>
                        </div>
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Quantity:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="quantity"
                                    value={destruct.values.quantity}
                                    onChange={destruct.handleChange}>
                                </input>
                                {destruct.errors.quantity && <p className="error">{destruct.errors.quantity}</p>}
                            </div>
                        </div>
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Rate:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="rate"
                                    value={destruct.values.rate}
                                    onChange={destruct.handleChange}>
                                </input>
                                {destruct.errors.rate && <p className="error">{destruct.errors.rate}</p>}
                            </div>
                        </div>
                        <div className="attendance-innerbox">
                            <label className="label"></label>
                            <div className="location-formGroup">
                                <button
                                    className="button"
                                    type="button"
                                    onClick={destruct.addItem}><DirectionsOutlinedIcon />&nbsp;Add&nbsp;Item
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* DATA GRID */}

                <div className="short-table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{ width: '100%', height: '100%' }}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        onRowClick={destruct.onRowClick}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

                {/* FOOTER BUTTONS */}

                <div className="view-footer">
                    <button 
                        className="view-button" 
                        type="button" 
                        onClick={destruct.handleSubmit}
                    ><PictureAsPdfOutlinedIcon/>&nbsp;Generate
                    </button>

                    <button
                        className="view-button"
                        type="button"
                        onClick={destruct.handleDelete}
                    ><DeleteForeverOutlinedIcon/>&nbsp;Delete
                    </button>
                </div> 

            </div>
            <ToastContainer/>
        </div>
    );
}

export default BillGoods;