import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import { ToastContainer } from 'react-toastify';
import Get from '../HTTPRequest/Get';
import CustomAutocomplete from '../Components/CustomAutoComplete';
import CreateBlob from '../Parent/CreateBlob';
import EncodeBase64 from '../Parent/EncodeBase64';
import CreateFileName from '../Parent/CreateFileName';
import Upload from '../HTTPRequest/Upload';
import { toast } from 'react-toastify';
import { ReactDialogBox } from 'react-js-dialog-box';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Post from '../HTTPRequest/Post';

const AddMap = () => {

    // STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [client, setClient] = useState([]);
    const types = ['Smartclass', 'CCTV', 'Speakers'];
    const initialValues =
    {
        client_id: '',
        type: types[0],
        docs_url: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [docs_errors, setDocsErrors] = useState();
    const [docs, setDocs] = useState();
    const [dialog, setDialog] = useState(false);
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [progress, setProgress] = useState(0);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=ticket';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let client = response.response_payload[0] || [];

                        const newItems = client.id.map((_, i) => ({
                            id: client.id[i],
                            name: client.address[i]
                        }));
                    
                        setClient(newItems);
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // ON SELECTION OF CLIENT...

    const selectClient = (item) => {
        if (item) {
            setValues({ ...values, client_id: item.id });
        } else {
            setValues({ ...values, client_id: '' });
        }
    }

    // HANDLING DIALOG OF DOCS UPLOAD...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {

            if (e.target.id === 'pdf_file') {

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setDocs(base64);
                    }

                    setValues({ ...values, docs_url: e.target.files[0].name });
                    setDocsErrors();

                }).catch(() => {
                    setDocsErrors();
                    toast.error("File conversion error");
                });
            }
        }
    }

    // VIEWING PDF...

    const viewPDF = () => {
        setLoading({ ...loading, 1: true });
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 1: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 1: false });
                }
            }
        } else {
            setLoading({ ...loading, 1: false });
        }
    }

    // CLEARING DOCS...

    const handleClear = () => {
        setDocs();
        setValues({ ...values, docs_url: '' });
        setDocsErrors();
        setProgress(0);
    }

    // VALIDATING DOCS UPLOADS...

    const validateDocs = () => {
        let errors = '';
        if (!values.docs_url) {
            errors = "*No pdf selected";
            setDocsErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.docs_url)[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors = "*Document is not a valid PDF file";
                setDocsErrors(errors);
            }
            else {
                if (docs === 'no_such_data' || !docs) {
                    errors = "*No pdf preview found";
                    setDocsErrors(errors);
                    setLoading({ ...loading, 2: false });
                }
            }
        }
        return errors;
    }

    // UPLOADING DOCS...

    const handleUpload = async () => {
        setProgress(0);
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 2: true });
            const options = {
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${user_context.user_data[1]}`
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress(percent);
                }
            }

            if (docs !== null) {
                let file_name = CreateFileName(values.docs_url);
                const payload = { file_name: file_name, encoded_string: docs };

                await Upload(user_context.url + '/upload', payload, options).then(response => {
                    setLoading({ ...loading, 2: false });
                    if (response['status'] === 1) {
                        toast.success(response?.message);
                        setValues({ ...values, docs_url: file_name });
                    } else {
                        toast.error(response?.message);
                    }
                });
            }
        }
    }

    // SUBMITTING FORM...

    const handleSubmit = async () => {
        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Post(user_context.url + '/map', payload, headers).then(response => {    
                setLoading({ ...loading, 0: false });
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                    toast.success(response?.message);                       
                } else {
                    toast.error(response?.message);
                }
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.client_id) {
            errors.client_id = "*Client is required";
        }

        if (!values.docs_url) {
            errors.docs_url = "*Docs is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const payload =
        {
            client_id: values.client_id,
            type: values.type,
            docs_url: values.docs_url
        };

        return payload;
    }

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR MAP DETAILS... */}

                <form className="location-form">

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;MAP</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Client:</label>
                        {client &&
                            <CustomAutocomplete
                                data={client}
                                placeholder="Search Client..."
                                selectValue={selectClient}
                                selected_value={values.client_id}
                            />
                        }
                        {errors.client_id && <p className="error">{errors.client_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Type:</label>
                        <select 
                            className="select" 
                            name="type" 
                            value={values.type} 
                            onChange={handleChange} >
                            {types.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {errors.type && <p className="error">{errors.type}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Documents:</label>
                        <div className="tax-formGroup">
                            <input 
                                type="text" 
                                autoComplete='off' 
                                className="input" 
                                name='docs_url' 
                                value={values.docs_url} 
                                readOnly
                            />
                            <button     
                                className="tax-button" 
                                type="button" 
                                onClick={handleDialog}
                            >
                                <CloudUploadOutlinedIcon />
                            </button>
                        </div>
                        {errors.docs_url && <p className="error">{errors.docs_url}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button 
                            className="button" 
                            type="button" 
                            onClick={handleSubmit} 
                            disabled={loading[0]}
                        >
                            Save
                        </button>
                    </div>

                </form>

            </div>

            {/* UPLOAD DOCS DIALOG BOX... */}

            {dialog && <ReactDialogBox
                closeBox={handleDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={values.docs_url} readOnly></input>
                        <progress className="form-progress" value={progress} max="100" />
                        <span className="progressText">{progress + "%"}</span>
                        {docs_errors && <p className="message">{docs_errors}</p>}

                        <input id="pdf_file" type="file" accept=".pdf" onChange={handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={viewPDF} disabled={loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={handleUpload} disabled={loading[2]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={handleClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                    </div>
                </div>
            </ReactDialogBox>}

            <ToastContainer/>
        </div>
    );
}

export default AddMap;  