import { useState, useContext } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import Post from '../HTTPRequest/Post';
import EncodeBase64 from '../Parent/EncodeBase64';
import CreateBlob from '../Parent/CreateBlob';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const MergePDFForm = () => {
    // DECLARING STATES...    

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const initialValues =
    {
        pdf_name: data_context.update_data['docs_url'],
        docs_url: ''
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({ 0: false, 1: false });
    const [progress, setProgress] = useState(0);
    const [docs_errors, setDocsErrors] = useState();
    const [docs, setDocs] = useState();
    const history = useHistory();

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'pdf_file') {
                setProgress(0);

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setDocs(base64);
                    }

                    setValues({ ...values, docs_url: e.target.files[0].name });
                    setDocsErrors();

                }).catch(() => {
                    setDocsErrors();
                    toast.error("File conversion error");
                });
            }
        }
    }

    // VIEWING PDF...

    const viewPDF = () => {
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 1: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 1: false });
                }
            }
        }
        else {
            setLoading({ ...loading, 1: false });
        }
    }

    // VALIDATING UPLOADS...

    const validateDocs = () => {
        let errors = '';

        if (!values.docs_url) {
            errors = "*No pdf selected";
            setDocsErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.docs_url)[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors = "*Document is not a valid PDF file";
                setDocsErrors(errors);
            }
        }

        return errors;
    }

    // CLEARING FIELDS...

    const handleClear = () => {
        setDocs();
        setValues({ ...values, docs_url: '' });
        setDocsErrors();
        setProgress(0);
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });
            let payload = createPayload(values);
            const options = {
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${user_context.user_data[1]}`
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress(percent);
                }
            }

            try {
                const response = await axios.post(user_context.url + '/merge', payload, options);
                console.log(response);
                toast.success(response?.data?.message);
            } catch (error) {
                toast.success(error?.message);
                setLoading({ ...loading, 0: false });
            } 
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.docs_url) {
            errors.docs_url = "*New Page is required";
        }

        if (!values.pdf_name) {
            errors.pdf_name = "*Existing PDF Name is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            existing_pdf: values.pdf_name,
            encoded_string: docs
        };

        return data;
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("/view_staff");
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleBrowse,
        viewPDF, handleClear, handleBack, values,
        errors, loading, progress, docs_errors
    };


}

export default MergePDFForm;