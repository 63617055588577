import React from "react";
import ViewReceivedChequeForm from "./ViewChequeForm";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import { useMediaQuery } from 'react-responsive';
import 'react-js-dialog-box/dist/index.css';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { CSVLink } from "react-csv";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { ToastContainer } from 'react-toastify';

const ViewReceivedCheque = () => {
    // DECLARING STATES...

    const { ...destruct } = ViewReceivedChequeForm();
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'ID', width: '5%' },
        { key: 'date', name: 'Date', width: '10%' },
        { key: 'cheque_no', name: 'Cheque No', width: '10%' },
        { key: 'party', name: 'Party', width: '25%' },
        { key: 'amount', name: 'Amount', width: '20%' },
        { key: 'docs_url', name: 'Docs', width: '25%' }
    ].map(c => ({ ...c, ...defaultColumnProperties }));
    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });
    const headers = ['S.No', 'ID', 'Date', 'Cheque No', 'Amount', 'Docs', 'Party'];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">
                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">VIEW&nbsp;CHEQUES</h1>
                    </div>

                    <div className="view-formGroup">
                        {!isMobile &&
                            <select
                                className="view-select"
                                name="selected_column"
                                value={destruct.values.selected_column}
                                onChange={destruct.handleChange}>
                                {destruct.columns.map((item, i) => (<option key={i}>{item}</option>))}
                            </select>}

                            <button 
                                className="view-button" 
                                type="submit" 
                                disabled={destruct.loading[0]}>
                                <RemoveRedEyeOutlinedIcon />&nbsp;Show
                            </button>

                            {destruct.permissions[5] === '1' && 
                            <CSVLink className="view-button" 
                                data={destruct.table_rows.map(item => Object.values(item))} 
                                headers={headers} 
                                filename={'CHEQUE.csv'} >
                                <TableViewOutlinedIcon />&nbsp;Export
                            </CSVLink>}

                            <button 
                                className="view-button" 
                                type="button" 
                                onClick={destruct.handleAttachment}                                 
                            >
                                <AttachFileOutlinedIcon />&nbsp;Attachment
                            </button>
                    </div>
                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* DATA GRID */}

                <div className="long-table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        onRowClick={destruct.onRowClick}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default ViewReceivedCheque;