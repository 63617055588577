import React, { useRef } from "react";
import './showTicket.css';
import ShowTicketForm from "./ShowTicketForm";
import head from '../Images/head.png'
import { LazyLoadImage } from "react-lazy-load-image-component";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ReactToPrint from "react-to-print";

const ShowTicket = () => {

    // DECLARING STATES...    

    const componentRef = useRef(null);
    const { ...destruct } = ShowTicketForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR TICKET DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">SHOW&nbsp;TICKET</h1>
                    </div>

                    <div className="ticket-form" ref={componentRef}>
                        <div><LazyLoadImage className="ticket-image" src={head} alt="Not found" /></div>

                        <div className="ticket-body">
                            <div className="ticket-label"><div className="ticket-particulars">Ticket ID:</div><div className="ticket-data">{destruct.initialValues.ticket_id}</div></div>
                            <div className="ticket-label"><div className="ticket-particulars">Date:</div><div className="ticket-data">{destruct.initialValues.date}</div></div>
                            <div className="ticket-label"><div className="ticket-particulars">Client ID:</div><div className="ticket-data">{destruct.initialValues.client_id}</div></div>
                            <div className="ticket-label"><div className="ticket-particulars">Address:</div><div className="ticket-data">{destruct.initialValues.client}</div></div>
                            <div className="ticket-label"><div className="ticket-particulars">Category:</div><div className="ticket-data">{destruct.initialValues.category}</div></div>
                            <div className="ticket-label"><div className="ticket-particulars">Type:</div><div className="ticket-data">{destruct.initialValues.type}</div></div>
                            <div className="ticket-label"><div className="ticket-particulars">Comment:</div><div className="ticket-data">{destruct.initialValues.comment}</div></div>
                            {destruct.initialValues.status === 'Pending' && <div className="ticket-label"><div className="ticket-particulars">Status:</div><div className="ticket-data-pending">{destruct.initialValues.status}</div></div>}
                            {destruct.initialValues.status === 'Closed' && <div className="ticket-label"><div className="ticket-particulars">Status:</div><div className="ticket-data-closed">{destruct.initialValues.status}</div></div>}
                            <div className="ticket-label"><div className="ticket-particulars">Staff Head:</div><div className="ticket-data">{destruct.initialValues.staff_head}</div></div>
                            <div className="ticket-label"><div className="ticket-particulars">Closing Date:</div><div className="ticket-data">{destruct.initialValues.closing_date}</div></div>
                        </div>

                    </div>

                    <div className="location-formGroup">
                        <ReactToPrint trigger={() => <button className="button" type="button"><PrintOutlinedIcon />&nbsp;Preview</button>} content={() => componentRef.current} />
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>

                </form>

            </div>
        </div>
    );
}

export default ShowTicket;