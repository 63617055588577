import React from "react";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { CSVLink } from "react-csv";
import BalanceSummaryForm from "./BalanceSummaryForm";

const BalanceSummary = () => {
    // DECLARING STATES...

    const { ...destruct } = BalanceSummaryForm();
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '20%' },
        { key: 'recipient', name: 'Recipient', width: '40%' },
        { key: 'balance', name: 'Balance', width: '40%', formatter: destruct.cellFormatter }
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    const headers = ['S.No', 'Recipient', 'Balance'];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">BALANCE&nbsp;SUMMARY</h1>
                    </div>

                    <div className="daily-formGroup">
                        <button className="view-button" type="submit" disabled={destruct.loading}><RemoveRedEyeOutlinedIcon />&nbsp;Show</button>
                        <CSVLink className="view-button" data={destruct.table_rows.map(item => Object.values(item))} headers={headers} filename={'BALANCE_SUMMARY.csv'} ><TableViewOutlinedIcon />&nbsp;Export</CSVLink>
                    </div>

                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}

                    />
                </div>

            </div>
        </div>
    );
}

export default BalanceSummary;