import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import FormatDate from '../Parent/FormatDate';
import Get from '../HTTPRequest/Get';

const DailyAttendanceForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const types = ['All', 'Present', 'Leave', 'Half Day', 'Special', 'No Information'];
    const initialValues = { date: '', type: types[0] };
    const [values, setValues] = useState(initialValues);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [payload, setPayload] = useState([]);
    const [response, setResponse] = useState([]);
    const [printing, setPrinting] = useState(false);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        setMessage();
        
        if (values.date) {
            setLoading({ ...loading, 0: true });           
            fetchData();
        }
        else {
            setMessage("You need to enter a date to perform this action");
        }
    }

    // FETCH DATA...

    const fetchData = async () =>{       
        let endpoint = user_context.url + '/attendance?filter_key=' + FormatDate(values.date) + '&route=daily_attendance';
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

        await Get(endpoint, headers).then(response => {
            setLoading({ ...loading, 0: false });
            setMessage(response.message);
            
            if (response.status === 1) {
                if (response.response_payload['result'] === 'success') {
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                    }
                    setPayload(data);
                    setResponse(data);
                }
            }
        });
    }

    // SHOWING PRINT PREVIEW...

    const handlePrint = () => {
        setPrinting(!printing);
    }

    // SHOWING RESULT AS PER TYPE SELECTED...

    useEffect(() => {
        let data = ([]);
        for (let i = 0; i < (Object.keys(payload).length); i++) {
            if (payload[i]['type'] === values.type) {
                data[i] = payload[i];
            }
            else if (payload[i]['type'] === null && values.type === 'No Information') {
                data[i] = payload[i];
            }
        }

        if (values.type === 'All') {
            setResponse(payload);
        }
        else {
            setResponse(data);
        }

        //eslint-disable-next-line
    }, [values.type]);

    // RETURNING VALUES...

    return { 
            handleSubmit, handleChange, handlePrint, fetchData,
            values, message, loading, response, types, printing };
}

export default DailyAttendanceForm;