import { useState, useContext } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const UpdateVendorForm = (props) => {

    // DECLARING STATES...

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    let initialValues =
    {
        id: data_context.update_data['id'],
        name: data_context.update_data['name'],
        address1: data_context.update_data['address1'],
        address2: data_context.update_data['address2'],
        district: data_context.update_data['district'],
        state: data_context.update_data['state'],
        country: data_context.update_data['country'],
        pincode: data_context.update_data['pincode'],
        gstin: data_context.update_data['gstin'],
        pan: data_context.update_data['pan'],
        tan: data_context.update_data['tan'],
        phone: data_context.update_data['phone'],
        contact_person: data_context.update_data['contact_person'],
        email: data_context.update_data['email']
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/vendor', payload, headers).then(response => {
                setLoading(false);
                if((response.status === 1) && (response.response_payload['result'] === 'success')) {
                    toast.success(response?.message);
                    let data = data_context.view_data.table_rows;
                    let index = GetKey(data, payload);
                    payload = { ...payload, sno: data[index]['sno'] };
                    data = ({ ...data, [index]: payload });
                    const row_values = Object.values(data);

                    let view_data = data_context.view_data;
                    view_data = ({ ...view_data, table_rows: row_values });
                    props.handleBack(view_data);
                } else {
                    toast.error(response?.message);
                }
            });
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_vendor");
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_mail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

        if (!values.id) {
            errors.id = "*Vendor ID is required";
        }

        if (!values.name) {
            errors.name = "*Name is required";
        }

        if (!values.address2) {
            errors.address2 = "*Address Line 2 is required";
        }

        if (!values.district) {
            errors.district = "*District is required";
        }

        if (!values.state) {
            errors.state = "*State is required";
        }

        if (!values.country) {
            errors.country = "*Country is required";
        }

        if (!values.pincode) {
            errors.pincode = "*Pincode is required";
        }

        if (!values.gstin) {
            errors.gstin = "*GSTIN is required";
        }

        if (!values.pan) {
            errors.pan = "*PAN is required";
        }

        if (!values.phone) {
            errors.phone = "*Phone is required";
        }

        if (!values.contact_person) {
            errors.contact_person = "*Contact Person is required";
        }

        if (!values.email) {
            errors.email = "*Email is required";
        }
        else if (!regex_mail.test(values.email)) {
            errors.email = "*Not a valid email id";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            name: values.name,
            address1: values.address1,
            address2: values.address2,
            district: values.district,
            state: values.state,
            country: values.country,
            pincode: values.pincode,
            gstin: values.gstin,
            pan: values.pan,
            tan: values.tan,
            phone: values.phone,
            contact_person: values.contact_person,
            email: values.email
        };

        return data;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, handleBack, values, errors, loading };
}

export default UpdateVendorForm;