import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import { Row } from 'react-data-grid';
import Get from '../HTTPRequest/Get';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import { toast } from 'react-toastify';

const ViewReceivedChequeForm = () => {
    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [columns] = useState(['Paid', 'Received']);
    const [values, setValues] = useState({ selected_column: columns[0]});
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false, 1: false});
    const [table_rows, setTableRows] = useState(data_context.view_data['table_rows'] || []);
    const [selected_row, setSelectedRow] = useState
        (
            data_context.view_data['selected_row'] !== undefined &&
                data_context.view_data['selected_row'] !== null
                ? data_context.view_data['selected_row'] : -1
        );
   
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/view_cheque');

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setMessage();
        setTableRows([]);
        setSelectedRow();
        setLoading({ ...loading, 0: true });
        let endpoint = '';
        values.selected_column === 'Paid' 
            ? endpoint = user_context.url + '/payment?route=paid_cheques&fiscal=' + data_context['fiscal']
            : endpoint = user_context.url + '/receipt?route=received_cheques&fiscal=' + data_context['fiscal'];
        const headers =
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${user_context.user_data[1]}`
        };

        await Get(endpoint, headers).then(response => {
            setLoading({ ...loading, 0: false });
            setMessage(response.message);
            if (response.status === 1) {
                if (response.response_payload['result'] === 'success') {
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        let date = CreateDate(payload[i]['date']);
                        date = FormatLocalDate(date);
                        payload[i]['date'] = date;
                        data[i] = payload[i];
                    }
                    setTableRows(data);
                }
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }  
    
    // FTECHING ATTACHMENT...

    const handleAttachment = () => {
        if(selected_row > -1) {
            const row = table_rows[selected_row];
            const docs = row['docs_url'];
            const base_url = user_context.url;
            const url = base_url.replace("/smarterpweb-api", "");
            const docs_url = `${url}/assets/${docs}`;
            window.open(docs_url, '_blank');
        } else {
            toast.error("No row selected");
        }        
    }

    // RETURNING VALUES...

    return {
        handleSubmit, 
        handleChange, 
        onRowClick, 
        rowRenderer,
        handleAttachment,
        columns, 
        message, 
        values, 
        loading, 
        table_rows, 
        permissions,
    }
}

export default ViewReceivedChequeForm;