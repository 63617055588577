import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import { Row } from 'react-data-grid';
import GetIndex from '../Parent/GetIndex';
import Get from '../HTTPRequest/Get';
import FormatLocalDate from '../Parent/FormatLocalDate';
import CreateDate from '../Parent/CreateDate';
import FormatDate from '../Parent/FormatDate';

const MiniStatementForm = () => {

    // DECLARING STATES...   

    const user_context = useContext(UserContext);
    const  data_context = useContext(DataContext);
    const [recipient_id, setRecipientID] = useState([]);
    const [recipient, setRecipient] = useState([]);
    const initialValues = { recipient_id: '', name: '', from: '', to: '' };
    const [values, setValues] = useState(initialValues);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false });
    const [table_rows, setTableRows] = useState([]);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=recipient';
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let recipient_id = response.response_payload[0]['id'] || [];
                        let recipient = response.response_payload[0]['name'] || [];                       

                        setRecipientID(recipient_id);
                        setRecipient(recipient);
                        setValues({...values, recipient_id: recipient_id[0], name: recipient[0]});
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });

        if (e.target.name === 'recipient_id') {
            setValues({ ...values, recipient_id: value, name: recipient[GetIndex(recipient_id, e.target.value)] });
        }
    }

    // SUBMITTING FORM...

    const handleShow = async () => {
        setMessage();
        setTableRows([]);

        if (values.recipient_id && values.from && values.to) {
            setLoading({ ...loading, 0: true });
            let endpoint = user_context.url + '/expense?id=' + values.recipient_id + '&field=' +FormatDate(values.from) +'&filter_key='+FormatDate(values.to) +'&route=mini_statement';
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Get(endpoint, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage(response.message);

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;

                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            let date = CreateDate(payload[i]['date']);
                            date = FormatLocalDate(date);
                            payload[i]['date'] = date;
                            data[i] = payload[i];
                        }

                        setTableRows(data);
                    }
                }
            });            
        }
        else {
            setMessage("Mandatory fields are empty");
        }
    }


    // ROW RENDERER...

    const rowRenderer = (props) => {

        return (
            <div>
                <Row {...props} />
            </div>

        );
    }

    // RETURNING VALUES...

    return { handleShow, handleChange, rowRenderer, message, values, loading, table_rows, recipient_id };


}

export default MiniStatementForm;