import React from "react";
import UpdatePurchaseOrderForm from "./UpdatePurchaseOrderForm";
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CustomAutocomplete from "../Components/CustomAutoComplete";
import { ToastContainer } from 'react-toastify';

const UpdatePurchaseOrder = (props) => {
    // DECLARING STATES...    

    const { ...destruct } = UpdatePurchaseOrderForm(props);

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                 {/* FORM FOR PURCHASE ORDER DETAILS... */}

                 <form className="location-form" onSubmit={destruct.handleSubmit}>
                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;PURCHASE&nbsp;ORDER</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Purchase Order ID:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="id" 
                            value={destruct.values.id} 
                            onChange={destruct.handleChange} 
                            disabled 
                        />
                        {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Reference No:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="ref_no" 
                            value={destruct.values.ref_no} 
                            onChange={destruct.handleChange} 
                            disabled 
                        />
                        {destruct.errors.ref_no && <p className="error">{destruct.errors.ref_no}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Date:</label>
                        <div className="datepicker" style={{ zIndex: 2 }}>
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Vendor:</label>
                        {destruct.vendor &&
                            <CustomAutocomplete
                                data={destruct.vendor}
                                placeholder="Search Vendor..."
                                selectValue={destruct.selectVendor}
                                selected_value={destruct.values.vendor_id}
                            />}
                        {destruct.errors.vendor_id && <p className="error">{destruct.errors.vendor_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Bill To:</label>
                        <select className="select" name="bill_to" value={destruct.values.bill_to} onChange={destruct.handleChange}>
                            {destruct.options && destruct.options.length
                                ? (destruct.options.map((item, i) => (<option key={i}>{item}</option>)))
                                : (<option value="">Loading...</option>)}
                        </select>
                        {destruct.errors.bill_to && <p className="error">{destruct.errors.bill_to}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Ship To:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="ship_to" 
                            value={destruct.values.ship_to} 
                            onChange={destruct.handleChange} 
                        />
                        {destruct.errors.ship_to && <p className="error">{destruct.errors.ship_to}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Deadline:</label>
                        <div className="datepicker" style={{ zIndex: 2 }}>
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.deadline}
                                    onChange={deadline => destruct.handleChange({ target: { value: deadline, name: 'deadline' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.deadline && <p className="error">{destruct.errors.deadline}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Status:</label>
                        <select className="select" name="status" value={destruct.values.status} onChange={destruct.handleChange}>
                            {destruct.statuses && destruct.statuses.length
                                ? (destruct.statuses.map((item, i) => (<option key={i}>{item}</option>)))
                                : (<option value="">Loading...</option>)}
                        </select>
                        {destruct.errors.status && <p className="error">{destruct.errors.status}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading[0]}>Update</button>
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>

                </form>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default UpdatePurchaseOrder;