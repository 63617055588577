import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import Post from '../HTTPRequest/Post';

const AddRecipientForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const initialValues =
    {
        name: '',
        address: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateForm(values);
        setErrors(errors);
        setMessage();

        if (Object.keys(errors).length === 0) {
            setLoading(true);

            let payload = createPayload(values);
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Post(user_context.url + '/recipient', payload, headers).then(response => {
                setLoading(false);
                setMessage(response.message);
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.name) {
            errors.name = "*Name is required";
        }

        if (!values.address) {
            errors.address = "*Address is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            name: values.name,
            address: values.address
        };

        return data;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, setValues, values, errors, message, loading };

}

export default AddRecipientForm;