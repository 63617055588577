
// MAIN FUNCTION THAT FORMATS THE DATE GIVEN...

const FormatLocalDate = (date) => {
    let formatted = '';
    if (date !== '' && date !== null && date !== undefined) {
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1;
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        formatted = dd + '-' + mm + '-' + yyyy;
    }

    return formatted;
}

export default FormatLocalDate;