import React from "react";
import AddCategoryForm from "./AddCategoryForm";
import ItemImage from "./ItemImage";
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

const AddCategory = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddCategoryForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR CATEGORY DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;CATEGORY</h1>
                    </div>

                    <div className="item-viewGroup">
                        <ItemImage image={destruct.image} placeholder={destruct.placeholder_image} width={destruct.dimension.width} height={destruct.dimension.height} />
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Image:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name='image_url' value={destruct.values.image_url} readOnly></input>
                            <button className="tax-button" type="button" onClick={destruct.handleDialog}><CloudUploadOutlinedIcon /></button>
                        </div>
                        {destruct.errors.image_url && <p className="error">{destruct.errors.image_url}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Name:</label>
                        <input type="text" autoComplete='off' className="input" name="name" value={destruct.values.name} onChange={destruct.handleChange} ></input>
                        {destruct.errors.name && <p className="error">{destruct.errors.name}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading[0]}>Save</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                    </div>

                </form>
            </div>

            {/* UPLOAD DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Image:</label>
                        <input type="text" autoComplete='off' className="input" name='image_url' value={destruct.values.image_url} readOnly></input>
                        <progress className="form-progress" value={destruct.progress[0]} max="100" />
                        <span className="progressText">{destruct.progress[0] + "%"}</span>
                        {destruct.image_errors && <p className="message">{destruct.image_errors}</p>}
                        {destruct.message[1] && <p className="message">{destruct.message[1]}</p>}

                        <input id="image_file" type="file" accept="image/*" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="image_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={destruct.handleUpload} disabled={destruct.loading[1]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                    </div>
                </div>
            </ReactDialogBox>}
        </div>
    );
}

export default AddCategory;