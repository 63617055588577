import React from "react";
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import AddPurchaseForm from "./AddPurchaseForm";
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import './tax.css';

const AddPurchase = (props) => {
    // DECLARING STATES...    

    const { ...destruct } = AddPurchaseForm(props);

    // MAIN FUNCTION...
    return (

        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR PURCHASE DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;PURCHASE</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Ledger ID:</label>
                        <input type="text" autoComplete='off' className="input" name="ledger_id" value={destruct.values.ledger_id} onChange={destruct.handleChange} disabled></input>
                        {destruct.errors.ledger_id && <p className="error">{destruct.errors.ledger_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Type:</label>
                        <div className="tax-formGroup">                           
                            <select className="select" name="type" value={destruct.values.type} onChange={destruct.handleChange} >
                                {destruct.types.map((item, i) => (<option key={i}>{item}</option>))}
                            </select>
                            <input type="text"
                                autoComplete='off'
                                className="tax-input"
                                name="tds"
                                value={destruct.values.tds}
                                placeholder="TDS %"
                                onChange={destruct.handleChange}
                                disabled={destruct.disable}>
                            </input>                            
                        </div>    
                        {destruct.errors.tds && <p className="error">{destruct.errors.tds}</p>}                   
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Taxable Value:</label>
                        <input type="text" autoComplete='off' className="input" name="taxable_value" value={destruct.values.taxable_value} onChange={destruct.handleChange}></input>
                        {destruct.errors.taxable_value && <p className="error">{destruct.errors.taxable_value}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">CGST:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name="cgst" value={destruct.values.cgst} onChange={destruct.handleChange}></input>
                            <input
                                type="text"
                                autoComplete='off'
                                className="tax-input"
                                name="cgst_percentage"
                                value={destruct.values.cgst_percentage}
                                placeholder="%"
                                onChange={destruct.handleChange}>
                            </input>
                            <button className="tax-button" type="button" onClick={() => destruct.handleCalculate('cgst')}><CalculateOutlinedIcon /></button>
                        </div>
                        {destruct.errors.cgst && <p className="error">{destruct.errors.cgst}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">SGST:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name="sgst" value={destruct.values.sgst} onChange={destruct.handleChange}></input>
                            <input
                                type="text"
                                autoComplete='off'
                                className="tax-input"
                                name="sgst_percentage"
                                value={destruct.values.sgst_percentage}
                                placeholder="%"
                                onChange={destruct.handleChange}>
                            </input>
                            <button className="tax-button" type="button" onClick={() => destruct.handleCalculate('sgst')}><CalculateOutlinedIcon /></button>
                        </div>
                        {destruct.errors.sgst && <p className="error">{destruct.errors.sgst}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">IGST:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name="igst" value={destruct.values.igst} onChange={destruct.handleChange}></input>
                            <input
                                type="text"
                                autoComplete='off'
                                className="tax-input"
                                name="igst_percentage"
                                value={destruct.values.igst_percentage}
                                placeholder="%"
                                onChange={destruct.handleChange}>
                            </input>
                            <button className="tax-button" type="button" onClick={() => destruct.handleCalculate('igst')}><CalculateOutlinedIcon/></button>
                        </div>
                        {destruct.errors.igst && <p className="error">{destruct.errors.igst}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Round Off:</label>
                        <input type="text" autoComplete='off' className="input" name="round_off" value={destruct.values.round_off} onChange={destruct.handleChange}></input>
                        {destruct.errors.round_off && <p className="error">{destruct.errors.round_off}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Total:</label>
                        <input type="text" autoComplete='off' className="input" name="total" value={destruct.values.total} onChange={destruct.handleChange}></input>
                        {destruct.errors.total && <p className="error">{destruct.errors.total}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Documents:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                            <button className="tax-button" type="button" onClick={destruct.handleDialog}><CloudUploadOutlinedIcon /></button>
                        </div>
                        {destruct.errors.docs_url && <p className="error">{destruct.errors.docs_url}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleSubmit} disabled={destruct.loading[0]}>Save</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>
                </form>
            </div>

            {/* UPLOAD DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>                        
                        <progress className="form-progress" value={destruct.progress} max="100" />
                        <span className="progressText">{destruct.progress + "%"}</span>
                        {destruct.docs_errors && <p className="message">{destruct.docs_errors}</p>}
                        {destruct.message[1] && <p className="message">{destruct.message[1]}</p>}

                        <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={destruct.viewPDF} disabled={destruct.loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={destruct.handleUpload} disabled={destruct.loading[2]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>                        
                    </div>
                </div>
            </ReactDialogBox>}
        </div>
    );
}

export default AddPurchase;