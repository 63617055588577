import { useState, useContext, useEffect } from 'react';
import UserContext from '../Parent/UserContext';
import { Row } from 'react-data-grid';
import Get from '../HTTPRequest/Get';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const StockSummaryForm = () =>{
    // DECLARING STATES...  

    const user_context = useContext(UserContext);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [key, setKey] = useState('');

    // FETCHING STOCK DATA...

    useEffect(() => {
        
        const fetchData = async () => {
            setMessage();
            setRows([]);
            setLoading(true);
            
            let endpoint = user_context.url + '/stock?route=stock_summary';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Get(endpoint, headers).then(response => {
                setLoading(false);
                setMessage(response.message);

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;

                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            data[i] = payload[i];
                        }
                        setRows(data);
                    }
                }
            });
        }

        fetchData();

        //eslint-disable-next-line
    }, [key]);

    // FILTERING DATA...

    const table_rows = rows.filter(row => row.name.toLowerCase().includes(key.toLowerCase()));

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { value } = e.target;
        setKey(value);
    }  

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                <Row {...props}/>
            </div>

        );
    }

    // GENERATING PDF FROM ARRAY OF OBJECTS...

    const generatePDF = () => {
        const doc = new jsPDF('l', 'pt', 'a4');
        const headers = ['S.No', 'Item', 'Model', 'Manufacturer', 'Category', 'Qty In', 'Qty Out', 'Balance'];
        const tableData = table_rows.map(obj => Object.values(obj));
        
        const title = 'STOCK REPORT';
        const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const centerX = (doc.internal.pageSize.width - titleWidth) / 2;
        doc.setTextColor(128, 128, 128);
        doc.text(title, centerX, 30);
        doc.setTextColor(0, 0, 0);

        doc.autoTable({
            head: [headers],
            body: tableData,
        });
        
        doc.save('STOCK.pdf');
    };

    // RETURNING VALUES...

    return { 
        rowRenderer, 
        generatePDF, 
        handleChange,
        message, 
        loading, 
        table_rows, 
        key 
    }
}

export default StockSummaryForm;