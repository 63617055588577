import React from "react";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { CSVLink } from "react-csv";
import LeaveListForm from "./LeaveListForm";

const LeaveList = () => {
    // DECLARING STATES...

    const { ...destruct } = LeaveListForm();
    const defaultColumnProperties = { sortable: false, resizable: true, editable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'name', name: 'Name', width: '15%' },
        { key: 'father', name: 'Father', width: '15%' },
        { key: 'salary', name: 'Salary', width: '9%' },
        { key: 'working', name: 'Working', width: '9%' },
        { key: 'present', name: 'Present', width: '9%' },
        { key: 'special', name: 'Special', width: '9%' },
        { key: 'leave', name: 'Leave', width: '9%' },
        { key: 'halfday', name: 'Half Day', width: '9%' },
        { key: 'no_information', name: 'No Info', width: '9%' },
        { key: 'holiday', name: 'Holiday', width: '9%' },
        { key: 'sunday', name: 'Sunday', width: '9%' },
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    const headers =
        [
            'S.No',
            'Name',
            'Father',
            'Salary',
            'Working Days',
            'Present',
            'Special',
            'Leave',
            'Half Day',
            'No Information',
            'Holiday',
            'Sunday'
        ];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="daily-formGroup">
                        <h1 className="form-head">LEAVE&nbsp;LIST</h1>
                    </div>

                    <div className="daily-formGroup">
                        <label className="attendance-label">Month:</label>
                        <select className="attendance-select" name="month" value={destruct.values.month} onChange={destruct.handleChange}>
                            {destruct.months.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>

                        <label className="attendance-label">Year:</label>
                        <select className="attendance-select" name="year" value={destruct.values.year} onChange={destruct.handleChange}>
                            {destruct.years.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>

                        <button className="view-button" type="submit" disabled={destruct.loading[0]}><RemoveRedEyeOutlinedIcon />&nbsp;Show</button>
                        <CSVLink className="view-button" data={destruct.table_rows.map(item => Object.values(item))} headers={headers} filename={'LEAVE_LIST.csv'} ><TableViewOutlinedIcon />&nbsp;Export</CSVLink>
                    </div>

                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>
            </div>
        </div>
    );
}

export default LeaveList;