import React from "react";
import BackupForm from "./BackupForm";
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';

const Backup = () =>{
    // DECLARING STATES...    

    const { ...destruct } = BackupForm();
 
    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">
                <form className="location-form" >
                    <div className="location-formGroup">
                        <h1 className="form-head">BACKUP</h1>
                    </div>

                    <div className="location-formGroup">
                        <input 
                            id="image_file" 
                            name="image_file" 
                            type="file" 
                            multiple
                            onChange={destruct.handleBrowse} 
                            onClick={(event) => { event.target.value = null }} 
                        />
                        <label 
                            htmlFor="image_file" 
                            className="button">
                            &nbsp;Browse
                        </label> 

                        <button 
                            className="button" 
                            type="button" 
                            disabled={destruct.loading[0]}
                            onClick={destruct.handleAssetsBackup}
                        >
                        <FolderCopyOutlinedIcon/>
                        &nbsp;Backup Assets
                        </button>               

                        <button 
                            className="button" 
                            type="button" 
                            disabled={destruct.loading[1]}
                            onClick={destruct.handleDatabaseBackup}
                        >
                        <StorageOutlinedIcon/>
                        &nbsp;Backup Database
                        </button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>} 
                    </div>                  

                </form>
            </div>
        </div>)
}

export default Backup;