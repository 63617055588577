import React, { useContext, useState } from "react";
import UserContext from "../Parent/UserContext";
import "./sidebar.css";
import Menu from "../Parent/Menu";

const Sidebar = (props) => {    
    // DECLARING VARIABLES...    

    const user_context = useContext(UserContext);
    const dashboard = [{ "name": "Home", "link": "/dashboard", "icon": "home" }];
    const [selected, setSelected] = useState();
    const location =
        [
            { "name": "Add Location", "link": "/add_location", "icon": "add_location" },
            { "name": "View Location", "link": "/view_location", "icon": "view_location" }
        ];
    const staff =
        [
            { "name": "Add Staff", "link": "/add_staff", "icon": "add_staff" },
            { "name": "View Staff", "link": "/view_staff", "icon": "view_staff" },
            { "name": "View Profile", "link": "/view_profile", "icon": "view_profile" }
        ];
    const partner =
        [
            { "name": "Add Partner", "link": "/add_partner", "icon": "add_partner" },
            { "name": "View Partner", "link": "/view_partner", "icon": "view_partner" }
        ];
    const work =
        [
            { "name": "Add Work", "link": "/add_work", "icon": "add_work" },
            { "name": "View Work", "link": "/view_work", "icon": "view_work" },
            { "name": "Add Map", "link": "/add_map", "icon": "add_map" },
            { "name": "View Map", "link": "/view_map", "icon": "view_map" },
        ];

    const expense =
        [
            { "name": "Add Recipient", "link": "/add_recipient", "icon": "add_recipient" },
            { "name": "View Recipient", "link": "/view_recipient", "icon": "view_recipient" },
            { "name": "Add Account", "link": "/add_account", "icon": "add_account" },
            { "name": "Make Entry", "link": "/make_entry", "icon": "make_entry" },
            { "name": "View Entry", "link": "/view_entry", "icon": "view_entry" },
            { "name": "View Statement", "link": "/view_statement", "icon": "view_statement" },
            { "name": "Mini Statement", "link": "/mini_statement", "icon": "mini_statement" },
            { "name": "Expense Sheet", "link": "/expense_sheet", "icon": "expense_sheet" },
            { "name": "Balance Summary", "link": "/balance_summary", "icon": "balance_summary" }
        ];

    const vendor =
        [
            { "name": "Add Vendor", "link": "/add_vendor", "icon": "add_vendor" },
            { "name": "View Vendor", "link": "/view_vendor", "icon": "view_vendor" },
            { "name": "Add Contract", "link": "/add_contract", "icon": "add_contract" },
            { "name": "View Contract", "link": "/view_contract", "icon": "view_contract" },
            { "name": "Add License", "link": "/add_license", "icon": "add_license" },
            { "name": "View License", "link": "/view_license", "icon": "view_license" }
        ];

    const client =
        [
            { "name": "Add Client", "link": "/add_client", "icon": "add_client" },
            { "name": "View Client", "link": "/view_client", "icon": "view_client" },
            { "name": "Add Agreement", "link": "/add_agreement", "icon": "add_agreement" },
            { "name": "View Agreement", "link": "/view_agreement", "icon": "view_agreement" },
            { "name": "Add Strength", "link": "/add_strength", "icon": "add_strength" },
            { "name": "View Strength", "link": "/view_strength", "icon": "view_strength" },
            { "name": "Show Class", "link": "/show_class", "icon": "show_class" }
        ];

    const attendance =
        [
            { "name": "Add Attendance", "link": "/add_attendance", "icon": "add_attendance" },
            { "name": "View Attendance", "link": "/view_attendance", "icon": "view_attendance" },
            { "name": "Daily Attendance", "link": "/daily_attendance", "icon": "daily_attendance" },
            { "name": "Monthly Attendance", "link": "/monthly_attendance", "icon": "monthly_attendance" },
            { "name": "Yearly Summary", "link": "/yearly_summary", "icon": "yearly_summary" },
            { "name": "Leave List", "link": "/leave_list", "icon": "leave_list" },            
            { "name": "Add Holiday", "link": "/add_holiday", "icon": "add_holiday" },
            { "name": "View Holiday", "link": "/view_holiday", "icon": "view_holiday"}            
        ];

    const heads =
        [
            { "name": "Add Head", "link": "/add_head", "icon": "add_head"},
            { "name": "View Head", "link": "/view_head", "icon": "view_head"},
        ];

    const tickets =
        [
            { "name": "Add Ticket", "link": "/add_ticket", "icon": "add_ticket" },
            { "name": "View Ticket", "link": "/view_ticket", "icon": "view_ticket" }
        ];

    const ledger =
        [
            { "name": "Add Ledger", "link": "/add_ledger", "icon": "add_ledger"},
            { "name": "View Ledger", "link": "/view_ledger", "icon": "view_ledger"},
            { "name": "Purchase", "link": "/view_purchase", "icon": "view_purchase"},
            { "name": "Sales", "link": "/view_sales", "icon": "view_sales"},
            { "name": "Credit Note", "link": "/view_credit_note", "icon": "view_credit_note"},
            { "name": "Debit Note", "link": "/view_debit_note", "icon": "view_debit_note"},
            { "name": "Payment", "link": "/view_payment", "icon": "view_payment"},
            { "name": "Receipt", "link": "/view_receipt", "icon": "view_receipt"},
            { "name": "Delivery Note", "link": "/view_delivery_note", "icon": "view_delivery_note"},
            { "name": "Receipt Note", "link": "/view_receipt_note", "icon": "view_receipt_note"},
            { "name": "Others", "link": "/view_others", "icon": "view_others"},
            { "name": "Show Ledger", "link": "/show_ledger", "icon": "show_ledger"},
        ];

    const item =
        [
            { "name": "Add Category", "link": "/add_category", "icon": "add_category"},
            { "name": "View Category", "link": "/view_category", "icon": "view_category"},
            { "name": "Add Item", "link": "/add_item", "icon": "add_item"},
            { "name": "View Item", "link": "/view_item", "icon": "view_item"}
        ];

    const stock =
        [
            { "name": "Add Stock", "link": "/add_stock", "icon": "add_stock"},
            { "name": "View Stock", "link": "/view_stock", "icon": "view_stock"},
            { "name": "Stock Summary", "link": "/stock_summary", "icon": "stock_summary"}
        ];
    const letters =
    [
        { "name": "Add PO", "link": "/add_purchase_order", "icon": "add_purchase_order"},
        { "name": "View PO", "link": "/view_purchase_order", "icon": "view_purchase_order"},        
        { "name": "Add Proposal", "link": "/add_proposal", "icon": "add_proposal"},
        { "name": "View Proposal", "link": "/view_proposal", "icon": "view_proposal"},
        { "name": "Add Quotation", "link": "/add_quotation", "icon": "add_quotation"},
        { "name": "View Quotation", "link": "/view_quotation", "icon": "view_quotation"},
        { "name": "Add Despatch", "link": "/add_letter_despatch", "icon": "add_letter_despatch"},
        { "name": "View Despatch", "link": "/view_letter_despatch", "icon": "view_letter_despatch"},
        { "name": "Add Received", "link": "/add_letter_received", "icon": "add_letter_received"},
        { "name": "View Received", "link": "/view_letter_received", "icon": "view_letter_received"},
        { "name": "Proposal Templates", "link": "/add_proposal_template", "icon": "add_proposal_template"},
        { "name": "Show Templates", "link": "/show_template", "icon": "show_template"},
    ];
    const cheque =
    [
        { "name": "View Cheques", "link": "/view_cheque", "icon": "view_cheque"}
    ];
    const task =
    [
        { "name": "Add Task", "link": "/add_task", "icon": "add_task"},
        { "name": "View Task", "link": "/view_task", "icon": "view_task"},
        { "name": "Sticky Note", "link": "/sticky_note", "icon": "sticky_note"}
    ];
    const invoice =
    [
        { "name": "Add Service Invoice", "link": "/add_service_invoice", "icon": "add_service_invoice"},
        { "name": "View Service Invoice", "link": "/view_service_invoice", "icon": "view_service_invoice"},
        { "name": "Show Service Invoices", "link": "/show_service_invoice", "icon": "show_service_invoice"}
    ];
    const duty =
    [
        { "name": "View Duty", "link": "/view_duty", "icon": "view_duty"},
        { "name": "Show Duty", "link": "/show_duty", "icon": "show_duty"},
        { "name": "Duty Summary", "link": "/duty_summary", "icon": "duty_summary"}
    ];
    const billings =
    [
        { "name": "Bill Goods", "link": "/bill_goods", "icon": "bill_goods"},
        { "name": "Bill Service", "link": "/bill_service", "icon": "bill_service"}         
    ];
    const office =
    [
        { "name": "Documents", "link": "/documents", "icon": "documents"}        
    ];
    const salary =
    [
        { "name": "Salary List", "link": "/salary_list", "icon": "salary_list"},
        { "name": "Salary Request", "link": "/salary_request", "icon": "salary_request"}        
    ];
    const settings =
    [
        { "name": "Add User", "link": "/add_user", "icon": "add_user"},
        { "name": "Backup", "link": "/backup", "icon": "backup"},
        { "name": "Fiscal Setup", "link": "/fiscal_setup", "icon": "fiscal_setup"},
        { "name": "Permissions", "link": "/permission", "icon": "permission"}
    ];

    // HANDLES SELECTION OF MENU ITEM...

    const handleMenuItemClick = (link) => {
        setSelected(link);
        props.handleUpdate({ key: '', selected_column: '', selected_row: '', table_rows: ''});
        props.handleBack({});
    };

    // CHECKS IF ANY MENU ITEM PRESENT IN USER DATA FOR A MENU...

    const hasMenuLinks = (menuItems) => {
        const flattenedUserMenuData = user_context.user_data[4].flat();
        return menuItems.some((menuItem) => flattenedUserMenuData.includes(menuItem.link));
    };

    // CONDITIONALLY RENDER EACH MEENU...

    const renderMenu = (title, menuItems) => {
        const shouldRenderMenu = hasMenuLinks(menuItems);        
        return shouldRenderMenu ? (
            <Menu 
                key={title} 
                title={title} 
                menu_item={menuItems} 
                open={props.open}
                setOpen={props.setOpen} 
                handleBack={props.handleBack} 
                handleUpdate={props.handleUpdate} 
                selected={selected}
                onMenuItemClick={handleMenuItemClick}
            />
        ) : null;
    };

    // RENDER ALL MENUS...

    const menusToRender = [
        { title: "Dashboard", items: dashboard },
        { title: "Location", items: location },
        { title: "Work", items: work },
        { title: "Staff", items: staff },
        { title: "Partner", items: partner },        
        { title: "Vendor", items: vendor },
        { title: "Client", items: client },
        { title: "Head", items: heads },
        { title: "Ledger", items: ledger },
        { title: "Expense", items: expense },
        { title: "Ticket", items: tickets },
        { title: "Attendance", items: attendance },
        { title: "Item", items: item },
        { title: "Stock", items: stock },
        { title: "Letters", items: letters },
        { title: "Cheque", items: cheque},
        { title: "Task", items: task},
        { title: "Invoice", items: invoice},
        { title: "Duty", items: duty},
        { title: "Billings", items: billings},
        { title: "Office", items: office},
        { title: "Salary", items: salary},
        { title: "Settings", items: settings}
    ];

    const renderedMenus = menusToRender.map(({ title, items }) =>
        renderMenu(title, items)
    ).filter(Boolean);
    

    // MAIN FUNCTION...

    return (
        // SIDEBAR COMPONENT...

        <div className="sidebar">
            <div className="sidebar-wrapper">
                <div className="sidebar-menu">

                    {/* ------------------------------------------------------------------------- */}
                    {/* MENUS... */}
                    {renderedMenus}                
                    {/* ------------------------------------------------------------------------- */}

                </div>
            </div>
        </div>

    );
}

export default Sidebar;