import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Get from '../HTTPRequest/Get';
import GetIndex from '../Parent/GetIndex';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';

const MonthlyAttendanceForm = () => {

    // STATES...   

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const years = ['2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'];
    const [staff_id, setStaffID] = useState([]);
    const [staff, setStaff] = useState([]);
    let initialValues = { staff_id: '', name: '', month: months[0], year: years[0] };
    const [values, setValues] = useState(initialValues);
    const [loading, setLoading] = useState({ 0: false, 1: false });
    const [message, setMessage] = useState();
    const [table_rows, setTableRows] = useState([]);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=staff';
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let staff_id = response.response_payload[0]['id'] || [];
                        let staff = response.response_payload[0]['name'] || [];

                        setStaffID(staff_id);
                        setStaff(staff);

                        setValues({
                            ...values, staff_id: staff_id[0], name: staff[0]
                        });
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });

        if (e.target.name === 'staff_id') {
            setValues({ ...values, staff_id: value, name: staff[GetIndex(staff_id, e.target.value)] });
        }
    }

    // SUBMITTING FORM...

    const handleShow = async () => {
        setMessage();
        setTableRows([]);

        if (values.staff_id) {
            setLoading({ ...loading, 0: true });

            let month = GetIndex(months, values.month) + 1;
            let endpoint = user_context.url + '/attendance?filter_key=' 
                            + month + '&field=' + values.year 
                            + '&id=' + values.staff_id 
                            + '&route=monthly_attendance';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Get(endpoint, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage(response.message);

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;

                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            let date = CreateDate(payload[i]['date']);
                            date = FormatLocalDate(date);
                            payload[i]['date'] = date;
                            data[i] = payload[i];
                        }

                        setTableRows(data);
                    }
                }
            });
        }
        else {
            setMessage("Select a staff to perform this action");
        }
    }

    // RETURNING VALUES...

    return { handleShow, handleChange, message, values, loading, table_rows, staff_id, months, years };
}

export default MonthlyAttendanceForm;