import React from "react";
import UpdateQuotationItemsForm from "./UpdateQuotationItemsForm";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ToastContainer } from 'react-toastify';

const UpdateQuotationItems = (props) => {

    // DECLARING STATES...    

    const { ...destruct } = UpdateQuotationItemsForm(props);

    // MAIN FUNCTION...

    return(
        <div className="page">
             <div className="location-wrapper">

                {/* FORM FOR QUOTATION ITEMS DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>
                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;QUOTATION&nbsp;ITEMS</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">ID:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="id" 
                            value={destruct.values.id} 
                            onChange={destruct.handleChange} 
                            disabled 
                        />
                        {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Quotation ID:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="quotation_id" 
                            value={destruct.values.quotation_id} 
                            onChange={destruct.handleChange} 
                            disabled 
                        />
                        {destruct.errors.quotation_id && <p className="error">{destruct.errors.quotation_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Item:</label>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={destruct.item}
                            getOptionLabel={(option) => option ? option.name : ''}
                            isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Search Item..." />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <span style={{ color: 'red' }}>{`ID: ${option.id}`}</span>
                                        <br />
                                        <span>{`Name: ${option.name}`}</span>
                                        <br />
                                        <span>{`Model: ${option.model}`}</span>
                                        <br />
                                        <span>{`Manufacturer: ${option.manufacturer}`}</span>
                                    </div>
                                </li>
                            )}
                            value={destruct.item.find(option => option.id === destruct.values.item_id) || null}
                            onChange={(event, value) => destruct.handleSelect(value)}
                            sx={{
                                width: '90%',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                "& .MuiInputBase-root": {
                                    fontFamily: 'sans-serif',
                                    height: 45,
                                    fontSize: '15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignContent: 'center'
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: 0
                                },
                                "&:hover fieldset": {
                                    border: 0
                                },
                                backgroundColor: '#FFFFFF',
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: '1px solid #9ecaed',
                                    boxShadow: '0 0 10px #9ecaed',
                                },
                            }}
                        />
                        {destruct.errors.item_id && <p className="error">{destruct.errors.item_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Quantity:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="quantity" 
                            value={destruct.values.quantity} 
                            onChange={destruct.handleChange} 
                        />
                        {destruct.errors.quantity && <p className="error">{destruct.errors.quantity}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Rate:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="rate" 
                            value={destruct.values.rate} 
                            onChange={destruct.handleChange} 
                        />
                        {destruct.errors.rate && <p className="error">{destruct.errors.rate}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button 
                            className="button" 
                            type="submit" 
                            disabled={destruct.loading}>Update
                        </button>
                    </div>

                    <div className="location-formGroup">
                        <button 
                            className="button" 
                            type="button" 
                            onClick={destruct.handleBack}>Back
                        </button>
                    </div>

                </form>
             </div>
             <ToastContainer/>
        </div>
    );
}

export default UpdateQuotationItems;