import React from "react";
import AddLetterDespatchForm from "./AddLetterDespatchForm";
import { ToastContainer } from 'react-toastify';
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const AddLetterDespatch = () => {

    // DECLARING STATES...    

    const { ...destruct } = AddLetterDespatchForm();

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="location-wrapper">

                 {/* FORM FOR LETTER DETAILS... */}

                 <form className="location-form" onSubmit={destruct.handleSubmit}>
                        
                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;LETTER&nbsp;DESPATCH</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Letter Type:</label>
                        <select className="select" name="letter_type" value={destruct.values.letter_type} onChange={destruct.handleChange} >
                            {destruct.letter && destruct.letter.id && destruct.letter.name
                                ? (destruct.letter.id.map((id, index) =>
                                    (<option key={id} value={id}>{destruct.letter.name[index]}</option>)))
                                : (<option value="">Loading...</option>)
                            }
                        </select>
                        {destruct.errors.letter_type && <p className="error">{destruct.errors.letter_type}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Party:</label>
                        <input
                            type="text"
                            autoComplete='off'
                            className="input"
                            name="party"
                            value={destruct.values.party}
                            onChange={destruct.handleChange}>
                        </input>
                        {destruct.errors.party && <p className="error">{destruct.errors.party}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button 
                            className="button" 
                            type="submit" 
                            disabled={destruct.loading[0]}>Save
                        </button>                       
                    </div>
                    
                 </form>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default AddLetterDespatch;