import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import { toast } from 'react-toastify';
import FormatDate from '../Parent/FormatDate';
import Post from '../HTTPRequest/Post';
import CreateBlob from '../Parent/CreateBlob';
import EncodeBase64 from '../Parent/EncodeBase64';
import CreateFileName from '../Parent/CreateFileName';
import Upload from '../HTTPRequest/Upload';

const AddLetterReceivedForm = () => {

    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const statuses = ['Active', 'Cancelled'];
    const [dialog, setDialog] = useState(false);
    const [docs_errors, setDocsErrors] = useState();
    const [docs, setDocs] = useState();

    const initialValues =
    {
        date: '',
        subject: '',
        party: '',
        status: statuses[0],
        docs_url: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({ 0: false, 1: false });   
    const [progress, setProgress] = useState(0);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {        
       
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Post(user_context.url + '/letter_received', payload, headers).then(response => {    
                setLoading({ ...loading, 0: false });
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                    toast.success(response?.message);                                        
                } else {
                    toast.error(response?.message);
                }
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.subject) {
            errors.subject = "*Subject is required";
        }

        if (!values.party) {
            errors.party = "*Party is required";
        }

        if (!values.docs_url) {
            errors.docs_url = "*Docs is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const payload =
        {
            date: FormatDate(values.date),
            subject: values.subject,
            party: values.party,
            status: values.status,
            docs_url: values.docs_url,
            fiscal: data_context['fiscal']
        };

        return payload;
    }

    // HANDLER FOR UPLOAD DIALOG...

    const handleDialog = () => {
        setDialog(!dialog);
    }

        // REGISTERING FILE INPUT VALUES...

        const handleBrowse = async (e) => {
            if (e.target.files && e.target.files.length > 0) {
                if (e.target.id === 'pdf_file') {
    
                    await EncodeBase64(e.target.files[0]).then((base64) => {
                        if (base64 !== null) {
                            setDocs(base64);
                        }
    
                        setValues({ ...values, docs_url: e.target.files[0].name });
                        setDocsErrors();
                    }).catch(() => {
                        setDocsErrors();
                        toast.error("File conversion error");
                    });
                }
            }
        }
    
        // VIEWING PDF...
    
        const viewPDF = () => {
            setLoading({ ...loading, 1: true });
            let errors = validateDocs();
    
            if (Object.keys(errors).length === 0) {
                if (docs !== undefined) {
                    var blob = CreateBlob(docs.split(",").pop());
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                        setLoading({ ...loading, 1: false });
                    }
                    else {
                        const blobUrl = URL.createObjectURL(blob);
                        window.open(blobUrl);
                        setLoading({ ...loading, 1: false });
                    }
                }
            } else {
                setLoading({ ...loading, 1: false });
            }
        }
    
        // VALIDATING UPLOADS...
    
        const validateDocs = () => {
            let errors = '';
    
            if (!values.docs_url) {
                errors = "*No pdf selected";
                setDocsErrors(errors);
            }
            else {
                let regex = /(?:\.([^.]+))?$/;
                let ext = regex.exec(values.docs_url)[1];
    
                if (ext !== "pdf" && ext !== "PDF") {
                    errors = "*Document is not a valid PDF file";
                    setDocsErrors(errors);
                }
            }
            return errors;
        }
    
        // CLEARING FIELDS...
    
        const handleClear = () => {
            setDocs();
            setValues({ ...values, docs_url: '' });
            setDocsErrors();
            setProgress(0);
        }
    
        // UPLOADING DOCS...
    
        const handleUpload = async () => {
            setProgress(0);
            let errors = validateDocs();
    
            if (Object.keys(errors).length === 0) {
                setLoading({ ...loading, 2: true });
                const options = {
                    headers: { 
                        'Accept': 'application/json', 
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${user_context.user_data[1]}` 
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress(percent);
                    }
                }
    
                if (docs !== null) {
                    let file_name = CreateFileName(values.docs_url);
                    const payload = { file_name: file_name, encoded_string: docs };
    
                    await Upload(user_context.url + '/upload', payload, options).then(response => {
                        setLoading({ ...loading, 2: false });
                        if (response['status'] === 1) {
                            toast.success(response?.message);
                            setValues({ ...values, docs_url: file_name });
                        } else {
                            toast.error(response?.message);
                        }
                    });
                }
            }
        }

    // RETURNING VALUES...

    return {
        handleChange,
        handleSubmit,   
        handleDialog,    
        handleUpload,
        handleClear,
        handleBrowse,
        viewPDF,
        values,
        errors,
        loading,
        statuses,
        dialog,
        progress,
        docs_errors
    };
    
}

export default AddLetterReceivedForm;