import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Get from '../HTTPRequest/Get';
import Post from '../HTTPRequest/Post';
import FormatDate from '../Parent/FormatDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import jsPDF from 'jspdf';
import { Row } from 'react-data-grid';
import { toast } from 'react-toastify';
import letter_head from '../Images/letter_head.png';

const AddProposalForm = () => {
    
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);    

    const initialValues =
    {
        date: '',
        client_id: '',
        proposal_type_id: '', 
        total: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({ 0: false });
    const [client, setClient] = useState([]);
    const [proposal_type, setProposalType] = useState([]);
    const [item, setItem] = useState([]);
    const [selected_row, setSelectedRow] = useState([]);
    const [table_rows, setTableRows] = useState([]);
    const [proposal_templates, setProposalTemplates] = useState([]);

    // SETTING UP DROPDOWNS...

    useEffect(() => {

        const fetchClient = async () => {
            let endpoint = user_context.url + '/dropdown?route=client_details';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let data = response.response_payload[0] || [];
                        const newItems = data.id.map((_, i) => ({
                            id: data.id[i],
                            name: data.address[i],
                            institution: data.institution[i]
                        }));

                        setClient(newItems);
                    }
                }
            });
        }

        const fetchProposalTypes = async () => {
            let endpoint = user_context.url + '/dropdown?route=proposal_types';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let data = response.response_payload[0] || [];
                        const newItems = data.id.map((_, i) => ({
                            id: data.id[i],
                            name: data.name[i]
                        }));

                        setProposalType(newItems);
                    }
                }
            });
        }

        const fetchItem = async () => {
            let endpoint = user_context.url + '/dropdown?route=item_details';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let item = response.response_payload[0] || [];

                        const newItems = item.id.map((_, i) => ({
                            id: item.id[i],
                            name: item.name[i],
                            manufacturer: item.manufacturer[i],
                            model: item.model[i],
                            unit: item.unit[i],
                            rate: item.rate[i]
                        }));

                        setItem(newItems);
                    }
                }
            });
        }

        const fetchProposalTemplates = async () => {
            let endpoint = user_context.url + '/proposal_templates';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;

                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            data[i] = payload[i];
                        }
                        setProposalTemplates(data);
                    }
                }
            });
        }

        fetchClient();
        fetchProposalTypes();
        fetchItem();
        fetchProposalTemplates();

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // FETCHING ITEMS FROM PROPOSAL TEMPLATES...

    useEffect(() => {
        if (values.proposal_type_id) {
            if (proposal_templates && proposal_templates.length) {
                let updated_rows = [];
                let sno = 0;

                for (let i = 0; i < proposal_templates.length; i++) {
                    if (values.proposal_type_id === proposal_templates[i]['proposal_type_id']) {
                        let item_id = proposal_templates[i]['item_id'];
                        sno = sno + 1;
                        const item_details = item_id ? item.find(data => data.id.includes(item_id)) : null;
                        let new_row_data =
                        {
                            sno: sno,
                            id: sno,
                            item_id: proposal_templates[i]['item_id'],
                            item: item_details ? item_details.name : null,
                            manufacturer: item_details ? item_details.manufacturer : null,
                            model: item_details ? item_details.model : null,
                            rate: proposal_templates[i]['rate'],
                            quantity: '',
                            unit: item_details ? item_details.unit : null,
                        }
                        updated_rows.push(new_row_data);
                    }
                }

                setTableRows(updated_rows);
            }
        }
        //eslint-disable-next-line
    }, [values.proposal_type_id]);

    // CALCULATING TOTAL...

    useEffect(() => {
        const calculatedTotal = table_rows.reduce((accumulator, product) => {
          return accumulator + product.rate * product.quantity;
        }, 0);
        setValues({...values, total: calculatedTotal});

        //eslint-disable-next-line
    }, [table_rows]);

    // ON SELECTION OF CLIENT...

    const selectClient = (item) => {
        if (item) {
            setValues({ ...values, client_id: item.id });
        } else {
            setValues({ ...values, client_id: '' });
        }
    }

    // ON SELECTION OF PROPOSAL TYPES...

    const selectProposalType = (item) => {
        if (item) {
            setValues({ ...values, proposal_type_id: item.id });
        }
    }

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLING NAME CHANGE...

    const handleItemChange = (event, id) => {
        const newValue = event.target.value;
        const updatedRows = table_rows.map((row) => {
            if (row.id === id) {
                return { ...row, item: newValue };
            }
            return row;
        });
        setTableRows(updatedRows);
    };

    // HANDLING RATE CHANGE...

    const handleRateChange = (event, id) => {
        const newValue = event.target.value;
        const updatedRows = table_rows.map((row) => {
            if (row.id === id) {
                let updatedRow = { 
                    ...row, 
                    rate: newValue 
                };
                if (row.quantity && newValue) {
                    updatedRow.amount = row.quantity * newValue;
                } else {
                    updatedRow.amount = 0;
                }
                return updatedRow;
            }
            return row;
        });
        setTableRows(updatedRows);
    };

    // HANDLING QUANTITY CHANGE...

    const handleQuantityChange = (event, id) => {
        const newValue = event.target.value;
        const updatedRows = table_rows.map((row) => {
            if (row.id === id) {
                let updatedRow = { 
                    ...row, 
                    quantity: newValue 
                };
                if (row.rate && newValue) {
                    updatedRow.amount = row.rate * newValue;
                } else {
                    updatedRow.amount = 0;
                }
                return updatedRow;
            }
            return row;
        });
        setTableRows(updatedRows);        
    };

    // SUBMITTING FORM...

    const handleSubmit = async () => {
        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            let flag = validateRows();            
            if (flag === 0) {
                setLoading({ ...loading, 0: true });

                let payload = createPayload(values);
                const headers =
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${user_context.user_data[1]}`
                };

                await Post(user_context.url + '/proposal', payload, headers).then(response => {    
                    setLoading({ ...loading, 0: false });
                    if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                        toast.success(response?.message);
                        generatePDF(response.response_payload);                         
                    } else {
                        toast.error(response?.message);
                    }
                });

            } else {
                toast.error("Some required data are missing in the table");
            }
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.client_id) {
            errors.client_id = "*Client is required";
        }

        if (!values.proposal_type_id) {
            errors.proposal_type_id = "*Proposal Type is required";
        }

        return errors;
    }

    // VALIDATING DATAGRID ROWS...

    const validateRows = () => {
        let flag = 0;
        table_rows.forEach(row => {

            if (row['item'] === '') {
                flag++;
            }

            if (row['rate'] === '') {
                flag++;
            }

            if (row['quantity'] === '') {
                flag++;
            }
        });

        return flag;
    }


    // MAKING POST DATA...

    const createPayload = (values) => {
        const payload =
        {
            date: FormatDate(values.date),
            client_id: values.client_id,
            proposal_type_id: values.proposal_type_id,
            fiscal: data_context['fiscal'],
            items: table_rows
        };

        return payload;
    }

    // GENERATING PDF...

    const generatePDF = (data) => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'A4'
        });

        doc.setFontSize(11);
        let yPosition = 20;
        doc.text('Ref No: ' + data?.ref_no, 10, yPosition + 45);
        doc.text('Date: ' + FormatLocalDate(values.date), 160, yPosition + 45);

        const imgWidth = 210; 
        const imgHeight = (imgWidth * 40) / 190; 
        doc.addImage(letter_head, 'PNG', 0, 10, imgWidth, imgHeight)

        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");

        const proposal_details = values.proposal_type_id ? proposal_type.find(data => data.id.includes(values.proposal_type_id)) : null;
        const titleText = proposal_details?.name ? 'PROPOSAL FOR ' + proposal_details.name.toUpperCase() : '';
        const textWidth = doc.getStringUnitWidth(titleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const xPosition = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(titleText, xPosition, yPosition + 60);

        const lineHeight = 0.1;
        const lineY = yPosition + 61;
        doc.setLineWidth(lineHeight);
        doc.line(xPosition, lineY, xPosition + textWidth, lineY);

        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.text('Party:', 15, yPosition + 70);
        doc.setFontSize(11);
        doc.setFont("helvetica", "normal");
        const client_details = values.client_id ? client.find(data => data.id.includes(values.client_id)) : null;
        doc.text('' + client_details?.institution + ', ' + client_details?.name, 17, yPosition + 75);

        yPosition = yPosition + 75;
        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.text('Note:', 115, yPosition + 10);
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.text('1) This proposal is valid for 7 days only.', 117, yPosition + 15);
        doc.text('2) All the rates are inclusive of GST.', 117, yPosition + 20);
       
        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.text('Account Details:', 15, yPosition + 10);
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.text('Beneficiary: Softech Smart Solutions', 17, yPosition + 15);
        doc.text('Account No: 1615020001936', 17, yPosition + 20);
        doc.text('Bank: The Federal Bank Limited', 17, yPosition + 25);
        doc.text('Branch: Jalandhar', 17, yPosition + 30);
        doc.text('IFSC: FDRL0001615', 17, yPosition + 35);

        const headers = ['SNo', 'Name', 'Manufacturer', 'Model', 'Unit', 'Quantity', 'Rate', 'Amount'];
        const items = table_rows; 
        const length = items?.length;

        let table_data = [];
        let totalAmount = 0;        

        for (let i = 0; i < length; i++) {
            const rate = parseFloat(items[i]['rate']).toFixed(2);
            const quantity = parseInt(items[i]['quantity']);
            let amount = parseFloat((rate * quantity).toFixed(2)) || 0;
            let amountText = amount.toLocaleString(undefined, { minimumFractionDigits: 2 });            
            
            table_data.push({
                sno: i + 1,
                item: items[i]['item'], 
                manufacturer: items[i]['manufacturer'],
                model: items[i]['model'],
                unit: items[i]['unit'],
                quantity: quantity,
                rate: rate,
                amount: amountText
            });

            totalAmount = totalAmount + parseFloat(amount);
        }
        
        const totalRow = {
            sno: { content: 'Total', colSpan: 7, styles: { halign: 'right' } },
            amount: totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })
        };
        table_data.push(totalRow);

        doc.autoTable({
            startY: yPosition + 40,
            head: [headers],
            body: table_data.map(row => Object.values(row)),
            rowPageBreak: 'avoid',
            styles: {
                cellPadding: 2,
                cellWidth: 'auto',
                valign: 'middle',
                halign: 'center', 
                lineWidth: 0.1,
                lineColor: [0, 0, 0], 
            },  
            headStyles: {
                fillColor: [48, 51, 78],
                textColor: [255, 255, 255], 
            },
            bodyStyles: {
                fillColor: [255, 255, 255], 
                textColor: [0, 0, 0]
            }
        });       

        doc.save(`${client_details?.name}.pdf`);
    };

    // ADDING ROW...

    const handleAddRow = () => {
        const sno = table_rows.length + 1;
        const new_row_data = {
            sno: sno,
            id: sno,
            item: '',
            item_id: null,
            manufacturer: '',
            model: '',
            rate: '',
            quantity: '',
            unit: 'Pcs'
        };

        setTableRows([...table_rows, new_row_data]);
    };

    // DELETING ROW...

    const handleDeleteRow = () => {
        if (selected_row && selected_row.length > 0) {
            const updatedRows = table_rows.filter(row => !selected_row.includes(row.id));
            
            updatedRows.forEach((row, idx) => {
                row.sno = idx + 1;
                row.id = idx + 1;
            });
            setTableRows(updatedRows);
            setSelectedRow([]);
        } else {
          toast.error("No rows selected");
        }
    };    
    
    // MOVE ROWS UP...

    const handleUp = () => {
        if(selected_row && selected_row.length > 0 && selected_row.length < 2){
            const id = selected_row[0];
            const index = table_rows.findIndex(row => row.id === id);
            if (index > 0) {
                const newRows = [...table_rows];
                const temp = newRows[index - 1];
                newRows[index - 1] = newRows[index];
                newRows[index] = temp;

                newRows.forEach((row, idx) => {
                    row.sno = idx + 1;
                });
                setTableRows(newRows);
            }
        } else {
            toast.error("Select a single row to move");
        }
    }

    // MOVE ROWS DOWN...

    const handleDown = () => {
        if(selected_row && selected_row.length > 0 && selected_row.length < 2){
            const id = selected_row[0];
            const index = table_rows.findIndex(row => row.id === id);
            if (index < table_rows.length - 1) {
                const newRows = [...table_rows];
                const temp = newRows[index + 1];
                newRows[index + 1] = newRows[index];
                newRows[index] = temp;

                newRows.forEach((row, idx) => {
                    row.sno = idx + 1;
                });
                setTableRows(newRows);
            }
        } else {
            toast.error("Select a single row to move");
        }
    }
      
    // RETURNING VALUES...

    return {
        handleChange,
        handleSubmit,
        selectClient,
        selectProposalType,
        rowRenderer,
        handleAddRow,
        handleDeleteRow,
        handleItemChange,
        handleRateChange,
        handleQuantityChange,
        setSelectedRow,
        handleUp,
        handleDown,
        values,
        errors,
        loading,
        client,
        proposal_type,
        table_rows,
        item,
        selected_row
    };
}

export default AddProposalForm;