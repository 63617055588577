import React from "react";
import AddLicenseForm from "./AddLicenseForm";

const AddLicense = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddLicenseForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR LICENSE DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;LICENSE</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Contract&nbsp;ID:</label>
                        <select className="select" name="contract_id" value={destruct.values.contract_id} onChange={destruct.handleChange}>
                            {destruct.contract && destruct.contract.id 
                            ? (destruct.contract.id.map((id, i) => (<option key={i} value={id}>{id}</option>)))    
                            : (<option value="">Loading...</option>)}
                        </select>
                        {destruct.errors.contract_id && <p className="error">{destruct.errors.contract_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Contract No:</label>
                        <input type="text" autoComplete='off' className="input" name="contract_no" value={destruct.values.contract_no} disabled></input>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Client&nbsp;ID:</label>
                        <select className="select" name="client_id" value={destruct.values.client_id} onChange={destruct.handleChange}>
                            {destruct.client && destruct.client.id 
                            ? (destruct.client.id.map((id, i) => (<option key={i} value={id}>{id}</option>)))    
                            : (<option value="">Loading...</option>)}
                        </select>
                        {destruct.errors.client_id && <p className="error">{destruct.errors.client_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Client:</label>
                        <input type="text" autoComplete='off' className="input" name="client" value={destruct.values.client} disabled></input>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Agreement&nbsp;ID:</label>
                        <select className="select" name="agreement_id" value={destruct.values.agreement_id} onChange={destruct.handleChange}>
                            {destruct.filtered && destruct.filtered.id 
                            ? (destruct.filtered.id.map((id, i) => (<option key={i} value={id}>{id}</option>)))    
                            : (<option value="">Loading...</option>)}
                        </select>
                        {destruct.errors.agreement_id && <p className="error">{destruct.errors.agreement_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Agreement:</label>
                        <input type="text" autoComplete='off' className="input" name="agreement" value={destruct.values.agreement} disabled></input>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">License:</label>
                        <input type="text" autoComplete='off' className="input" name="license" value={destruct.values.license} onChange={destruct.handleChange}></input>
                        {destruct.errors.license && <p className="error">{destruct.errors.license}</p>}
                    </div>

                    <div className="location-formGroup">
                    <button className="button" type="submit" disabled={destruct.loading[0]}>Save</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                    </div>

                </form>

            </div>
        </div>
    );
}

export default AddLicense;