import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import EncodeBase64 from '../Parent/EncodeBase64';
import CreateFileName from '../Parent/CreateFileName';
import no_image from '../Images/no_image.png';
import CreateBlob from '../Parent/CreateBlob';
import Upload from '../HTTPRequest/Upload';
import Get from '../HTTPRequest/Get';
import Post from '../HTTPRequest/Post';

const AddItemForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const initialValues =
    {
        category_id: '',
        name: '',
        manufacturer: '',
        model: '',
        hsn: '',
        cgst: '',
        sgst: '',
        igst: '',
        unit: '',
        image_url: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({ 0: '', 1: '' });
    const [loading, setLoading] = useState({ 0: false, 1: false });
    let placeholder_image = no_image;
    const [image, setImage] = useState(placeholder_image);
    const [image_errors, setImageErrors] = useState();
    const [progress, setProgress] = useState({ 0: 0 });
    const [dimension, setDimension] = useState({ width: '', height: '' });
    const [dialog, setDialog] = useState(false);
    const [item, setItem] = useState([]);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=category';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        const id = response.response_payload[0]['id'];
                        const name = response.response_payload[0]['name']
                        const newItems = id.map((_, i) => ({
                            id: id[i],
                            name: name[i]
                        }));
                    
                        setItem(newItems);
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);


    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // ON SELECTION...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, category_id: item.id });
        }        
    }

    // HANDLER FOR UPLOAD DIALOG...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // GETTING DIMENSIONS OF AN IMAGE...

    useEffect(() => {
        var blob = CreateBlob(image.split(",").pop());
        var _URL = window.URL || window.webkitURL;
        let img = new Image();
        var objectUrl = _URL.createObjectURL(blob);
        img.onload = function () {
            setDimension({ width: this.width, height: this.height });
            _URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;

        //eslint-disable-next-line    
    }, [image]);

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'image_file') {
                setProgress({ ...progress, 0: 0 });

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setImage(base64);
                    }
                    setValues({ ...values, image_url: e.target.files[0].name });
                    setImageErrors();
                    setMessage({ ...message, 1: '' });

                }).catch(() => {
                    setImageErrors();
                    setMessage({ ...message, 1: "File conversion error" });
                });
            }
        }
    }

    // CLEARING FIELDS...

    const handleClearImage = () => {
        setImage(placeholder_image);
        setValues({ ...values, image_url: '' });
        setImageErrors();
        setMessage({ ...message, 1: '' });
        setProgress({ ...progress, 0: 0 });

    }

    // VALIDATING UPLOADS...

    const validateUpload = () => {
        let errors = '';

        if (!values.image_url) {
            errors = "*No image selected";
            setImageErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.image_url)[1];

            if (ext !== "jpg" && ext !== "jpeg" && ext !== "JPG" && ext !== "JPEG") {
                errors = "*Image is not a valid JPG file";
                setImageErrors(errors);
            }
        }

        return errors;
    }

    // UPLOADING IMAGES...

    const handleUpload = async () => {
        if (message[1] === '') {
            setProgress({ ...progress, 0: 0 });
            setMessage({ ...message, 1: '' });
            let errors = validateUpload();

            if (Object.keys(errors).length === 0) {
                setLoading({ ...loading, 1: true });
                const options = {
                    headers:
                    {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${user_context.user_data[1]}`
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress({ ...progress, 0: percent });
                    }
                }

                if (image !== null) {
                    let file_name = CreateFileName(values.image_url);
                    const payload = { file_name: file_name, encoded_string: image };

                    await Upload(user_context.url + '/upload', payload, options).then(response => {
                        setMessage({ ...message, 1: response.message });
                        setLoading({ ...loading, 1: false });
                        if (response['status'] === 1) {
                            setValues({ ...values, image_url: file_name });
                        }
                    });
                }
            }
        } else {
            setMessage({ ...message, 1: "File already uploaded. Clear the field to upload again" });
        }
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Post(user_context.url + '/item', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage({ ...message, 0: response.message });
            });
        } else {
            setMessage({ 0: "Validation failed. Page up to check for errors" });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex = /^[0-9]*$/;

        if (!values.category_id) {
            errors.category_id = "*Category ID is required";
        }

        if (!values.name) {
            errors.name = "*Name is required";
        }

        if (!values.manufacturer) {
            errors.manufacturer = "*Manufacturer is required";
        }

        if (!values.model) {
            errors.model = "*Model is required";
        }

        if (!values.hsn) {
            errors.hsn = "*HSN is required";
        }

        if (values.cgst) {
            if (!regex.test(values.cgst)) {
                errors.tax = "*Only digits allowed";
            }
        }

        if (values.sgst) {
            if (!regex.test(values.sgst)) {
                errors.tax = "*Only digits allowed";
            }
        }

        if (values.igst) {
            if (!regex.test(values.igst)) {
                errors.tax = "*Only digits allowed";
            }
        }

        if (!values.unit) {
            errors.unit = "*Unit is required";
        }

        if (!values.image_url) {
            errors.image_url = "*Image is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            category_id: values.category_id,
            name: values.name,
            manufacturer: values.manufacturer,
            model: values.model,
            hsn: values.hsn,
            cgst: values.cgst || null,
            sgst: values.sgst || null,
            igst: values.igst || null,
            unit: values.unit,
            image_url: values.image_url
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleBrowse, handleClearImage,
        handleUpload, handleDialog, handleSelect, 
        values, errors, message, loading, progress, image, image_errors,
        placeholder_image, dimension, dialog, item
    };
}

export default AddItemForm;