import React from "react";
import UpdateCreditNoteForm from "./UpdateCreditNoteForm";
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';

const UpdateCreditNote = (props) => {
    // DECLARING STATES...    

    const { ...destruct } = UpdateCreditNoteForm(props);

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR CREDIT NOTE DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;CREDIT&nbsp;NOTE</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Credit Note ID:</label>
                        <input type="text" autoComplete='off' className="input" name="id" value={destruct.values.id} onChange={destruct.handleChange} disabled></input>
                        {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Ledger ID:</label>
                        <input type="text" autoComplete='off' className="input" name="ledger_id" value={destruct.values.ledger_id} onChange={destruct.handleChange} disabled></input>
                        {destruct.errors.ledger_id && <p className="error">{destruct.errors.ledger_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Against Invoice:</label>
                        <input type="text" autoComplete='off' className="input" name="against_invoice" value={destruct.values.against_invoice} onChange={destruct.handleChange}></input>
                        {destruct.errors.against_invoice && <p className="error">{destruct.errors.against_invoice}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Taxable Value:</label>
                        <input type="text" autoComplete='off' className="input" name="taxable_value" value={destruct.values.taxable_value} onChange={destruct.handleChange}></input>
                        {destruct.errors.taxable_value && <p className="error">{destruct.errors.taxable_value}</p>}
                    </div>

                    <div className="location-formGroup">
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="tax-input-long" name="cgst" value={destruct.values.cgst} placeholder="CGST" onChange={destruct.handleChange}></input>
                            <input type="text" autoComplete='off' className="tax-input-long" name="sgst" value={destruct.values.sgst} placeholder="SGST" onChange={destruct.handleChange}></input>
                            <input type="text" autoComplete='off' className="tax-input-long" name="igst" value={destruct.values.igst} placeholder="IGST" onChange={destruct.handleChange}></input>
                        </div>
                        {destruct.errors.tax && <p className="error">{destruct.errors.tax}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Round Off:</label>
                        <input type="text" autoComplete='off' className="input" name="round_off" value={destruct.values.round_off} onChange={destruct.handleChange}></input>
                        {destruct.errors.round_off && <p className="error">{destruct.errors.round_off}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Total:</label>
                        <input type="text" autoComplete='off' className="input" name="total" value={destruct.values.total} onChange={destruct.handleChange}></input>
                        {destruct.errors.total && <p className="error">{destruct.errors.total}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Documents:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                            <button className="tax-button" type="button" onClick={destruct.handleDialog}><CloudUploadOutlinedIcon /></button>
                        </div>
                        {destruct.errors.docs_url && <p className="error">{destruct.errors.docs_url}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleSubmit} disabled={destruct.loading[0]}>Update</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>
                </form>
            </div>

            {/* UPLOAD DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                        <progress className="form-progress" value={destruct.progress} max="100" />
                        <span className="progressText">{destruct.progress + "%"}</span>
                        {destruct.docs_errors && <p className="message">{destruct.docs_errors}</p>}
                        {destruct.message[1] && <p className="message">{destruct.message[1]}</p>}

                        <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={destruct.viewPDF} disabled={destruct.loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={destruct.handleUpload} disabled={destruct.loading[2]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                    </div>
                </div>
            </ReactDialogBox>}
        </div>
    );
}

export default UpdateCreditNote;