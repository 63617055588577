import React from "react";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { CSVLink } from "react-csv";
import ShowClassForm from "./ShowClassForm";

const ShowClass = () => {
    // DECLARING STATES...

    const { ...destruct } = ShowClassForm();
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'school', name: 'School', width: '38%' },
        { key: 'address', name: 'Address', width: '20%' },
        { key: 'agreement', name: 'Agreement', width: '15%' },
        { key: 'classes', name: 'Classes', width: '10%' },
        { key: 'license', name: 'License', width: '10%' }
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    const headers = ['S.No', 'School', 'Address', 'Classes', 'License', 'Agreement'];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="daily-formGroup">
                        <h1 className="form-head">SHOW&nbsp;CLASS</h1>
                    </div>

                    <div className="daily-formGroup">
                        <button className="view-button" type="submit" disabled={destruct.loading[0]}><RemoveRedEyeOutlinedIcon />&nbsp;Show</button>
                        {destruct.permissions[5] === '1' && <CSVLink className="view-button" data={destruct.table_rows.map(item => Object.values(item))} headers={headers} filename={'SHOW_CLASS.csv'} ><TableViewOutlinedIcon />&nbsp;Export</CSVLink>}
                    </div>

                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

            </div>
        </div>
    );
}

export default ShowClass;