import React from "react";
import AddHolidayForm from "./AddHolidayForm";
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const AddHoliday = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddHolidayForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR ATTENDANCE DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;HOLIDAY</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Reason:</label>
                        <input type="text" autoComplete='off' className="input" name="reason" value={destruct.values.reason} onChange={destruct.handleChange}></input>
                        {destruct.errors.reason && <p className="error">{destruct.errors.reason}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading}>Save</button>
                        {destruct.message && <p className="message">{destruct.message}</p>}
                    </div>

                </form>

            </div>
        </div>
    );
}

export default AddHoliday;