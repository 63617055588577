import React from "react";
import UpdateStockForm from "./UpdateStockForm";
import 'react-data-grid/dist/react-data-grid.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const UpdateStock = (props) => {
    // DECALRING STATES...

    const { ...destruct } = UpdateStockForm(props);

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="location-wrapper">
                {/* FORM FOR STOCK DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;STOCK</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Ledger ID:</label>
                        <input
                            type="text"
                            autoComplete='off'
                            className="input"
                            name="ledger_id"
                            value={destruct.values.ledger_id}
                            onChange={destruct.handleChange}
                            disabled>
                        </input>
                        {destruct.errors.ledger_id && <p className="error">{destruct.errors.ledger_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Type:</label>
                        <select className="select" name="type" value={destruct.values.type} onChange={destruct.handleChange}>
                            {destruct.types.map((item, i) => {
                                if (!destruct.disable && i === 0) {
                                    return null;
                                }
                                return <option key={i}>{item}</option>;
                            })}
                        </select>
                        {destruct.errors.type && <p className="error">{destruct.errors.type}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Item:</label>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={destruct.item}
                            getOptionLabel={(option) => option ? option.name : ''} 
                            isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Search Party..." />                                       
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                    <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                    <br />
                                    <span>{`Name: ${option.name}`}</span>
                                    <br />
                                    <span>{`Model: ${option.model}`}</span>
                                    <br />
                                    <span>{`Manufacturer: ${option.manufacturer}`}</span>
                                    </div>
                                </li>
                            )}
                            value={destruct.item.find(option => option.name === destruct.values.name) || null}
                            onChange={(event, value) => destruct.handleSelect(value)}
                            sx={{
                                width: '90%',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                "& .MuiInputBase-root": {
                                    fontFamily: 'sans-serif',
                                    height: 45,
                                    fontSize: '15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignContent: 'center'
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: 0
                                },
                                "&:hover fieldset": {
                                    border: 0
                                },
                                backgroundColor: '#FFFFFF',
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: '1px solid #9ecaed',
                                    boxShadow: '0 0 10px #9ecaed',
                                },
                            }}
                        />
                        {destruct.errors.recipient_id && <p className="error">{destruct.errors.recipient_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Model:</label>
                        <input
                            type="text"
                            autoComplete='off'
                            className="input"
                            name="model"
                            value={destruct.values.model}
                            onChange={destruct.handleChange}
                            disabled>
                        </input>                        
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Manufacturer:</label>
                        <input
                            type="text"
                            autoComplete='off'
                            className="input"
                            name="manufacturer"
                            value={destruct.values.manufacturer}
                            onChange={destruct.handleChange}
                            disabled>
                        </input>                        
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Rate:</label>
                        <input
                            type="text"
                            autoComplete='off'
                            className="input"
                            name="rate"
                            value={destruct.values.rate}
                            onChange={destruct.handleChange}>
                        </input>
                        {destruct.errors.rate && <p className="error">{destruct.errors.rate}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Qty In:</label>
                        <input
                            type="text"
                            autoComplete='off'
                            className="input"
                            name="qty_in"
                            value={destruct.values.qty_in}
                            onChange={destruct.handleChange}>
                        </input>
                        {destruct.errors.qty_in && <p className="error">{destruct.errors.qty_in}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Qty Out:</label>
                        <input
                            type="text"
                            autoComplete='off'
                            className="input"
                            name="qty_out"
                            value={destruct.values.qty_out}
                            onChange={destruct.handleChange}>
                        </input>
                        {destruct.errors.qty_out && <p className="error">{destruct.errors.qty_out}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleSubmit} disabled={destruct.loading[0]}>Update</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default UpdateStock;