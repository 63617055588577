import React, { useState, useContext } from "react";
import UserContext from '../Parent/UserContext';
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { ToastContainer } from 'react-toastify';
import Get from "../HTTPRequest/Get";
import { toast } from 'react-toastify';
import { CSVLink } from "react-csv";
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';

const DutySummary = () => {

    // STATES...

    const user_context = useContext(UserContext);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const years = ['2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'];
    let initialValues = { month: months[0], year: years[0] };
    const [values, setValues] = useState(initialValues);
    const [table_rows, setTableRows] = useState([]);
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '10%' },
        { key: 'id', name: 'ID', width: '10%' },
        { key: 'staff', name: 'Staff', width: '35%' },
        { key: 'total_distance', name: 'Total Distance', width: '15%' },
        { key: 'total_hours', name: 'Total Hours', width: '15%' },
        { key: 'total_days', name: 'Total Days', width: '15%' }
    ].map(c => ({ ...c, ...defaultColumnProperties }));
    const [loading, setLoading] = useState({ 0: false });
    const headers = ['S.No', 'ID', 'Staff', 'Total Distance', 'Total Hours', 'Total Days'];

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMIT FORM...

    const handleSubmit = async e => {

        e.preventDefault();
        setLoading({...loading, 0 : true});

        const headers = { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${user_context.user_data[1]}` 
        };

        const endpoint = user_context.url + '/duty?route=duty_summary&filter_key=' + values.month + '&field=' + values.year;

        await Get(endpoint, headers).then(response => {
            setLoading({ ...loading, 0: false });            
            if ((response.status === 1) && (response.response_payload['result'] === 'success')) { 
                toast.success(response?.message);               
                let payload = response.response_payload;
                let data_length = (Object.keys(payload).length) - 1;

                let data = ([]);
                for (let i = 0; i < data_length; i++) {
                    data[i] = payload[i];
                }
                setTableRows(data);                
            } else {
                toast.error(response?.message);
            }
        });
    }   

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">DUTY&nbsp;SUMMARY</h1>
                    </div>

                    <div className="view-formGroup">                       
                        <label className="attendance-label">Month:</label>
                        <select className="attendance-select" name="month" value={values.month} onChange={handleChange}>
                            {months.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>

                        <label className="attendance-label">Year:</label>
                        <select className="attendance-select" name="year" value={values.year} onChange={handleChange}>
                            {years.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>

                        <button 
                            className="view-button" 
                            type="submit" 
                            disabled={loading[0]}
                        ><RemoveRedEyeOutlinedIcon />&nbsp;Show
                        </button>

                        <CSVLink 
                            className="view-button" 
                            data={table_rows.map(item => Object.values(item))} 
                            headers={headers} filename={'TICKET.csv'} ><TableViewOutlinedIcon />&nbsp;Export
                        </CSVLink>

                    </div>
                </form>

                {/* DATA GRID */}

                <div className="long-table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={table_rows}
                        enableCellSelect={false}
                    />
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default DutySummary;