import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import { Row } from 'react-data-grid';
import Get from '../HTTPRequest/Get';

const BalanceSummaryForm = () => {

    // DECLARING STATES...  

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [table_rows, setTableRows] = useState([]);

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        setMessage();
        setTableRows([]);

        setLoading(true);
        let endpoint = user_context.url + '/expense?route=balance_summary&fiscal=' + data_context['fiscal'];
        const headers = 
        { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${user_context.user_data[1]}` 
        };

        await Get(endpoint, headers).then(response => {
            setLoading(false);
            setMessage(response.message);

            if (response.status === 1) {
                if (response.response_payload['result'] === 'success') {
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;

                    let data = ([]);
                    let total = 0;
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                        total = total + parseFloat(payload[i]['balance'] || 0);
                    }

                    total = parseFloat(total.toFixed(2));
                    let footer = { sno: '', recipient: 'TOTAL', balance: total };
                    data.push(footer);
                    setTableRows(data);
                }
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                <Row {...props}/>
            </div>

        );
    }

    // CELL RENDERER...

    const cellFormatter = (props) => 
    {        
        return(
            <div>
                {props.row['balance'] === '0.00'  &&  <label style={{ color: 'green' }}> {props.row['balance']} </label>}
                {props.row['balance'] !== '0.00'  &&  <label style={{ color: 'red' }}> {props.row['balance']} </label>}
            </div>
            
        );
    };

    // RETURNING VALUES...

    return { handleSubmit, rowRenderer, cellFormatter, message, loading, table_rows };

}

export default BalanceSummaryForm;