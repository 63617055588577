import React from "react";
import UpdateTicketForm from "./UpdateTicketForm";
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const UpdateTicket = (props) => {
    // DECLARING STATES...    

    const { ...destruct } = UpdateTicketForm(props);
    const isMobile = useMediaQuery({ query: `(max-width: 900px)` });

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="attendance-wrapper">

                {/* NORMAL VIEW ON A COMPUTER BROWSER... */}
                {!isMobile && <div className="attendance-form">
                    <div className="attendance-header">
                        <div className="location-formGroup">
                            <h1 className="form-head">UPDATE&nbsp;TICKET</h1>
                        </div>
                    </div>

                    <div className="attendance-body">
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Ticket ID:</label>
                                <input type="text" autoComplete='off' className="input" name="id" value={destruct.values.id} onChange={destruct.handleChange} disabled ></input>
                                {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Date:</label>
                                <div className="datepicker" style={{ zIndex: 2 }}>
                                    <CalendarMonthOutlinedIcon />
                                    <div className='datepicker-component'>
                                        <DatePicker
                                            className='datepicker-inner-component'
                                            dateFormat="dd-MM-yyyy"
                                            selected={destruct.values.date}
                                            onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                            fixedHeight
                                            todayButton="TODAY"
                                        />
                                    </div>
                                </div>
                                {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Client:</label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={destruct.item}
                                    getOptionLabel={(option) => option ? option.name : ''} 
                                    isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Search Client..." />                                       
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                                <br />
                                                <span>{`${option.name}`}</span>
                                            </div>
                                        </li>
                                    )}
                                    value={destruct.item.find(option => option.name === destruct.values.client) || null}
                                    onChange={(event, value) => destruct.handleSelect(value)}
                                    sx={{
                                        width: '90%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        "& .MuiInputBase-root": {
                                            fontFamily: 'sans-serif',
                                            height: 45,
                                            fontSize: '15px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0
                                        },
                                        "&:hover fieldset": {
                                            border: 0
                                        },
                                        backgroundColor: '#FFFFFF',
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: '1px solid #9ecaed',
                                            boxShadow: '0 0 10px #9ecaed',
                                        },
                                    }}
                                />
                                {destruct.errors.client_id && <p className="error">{destruct.errors.client_id}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Category:</label>
                                <select className="select" name="category" value={destruct.values.category} onChange={destruct.handleChange}>
                                    {destruct.category && destruct.category.id
                                        ? (destruct.category.name.map((id, i) => (<option key={i} value={id}>{id}</option>)))
                                        : (<option value="">Loading...</option>)}
                                </select>
                                {destruct.errors.category && <p className="error">{destruct.errors.category}</p>}
                            </div>                           
                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Type:</label>
                                <select className="select" name="type" value={destruct.values.type} onChange={destruct.handleChange} >
                                    {destruct.type.map((item, i) => (<option key={i}>{item}</option>))}
                                </select>
                                {destruct.errors.type && <p className="error">{destruct.errors.type}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Comment:</label>
                                <input type="text" autoComplete='off' className="input" name="comment" value={destruct.values.comment} onChange={destruct.handleChange}></input>
                                {destruct.errors.comment && <p className="error">{destruct.errors.comment}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Status:</label>
                                <select className="select" name="status" value={destruct.values.status} onChange={destruct.handleChange} >
                                    {destruct.statuses.map((item, i) => (<option key={i}>{item}</option>))}
                                </select>
                                {destruct.errors.status && <p className="error">{destruct.errors.status}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Staff Head:</label>
                                <input type="text" autoComplete='off' className="input" name="staff_head" value={destruct.values.staff_head} onChange={destruct.handleChange} disabled={destruct.disable[0]}></input>
                                {destruct.errors.staff_head && <p className="error">{destruct.errors.staff_head}</p>}
                            </div>                            
                        </div>

                        <div className="attendance-innerbox">  
                            <div className="location-formGroup">
                                <label className="label">Closing&nbsp;Date:</label>
                                <div className="datepicker">
                                    <CalendarMonthOutlinedIcon />
                                    <div className='datepicker-component'>
                                        <DatePicker
                                            className='datepicker-inner-component'
                                            dateFormat="dd-MM-yyyy"
                                            selected={destruct.values.closing_date}
                                            onChange={date => destruct.handleChange({ target: { value: date, name: 'closing_date' } })}
                                            fixedHeight
                                            todayButton="TODAY"
                                        />
                                    </div>
                                </div>
                                {destruct.errors.closing_date && <p className="error">{destruct.errors.closing_date}</p>}
                            </div>      

                            <div className="location-formGroup">
                                <label className="label">Documents:</label>
                                <div className="tax-formGroup">
                                    <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                                    <button className="tax-button" type="button" onClick={destruct.handleDialog}><CloudUploadOutlinedIcon /></button>
                                </div>
                            </div>

                            <div className="location-formGroup">
                                <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                            </div>
                        </div>
                    </div>

                    <div className="attendance-footer">
                        <div className="location-formGroup">
                            <button className="short-button" type="submit" onClick={destruct.handleSubmit} disabled={destruct.loading[0]}>Update</button>
                            {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                        </div>
                    </div>
                </div>}

                {/* MEDIA QUERY VIEW ON MOBILE DEVICE... */}

                {isMobile && <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;TICKET</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Ticket ID:</label>
                        <input type="text" autoComplete='off' className="input" name="id" value={destruct.values.id} onChange={destruct.handleChange} disabled ></input>
                        {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Date:</label>
                        <div className="datepicker" style={{ zIndex: 2 }}>
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Client:</label>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={destruct.item}
                            getOptionLabel={(option) => option ? option.name : ''} 
                            isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Search Client..." />                                       
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                        <br />
                                        <span>{`${option.name}`}</span>
                                    </div>
                                </li>
                            )}
                            value={destruct.item.find(option => option.name === destruct.values.client) || null}
                            onChange={(event, value) => destruct.handleSelect(value)}
                            sx={{
                                width: '90%',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                "& .MuiInputBase-root": {
                                    fontFamily: 'sans-serif',
                                    height: 45,
                                    fontSize: '15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignContent: 'center'
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: 0
                                },
                                "&:hover fieldset": {
                                    border: 0
                                },
                                backgroundColor: '#FFFFFF',
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: '1px solid #9ecaed',
                                    boxShadow: '0 0 10px #9ecaed',
                                },
                            }}
                        />
                        {destruct.errors.client_id && <p className="error">{destruct.errors.client_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Category:</label>
                        <select className="select" name="category" value={destruct.values.category} onChange={destruct.handleChange}>
                            {destruct.category && destruct.category.id
                                ? (destruct.category.name.map((id, i) => (<option key={i} value={id}>{id}</option>)))
                                : (<option value="">Loading...</option>)}
                        </select>
                        {destruct.errors.category && <p className="error">{destruct.errors.category}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Type:</label>
                        <select className="select" name="type" value={destruct.values.type} onChange={destruct.handleChange} >
                            {destruct.type.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.type && <p className="error">{destruct.errors.type}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Comment:</label>
                        <input type="text" autoComplete='off' className="input" name="comment" value={destruct.values.comment} onChange={destruct.handleChange}></input>
                        {destruct.errors.comment && <p className="error">{destruct.errors.comment}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Status:</label>
                        <select className="select" name="status" value={destruct.values.status} onChange={destruct.handleChange} >
                            {destruct.statuses.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.status && <p className="error">{destruct.errors.status}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Staff Head:</label>
                        <input type="text" autoComplete='off' className="input" name="staff_head" value={destruct.values.staff_head} onChange={destruct.handleChange} disabled={destruct.disable[0]}></input>
                        {destruct.errors.staff_head && <p className="error">{destruct.errors.staff_head}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Closing&nbsp;Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.closing_date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'closing_date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.closing_date && <p className="error">{destruct.errors.closing_date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                        <progress className="form-progress" value={destruct.progress} max="100" />
                        <span className="progressText">{destruct.progress + "%"}</span>
                        {destruct.docs_errors && <p className="message">{destruct.docs_errors}</p>}
                        {destruct.message[1] && <p className="message">{destruct.message[1]}</p>}

                        <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>

                        <button className="button" type="button" onClick={destruct.viewPDF} disabled={destruct.loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={destruct.handleUpload} disabled={destruct.loading[2]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleDocsClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                        <button className="button" type="submit" disabled={destruct.loading[0]}>Update</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>
                </form>}
            </div>
            {/* UPLOAD DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>                        
                        <progress className="form-progress" value={destruct.progress} max="100" />
                        <span className="progressText">{destruct.progress + "%"}</span>
                        {destruct.docs_errors && <p className="message">{destruct.docs_errors}</p>}
                        {destruct.message[1] && <p className="message">{destruct.message[1]}</p>}

                        <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={destruct.viewPDF} disabled={destruct.loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={destruct.handleUpload} disabled={destruct.loading[2]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>                        
                    </div>
                </div>
            </ReactDialogBox>}
        </div>
    );
}

export default UpdateTicket;