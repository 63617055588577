import React from "react";
import AddProposalTemplateForm from "./AddProposalTemplateForm";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DataGrid from 'react-data-grid';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';

const AddProposalTemplate = () =>{
    // DECLARING STATES...    

    const { ...destruct } = AddProposalTemplateForm();
    const defaultColumnProperties = { sortable: false, resizable: true };

    const table_columns = [
        { key: 'sno', name: 'S.No', width: '10%' },
        { key: 'id', name: 'ID', width: '10%' },
        { key: 'item_id', name: 'Item ID', width: '10%' },
        { key: 'name', name: 'Name', width: '20%' },
        { key: 'manufacturer', name: 'Manufacturer', width: '20%' },
        { key: 'model', name: 'Model', width: '20%' },
        { key: 'rate', name: 'Rate', width: '10%' }
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="view-wrapper">
                <div className="view-form">
                    <div className="view-formGroup">
                        <h1 className="form-head">ADD&nbsp;PROPOSAL&nbsp;TEMPLATE</h1>
                    </div>

                    <div className="attendance-body">
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Item:</label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={destruct.item}
                                    getOptionLabel={(option) => option ? option.name : ''} 
                                    isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Search Item..." />                                       
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                                <br />
                                                <span>{`Name: ${option.name}`}</span>
                                                <br />
                                                <span>{`Model: ${option.model}`}</span>
                                                <br />
                                                <span>{`Manufacturer: ${option.manufacturer}`}</span>
                                                <br />
                                                <span>{`Last Purchase Price: ${option.rate}`}</span>
                                            </div>
                                        </li>
                                    )}
                                    onChange={(event, value) => destruct.selectItem(value)}
                                    sx={{
                                        width: '90%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        "& .MuiInputBase-root": {
                                            fontFamily: 'sans-serif',
                                            height: 45,
                                            fontSize: '15px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0
                                        },
                                        "&:hover fieldset": {
                                            border: 0
                                        },
                                        backgroundColor: '#FFFFFF',
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: '1px solid #9ecaed',
                                            boxShadow: '0 0 10px #9ecaed',
                                        },
                                    }}
                                />
                                {destruct.errors.item_id && <p className="error">{destruct.errors.item_id}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Proposal Type:</label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={destruct.proposal_types}
                                    getOptionLabel={(option) => option ? option.name : ''} 
                                    isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Search Proposal Type..." />                                       
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                                <br />
                                                <span>{`Name: ${option.name}`}</span>
                                            </div>
                                        </li>
                                    )}
                                    onChange={(event, value) => destruct.selectProposalType(value)}
                                    sx={{
                                        width: '90%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        "& .MuiInputBase-root": {
                                            fontFamily: 'sans-serif',
                                            height: 45,
                                            fontSize: '15px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0
                                        },
                                        "&:hover fieldset": {
                                            border: 0
                                        },
                                        backgroundColor: '#FFFFFF',
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: '1px solid #9ecaed',
                                            boxShadow: '0 0 10px #9ecaed',
                                        },
                                    }}
                                />
                                {destruct.errors.proposal_type_id && <p className="error">{destruct.errors.proposal_type_id}</p>}
                            </div>                            
                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Rate:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="rate"
                                    value={destruct.values.rate}
                                    onChange={destruct.handleChange}>
                                </input>
                                {destruct.errors.rate && <p className="error">{destruct.errors.rate}</p>}
                            </div>

                            <div className="location-formGroup">
                                <button 
                                    className="button" 
                                    type="button" 
                                    disabled={destruct.loading[0]} 
                                    onClick={destruct.handleSubmit}><SaveOutlinedIcon/>&nbsp;Save
                                </button>
                                {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                            </div>
                        </div>   

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Profit Percentage:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="percentage"
                                    value={destruct.values.percentage}
                                    onChange={destruct.handleChange}>
                                </input>
                                {destruct.errors.percentage && <p className="error">{destruct.errors.percentage}</p>}
                            </div>

                            <div className="location-formGroup">
                                <button 
                                    className="button" 
                                    type="button"                                     
                                    onClick={destruct.handleCalculate}><CalculateOutlinedIcon/>&nbsp;Calculate
                                </button>
                                {destruct.message[1] && <p className="message">{destruct.message[1]}</p>}
                            </div>
                        </div>                               

                    </div>  
                </div>

                {/* DATA GRID */}

                <div className="short-table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        onRowClick={destruct.onRowClick}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

                {/* FOOTER BUTTONS */}

                <div className="view-footer">
                    <button
                        className="view-button"
                        type="button"
                        onClick={destruct.handleDelete}                        
                    ><DeleteForeverOutlinedIcon />&nbsp;Delete
                    </button>                                       
                </div>  

            </div>

            {/* DELETE DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='20%'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'
            >

                <div className="dialog-container">
                    <label>Do you really want to delete?</label>
                    <button className="button" type="button" onClick={destruct.handleConfirm}>Yes</button>
                </div>
            </ReactDialogBox>}
        </div>
    );
}

export default AddProposalTemplate;