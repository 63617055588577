import React, { useRef } from "react";
import DailyAttendanceForm from "./DailyAttendanceForm";
import { useMediaQuery } from 'react-responsive';
import DatePicker from "react-datepicker";
import './dailyAttendance.css';
import DailyRow from "./DailyRow";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ReactToPrint from "react-to-print";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';


const DailyAttendance = () => {
    
    // DECLARING STATES...

    const { ...destruct } = DailyAttendanceForm();
    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });
    let componentRef = useRef();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="daily-wrapper">

                {/* SEARCH BOX */}

                <form className="daily-form" onSubmit={destruct.handleSubmit}>
                    <div className="daily-formGroup">
                        <h1 className="form-head">DAILY&nbsp;ATTENDANCE</h1>
                    </div>

                    <div className="daily-formGroup">
                        <label className="daily-label">Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {!isMobile && <select className="view-select" name="type" value={destruct.values.type} onChange={destruct.handleChange}>
                            {destruct.types.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>}
                        <button className="view-button" type="submit" disabled={destruct.loading[0]}>View&nbsp;All</button>
                        {!isMobile && <ReactToPrint trigger={() => <button className="view-button" type="button"><PrintOutlinedIcon />&nbsp;Preview</button>} content={() => componentRef.current} />}

                    </div>
                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* CONTENT BODY TO DISPLAY ATTENDANCE */}

                <div className="daily-container" >
                    <div className="daily-container-inner" ref={componentRef}>
                        <DailyRow response={destruct.response} destruct={destruct}/>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default DailyAttendance;