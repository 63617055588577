import { React, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ log_auth, component: Component, ...rest }) => {
    const user_values = useContext(UserContext);
    return (
        <Route {...rest} render={(props) => {
            if (user_values.log_auth) return <Component {...props}
                handleUpdate={rest.handleUpdate}
                handleBack={rest.handleBack}
            />;
            if (!user_values.log_auth) return <Redirect to={{ path: "/", state: { from: props.location } }} />;
        }} />
    );
}

export default ProtectedRoute;
