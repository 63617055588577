import { React } from "react";
import DashboardForm from "./DashboardForm";
import "./dashboard.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-data-grid/dist/react-data-grid.css';
import { useMediaQuery } from 'react-responsive';
import Skeleton from '@mui/material/Skeleton';
import Chart from 'react-apexcharts'
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import no_image from '../Images/no_image.png';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import './birthdayCard.css';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

const Dashboard = () => {

    // DECLARING STATES...    
    const { ...destruct } = DashboardForm();
    const isMobile = useMediaQuery({ query: `(max-width: 1150px)` });

    // USER CARD...

    const UserCard = (
        <>
            {destruct.loading[0] &&
                <div className="dashboard-card">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Skeleton animation="wave" width={120} />
                        <Skeleton animation="wave" width={120} />
                    </div>

                    <div className="dashboard-logo">
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'end' }}>
                            <Skeleton animation="wave" variant="circular" width={120} height={120} />
                        </div>
                    </div>
                </div>}

            {!destruct.loading[0] &&
                <div className="dashboard-card">
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row', height: '100%', alignItems: 'center', margin: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <li className="dashboard-label">
                                {destruct.user[1].toUpperCase()}
                            </li>
                            <li className="dashboard-label">
                                ID:&nbsp;{destruct.user[4]}
                            </li>
                        </div>
                        <div className="dashboard-logo">
                            <div style={{ height: '100%', display: 'flex', justifyContent: 'end' }}>
                                <LazyLoadImage
                                    style={{ width: '100px', height: '100px', borderRadius: '50%', aspectRatio: '2/2' }}
                                    src={(destruct.user[3] !== 'no_such_data') ? destruct.user[3] : no_image}
                                    alt="Not found"
                                />
                            </div>
                        </div>
                    </div>
                </div>}
        </>

    );

    // ATTENDANCE CARD...

    const options = {
        dataLabels: { enabled: false },
        labels: ['Present', 'Leave', 'Half Day', 'Special', 'No Info'],
        responsive: [{ breakpoint: 480, options: { legend: { position: 'bottom' }, dataLabels: { enabled: false } } }]
    };

    const series =
        [
            parseInt(destruct?.attendance?.present_count || 0),
            parseInt(destruct?.attendance?.leave_count || 0),
            parseInt(destruct?.attendance?.half_day_count || 0),
            parseInt(destruct?.attendance?.special_count || 0),
            parseInt(destruct?.attendance?.no_info_count || 0)
        ];

    const AttendanceCard = (
        <>
            {destruct.loading[0] &&
                <div className="dashboard-card">
                    <Skeleton animation="wave" variant="circular" width={120} height={120} />
                </div>}

            {!destruct.loading[0] &&
                <div className="dashboard-card">
                    <Chart type='donut' width={500} height={150} options={options} series={series} />
                </div>}
        </>
    );

    // TASK CARD...

    const table_columns = Object.keys(destruct.task[0] || {});
    const skeletonRow = (height, marginRight) => (
        <div style={{ width: '90%', height: 40, display: 'flex', flexDirection: 'row' }}>
            <Skeleton animation="wave" width={'25%'} height={height} style={{ marginRight: marginRight }} />
            <Skeleton animation="wave" width={'25%'} height={height} style={{ marginRight: marginRight }} />
            <Skeleton animation="wave" width={'25%'} height={height} style={{ marginRight: marginRight }} />
            <Skeleton animation="wave" width={'25%'} height={height} />
        </div>
    );
    const TaskCard = (
        <>
            {destruct.loading[0] && (
                <div className="dashboard-bigcard" style={{ minHeight: 300, display: 'flex', flexDirection: 'column' }}>
                    {skeletonRow(40, '10px')}
                    {skeletonRow(30, '10px')}
                    {skeletonRow(20, '10px')}
                    {skeletonRow(20, '10px')}
                </div>
            )}

            {!destruct.loading[0] && (
                <div className="dashboard-bigcard" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <TableContainer style={{ minHeight: 290, maxHeight: 290, overflowY: 'scroll' }}>
                        {destruct.task.length === 0 ? (
                            <p style={{
                                textAlign: 'center',
                                padding: '10px',
                                fontWeight: 'bold',
                                fontSize: '22px',
                                color: 'gray'
                            }}>
                                <EmojiEventsOutlinedIcon style={{ marginRight: '5px' }} /> No Pending Tasks
                            </p>
                        ) : (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {table_columns.map((column) => (
                                            <TableCell key={column}>{column.toUpperCase()}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {destruct.task.map((row, index) => (
                                        <TableRow key={index}>
                                            {table_columns.map((column) => (
                                                <TableCell key={column} style={{ padding: 5 }}>
                                                    {row[column]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </div>
            )}
        </>
    );


    // PENDING TASKS...

    const skeletonCount = 4;

    const PendingTask = (
        <>
            {destruct.loading[0] && <div className="dashboard-bigcardscroll" style={{ minHeight: 300, maxHeight: 300, overflowY: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '95%' }}>
                    {[...Array(skeletonCount)].map((_, index) => (
                        <div key={index} style={{ width: '100%', margin: '5px', display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: '10px' }}>
                                <Skeleton animation="wave" variant="circle" width={50} height={50} style={{ borderRadius: '50%' }} />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Skeleton animation="wave" width={100} height={20} />
                                <Skeleton animation="wave" width={100} height={10} />
                                <Skeleton animation="wave" width={100} height={10} />
                            </div>

                            <div style={{ flex: 1, marginLeft: '10px', position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <Skeleton animation="wave" width={'100%'} height={15} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>}

            {!destruct.loading[0] && <div className="dashboard-bigcardscroll" style={{ minHeight: 300, maxHeight: 300, overflowY: 'auto' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '95%' }}>
                    {destruct.pending.map((row, index) => (
                        <div key={index} style={{ width: '100%', margin: '5px', display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: '10px' }}>
                                <img
                                    src={row['avatar'] ? `${destruct.url}/assets/${row['avatar']}` : no_image}
                                    alt="Avatar"
                                    style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: 'bold', color: '#555' }}>
                                    {row['name']}
                                </div>
                                <div style={{ fontSize: '12px', color: 'gray' }}>
                                    {`Total Tasks: ${row['total_tasks_count']}`}
                                </div>
                                <div style={{ fontSize: '12px', color: 'gray' }}>
                                    {`Pending Tasks: ${row['pending_tasks_count']}`}
                                </div>
                            </div>
                            <div style={{ flex: 1, marginLeft: '10px', position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '100%', height: '8px', backgroundColor: '#f0f0f0', borderRadius: '4px', overflow: 'hidden' }}>
                                    <div style={{ width: `${row['closed_tasks_percentage']}%`, height: '100%', backgroundColor: '#787eff', borderRadius: '4px' }}></div>
                                </div>
                                <span style={{ marginLeft: '5px', fontSize: '10px', color: 'gray' }}>{`${row['closed_tasks_percentage']}%`}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
        </>
    );

    // BUSINESS CARD...

    const business_chart = {
        options: {
            chart: {
                id: 'bar-chart'
            },
            xaxis: {
                categories: ['Purchase', 'Sales']
            }
        },
        series: [{
            name: 'Total',
            data: [
                destruct?.business?.total_purchase ? parseFloat(destruct.business.total_purchase) : 0,
                destruct?.business?.total_sales ? parseFloat(destruct.business.total_sales) : 0
            ]
        }]
    }

    const BusinessCard = (
        <div className="dashboard-bigcard">
            <Chart options={business_chart.options} series={business_chart.series} type="bar" width="400" />
        </div>
    );

    // EXPENSE CARD...

    const expense_chart = {
        options: {
            chart: {
                id: 'line-chart'
            },
            xaxis: {
                categories: ['April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'January', 'February', 'March']
            },
            colors: ['#8B8000'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 3
            },
            markers: {
                size: 6
            }
        },
        series: [{
            name: 'Monthly Expenses',
            data: [
                destruct?.expense[3]?.total_credit ? parseFloat(destruct?.expense[3]?.total_credit) : 0,
                destruct?.expense[4]?.total_credit ? parseFloat(destruct?.expense[4]?.total_credit) : 0,
                destruct?.expense[5]?.total_credit ? parseFloat(destruct?.expense[5]?.total_credit) : 0,
                destruct?.expense[6]?.total_credit ? parseFloat(destruct?.expense[6]?.total_credit) : 0,
                destruct?.expense[7]?.total_credit ? parseFloat(destruct?.expense[7]?.total_credit) : 0,
                destruct?.expense[8]?.total_credit ? parseFloat(destruct?.expense[8]?.total_credit) : 0,
                destruct?.expense[9]?.total_credit ? parseFloat(destruct?.expense[9]?.total_credit) : 0,
                destruct?.expense[10]?.total_credit ? parseFloat(destruct?.expense[10]?.total_credit) : 0,
                destruct?.expense[11]?.total_credit ? parseFloat(destruct?.expense[11]?.total_credit) : 0,
                destruct?.expense[0]?.total_credit ? parseFloat(destruct?.expense[0]?.total_credit) : 0,
                destruct?.expense[1]?.total_credit ? parseFloat(destruct?.expense[1]?.total_credit) : 0,
                destruct?.expense[2]?.total_credit ? parseFloat(destruct?.expense[2]?.total_credit) : 0,
            ]
        }]
    };


    const ExpenseCard = (
        <div className="dashboard-bigcard">
            <Chart options={expense_chart.options} series={expense_chart.series} type="line" width="400" />
        </div>
    );

    // BIRTHDAY CARD...

    const BirthdayCard = (
        <div className='dashboard-card' onClick={destruct.handleCardClick} style={{ cursor: 'pointer' }}>
            {destruct.isAnimationEnabled && <div>
                <div className="balloon-container">
                    <CakeOutlinedIcon className="balloon" style={{ color: '#FFA500', marginRight: '5px', textShadow: '0 0 5px #FFD700', fontWeight: 500, fontSize: '40px' }} />
                </div>

                <div className="birthday-names-container">
                    <ul className="birthday-list">
                        {destruct.birthday.map((person, index) => (
                            <img
                                key={index}
                                className="animated-photo"
                                style={{
                                    animation: `fly-along 4s ease-out ${index * 0.5}s infinite`,
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                    border: '5px solid transparent',
                                    background: `linear-gradient(45deg, #FFD700, #FF4500)`,
                                }}
                                src={person.photo_url ? `${destruct.url}/assets/${person.photo_url}` : no_image}
                                alt={`Birthday Person ${index + 1}`}
                            />
                        ))}
                    </ul>
                </div>
            </div>}

            {!destruct.isAnimationEnabled && <div>
                <div className="balloon-container">
                    <CakeOutlinedIcon style={{ color: '#FFA500', marginRight: '5px', textShadow: '0 0 5px #FFD700', fontWeight: 500, fontSize: '40px' }} />
                </div>

                <div style={{ margin: '20px 0' }}></div>

                <div className="birthday-names-container">
                    <ul className="birthday-list">
                        {destruct.birthday.map((person, index) => (
                            <img
                                key={index}
                                className="animated-photo"
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%',
                                    border: '5px solid transparent',
                                    background: `linear-gradient(45deg, #FFD700, #FF4500)`,
                                    marginRight: '5px'
                                }}
                                src={person.photo_url ? `${destruct.url}/assets/${person.photo_url}` : no_image}
                                alt={`Birthday Person ${index + 1}`}
                            />
                        ))}
                    </ul>
                </div>
            </div>}

        </div>
    );

    // MAIN FUNCTION...

    return (
        <div className="page">
            {destruct.permissions && <>
                {/* VIEW ON A COMPUTER SCREEN */}
                {!isMobile && <div className="dashboard-wrapper">
                    <div className="dashboard-rowWrapper">
                        {UserCard}
                        {AttendanceCard}
                        {BirthdayCard}
                    </div>
                    <div className="dashboard-rowWrapper">
                        {TaskCard}
                        {PendingTask}
                    </div>
                    <div className="dashboard-rowWrapper">
                        {BusinessCard}
                        {ExpenseCard}
                    </div>
                </div>}

                {/* VIEW ON A MOBILE DEVICE */}
                {isMobile && <div className="dashboard-wrapper">
                    <div className="dashboard-rowWrapper">
                        {UserCard}
                    </div>
                    <div className="dashboard-rowWrapper">
                        {AttendanceCard}
                    </div>
                    <div className="dashboard-rowWrapper">
                        {BirthdayCard}
                    </div>
                    <div className="dashboard-rowWrapper">
                        {TaskCard}
                    </div>
                    <div className="dashboard-rowWrapper">
                        {PendingTask}
                    </div>
                    <div className="dashboard-rowWrapper">
                        {BusinessCard}
                    </div>
                    <div className="dashboard-rowWrapper">
                        {ExpenseCard}
                    </div>
                </div>}
            </>}
        </div>
    );
}

export default Dashboard;