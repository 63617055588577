import React from "react";
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { CSVLink } from "react-csv";
import YearlySummaryForm from "./YearlySummaryForm";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { useMediaQuery } from 'react-responsive';

const YearlySummary = () => {
    // DECLARING STATES...

    const { ...destruct } = YearlySummaryForm();
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '10%' },
        { key: 'staff', name: 'Staff', width: '15%' },
        { key: 'father', name: 'Father', width: '15%' },
        { key: 'present', name: 'Present', width: '15%' },
        { key: 'special', name: 'Special', width: '15%' },
        { key: 'leave', name: 'Leave', width: '15%' },
        { key: 'halfday', name: 'Half Day', width: '15%' },
        { key: 'no_information', name: 'No Information', width: '15%' }
    ].map(c => ({ ...c, ...defaultColumnProperties }));
    const headers = ['S.No', 'Staff', 'Father', 'Present', 'Special', 'Leave', 'Half Day', 'No Information'];
    const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">YEARLY&nbsp;SUMMARY</h1>
                    </div>

                    <div className="view-formGroup">
                        <progress className="staff-progress" value={destruct.progress} max="100" />                        
                        <button className="view-button" type="submit" disabled={destruct.loading}><RemoveRedEyeOutlinedIcon />&nbsp;Show</button>
                        <CSVLink className="view-button" data={destruct.table_rows.map(item => Object.values(item))} headers={headers} filename={'YEARLY_SUMMARY.csv'} ><TableViewOutlinedIcon />&nbsp;Export</CSVLink>
                        {!isMobile && <button className="view-button" type="button" onClick={destruct.generatePDF}><PictureAsPdfOutlinedIcon/>&nbsp;PDF</button>}
                    </div>

                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        onRowClick={destruct.onRowClick}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

            </div>
        </div>
    );
}

export default YearlySummary;