import React from "react";
import AddQuotationForm from "./AddQuotationForm";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DatePicker from "react-datepicker";
import CustomAutocomplete from "../Components/CustomAutoComplete";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import { ToastContainer } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import DataGrid from 'react-data-grid';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

const AddQuotation = () => {

    // STATES...

    const { ...destruct } = AddQuotationForm();
    const defaultColumnProperties = { sortable: false, resizable: true };

    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'ID', width: '10%' },
        { key: 'name', name: 'Name', width: '25%' },
        { key: 'manufacturer', name: 'Manufacturer', width: '25%' },
        { key: 'model', name: 'Model', width: '20%' },
        { key: 'quantity', name: 'Quantity', width: '7%' },
        { key: 'rate', name: 'Rate', width: '8%' },
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="view-wrapper">
                <form className="view-form">
                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;QUOTATION</h1>
                    </div>

                    <div className="attendance-body">
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Date:</label>
                                <div className="datepicker">
                                    <CalendarMonthOutlinedIcon />
                                    <div className='datepicker-component'>
                                        <DatePicker
                                            className='datepicker-inner-component'
                                            dateFormat="dd-MM-yyyy"
                                            selected={destruct.values.date}
                                            onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                            fixedHeight
                                            todayButton="TODAY"
                                        />
                                    </div>
                                </div>
                                {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Party:</label>
                                {destruct.item &&
                                    <CustomAutocomplete
                                        data={destruct.item}
                                        placeholder="Search Party..."
                                        selectValue={destruct.handleSelect}
                                        selected_value={destruct.values.party_id}
                                    />}
                                {destruct.errors.party_id && <p className="error">{destruct.errors.party_id}</p>}
                            </div>
                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Party Type:</label>
                                <select className="select" name="party_type" value={destruct.values.party_type} onChange={destruct.handleChange} disabled={destruct.disable} >
                                    {destruct.party_types.map((item, i) => (<option key={i}>{item}</option>))}
                                </select>
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Documents:</label>
                                <div className="tax-formGroup">
                                    <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                                    <button className="tax-button" type="button" onClick={destruct.handleDialog}><CloudUploadOutlinedIcon /></button>
                                </div>
                                {destruct.errors.docs_url && <p className="error">{destruct.errors.docs_url}</p>}                                
                            </div>

                        </div>
                    </div>                   
                </form>

                <div className="view-form">
                    <div className="attendance-body">
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Item:</label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={destruct.items}
                                    getOptionLabel={(option) => option ? option.name : ''}
                                    isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Search Item..." />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <span style={{ color: 'red' }}>{`ID: ${option.id}`}</span>
                                                <br />
                                                <span>{`Name: ${option.name}`}</span>
                                                <br />
                                                <span>{`Model: ${option.model}`}</span>
                                                <br />
                                                <span>{`Manufacturer: ${option.manufacturer}`}</span>
                                            </div>
                                        </li>
                                    )}
                                    onChange={(event, value) => destruct.selectItem(value)}
                                    sx={{
                                        width: '90%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        "& .MuiInputBase-root": {
                                            fontFamily: 'sans-serif',
                                            height: 45,
                                            fontSize: '15px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0
                                        },
                                        "&:hover fieldset": {
                                            border: 0
                                        },
                                        backgroundColor: '#FFFFFF',
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: '1px solid #9ecaed',
                                            boxShadow: '0 0 10px #9ecaed',
                                        },
                                    }}
                                />
                                {destruct.errors.item_id && <p className="error">{destruct.errors.item_id}</p>}
                            </div>
                        </div>   

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Quantity:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="quantity"
                                    value={destruct.values.quantity}
                                    onChange={destruct.handleChange}>
                                </input>
                                {destruct.errors.quantity && <p className="error">{destruct.errors.quantity}</p>}
                            </div>
                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Rate:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="rate"
                                    value={destruct.values.rate}
                                    onChange={destruct.handleChange}>
                                </input>
                                {destruct.errors.rate && <p className="error">{destruct.errors.rate}</p>}
                            </div>
                        </div>

                        <div className="attendance-innerbox">
                            <label className="label"></label>
                            <div className="location-formGroup">
                                <button
                                    className="view-button"
                                    type="button"
                                    onClick={destruct.addItem}><DirectionsOutlinedIcon />&nbsp;Add&nbsp;Item
                                </button>
                            </div>
                        </div>

                    </div>               
                </div>

                {/* DATA GRID */}

                <div className="short-table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{ width: '100%', height: '100%' }}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        onRowClick={destruct.onRowClick}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

                {/* FOOTER BUTTONS */}

                <div className="view-footer">

                    <button
                        className="view-button"
                        type="button"
                        disabled={destruct.loading[0]}
                        onClick={destruct.handleSubmit}><SaveOutlinedIcon />&nbsp;Save
                    </button>
                    <button
                        className="view-button"
                        type="button"
                        onClick={destruct.handleDelete}
                    ><DeleteForeverOutlinedIcon />&nbsp;Delete
                    </button>
                </div> 

            </div>            

            {/* UPLOAD DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>                        
                        <progress className="form-progress" value={destruct.progress} max="100" />
                        <span className="progressText">{destruct.progress + "%"}</span>
                        {destruct.docs_errors && <p className="message">{destruct.docs_errors}</p>}

                        <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={destruct.viewPDF} disabled={destruct.loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={destruct.handleUpload} disabled={destruct.loading[2]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>                        
                    </div>
                </div>
            </ReactDialogBox>}
            <ToastContainer/>
        </div>
    );
}

export default AddQuotation;