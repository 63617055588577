import React, { useRef } from 'react';
import ViewStaffForm from "./ViewStaffForm";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useMediaQuery } from 'react-responsive';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import id_card from '../Images/id-card.png';
import ReactToPrint from "react-to-print";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { ToastContainer } from 'react-toastify';

const ViewStaff = (props) => {

    // DECLARING STATES...

    let componentRef = useRef(); 
    const { ...destruct } = ViewStaffForm(props);
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'Staff ID', width: '8%' },
        { key: 'name', name: 'Name', width: '12%' },
        { key: 'address1', name: 'Address 1', width: '15%' },
        { key: 'address2', name: 'Address 2', width: '15%' },
        { key: 'district', name: 'District', width: '10%' },
        { key: 'state', name: 'State', width: '10%' },
        { key: 'pincode', name: 'Pincode', width: '10%' },
        { key: 'phone', name: 'Phone', width: '10%' },
        { key: 'alt', name: 'App ID', width: '15%' },
        { key: 'email', name: 'Email', width: '15%' },
        { key: 'qualification', name: 'Qualification', width: '10%' },
        { key: 'gender', name: 'Gender', width: '10%' },
        { key: 'bloodgroup', name: 'Bloodgroup', width: '10%' },
        { key: 'dob', name: 'DOB', width: '10%' },
        { key: 'doj', name: 'DOJ', width: '10%' },
        { key: 'dor', name: 'DOR', width: '10%' },
        { key: 'basic_pay', name: 'Basic Pay', width: '10%' },
        { key: 'hra', name: 'HRA', width: '10%' },
        { key: 'earned_increment', name: 'Earned Increment', width: '10%' },
        { key: 'ta', name: 'TA', width: '10%' },
        { key: 'performance_allowance', name: 'Performace Allowance', width: '10%' },
        { key: 'salary', name: 'Salary', width: '10%' },
        { key: 'designation', name: 'Designation', width: '10%' },
        { key: 'status', name: 'Status', width: '10%' },
        { key: 'father', name: 'Father', width: '10%' },
        { key: 'mother', name: 'Mother', width: '10%' },
        { key: 'account', name: 'Account No', width: '10%' },
        { key: 'uan', name: 'UAN', width: '10%' },
        { key: 'esi', name: 'ESI', width: '10%' },
        { key: 'photo_url', name: 'Photo', width: '20%' },
        { key: 'docs_url', name: 'Docs', width: '20%' }
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    const isMobile = useMediaQuery({ query: `(max-width: 1150px)` });       

    // ID CARD COMPONENT...

    const Card = (
        <div  className="card-container">
            <div style={{
                position: 'absolute',                
                width: '56mm',
                height: '88mm',
                backgroundImage: `url(${id_card})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                overflow: 'auto',
                fontSize: '10px'
            }} ref={componentRef}>

                <div className="photo-holder">
                    <img
                        src={destruct.card_data['photo_url']}
                        alt="User Pic"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>
                <div className="title">
                    <span>{destruct?.card_data['name']?.toUpperCase()}</span>
                </div>

                <div className="sub-title">
                    <span>{destruct.card_data['designation']}</span>
                </div>

                <div className="data-container">
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid lightblue' }}>
                        <div className="label-column">
                            <span>Father</span><span>:</span>
                        </div>
                        <div className="values-column">
                            <span >{destruct.card_data['father']}</span>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid lightblue' }}>
                        <div className="label-column">
                            <span>Date of Birth</span><span>:</span>
                        </div>
                        <div className="values-column">
                            <span >{destruct.card_data['dob']}</span>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid lightblue' }}>
                        <div className="label-column">
                            <span>Bloodgroup</span><span>:</span>
                        </div>
                        <div className="values-column">
                            <span >{destruct.card_data['bloodgroup']}</span>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid lightblue' }}>
                        <div className="label-column">
                            <span>Phone</span><span>:</span>
                        </div>
                        <div className="values-column">
                            <span >{destruct.card_data['phone']}</span>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div className="label-column">
                            <span>Address</span><span>:</span>
                        </div>
                        <div className="values-column">
                            <span >{destruct.card_data['address']}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">VIEW&nbsp;STAFF</h1>
                    </div>

                    <div className="view-formGroup">
                        <label className="view-label">Key:</label>
                        <input type="text" autoComplete="off" className="view-input" placeholder="Enter key to search..." name="key" value={destruct.values.key} onChange={destruct.handleChange}></input>
                        {!isMobile && <select className="view-select" name="selected_column" value={destruct.values.selected_column} onChange={destruct.handleChange}>
                            {destruct.columns.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>}
                        {!destruct.loading[0] && <button className="view-button" type="submit">View&nbsp;All</button>}
                        {destruct.loading[0] && <button className="view-button" type="submit" disabled >View&nbsp;All</button>}
                    </div>      
                    {destruct.errors[0] && <p className="message">{destruct.errors[0]}</p>}
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{ width: '100%', height: '100%' }}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        onRowClick={destruct.onRowClick}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

                {/* FOOTER BUTTONS FOR CRUD ACTIONS... */}

                <div className="view-footer">
                    {!isMobile && <div className="progress-container"><progress className="progress" value={destruct.progress[0]} max="100" /></div>}
                    {!isMobile && <input type="checkbox" className="checkbox" checked={destruct.checked} onChange={() => destruct.setChecked(!destruct.checked)} />}                  
                    {!isMobile && <button className="view-button" type="button" onClick={destruct.handleIDCard}><PortraitOutlinedIcon />&nbsp;Print&nbsp;ID&nbsp;Card</button>}
                    {!isMobile && <button className="view-button" type="button" onClick={destruct.handleAttachment} disabled={destruct.loading[3]}><AttachFileOutlinedIcon />&nbsp;Attachment</button>}
                    {(!isMobile && destruct.permissions[1] === '1') && <button className="view-button" type="button" onClick={destruct.handleMerge} ><PictureAsPdfOutlinedIcon />&nbsp;Merge&nbsp;PDF</button>}
                    {destruct.permissions[3] === '1' && <button className="view-button" type="button" onClick={destruct.handleUpdate} disabled={destruct.loading[2]}><CreateOutlinedIcon />&nbsp;Update</button>}
                    {destruct.permissions[4] === '1' && <button className="view-button" type="button" onClick={destruct.handleDelete} disabled={destruct.loading[1]}><DeleteForeverOutlinedIcon />&nbsp;Delete</button>}
                </div>
            </div>

            {/* DELETE DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='20%'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'
            >

                <div className="dialog-container">
                    <label>Do you really want to delete?</label>
                    <button className="button" type="button" onClick={destruct.handleConfirm}>Yes</button>
                </div>
            </ReactDialogBox>}

            {/* ID CARD DIALOG BOX... */}

            {destruct.card_dialog && (
                <ReactDialogBox
                    closeBox={destruct.handleCardDialog}
                    modalWidth='289px'
                    bodyHeight='450px'
                    headerBackgroundColor='rgb(44, 53, 68)'
                    headerTextColor='white'
                    headerHeight='65'
                    closeButtonColor='white'
                    bodyBackgroundColor='rgb(226, 218, 218)'
                    bodyTextColor='black'
                >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        {Card}
                        <ReactToPrint
                            trigger={() =>
                                <button
                                    className="button"
                                    type="button"
                                >
                                    <PrintOutlinedIcon />&nbsp;Preview                                    
                                </button>}
                            content={() => componentRef.current}
                        />
                    </div>

                </ReactDialogBox>
            )}
            <ToastContainer/>
        </div>
    );
}

export default ViewStaff