import { useState, useContext, useEffect } from "react";
import UserContext from '../Parent/UserContext';
import DataContext from "../Parent/DataContext";
import Post from '../HTTPRequest/Post';
import Get from "../HTTPRequest/Get";
import { toast } from 'react-toastify';
import Download from "../HTTPRequest/Download";
import CreateBlob from "../Parent/CreateBlob";

const AddServiceInvoiceForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const invoice_types = ['Purchase', 'Sales'];
    const months = ['April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'January', 'February', 'March'];
    const initialValues = 
    { 
        invoice_type: invoice_types[0],
        party_id: '',
        ledger_id: '',
        invoice_no: '',
        party_type: '',
        agreement_id: '',
        docs_url: '',
        agreement_url: '',
        month: months[0]
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [vendor, setVendor] = useState([]);
    const [client, setClient] = useState([]);
    const [item, setItem] = useState([]);
    const [disable, setDisable] = useState(true);
    const [invoice_details, setInvoiceDetails] = useState([]);
    const [invoice, setInvoice] = useState([]);
    const [agreement, setAgreement] = useState([]);
    const [contract, setContract] = useState([]);
    const [agreement_details, setAgreementDetails] = useState([]);
    const [progress, setProgress] = useState({ 0: 0 });

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=invoice';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                    

                    let vendor = response.response_payload[0] || [];
                    let client = response.response_payload[1] || [];
                    
                    setVendor(vendor);
                    setClient(client);
                    
                    const newItems = vendor.id.map((_, i) => ({
                        id: vendor.id[i],
                        name: vendor.name[i]
                    }));
                
                    setItem(newItems);
                    setDisable(false);                    
                }
            });
        }

        const fetchInvoice = async () => {
            let endpoint = user_context.url + '/invoice?route=get_invoice&fiscal=' + data_context['fiscal'];
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {          
                if ((response.status === 1) && (response.response_payload['result'] === 'success') ) {                   
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;
                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                    }

                    setInvoiceDetails(data);                    
                }
            });
        }

        const fetchAgreement = async () => {
            let endpoint = user_context.url + '/dropdown?route=license';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {          
                if ((response.status === 1) && (response.response_payload['result'] === 'success') ) {        
                              
                    let contract = response.response_payload[0] || [];
                    let agreement = response.response_payload[2] || [];                  

                    const new_contract = contract.id.map((_, i) => ({
                        id: contract.id[i],
                        name: contract.contract_no[i], 
                        party_id: contract.vendor_id[i],
                        docs_url: contract.docs_url[i],
                    }));
                
                    setContract(new_contract);

                    const new_agreement = agreement.id.map((_, i) => ({
                        id: agreement.id[i],
                        name: agreement.agreement_no[i],
                        party_id: agreement.client_id[i],
                        docs_url: agreement.docs_url[i],
                    }));
                
                    setAgreement(new_agreement);                   
                }
            });
        }

        setSelect();
        fetchInvoice();
        fetchAgreement();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING TYPE CHANGE...

    useEffect(() => {
        setErrors({});
        setItem([]);
        setValues({...values, party_id: ''});
   
        if (values.invoice_type === 'Purchase' && vendor.id && vendor.id.length !== 0) {
            const newItems = vendor.id.map((_, i) => ({
                id: vendor.id[i],
                name: vendor.name[i] 
            }));
        
            setItem(newItems);
        }

        if (values.invoice_type === 'Sales' && client.id && client.id.length !== 0) {
            const newItems = client.id.map((_, i) => ({
                id: client.id[i],
                name: client.address[i] 
            }));
        
            setItem(newItems);
        }
   
        //eslint-disable-next-line
    }, [values.invoice_type]);

    // REGISTERING PARTY CHANGE...

    useEffect(() => {
        
        if(values.party_id !== '') {
            setValues({...values, ledger_id: '', agreement_id: ''})
            let party_type = '';
            const party_id = values.party_id;
            if(values.invoice_type === 'Purchase') {
                party_type = 'Vendor';
            } else {
                party_type = 'Client';
            }

            const filtered = invoice_details.filter(detail => {
                return detail.party_id === party_id && detail.party_type === party_type;
            });

            setInvoice(filtered);
            
            if(values.invoice_type === 'Purchase') {
                const filtered = contract.filter(detail => {
                    return detail.party_id === party_id
                });

                setAgreementDetails(filtered);
            } else {
                const filtered = agreement.filter(detail => {
                    return detail.party_id === party_id
                });

                setAgreementDetails(filtered);
            }
        }           
   
        //eslint-disable-next-line
    }, [values.party_id]);

   
    // ON SELECTION OF PARTY...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, party_id: item.id });
        } else {
            setValues({ ...values, party_id: '' });
        }        
    } 

    // ON SELECTION OF INVOICE...

    const selectInvoice = (item) => {
        if(item){
            setValues({ ...values, ledger_id: item.id,  party_type: item.party_type, invoice_no: item.vch_no, docs_url: item.docs_url});
        } else {
            setValues({ ...values, ledger_id: '',  party_type: '', invoice_no: '', docs_url: ''});
        }        
    } 

    // ON SELECTION OF AGREEMENT...

    const selectAgreement = (item) => {   
        if(item){
            setValues({ ...values, agreement_id: item.id, agreement_url: item.docs_url});
        } else {
            setValues({ ...values, agreement_id: '', agreement_url: ''});
        }        
    } 

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }


    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();        
        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading({...loading, 2 : true});

            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Post(user_context.url + '/invoice', payload, headers).then(response => {
                setLoading({...loading, 2 : false});
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                    toast.success(response?.message);                       
                } else {
                    toast.error(response?.message);
                }
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.party_id) {
            errors.party_id= "*Party is required";
        }

        if (!values.ledger_id) {
            errors.ledger_id= "*Invoice is required";
        }

        if (!values.agreement_id) {
            errors.agreement_id= "*Agreement is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const payload =
        {
            ledger_id: values.ledger_id,
            invoice_no: values.invoice_no,
            invoice_type: values.invoice_type,
            party_type: values.party_type,
            party_id: values.party_id,
            agreement_id: values.agreement_id,
            month: values.month,
            fiscal: data_context['fiscal']
        };

        return payload;
    }

    // SHOWING ATTACHMENT...

    const handleAttachment = async (docs_url) => {
        setLoading({ ...loading, 0: true });
        const options = {
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            },
            onDownloadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setProgress({ ...progress, 0: percent });
            }
        }
        await Download(user_context.url + '/download?file_docs=' + docs_url, options).then(response => {                
            toast.success(response['message']);
            setLoading({ ...loading, 0: false });
            if (response['status'] === 1 && response.response_payload['result'] === 'success') {
                viewPDF(response.response_payload['docs'], docs_url);
            }
        });        
    }

    // VIEWING PDF...

    const viewPDF = (docs, docs_url) => {
        setLoading({ ...loading, 1: true });
        let errors = validateDocs(docs, docs_url);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 1: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 1: false });
                }
            }
        } else {
            setLoading({ ...loading, 1: false });
        }
    }

    // VALIDATING DOCS

    const validateDocs = (docs, docs_url) => {
        let errors = {};
        if (!docs_url) {
            errors[0] = "*No attachment found";
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(docs_url)[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors[0] = "*Document is not a valid PDF file";
            }
            else {
                if (docs === 'no_such_data' || !docs) {
                    errors[0] = "*No pdf preview found";
                    setLoading({ ...loading, 1: false });
                }
            }
        }
        return errors;
    }

    // RETURNING VALUES...

    return { 
        handleChange, 
        handleSubmit, 
        handleSelect, 
        selectInvoice,
        handleAttachment,
        selectAgreement,
        values, 
        errors, 
        loading, 
        invoice_types, 
        item,
        disable,
        invoice,
        progress,
        months,
        agreement_details
    };
}

export default AddServiceInvoiceForm;