import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Services.module.css';
import service1 from '../Images/Services 1.png';
import service2 from '../Images/Services 2.png';
import service3 from '../Images/Services 3.png';
import service4 from '../Images/Service 4.jpg';
import star from '../Images/star.png'
import ratingImage from '../Images/ratingImage.png'


const Services = (props) => {
  return (
    <>
      <div className="service">
        {/* ********************************Hero Section******************************** */}
        <div className={styles.heroSection}>
          <div className={styles.heroImage} />
          <div className={styles.heroText}>
            <div className={styles.title}>Welcome to</div>
            <div className={styles.subtitle}>Softech Smart <br /> Solutions</div>
            <div className={styles.description}>Partner with us for a technology-driven educational ecosystem!</div>
            <a href="mailto:softechsmartsolutions@gmail.com?subject=Inquiry from [YourName]: [YourSubject]&body=Dear Softech Smart Solutions Team,%0D%0A%0D%0AMy name is [YourName], and I am reaching out to inquire about [YourSubject].%0D%0A%0D%0A[Please write your questions or comments here.]%0D%0A%0D%0AThank you for your time and assistance. Looking forward to hearing back from you soon.%0D%0A%0D%0ABest regards,%0D%0A[YourName]%0D%0A[YourContactInformation, if desired]" target="_blank" rel="noopener noreferrer"><button className={styles.contactButton}>Contact Us</button></a>
          </div>
        </div>

        {/* ********************************Service Section******************************** */}
        <div className={styles.services}>
          <h1>Services</h1>
          {[{
            title: 'Business Planning Softwares',
            description: 'Erp Solutions for Streamlined Administration.',
            image: service1,
            align: 'left',
          },
          {
            title: 'Smart Class',
            description: 'Tech for Interactive Learning.',
            image: service2,
            align: 'right',
          },
          {
            title: 'Computer Labs',
            description: 'For Digital Empowerment.',
            image: service3,
            align: 'left',
          },
          {
            title: 'CCTV Systems',
            description: 'For Enhanced Security.',
            image: service4,
            align: 'right',
          }].map((service, index) => (
            <div key={index} className={index % 2 === 0 ? styles.containerOdd : styles.container}>
              <div className={styles[service.align]}>
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
              <div>
                <img src={service.image} loading="lazy" alt="" className={styles.image} />
              </div>
            </div>
          ))}
        </div>

      {/* ********************************Rating Section******************************** */}

            <div className={styles.ratingSection}><div className={styles.bigScreen}>
          <div className={styles.imageSection}>
            <img src={ratingImage} alt="sectionImg" />
          </div>
          <div className={styles.textSection}>
            <h1>Perfect Solution for Schools & Businesses</h1>
            <h2>Pricing plans that fit like a glove.</h2>
            <div className={styles.buttons}>
              <a href="mailto:softechsmartsolutions@gmail.com?subject=Inquiry from [YourName]: [YourSubject]&body=Dear Softech Smart Solutions Team,%0D%0A%0D%0AMy name is [YourName], and I am reaching out to inquire about [YourSubject].%0D%0A%0D%0A[Please write your questions or comments here.]%0D%0A%0D%0AThank you for your time and assistance. Looking forward to hearing back from you soon.%0D%0A%0D%0ABest regards,%0D%0A[YourName]%0D%0A[YourContactInformation, if desired]" target="_blank" rel="noopener noreferrer"><button className={styles.contactButtonRating}>Contact Us</button></a>
              <Link to="/login"><button className={styles.loginButtonRating}>Log In</button></Link>
            </div>
            <hr />
            <div className={styles.starRating}>
              <div className={styles.star}><img src={star} alt="Star Rating" /></div>
              <div><p>
                <strong>52 Schools</strong> trust Softech Smart Solutions <br/>and they rate it as <strong>5-stars</strong>.
              </p></div>
            </div>
          </div>
        </div>
        </div>      


      </div>
    </>
  );
}

export default Services;
