import { useState, useContext, useEffect } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import { useHistory } from 'react-router-dom';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';
import CreateLocalDate from '../Parent/CreateLocalDate';
import Get from '../HTTPRequest/Get';
import GetIndex from '../Parent/GetIndex';
import { toast } from 'react-toastify';
import FormatDate from '../Parent/FormatDate';
import FormatLocalDate from '../Parent/FormatLocalDate';

const UpdateProposalForm = (props) => {
    // DECLARING STATES...

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const statuses = ['Active', 'Cancelled']
    let initialValues =
    {
        id: data_context.update_data['id'],
        ref_no: data_context.update_data['ref_no'],
        date: CreateLocalDate(data_context.update_data['date']),
        client_id: data_context.update_data['client_id'],
        proposal_type_id: data_context.update_data['proposal_type_id'],
        status: statuses[GetIndex(statuses, data_context.update_data['status'])]
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({ 0: false });
    const history = useHistory();
    const [client, setClient] = useState([]);
    const [proposal_type, setProposalType] = useState([]);

    // SETTING UP DROPDOWNS...

    useEffect(() => {

        const fetchClient = async () => {
            let endpoint = user_context.url + '/dropdown?route=client_details';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let data = response.response_payload[0] || [];
                        const newItems = data.id.map((_, i) => ({
                            id: data.id[i],
                            name: data.address[i],
                            institution: data.institution[i]
                        }));

                        setClient(newItems);
                    }
                }
            });
        }

        const fetchProposalTypes = async () => {
            let endpoint = user_context.url + '/dropdown?route=proposal_types';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let data = response.response_payload[0] || [];
                        const newItems = data.id.map((_, i) => ({
                            id: data.id[i],
                            name: data.name[i]
                        }));

                        setProposalType(newItems);
                    }
                }
            });
        }

        fetchClient();
        fetchProposalTypes();

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // ON SELECTION OF CLIENT...

    const selectClient = (item) => {
        if (item) {
            setValues({ ...values, client_id: item.id });
        } else {
            setValues({ ...values, client_id: '' });
        }
    }

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {        
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
       
        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });
            let payload = createPayload(values);
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Put(user_context.url + '/proposal', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });               

                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                    
                    toast.success(response?.message);
                    let payload =
                    {
                        id: values.id,
                        ref_no: values.ref_no,
                        date: FormatLocalDate(values.date),
                        client_id: values.client_id,
                        client: (client.find(client => client.id === values.client_id))['name'],
                        proposal_type_id: values.proposal_type_id,
                        proposal_type: (proposal_type.find(proposal_type => proposal_type.id === values.proposal_type_id))['name'],
                        status: values.status
                    };                

                    let data = data_context.view_data.table_rows;
                    let index = GetKey(data, payload);
                    payload = { ...payload, sno: data[index]['sno'] };
                    data = ({ ...data, [index]: payload });
                    const row_values = Object.values(data);

                    let view_data = data_context.view_data;
                    view_data = ({ ...view_data, table_rows: row_values });
                    props.handleBack(view_data);
                } else {
                    toast.error(response?.message);
                }
            });
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_proposal");
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.id) {
            errors.id = "*Purchase Order ID is required";
        }

        if (!values.ref_no) {
            errors.ref_no = "*Reference No is required";
        }

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.client_id) {
            errors.client_id = "*Client is required";
        }

        if (!values.proposal_type_id) {
            errors.proposal_type_id = "*Proposal Type is required";
        }

        if (!values.status) {
            errors.status = "*Status is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            ref_no: values.ref_no,
            date: FormatDate(values.date),
            client_id: values.client_id,
            proposal_type_id: values.proposal_type_id,
            status: values.status,
            fiscal: data_context['fiscal']
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange,
        handleSubmit,
        selectClient,
        handleBack,
        values,
        errors,
        loading,
        client,
        proposal_type,
        statuses
    };
}

export default UpdateProposalForm;