import { useState, useEffect, useContext } from 'react';
import UserContext from "../Parent/UserContext";
import DataContext from "../Parent/DataContext";
import Get from "../HTTPRequest/Get";
import FormatDate from "../Parent/FormatDate";
import EncodeBase64 from "../Parent/EncodeBase64";
import CreateFileName from "../Parent/CreateFileName";
import Upload from "../HTTPRequest/Upload";
import CreateBlob from "../Parent/CreateBlob";
import { toast } from 'react-toastify';
import Put from '../HTTPRequest/Put';
import { useHistory } from 'react-router-dom';
import CreateLocalDate from '../Parent/CreateLocalDate';
import GetKey from '../Parent/GetKey';
import FormatLocalDate from '../Parent/FormatLocalDate';
import CreateDate from '../Parent/CreateDate';

const UpdateQuotationForm = (props) => {

    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const party_types = ['Vendor', 'Head'];
    const initialValues = 
    { 
        id: data_context.update_data['id'],
        date: CreateLocalDate(data_context.update_data['date']),
        party_type: data_context.update_data['party_type'],
        party_id: data_context.update_data['party_id'],
        docs_url: data_context.update_data['docs_url']
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({ 0: false, 1: false });   
    const [item, setItem] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [head, setHead] = useState([]);
    const [disable, setDisable] = useState(true);
    const [progress, setProgress] = useState(0);
    const [docs_errors, setDocsErrors] = useState();
    const [docs, setDocs] = useState(data_context.update_data['docs']);
    const [dialog, setDialog] = useState(false);
    const history = useHistory();

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=ledger';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) &&(response.response_payload['result'] === 'success')) {                    
                        
                    let vendor = response.response_payload[0] || []; 
                    let head = response.response_payload[2] || []; 

                    setVendor(vendor);
                    setHead(head);

                    let party_type = data_context.update_data['party_type'];
                    
                    if (party_type === 'Vendor') {
                        const newItems = vendor.id.map((_, i) => ({
                            id: vendor.id[i],
                            name: vendor.name[i] 
                        }));
                    
                        setItem(newItems);
                    }; 
                    
                    if (party_type === 'Head') {
                        const newItems = head.id.map((_, i) => ({
                            id: head.id[i],
                            name: head.name[i] 
                        }));
                    
                        setItem(newItems);
                    }
                    
                    setDisable(false);
                }
            });
        }

        setSelect();

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING TYPE CHANGE...

    useEffect(() => {
        setErrors({});
        setItem([]);

        if (values.party_type === 'Vendor' && vendor.id && vendor.id.length !== 0) {
            const newItems = vendor.id.map((_, i) => ({
                id: vendor.id[i],
                name: vendor.name[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Head' && head.id && head.id.length !== 0) {
            const newItems = head.id.map((_, i) => ({
                id: head.id[i],
                name: head.name[i] 
            }));
        
            setItem(newItems);
        }

        //eslint-disable-next-line
    }, [values.party_type]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // ON SELECTION OF PARTY...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, party_id: item.id });
        } else {
            setValues({ ...values, party_id: '' });
        }        
    } 

    // ON SELECTION OF ITEM...

    const selectItem = (item) => {
        if (item) {
            setValues({ ...values, item_id: item.id });
        } else {
            setValues({ ...values, item_id: '' });
        }
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();        
        let errors = validateForm(values);
        setErrors(errors);        

        if (Object.keys(errors).length === 0) {

            setLoading({...loading, 0 : true});

            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/quotation', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                    toast.success(response?.message); 

                    const party = item.find(item => item.id === values.party_id);

                    let payload = { 
                        id: values.id,
                        date: FormatDate(values.date),
                        party_type: values.party_type,
                        party_id: values.party_id,
                        party: party ? party.name : '',
                        docs_url: values.docs_url
                    };
                    
                    let data = data_context.view_data.table_rows;
                    let index = GetKey(data, payload); 

                    payload = { ...payload, sno: data[index]['sno'] };
                    payload = { ...payload, date: FormatLocalDate(CreateDate(payload['date']))};

                    data = ({ ...data, [index]: payload });
                    const row_values = Object.values(data);
                    let view_data = data_context.view_data;
                    view_data = ({ ...view_data, table_rows: row_values });
                    props.handleBack(view_data);

                } else {
                    toast.error(response?.message);
                }                
            });
   
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};    

        if (!values.id) {
            errors.id = "*ID is required";
        }

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.party_id) {
            errors.party_id = "*Party ID is required";
        }

        if (!values.docs_url) {
            errors.docs_url = "*Docs is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const payload =
        {
            id: values.id,
            date: FormatDate(values.date),
            party_type: values.party_type,
            party_id: values.party_id,
            docs_url: values.docs_url
        };

        return payload;
    }

    // HANDLER FOR UPLOAD DIALOG...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'pdf_file') {

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setDocs(base64);
                    }

                    setValues({ ...values, docs_url: e.target.files[0].name });
                    setDocsErrors();
                }).catch(() => {
                    setDocsErrors();
                    toast.error("File conversion error");
                });
            }
        }
    }

    // VIEWING PDF...

    const viewPDF = () => {
        setLoading({ ...loading, 1: true });
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 1: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 1: false });
                }
            }
        } else {
            setLoading({ ...loading, 1: false });
        }
    }

    // VALIDATING UPLOADS...

    const validateDocs = () => {
        let errors = '';

        if (!values.docs_url) {
            errors = "*No pdf selected";
            setDocsErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.docs_url)[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors = "*Document is not a valid PDF file";
                setDocsErrors(errors);
            }
            else {
                if (docs === 'no_such_data' || !docs) {
                    errors = "*No pdf preview found";
                    setDocsErrors(errors);
                    setLoading({ ...loading, 1: false });
                }
            }
        }

        return errors;
    }

    // CLEARING FIELDS...

    const handleClear = () => {
        setDocs();
        setValues({ ...values, docs_url: '' });
        setDocsErrors();
        setProgress(0);
    }

    // UPLOADING DOCS...

    const handleUpload = async () => {
        setProgress(0);
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 2: true });
            const options = {
                headers: { 
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${user_context.user_data[1]}` 
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress(percent);
                }
            }

            if (docs !== null) {
                let file_name = CreateFileName(values.docs_url);
                const payload = { file_name: file_name, encoded_string: docs };

                await Upload(user_context.url + '/upload', payload, options).then(response => {
                    setLoading({ ...loading, 2: false });
                    if (response['status'] === 1) {
                        toast.success(response?.message);
                        setValues({ ...values, docs_url: file_name });
                    } else {
                        toast.error(response?.message);
                    }
                });
            }
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_quotation");
    }

    // RETURNING VALUES...

    return { 
        handleChange, 
        handleSubmit, 
        handleSelect,
        handleBrowse, 
        handleDialog,
        viewPDF,
        handleClear,
        handleUpload,
        selectItem,
        handleBack,
        values, 
        errors, 
        loading, 
        item,
        party_types,
        disable,
        progress,
        docs_errors,
        dialog
    };
}

export default UpdateQuotationForm;