import { useState, useContext} from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';
import { useHistory } from 'react-router-dom';

const UpdateHeadForm = (props) => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const initialValues =
    {
        id: data_context.update_data['id'],
        name: data_context.update_data['name'],
        address: data_context.update_data['address'],
        contact: data_context.update_data['contact']
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({ 0: '' });
    const [loading, setLoading] = useState({ 0: false });
    const history = useHistory();


    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});

        if (Object.keys(errors).length === 0) {
            setLoading({...loading, 0: true});
            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/head', payload, headers).then(response => {
                setLoading({...loading, 0: false});
                setMessage({...message, 0: response.message});

                if(response.status === 1){
                    if (response.response_payload['result'] === 'success') {
                        let data = data_context.view_data.table_rows;
                        let index = GetKey(data, payload);
                        payload = { ...payload, sno: data[index]['sno'] };
                        data = ({ ...data, [index]: payload });
                        const row_values = Object.values(data);
    
                        let view_data = data_context.view_data;
                        view_data = ({ ...view_data, table_rows: row_values });
                        props.handleBack(view_data);
                    }
                }                
            });
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_head");
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_number = /^[0-9]*$/;

        if (!values.id) {
            errors.id = "*Head ID is required";
        }

        if (!values.name) {
            errors.name = "*Head Name is required";
        }

        if (values.contact) {
            if (!regex_number.test(values.contact)) {
                errors.contact = "*Only digits allowed";
            }
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            name: values.name,
            address: values.address,
            contact: values.contact,
        };

        return data;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, handleBack, values, errors, message, loading };
}

export default UpdateHeadForm;