import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Post from '../HTTPRequest/Post';
import Get from '../HTTPRequest/Get';
import { toast } from 'react-toastify';

const AddWorkForm = () => {
    // DECLARING STATES...        

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const [staff, setStaff] = useState([]);
    const [location, setLocation] = useState([]);
    const initialValues = {
        staff: '',
        location: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=work';
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                    
                    let staff = response.response_payload[0] || [];
                    let location = response.response_payload[1] || [];

                    setStaff(staff);;
                    setLocation(location);

                    setValues({ ...values, staff: staff.id[0], location: location.id[0] });
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);

            const payload = { staff_id: values.staff, location_id: values.location };
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Post(user_context.url + '/work', payload, headers).then(response => {
                setLoading(false);
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                    toast.success(response?.message);                     
                } else {
                    toast.error(response?.message);
                }
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.staff) {
            errors.staff = "*Staff is required";
        }

        if (!values.location) {
            errors.location = "*Location is required";
        }

        return errors;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, values, errors, loading, staff, location };
}

export default AddWorkForm;