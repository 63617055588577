import React from "react";
import AddProposalForm from "./AddProposalForm";
import CustomAutocomplete from "../Components/CustomAutoComplete";
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { DataGrid } from '@mui/x-data-grid';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { ToastContainer } from 'react-toastify';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

const AddProposal = () => {
    
    // DECLARING STATES...    

    const { ...destruct } = AddProposalForm();
    const table_columns = [
        { field: 'sno', headerName: 'S.No', width: 50 },
        {
            field: 'item', headerName: 'Item', width: 250,
            renderCell: ({ value, row }) => (
                <input
                    autoComplete='off'
                    className="input"
                    value={value}
                    type="text"
                    onChange={(event) => destruct.handleItemChange(event, row.id)}
                    onKeyDown={(event) => {
                        if (event.key === ' ') {
                            event.stopPropagation(); 
                        }
                    }}
                />
            )
        },
        { field: 'manufacturer', headerName: 'Manufacturer', width: 250 },
        { field: 'model', headerName: 'Model', width: 250 },
        {
            field: 'rate', headerName: 'Rate', width: 150,
            renderCell: ({ value, row }) => (
                <input
                    autoComplete='off'
                    className="input"
                    value={value}
                    type="number"
                    step="0.01"
                    onChange={(event) => destruct.handleRateChange(event, row.id)}
                />
            )
        },
        {
            field: 'quantity', headerName: 'Quantity', width: 150,
            renderCell: ({ value, row }) => (
                <input
                    autoComplete='off'
                    className="input"
                    value={value}
                    type="number"
                    onChange={(event) => destruct.handleQuantityChange(event, row.id)}
                />
            )
        },
        { field: 'amount', headerName: 'Amount', width: 150 },
    ];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">
                <div className="view-form">
                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;PROPOSAL</h1>
                    </div>

                    <div className="attendance-body">
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Date:</label>
                                <div className="datepicker">
                                    <CalendarMonthOutlinedIcon />
                                    <div className='datepicker-component'>
                                        <DatePicker
                                            className='datepicker-inner-component'
                                            dateFormat="dd-MM-yyyy"
                                            selected={destruct.values.date}
                                            onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                            fixedHeight
                                            todayButton="TODAY"
                                        />
                                    </div>
                                </div>
                                {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                            </div>

                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Client:</label>
                                {destruct.client &&
                                    <CustomAutocomplete
                                        data={destruct.client}
                                        placeholder="Search Client..."
                                        selectValue={destruct.selectClient}
                                        selected_value={destruct.values.client_id}
                                    />}
                                {destruct.errors.client_id && <p className="error">{destruct.errors.client_id}</p>}
                            </div>
                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Proposal Type:</label>
                                {destruct.proposal_type &&
                                    <CustomAutocomplete
                                        data={destruct.proposal_type}
                                        placeholder="Search Proposal Type..."
                                        selectValue={destruct.selectProposalType}
                                        disabled={!(destruct.item && destruct.item.length > 0)}
                                        selected_value={destruct.values.proposal_type_id}
                                    />}
                                {destruct.errors.proposal_type_id && <p className="error">{destruct.errors.proposal_type_id}</p>}
                            </div>
                        </div>
                    </div>
                </div>

                {/* DATA GRID */}

                <div className="table-container">
                    <div style={{ width: '100%', height: '100%', backgroundColor: '#FFFF' }}>
                        <DataGrid
                            rows={destruct.table_rows} 
                            columns={table_columns}
                            disableRowSelectionOnClick={true}
                            checkboxSelection
                            rowSelectionModel={destruct.selected_row}
                            onRowSelectionModelChange={(newSelection) => {
                                destruct.setSelectedRow(newSelection);
                            }}                          
                        />    
                    </div>     
                </div>

                {/* FOOTER BUTTONS */}

                <div className="view-footer">
                    <label 
                        className="view-label"
                        style={{marginRight: '5px'}}
                    >Total:
                    </label>

                    <input 
                        type="text" 
                        autoComplete='off' 
                        className="short-input" 
                        name="total" 
                        value={destruct.values.total} 
                        readOnly
                    >
                    </input>

                    <button
                        className="view-button"
                        type="button"
                        onClick={destruct.handleUp}
                    ><ArrowUpwardOutlinedIcon />
                    </button>

                    <button
                        className="view-button"
                        type="button"
                        onClick={destruct.handleDown}
                    ><ArrowDownwardOutlinedIcon />
                    </button>

                    <button
                        className="view-button"
                        type="button"
                        disabled={destruct.loading[0]}
                        onClick={destruct.handleSubmit}
                    ><SaveOutlinedIcon />&nbsp;Save
                    </button>

                    <button
                        className="view-button"
                        type="button"
                        onClick={destruct.handleDeleteRow}
                    ><DeleteForeverOutlinedIcon />&nbsp;Delete&nbsp;Row
                    </button>

                    <button
                        className="view-button"
                        type="button"
                        onClick={destruct.handleAddRow}
                    ><PlaylistAddOutlinedIcon />&nbsp;Add&nbsp;Row
                    </button>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default AddProposal;