import React from "react";
import AddStockForm from "./AddStockForm";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { CSVLink } from "react-csv";
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';

const AddStock = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddStockForm();
    const defaultColumnProperties = { sortable: false, resizable: true };

    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'ID', width: '5%' },
        { key: 'ledger_id', name: 'Ledger ID', width: '7%' },
        { key: 'type', name: 'Type', width: '10%' },
        { key: 'item_id', name: 'Item ID', width: '10%' },
        { key: 'name', name: 'Name', width: '13%' },
        { key: 'model', name: 'Model', width: '10%' },
        { key: 'manufacturer', name: 'Manufacturer', width: '10%' },
        { key: 'rate', name: 'Rate', width: '10%' },
        { key: 'qty_in', name: 'Qty In', width: '10%' },
        { key: 'qty_out', name: 'Qty Out', width: '10%' }
    ].map(c => ({ ...c, ...defaultColumnProperties }));
    const headers = ['S.No', 'ID', 'Ledger ID', 'Type', 'Item ID', 'Rate', 'Qty In', 'Qty Out', 'Name', 'Model', 'Manufacturer'];

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="view-wrapper">
                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">ADD&nbsp;STOCK</h1>
                    </div>

                    <div className="attendance-body">
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Item:</label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={destruct.item}
                                    getOptionLabel={(option) => option ? option.name : ''} 
                                    isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Search Item..." />                                       
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                                <br />
                                                <span>{`Name: ${option.name}`}</span>
                                                <br />
                                                <span>{`Model: ${option.model}`}</span>
                                                <br />
                                                <span>{`Manufacturer: ${option.manufacturer}`}</span>
                                            </div>
                                        </li>
                                    )}
                                    onChange={(event, value) => destruct.handleSelect(value)}
                                    sx={{
                                        width: '90%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        "& .MuiInputBase-root": {
                                            fontFamily: 'sans-serif',
                                            height: 45,
                                            fontSize: '15px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0
                                        },
                                        "&:hover fieldset": {
                                            border: 0
                                        },
                                        backgroundColor: '#FFFFFF',
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: '1px solid #9ecaed',
                                            boxShadow: '0 0 10px #9ecaed',
                                        },
                                    }}
                                />
                                {destruct.errors.item_id && <p className="error">{destruct.errors.item_id}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Model:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="model"
                                    value={destruct.values.model}
                                    onChange={destruct.handleChange}
                                    disabled>
                                </input>
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Manufacturer:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="manufacturer"
                                    value={destruct.values.manufacturer}
                                    onChange={destruct.handleChange}
                                    disabled>
                                </input>
                            </div>
                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Ledger ID:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="ledger_id"
                                    value={destruct.values.ledger_id}
                                    onChange={destruct.handleChange}
                                    disabled>
                                </input>
                                {destruct.errors.ledger_id && <p className="error">{destruct.errors.ledger_id}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Type:</label>
                                <select className="select" name="type" value={destruct.values.type} onChange={destruct.handleChange} disabled={destruct.disable}>
                                    {destruct.types.map((item, i) => {
                                        if (!destruct.disable && i === 0) {
                                            return null;
                                        }
                                        return <option key={i}>{item}</option>;
                                    })}
                                </select>
                                {destruct.errors.type && <p className="error">{destruct.errors.type}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Rate:</label>
                                <div className="tax-formGroup">
                                    <input
                                        type="text"
                                        autoComplete='off'
                                        className="input"
                                        name="rate"
                                        value={destruct.values.rate}
                                        onChange={destruct.handleChange}>
                                    </input>
                                    <button
                                        className="tax-button"
                                        type="button"
                                        onClick={destruct.handleCalculate}
                                    >
                                        <CalculateOutlinedIcon />
                                    </button>
                                </div>
                                {destruct.errors.rate && <p className="error">{destruct.errors.rate}</p>}
                            </div>
                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Qty In:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="qty_in"
                                    value={destruct.values.qty_in}
                                    onChange={destruct.handleChange}>
                                </input>
                                {destruct.errors.qty_in && <p className="error">{destruct.errors.qty_in}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Qty Out:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="qty_out"
                                    value={destruct.values.qty_out}
                                    onChange={destruct.handleChange}>
                                </input>
                                {destruct.errors.qty_out && <p className="error">{destruct.errors.qty_out}</p>}
                            </div>

                            <div className="location-formGroup">
                                <button
                                    className="button"
                                    type="button"
                                    onClick={destruct.handleSubmit}
                                    disabled={destruct.loading[0]}
                                >
                                    Save
                                </button>
                                {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                            </div>
                        </div>
                    </div>
                </form>

                {/* DATA GRID */}

                <div className="short-table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        onRowClick={destruct.onRowClick}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

                {/* FOOTER BUTTONS */}

                <div className="view-footer">
                    <label className="view-label">Total:</label>
                    <input
                        type="text"
                        autoComplete='off'
                        className="short-input"
                        name="total"
                        value={destruct.total}
                        readOnly
                    >
                    </input>

                    {destruct.permissions[5] === '1' && 
                        <CSVLink 
                            className="view-button" 
                            data={destruct.table_rows.map(item => Object.values(item))} 
                            headers={headers} filename={'STOCK.csv'} >
                        <TableViewOutlinedIcon />&nbsp;Export</CSVLink>}

                    <button
                        className="view-button"
                        type="button"
                        onClick={destruct.handleBack}
                        disabled={!destruct.from_page}>
                        <ArrowBackOutlinedIcon />Back
                    </button>

                    {destruct.permissions[4] === '1' &&
                        <button
                            className="view-button"
                            type="button"
                            onClick={destruct.handleDelete}
                            disabled={destruct.loading[1]}
                        >
                            <DeleteForeverOutlinedIcon />&nbsp;Delete
                        </button>}                    
                </div>
            </div>

            {/* DELETE DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='20%'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'
            >

                <div className="dialog-container">
                    <label>Do you really want to delete?</label>
                    <button className="button" type="button" onClick={destruct.handleConfirm}>Yes</button>
                </div>
            </ReactDialogBox>}

            {/* CALCULATE DIALOG BOX... */}

            {destruct.calculate && <ReactDialogBox
                closeBox={destruct.handleCalculate}
                modalWidth='20%'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'
            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <input
                            type="text"
                            autoComplete='off'
                            className="input"
                            name="calculate_rate"
                            value={destruct.values.calculate_rate}
                            onChange={destruct.handleChange}
                            placeholder="Rate">
                        </input>
                        {destruct.errors.calculate_rate && <p className="error">{destruct.errors.calculate_rate}</p>}
                    </div>

                    <div className="location-formGroup">
                        <input
                            type="text"
                            autoComplete='off'
                            className="input"
                            name="calculate_tax"
                            value={destruct.values.calculate_tax}
                            onChange={destruct.handleChange}
                            placeholder="Tax">
                        </input>
                        {destruct.errors.calculate_tax && <p className="error">{destruct.errors.calculate_tax}</p>}
                    </div>

                    <button className="button" type="button" onClick={destruct.calculateRate}>Calculate</button>
                </div>
            </ReactDialogBox>}
        </div>)
}

export default AddStock;