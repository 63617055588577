import React, { memo } from "react";
import './viewImage.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

const ViewImage = memo((props) => {
    // MAIN FUNCTION...

    return (
        <div className="image-wrapper">
            <LazyLoadImage className="image" src={props.image} alt="Not found" />
        </div>
    );
})

export default ViewImage;