import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Get from '../HTTPRequest/Get';
import { Row } from 'react-data-grid';
import FormatDate from '../Parent/FormatDate';
import Post from '../HTTPRequest/Post';
import jsPDF from 'jspdf';
import FormatLocalDate from '../Parent/FormatLocalDate';
import { toast } from 'react-toastify';

const AddPurchaseOrderForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const options = ['Ludhiana', 'Jalandhar'];
    const initialValues =
    {
        date: '',
        vendor_id: '',
        deadline: '',
        bill_to: options[0],
        ship_to: 'Softech Smart Solutions, Jalandhar',
        item_id: '',
        quantity: ''        
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [vendor, setVendor] = useState([]);
    const [item, setItem] = useState([]);
    const [message, setMessage] = useState({ 0: '' });
    const [loading, setLoading] = useState({ 0: false });
    const [selected_row, setSelectedRow] = useState();
    const [table_rows, setTableRows] = useState([]);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const fetchVendor = async () => {
            let endpoint = user_context.url + '/dropdown?route=vendor_details';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
             
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        
                        let vendor = response.response_payload[0] || [];

                        const newItems = vendor.id.map((_, i) => ({
                            id: vendor.id[i],
                            name: vendor.name[i],
                            address: vendor.address[i],
                            district: vendor.district[i],
                            state: vendor.state[i],
                            pincode: vendor.pincode[i]
                        }));

                        setVendor(newItems);
                    }
                }
            });
        }

        const fetchItem = async () => {
            let endpoint = user_context.url + '/dropdown?route=item';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let item = response.response_payload[0] || [];

                        const newItems = item.id.map((_, i) => ({
                            id: item.id[i],
                            name: item.name[i],
                            manufacturer: item.manufacturer[i],
                            model: item.model[i],
                            unit: item.unit[i]
                        }));

                        setItem(newItems);
                    }
                }
            });
        }

        fetchVendor();
        fetchItem();

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // ON SELECTION OF VENDOR...

    const selectVendor = (item) => {
        if (item) {
            setValues({ ...values, vendor_id: item.id });
        }
    }

    // ON SELECTION OF ITEM...

    const selectItem = (item) => {
        if (item) {
            setValues({ ...values, item_id: item.id });
        }
    }

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({ 0: '' });
        if (Object.keys(errors).length === 0) {
            if(table_rows && table_rows.length > 0) {
                if(FormatDate(values.date) < FormatDate(values.deadline))
                {
                    setLoading({ ...loading, 0: true });

                    let payload = createPayload(values);
                    const headers =
                    {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${user_context.user_data[1]}`
                    };
        
                    await Post(user_context.url + '/purchase_order', payload, headers).then(response => {                        
                        setLoading({ ...loading, 0: false });
                        if(response.status === 1){
                            if (response.response_payload['result'] === 'success') {
                                toast.success(response?.message);
                                generatePDF(response.response_payload['ref_no']);
                            } else {
                                toast.error(response?.message);
                            }
                        }
                    });
                } else {
                    toast.error("Deadline date must be greater than PO date");
                }
                
            } else {
                toast.error("No Item added");
            }            
        }        
    }

    // GENERATING PDF...

    const generatePDF = (ref_no) => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'A4',
        });
    
        doc.setFontSize(11);
        const yPosition = 20; 
        doc.text('Ref No: ' + ref_no, 10, yPosition+45);
        doc.text('Date: ' + FormatLocalDate(values.date), 160, yPosition+45);  
        
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");

        const titleText = 'PURCHASE ORDER';
        const textWidth = doc.getStringUnitWidth(titleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const xPosition = (doc.internal.pageSize.width - textWidth) / 2;         

        doc.text(titleText, xPosition, yPosition+60);

        const lineHeight = 0.1; 
        const lineY = yPosition + 61; 
        doc.setLineWidth(lineHeight);
        doc.line(xPosition, lineY, xPosition + textWidth, lineY);

        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");        
        const vendor_details = values.vendor_id ? vendor.find(vendorItem => vendorItem.id.includes(values.vendor_id)) : null;       
        doc.text('Vendor: ' + vendor_details?.name, 10, yPosition+70);    
        doc.text('' + vendor_details?.address, 23, yPosition+75);    
        const address = vendor_details?.district + ', ' + vendor_details?.state;      
        doc.text(''+ address, 23, yPosition+80);
        doc.text('' + vendor_details?.pincode, 23, yPosition+85); 
        
        if(values.bill_to === 'Ludhiana')
        {
            doc.text('Bill To: Softech Smart Solutions', 140, yPosition+70);  
            doc.text('Shop No 4, Silver Kunj', 152, yPosition+75);
            doc.text('Near Jalandhar Byepass', 152, yPosition+80);
            doc.text('Ludhiana, Punjab, 141008', 152, yPosition+85);
            doc.text('GSTIN: 03ADLFS5708D1Z8', 152, yPosition+90);
        } else {
            doc.text('Bill To: Softech Smart Solutions', 140, yPosition+70);  
            doc.text('Flat No GF 4', 152, yPosition+75);
            doc.text('Guru Gobind Singh Avenue', 152, yPosition+80);
            doc.text('Chogtti, Jalandhar, 144009', 152, yPosition+85);
            doc.text('GSTIN: 03ADLFS5708D1Z8', 152, yPosition+90);
        }  
        
        doc.text('Ship To: ' + values.ship_to, 10, yPosition+100);
        
        doc.setFontSize(11);
        doc.setFont("helvetica", "italic");
        doc.text('We are pleased to place an order for the following materials/services. Kindly deliver the materials/services', 15, yPosition+110);
        doc.text('on or before ' + FormatLocalDate(values.deadline) + '. The order will be deemed cancelled, if the delivery is delayed.', 15, yPosition+115);

        const headers = ['SNo', 'Name', 'Manufacturer', 'Model', 'Quantity', 'Unit'];
        const data = table_rows.map(obj => {
            const newObj = { ...obj }; 
            delete newObj.id; 
            return newObj; 
        });
        
        doc.autoTable({
            startY: yPosition + 125,
            head: [headers],
            body: data.map(row => Object.values(row)),
            rowPageBreak: 'avoid',
            margin: {bottom: 50},
            styles: {
                cellPadding: 2,
                cellWidth: 'auto',
                valign: 'middle',
                halign: 'center', 
                lineWidth: 0.1,
                lineColor: [0, 0, 0], 
            },  
            headStyles: {
                fillColor: [48, 51, 78],
                textColor: [255, 255, 255], 
            },
            bodyStyles: {
                fillColor: [255, 255, 255], 
                textColor: [0, 0, 0]
            }
        });

        doc.save(`${vendor_details?.name}.pdf`);
    };

    // ADDING ITEM TO DATAGRID...

    const addItem = () => {
        let errors = validateItem(values);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            const data = item.find(data => data.id === values.item_id);
            const sno = table_rows.length + 1;
            const new_row_data =
            {
                sno: sno,
                id: values.item_id,
                name: data.name,
                manufacturer: data.manufacturer,
                model: data.model,
                quantity: values.quantity,
                unit: data.unit
            }
            const updated_rows = [...table_rows, new_row_data];
            setTableRows(updated_rows);
        }
    }

    // VALIDATING ITEM FORM...

    const validateItem = (values) => {
        let errors = {};
        let regex_number = /^[0-9]*$/;

        if (!values.item_id) {
            errors.item_id = "*Item ID is required";
        }

        if (!values.quantity) {
            errors.quantity = "*Quantity is required";
        } else if (!regex_number.test(values.quantity)) {
            errors.quantity = "*Only digits allowed";
        }

        return errors;
    }

    // VALIDATING MAIN FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.vendor_id) {
            errors.vendor_id = "*Vendor is required";
        }

        if (!values.deadline) {
            errors.deadline = "*Deadline is required";
        }

        return errors;
    }

    // DELETING ITEM FROM DATAGRID...

    const handleDelete = () => {
        if (selected_row > -1) {
            const updatedRows = [...table_rows];
            updatedRows.splice(selected_row, 1);
            setTableRows(updatedRows);
            reorderArray(updatedRows);
        } else {
            setMessage({ ...message, 0: "No row selected" });
        }
    }

    // FUNCTION TO REARRANGE SNO AFTER DELETION...

    const reorderArray = (rows) => {
        rows.forEach((row, index) => {
            row.sno = index + 1;
        });
        setTableRows([...rows]);
    };

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // MAKING POST DATA...
  
    const createPayload = (values) => {
        const data =
        {
            date: FormatDate(values.date),
            vendor_id: values.vendor_id,
            bill_to: values.bill_to,
            ship_to: values.ship_to,
            deadline: FormatDate(values.deadline),
            status: 'Active',
            fiscal: data_context['fiscal'],
            item: table_rows
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange,
        handleSubmit,
        selectVendor,
        selectItem,
        rowRenderer,
        onRowClick,
        addItem,
        handleDelete,
        values,
        errors,
        vendor,
        options,
        item,
        message,
        loading,
        table_rows
    };

}

export default AddPurchaseOrderForm;