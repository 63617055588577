import React, { useState, useEffect, useContext } from "react";
import UserContext from "../Parent/UserContext";
import DataContext from "../Parent/DataContext";
import Get from "../HTTPRequest/Get";
import CustomAutocomplete from "../Components/CustomAutoComplete";
import { DataGrid } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Put from "../HTTPRequest/Put";
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import Post from "../HTTPRequest/Post";

const Permissions = () => {
    
    //STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const types = ['Any', 'Web', 'Mobile'];
    const initialValues = 
    { 
        type: types[0],
        role_id: ''
    };
    const [values, setValues] = useState(initialValues);
    const [role, setRole] = useState([]);

    const table_columns = [
        { field: 'sno', headerName: 'S.No', width: 50 },
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'page_id', headerName: 'Page ID', width: 100 },
        { field: 'page_name', headerName: 'Page Name', width: 250 },
        { field: 'type', headerName: 'Page Type', width: 100 },
        { field: 'role_id', headerName: 'Role ID', width: 100 },
        { field: 'role_name', headerName: 'Role Name', width: 150 },
        { field: 'create', headerName: 'Create', width: 80, renderCell: (params) => <Checkbox checked={params.value === '1'} onChange={() => handleCheckboxChange(params, 'create')}/> },
        { field: 'view', headerName: 'View', width: 80, renderCell: (params) => <Checkbox checked={params.value === '1'} onChange={() => handleCheckboxChange(params, 'view')}/> },
        { field: 'edit', headerName: 'Edit', width: 80, renderCell: (params) => <Checkbox checked={params.value === '1'} onChange={() => handleCheckboxChange(params, 'edit')}/> },
        { field: 'remove', headerName: 'Delete', width: 80, renderCell: (params) => <Checkbox checked={params.value === '1'} onChange={() => handleCheckboxChange(params, 'remove')}/> },
        { field: 'export', headerName: 'Export', width: 80, renderCell: (params) => <Checkbox checked={params.value === '1'} onChange={() => handleCheckboxChange(params, 'export')}/> },
        { field: 'print', headerName: 'Print', width: 80, renderCell: (params) => <Checkbox checked={params.value === '1'} onChange={() => handleCheckboxChange(params, 'print')}/> },
        { field: 'send', headerName: 'Send', width: 80, renderCell: (params) => <Checkbox checked={params.value === '1'} onChange={() => handleCheckboxChange(params, 'send')}/> }
    ];
    const [master_rows, setMasterRows] = useState([]);
    const [table_rows, setTableRows] = useState([]);
    const [loading, setLoading] = useState({ 0 : false, 1: false});
    const [page, setPage] = useState([]);
    const [dialog, setDialog] = useState(false);
    const [payload, setPayload] = useState({ role_id: '', page_id: '' })

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=roles';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                    
                    let data = response.response_payload[0] || [];                    
                    const newItems = data.id.map((_, i) => ({
                        id: data.id[i],
                        name: data.name[i]
                    }));

                    setRole(newItems);
                    
                }
            });
        }

        const setPermissions = async () => {
            let endpoint = user_context.url + '/permission';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {                
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                   
                    let payload = response.response_payload;                    
                        let data_length = (Object.keys(payload).length) - 1;
                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            data[i] = payload[i];
                        }

                        setMasterRows(data);                                           
                }
            });
        }

        const setPages = async () => {
            let endpoint = user_context.url + '/dropdown?route=pages';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                  
                    let data = response.response_payload[0] || [];                    
                    const newItems = data.id.map((_, i) => ({
                        id: data.id[i],
                        name: data.name[i]
                    }));

                    setPage(newItems);
                    
                }
            });
        }

        setSelect();
        setPermissions();
        setPages();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // ON SELECTION OF ROLE...

    const selectRole = (item) => {
        if (item) {
            setValues({ ...values, role_id: item.id });
        } else {
            setValues({ ...values, role_id: '' });
        }
    }

    // ON CHANGE OF ROLE...

    useEffect(() => {
        if(values.role_id) {
            var filteredRows = master_rows.filter(function(row) {
                return row.role_id === values.role_id;
            });

            filteredRows = filteredRows.filter(function(row) {
                return row.type === values.type;
            });

            filteredRows = filteredRows.map((row, index) => {
                return { ...row, sno: index + 1 };
            });
            
            setTableRows(filteredRows);
        }

        //eslint-disable-next-line
    }, [values.role_id, values.type]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // CHECKBOX EVENT...

    const handleCheckboxChange = (params, type) => {
        const updatedRows = table_rows.map((row) => {
            if (row.id === params.row.id) {
                return { ...row, [type]: params.value === '1' ? '0' : '1' };
            }
            return row;
        });
    
        setTableRows(updatedRows);
    };    
    
    // SUBMITTING FORM...

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (table_rows && table_rows.length > 0) {
            setLoading({...loading, 0: true});
            let payload = { rows: table_rows };
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/permission', payload, headers).then(response => {
                setLoading({...loading, 0: false});               
                if((response.status === 1) && (response.response_payload['result'] === 'success')){                    
                    toast.success(response?.message);   
                    
                    const newMasterRows = master_rows.map(masterRow => {
                        const correspondingTableRow = table_rows.find(tableRow => tableRow.id === masterRow.id);
                        if (correspondingTableRow) {
                          return correspondingTableRow;
                        } else {
                          return masterRow;
                        }
                    });
                    setMasterRows(newMasterRows);
                      
                      
                } else {
                    toast.error(response?.message);
                }             
            });
        } else {
            toast.error("No row found");
        }
    }

    // HANDLER FOR DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // ON SELECTION OF PAYLOAD ROLE...

    const selectPayloadRole = (item) => {        
        if (item) {
            setPayload({ ...payload, role_id: item.id });
        } else {
            setPayload({ ...payload, role_id: '' });
        }
    }

    // ON SELECTION OF PAGE...

    const selectPage = (item) => {        
        if (item) {
            setPayload({ ...payload, page_id: item.id });
        } else {
            setPayload({ ...payload, page_id: '' });
        }
    }

    // ON ADDING PERMISSION...

    const addPermission = async () => {
        if(payload.role_id !== '' && payload.page_id !== '') {
            setLoading({...loading, 1: true});

            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Post(user_context.url + '/permission', payload, headers).then(response => {
                setLoading({...loading, 1: false});
                toast.success(response?.message);
            });
        } else {
            toast.error("Some fields are empty");
        }
    }

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="view-wrapper">
                <form className="view-form" onSubmit={handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">PERMISSIONS</h1>
                    </div>

                    <div className="view-formGroup">
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' , width: '300px'}}>
                            <label className="view-label" style={{marginRight: '5px'}}>Role:</label>                        
                            {role &&
                                <CustomAutocomplete
                                    data={role}
                                    placeholder="Search Roles..."
                                    selectValue={selectRole}
                                    selected_value={values.role_id}
                            />}
                        </div> 

                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' , width: '200px'}}>
                            <label className="view-label">Type:</label>                    
                            <select className="view-select" name="type" value={values.type} onChange={handleChange} disabled={Boolean(!values.role_id)}>
                                {types.map((item, i) => (<option key={i}>{item}</option>))}
                            </select> 
                        </div>      
                          
                        <button className="view-button" type="submit" disabled={loading[0]}><SaveOutlinedIcon/>&nbsp;Save</button> 
                        <button className="view-button" type="button" onClick={handleDialog}><BlockOutlinedIcon/>&nbsp;Add&nbsp;Permission</button>                                               
                    </div>
                </form> 

                 {/* DATA GRID */}

                 <div className="table-container">
                    <div style={{ width: '100%', height: '100%', backgroundColor: '#FFFF' }}>
                        <DataGrid
                            editMode="cell"
                            rows={table_rows}
                            columns={table_columns}
                            disableSelectionOnClick
                        />
                    </div>
                </div>
            </div> 
            {/* DELETE DIALOG BOX... */}

            {dialog && <ReactDialogBox
                closeBox={handleDialog}
                modalWidth='30%'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'
            >

                <div className="dialog-container">
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px' , width: '300px'}}>
                        <label className="view-label" style={{marginRight: '5px'}}>Role:</label>                        
                        {role &&
                            <CustomAutocomplete
                                data={role}
                                placeholder="Search Roles..."
                                selectValue={selectPayloadRole}
                                selected_value={payload.role_id}
                        />}
                    </div> 

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px' , width: '300px'}}>
                        <label className="view-label" style={{marginRight: '5px'}}>Page:</label>                        
                        {page &&
                            <CustomAutocomplete
                                data={page}
                                placeholder="Search Pages..."
                                selectValue={selectPage}
                                selected_value={payload.page_id}
                        />}
                    </div> 

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={addPermission} disabled={loading[1]}>Save</button>
                    </div>
                </div>
            </ReactDialogBox>}
            <ToastContainer/>           
        </div>
    );
}

export default Permissions;