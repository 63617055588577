import React from "react";
import AddVendorForm from "./AddVendorForm";
import { ToastContainer } from 'react-toastify';

const AddVendor = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddVendorForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR VENDOR DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;VENDOR</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Name:</label>
                        <input type="text" autoComplete='off' className="input" name="name" value={destruct.values.name} onChange={destruct.handleChange}></input>
                        {destruct.errors.name && <p className="error">{destruct.errors.name}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Address Line 1:</label>
                        <input type="text" autoComplete='off' className="input" name="address1" value={destruct.values.address1} onChange={destruct.handleChange}></input>
                        {destruct.errors.address1 && <p className="error">{destruct.errors.address1}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Address Line 2:</label>
                        <input type="text" autoComplete='off' className="input" name="address2" value={destruct.values.address2} onChange={destruct.handleChange}></input>
                        {destruct.errors.address2 && <p className="error">{destruct.errors.address2}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">District:</label>
                        <input type="text" autoComplete='off' className="input" name="district" value={destruct.values.district} onChange={destruct.handleChange}></input>
                        {destruct.errors.district && <p className="error">{destruct.errors.district}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">State:</label>
                        <input type="text" autoComplete='off' className="input" name="state" value={destruct.values.state} onChange={destruct.handleChange}></input>
                        {destruct.errors.state && <p className="error">{destruct.errors.state}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Country:</label>
                        <input type="text" autoComplete='off' className="input" name="country" value={destruct.values.country} onChange={destruct.handleChange}></input>
                        {destruct.errors.country && <p className="error">{destruct.errors.country}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Pincode:</label>
                        <input type="text" autoComplete='off' className="input" name="pincode" value={destruct.values.pincode} onChange={destruct.handleChange}></input>
                        {destruct.errors.pincode && <p className="error">{destruct.errors.pincode}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">GSTIN:</label>
                        <input type="text" autoComplete='off' className="input" name="gstin" value={destruct.values.gstin} onChange={destruct.handleChange}></input>
                        {destruct.errors.gstin && <p className="error">{destruct.errors.gstin}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">PAN:</label>
                        <input type="text" autoComplete='off' className="input" name="pan" value={destruct.values.pan} onChange={destruct.handleChange}></input>
                        {destruct.errors.pan && <p className="error">{destruct.errors.pan}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">TAN:</label>
                        <input type="text" autoComplete='off' className="input" name="tan" value={destruct.values.tan} onChange={destruct.handleChange}></input>
                        {destruct.errors.tan && <p className="error">{destruct.errors.tan}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Phone:</label>
                        <input type="text" autoComplete='off' className="input" name="phone" value={destruct.values.phone} onChange={destruct.handleChange}></input>
                        {destruct.errors.phone && <p className="error">{destruct.errors.phone}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Contact Person:</label>
                        <input type="text" autoComplete='off' className="input" name="contact_person" value={destruct.values.contact_person} onChange={destruct.handleChange}></input>
                        {destruct.errors.contact_person && <p className="error">{destruct.errors.contact_person}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Email:</label>
                        <input type="text" autoComplete='off' className="input" name="email" value={destruct.values.email} onChange={destruct.handleChange}></input>
                        {destruct.errors.email && <p className="error">{destruct.errors.email}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading}>Save</button>
                    </div>

                </form>

            </div>
            <ToastContainer/>
        </div>
    );

}

export default AddVendor;