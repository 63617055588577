
// MAIN FUNCTION THAT RETURNS KEY OF AN ARRAY OF OBJECTS...

const GetKey = (data, item) => {
    let key = -1;

    for (const key in data) {
        if (JSON.stringify(data[key]['id']) === JSON.stringify(item.id)) {
            return key;
        }
    }

    return key;
}

export default GetKey;