import React from "react";
import AddAgreementForm from "./AddAgreementForm";
import DatePicker from "react-datepicker";
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import '../Ledger/Purchase/tax.css';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';

const AddAgreement = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddAgreementForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR AGREEMENT DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;AGREEMENT</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Client&nbsp;ID:</label>
                        <select className="select" name="client_id" value={destruct.values.client_id} onChange={destruct.handleChange}>
                            {destruct.client_id && destruct.client_id.length 
                                ? (destruct.client_id.map((item, i) => (<option key={i}>{item}</option>)))
                                : (<option value="">Loading...</option>)}
                        </select>
                        {destruct.errors.client_id && <p className="error">{destruct.errors.client_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Client:</label>
                        <input type="text" autoComplete='off' className="input" name="client" value={destruct.values.client} disabled></input>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Agreement Number:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name="agreement_no" value={destruct.values.agreement_no} onChange={destruct.handleChange}></input>
                            <button className="tax-button" type="button" onClick={destruct.handleContract} disabled={destruct.loading[3]}><LiveHelpOutlinedIcon/></button>        
                        </div>                        
                        {destruct.errors.agreement_no && <p className="error">{destruct.errors.agreement_no}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Status:</label>
                        <select className="select" name="status" value={destruct.values.status} onChange={destruct.handleChange}>
                            {destruct.statuses.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.status && <p className="error">{destruct.errors.status}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Type:</label>
                        <select className="select" name="type" value={destruct.values.type} onChange={destruct.handleChange} >
                            {destruct.types.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.type && <p className="error">{destruct.errors.type}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Signatory 1:</label>
                        <input type="text" autoComplete='off' className="input" name="signatory_1" value={destruct.values.signatory_1} onChange={destruct.handleChange}></input>
                        {destruct.errors.signatory_1 && <p className="error">{destruct.errors.signatory_1}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Signatory 2:</label>
                        <input type="text" autoComplete='off' className="input" name="signatory_2" value={destruct.values.signatory_2} onChange={destruct.handleChange}></input>
                        {destruct.errors.signatory_2 && <p className="error">{destruct.errors.signatory_2}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Signatory 3:</label>
                        <input type="text" autoComplete='off' className="input" name="signatory_3" value={destruct.values.signatory_3} onChange={destruct.handleChange}></input>
                        {destruct.errors.signatory_3 && <p className="error">{destruct.errors.signatory_3}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Witness 1:</label>
                        <input type="text" autoComplete='off' className="input" name="witness_1" value={destruct.values.witness_1} onChange={destruct.handleChange}></input>
                        {destruct.errors.witness_1 && <p className="error">{destruct.errors.witness_1}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Witness 2:</label>
                        <input type="text" autoComplete='off' className="input" name="witness_2" value={destruct.values.witness_2} onChange={destruct.handleChange}></input>
                        {destruct.errors.witness_2 && <p className="error">{destruct.errors.witness_2}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Start&nbsp;Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.start_date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'start_date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.start_date && <p className="error">{destruct.errors.start_date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">End&nbsp;Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.end_date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'end_date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.end_date && <p className="error">{destruct.errors.end_date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Tenure:</label>
                        <input type="text" autoComplete='off' className="input" name="tenure" value={destruct.values.tenure} onChange={destruct.handleChange}></input>
                        {destruct.errors.tenure && <p className="error">{destruct.errors.tenure}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Consideration:</label>
                        <input type="text" autoComplete='off' className="input" name="consideration" value={destruct.values.consideration} onChange={destruct.handleChange}></input>
                        {destruct.errors.consideration && <p className="error">{destruct.errors.consideration}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Classes:</label>
                        <input type="text" autoComplete='off' className="input" name="classes" value={destruct.values.classes} onChange={destruct.handleChange}></input>
                        {destruct.errors.classes && <p className="error">{destruct.errors.classes}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Documents:</label>
                        <div className="tax-formGroup">
                            <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                            <button className="tax-button" type="button" onClick={destruct.handleDialog}><CloudUploadOutlinedIcon /></button>
                        </div>
                        {destruct.errors.docs_url && <p className="error">{destruct.errors.docs_url}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleSubmit} disabled={destruct.loading[0]}>Save</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                    </div>
                </form>
            </div>
            {/* UPLOAD DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>                        
                        <progress className="form-progress" value={destruct.progress} max="100" />
                        <span className="progressText">{destruct.progress + "%"}</span>
                        {destruct.docs_errors && <p className="message">{destruct.docs_errors}</p>}
                        {destruct.message[1] && <p className="message">{destruct.message[1]}</p>}

                        <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={destruct.viewPDF} disabled={destruct.loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={destruct.handleUpload} disabled={destruct.loading[2]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={destruct.handleClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>                        
                    </div>
                </div>
            </ReactDialogBox>}

            {/* AGREEMENT DIALOG BOX... */}

            {destruct.contract_dialog && <ReactDialogBox
                closeBox={destruct.handleContractDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='200px'            >

                <div className="dialog-container">
                    {destruct.contract && <textarea className="text-area" value={destruct.contract.join('\n')} readOnly/>}
                    {!destruct.contract && <textarea className="text-area" placeholder="No data found" readOnly/>}
                </div>
            </ReactDialogBox>}
        </div>
    );
}

export default AddAgreement;