import React from "react";
import AddTaskForm from "./AddTaskForm";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DatePicker from "react-datepicker";

const AddTask = () =>{
    // DECLARING STATES...    

    const { ...destruct } = AddTaskForm(); 

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                 {/* FORM FOR TASK DETAILS... */}
                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;TASK</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Date:</label>
                        <div className="datepicker" style={{ zIndex: 2 }}>
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Assignee:</label>
                        <select className="select" name="assignee" value={destruct.values.assignee} onChange={destruct.handleChange} >
                            {destruct.staff && destruct.staff.id && destruct.staff.name
                                ? (destruct.staff.id.map((id, index) =>
                                    (<option key={id} value={id}>{id} - {destruct.staff.name[index]}</option>)))
                                : (<option value="">Loading...</option>)
                            }
                        </select>
                        {destruct.errors.assignee && <p className="error">{destruct.errors.assignee}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Task:</label>
                        <input type="text" autoComplete='off' className="input" name="task" value={destruct.values.task} onChange={destruct.handleChange}></input>
                        {destruct.errors.task && <p className="error">{destruct.errors.task}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Status:</label>
                        <select className="select" name="status" value={destruct.values.status} onChange={destruct.handleChange} >
                            {destruct.statuses.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.status && <p className="error">{destruct.errors.status}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading[0]}>Save</button>
                        {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                    </div>

                </form>
            </div>
        </div>
    );
}

export default AddTask;