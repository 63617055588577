import React from "react";
import './addStaff.css';
import AddStaffForm from "./AddStaffForm";
import "react-datepicker/dist/react-datepicker.css";
import ViewImage from "../Parent/ViewImage";
import Row from "../Parent/Row";
import { useMediaQuery } from 'react-responsive';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { ToastContainer } from 'react-toastify';

const AddStaff = () => {

    // DECLARING STATES...  

    const { ...destruct } = AddStaffForm();
    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });

    let personal1 =
        [
            { label: "Name:", name: "name", type: "text", value: destruct.values.name, errors: destruct.errors.name, onChange: destruct.handleChange },
            { label: "Address Line 1:", name: "address1", type: "text", value: destruct.values.address1, errors: destruct.errors.address1, onChange: destruct.handleChange }
        ];

    let personal2 =
        [
            { label: "Address Line 2:", name: "address2", type: "text", value: destruct.values.address2, errors: destruct.errors.address2, onChange: destruct.handleChange },
            { label: "District:", name: "district", type: "text", value: destruct.values.district, errors: destruct.errors.district, onChange: destruct.handleChange }
        ];

    let personal3 =
        [
            { label: "State:", name: "state", type: "select", value: destruct.values.state, values: destruct.states, errors: destruct.errors.state, onChange: destruct.handleChange },
            { label: "Pincode:", name: "pincode", type: "text", value: destruct.values.pincode, errors: destruct.errors.pincode, onChange: destruct.handleChange },
        ];

    let personal4 =
        [
            { label: "Bloodgroup:", name: "bloodgroup", type: "select", value: destruct.values.bloodgroup, values: destruct.bloodgroups, errors: destruct.errors.bloodgroup, onChange: destruct.handleChange },
            { label: "Gender:", name: "gender", type: "select", value: destruct.values.gender, values: destruct.genders, errors: destruct.errors.gender, onChange: destruct.handleChange }
        ];

    let professional1 =
        [
            { label: "Date of Joining:", name: "doj", type: "date", value: destruct.values.doj, errors: destruct.errors.doj, onChange: destruct.handleChange },
            { label: "Date of Resignation:", name: "dor", type: "date", value: destruct.values.dor, errors: destruct.errors.dor, onChange: destruct.handleChange }
        ];

    let professional2 =
        [
            { label: "Qualification:", name: "qualification", type: "text", value: destruct.values.qualification, errors: destruct.errors.qualification, onChange: destruct.handleChange },
            { label: "Designation:", name: "designation", type: "select", value: destruct.values.designation, values: destruct.designation, errors: destruct.errors.designation, onChange: destruct.handleChange }
        ];

    let professional3 =
        [
            { label: "Salary:", name: "salary", type: "text", value: destruct.values.salary, errors: destruct.errors.salary, onChange: destruct.handleChange },
            { label: "Date of Birth:", name: "dob", type: "date", value: destruct.values.dob, errors: destruct.errors.dob, onChange: destruct.handleChange }
        ];

    let professional4 =
        [
            { label: "Status:", name: "status", type: "select", value: destruct.values.status, values: destruct.statuses, errors: destruct.errors.status, onChange: destruct.handleChange}            
        ];

    let salary1 =
        [
            { label: "Basic Pay:", name: "basic_pay", type: "text", value: destruct.values.basic_pay, errors: destruct.errors.basic_pay, onChange: destruct.handleChange },
            { label: "HRA:", name: "hra", type: "text", value: destruct.values.hra, errors: destruct.errors.hra, onChange: destruct.handleChange }
        ];
    
    let salary2 =
        [
            { label: "Earned Increment:", name: "earned_increment", type: "text", value: destruct.values.earned_increment, errors: destruct.errors.earned_increment, onChange: destruct.handleChange },
            { label: "TA:", name: "ta", type: "text", value: destruct.values.ta, errors: destruct.errors.ta, onChange: destruct.handleChange }
        ];

    let salary3 =
        [
            { label: "Performance Allowance:", name: "performance_allowance", type: "text", value: destruct.values.performance_allowance, errors: destruct.errors.performance_allowance, onChange: destruct.handleChange }          
        ];

    let communication1 =
        [
            { label: "Phone:", name: "phone", type: "text", value: destruct.values.phone, errors: destruct.errors.phone, onChange: destruct.handleChange },
            { label: "App ID:", name: "alternative", type: "text", value: destruct.values.alternative, errors: destruct.errors.alternative, onChange: destruct.handleChange }
        ];

    let communication3 =
        [
            { label: "Email:", name: "email", type: "text", value: destruct.values.email, errors: destruct.errors.email, onChange: destruct.handleChange }
        ];

    let extra1 =
        [
            { label: "Father:", name: "father", type: "text", value: destruct.values.father, errors: destruct.errors.father, onChange: destruct.handleChange },
            { label: "Mother:", name: "mother", type: "text", value: destruct.values.mother, errors: destruct.errors.mother, onChange: destruct.handleChange }
        ];

    let extra2 =
        [
            { label: "Account No:", name: "account", type: "text", value: destruct.values.account, errors: destruct.errors.account, onChange: destruct.handleChange },
            { label: "UAN:", name: "uan", type: "text", value: destruct.values.uan, errors: destruct.errors.uan, onChange: destruct.handleChange }
        ];

    let extra3 =
        [
            { label: "ESI:", name: "esi", type: "text", value: destruct.values.esi, errors: destruct.errors.esi, onChange: destruct.handleChange }
        ];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="staff-wrapper">

                {/* FORM FOR STAFF DETAILS... */}

                <form className="staff-form" onSubmit={destruct.handleSubmit}>

                    {/* FORM HEAD... */}
                    <div className="staff-formGroup">
                        <h1 className="form-head">ADD&nbsp;STAFF</h1>
                    </div>

                    {/* PERSONAL DETAILS... */}

                    <h1 className="form-subhead">Personal&nbsp;Details</h1>

                    <div className="staff-sectionWrapper">
                        <Row structure={personal1} single={false} />
                        <Row structure={personal2} single={false} />
                        <Row structure={personal3} single={false} />
                        <Row structure={personal4} single={false} />
                    </div>

                    {/* PROFESSIONAL DETAILS... */}

                    <h1 className="form-subhead">Professional&nbsp;Details</h1>

                    <div className="staff-sectionWrapper">
                        <Row structure={professional1} single={false} />
                        <Row structure={professional2} single={false} />
                        <Row structure={professional3} single={false} />
                        <Row structure={professional4} single={true} />
                    </div>

                    {/* SALARY DETAILS... */}

                    <h1 className="form-subhead">Salary&nbsp;Details</h1>

                    <div className="staff-sectionWrapper">
                        <Row structure={salary1} single={false} />
                        <Row structure={salary2} single={false} />
                        <Row structure={salary3} single={true} />
                    </div>

                    {/* COMMUNICATION... */}

                    <h1 className="form-subhead">Communication</h1>

                    <div className="staff-sectionWrapper">
                        <Row structure={communication1} single={false} />
                        <Row structure={communication3} single={true} />
                    </div>

                    {/* EXTRA... */}

                    <h1 className="form-subhead">Extra</h1>

                    <div className="staff-sectionWrapper">
                        <Row structure={extra1} single={false} />
                        <Row structure={extra2} single={false} />
                        <Row structure={extra3} single={true} />
                    </div>


                    {/* PHOTO... */}

                    <h1 className="form-subhead">Upload&nbsp;Photo</h1>

                    {!isMobile &&
                        <div className="staff-sectionWrapper">

                            <div className="staff-rowWrapper">
                                <div className="staff-viewGroup">
                                    <ViewImage image={destruct.image} placeholder={destruct.placeholder_image} />
                                </div>

                                <div className="staff-actionGroup">
                                    <div className="staff-docGroup">
                                        <input type="text" autoComplete='off' className="browse-input" name='image_url' value={destruct.values.image_url} readOnly></input>
                                    </div>

                                    <div className="staff-docGroup">
                                        <progress className="staff-progress" value={destruct.progress[0]} max="100" />
                                        <span className="staff-progressText">{destruct.progress[0] + "%"}</span>
                                    </div>

                                    <div className="staff-docGroup">
                                        {destruct.image_errors && <p className="error">{destruct.image_errors}</p>}
                                    </div>

                                    <div className="staff-docGroup">
                                        <input id="image_file" name="image_file" type="file" accept="image/*" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                                        <label htmlFor="image_file" className="browse-button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                                        <button className="browse-button" type="button" onClick={() => destruct.handleUpload('image_upload')} disabled={destruct.loading[0]}><FilterDramaOutlined />&nbsp;Upload</button>
                                        <button className="browse-button" type="button" onClick={() => destruct.handleClear('image_clear')}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                                    </div>
                                </div>
                            </div>

                        </div>}

                    {isMobile &&
                        <div className="staff-sectionWrapper">

                            <div className="staff-viewGroup">
                                <ViewImage image={destruct.image} placeholder={destruct.placeholder_image} />
                            </div>

                            <div className="staff-actionGroup">
                                <input type="text" autoComplete='off' className="browse-input" name='image_url' value={destruct.values.image_url} readOnly></input>
                                <progress className="staff-progress" value={destruct.progress[0]} max="100" />
                                {destruct.image_errors && <p className="error">{destruct.image_errors}</p>}
                                <input id="image_file" name="image_file" type="file" accept="image/*" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                                <label htmlFor="image_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                                <button className="button" type="button" onClick={() => destruct.handleUpload('image_upload')} disabled={destruct.loading[0]}><FilterDramaOutlined />&nbsp;Upload</button>
                                <button className="button" type="button" onClick={() => destruct.handleClear('image_clear')}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                            </div>

                        </div>}

                    {/* DOCUMENTS... */}

                    <h1 className="form-subhead">Upload&nbsp;Documents</h1>

                    {!isMobile &&
                        <div className="staff-sectionWrapper">

                            <div className="staff-rowWrapper">
                                <div className="staff-actionGroup">

                                    <div className="staff-docGroup">
                                        <input type="text" autoComplete='off' className="browse-input" name='pdf_url' value={destruct.values.pdf_url} readOnly></input>
                                    </div>

                                    <div className="staff-docGroup">
                                        <progress className="staff-progress" value={destruct.progress[1]} max="100" />
                                        <span className="staff-progressText">{destruct.progress[1] + "%"}</span>
                                    </div>

                                    <div className="staff-docGroup">

                                    </div>

                                    <div className="staff-docGroup">
                                        {destruct.pdf_errors && <p className="error">{destruct.pdf_errors}</p>}
                                    </div>

                                    <div className="staff-docGroup">
                                        <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                                        <label htmlFor="pdf_file" className="browse-button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                                        <button className="browse-button" type="button" onClick={() => destruct.handleUpload('pdf_upload')} disabled={destruct.loading[1]}><FilterDramaOutlined />&nbsp;Upload</button>
                                        <button className="browse-button" type="button" onClick={() => destruct.viewPDF()} disabled={destruct.loading[4]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                                        <button className="browse-button" type="button" onClick={() => destruct.handleClear('pdf_clear')}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                                    </div>

                                </div>
                            </div>

                        </div>}

                    {isMobile &&
                        <div className="staff-sectionWrapper">

                            <div className="staff-actionGroup">
                                <input type="text" autoComplete='off' className="browse-input" name='pdf_url' value={destruct.values.pdf_url} readOnly></input>
                                <progress className="staff-progress" value={destruct.progress[1]} max="100" />
                                {destruct.pdf_errors && <p className="error">{destruct.pdf_errors}</p>}
                                <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                                <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                                <button className="button" type="button" onClick={() => destruct.handleUpload('pdf_upload')} disabled={destruct.loading[1]}><FilterDramaOutlined />&nbsp;Upload</button>
                                <button className="button" type="button" onClick={() => destruct.viewPDF()} disabled={destruct.loading[4]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                                <button className="button" type="button" onClick={() => destruct.handleClear('pdf_clear')}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                            </div>

                        </div>}

                    <div className="staff-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading[3]}>Save</button>
                    </div>

                </form>

            </div>
            <ToastContainer/>
        </div>
    )
}

export default AddStaff;