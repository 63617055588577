
// MAIN FUNCTION THAT RETURNS INDEX OF AN ARRAY...

const GetIndex = (array, key) => {
    let index = -1;

    for (let i = 0; i < array.length; i++) {        
        if (array[i] === key) {
            index = i;
        }
    }

    return index;
}

export default GetIndex;
