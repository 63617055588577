import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const LoginForm = (props) => {
    
    //DECLARING STATES...

    const user_context = useContext(UserContext);
    const [values, setValues] = useState({ username: '', password: '' });
    const [errors, setErrors] = useState({});
    const [response_data, setResponseData] = useState({});
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    // REGISTERING INPUT VALUES....

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateLogin(values);
        setErrors(errors);
        setMessage();

        if (Object.keys(errors).length === 0) {
            setLoading(true);

            const request_payload = { 
                username: values.username, 
                password: values.password, 
                device_id: null 
            };
            let endpoint = user_context.url + '/login';       
            const headers = { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8' 
            };

            try {
                const response = await axios.post(endpoint, request_payload, { headers, timeout: 30000 });
                if (response.status === 200) {
                    setMessage(response.data['message']);
                    setResponseData(response.data);
                }
            } catch (error) {                
                setMessage(error.message);
            } finally {
                setLoading(false);
            }
        }
    }

    // VALIDATING FORM...

    const validateLogin = (values) => {
        let errors = {};

        if (!values.username) {
            errors.username = "*Username is required";
        }

        if (!values.password) {
            errors.password = "*Password is required";
        }

        return errors;
    }

    // REDIRECTING TO HOMEPAGE IF AUTHENTICATED...

    useEffect(() => {
        if (response_data.result === 'success') {
            props.handleChange(response_data);
            history.push("/home");
        }

        //eslint-disable-next-line
    }, [response_data]);


    // RETURNING VALUES...

    return { handleChange, handleSubmit, values, errors, message, loading };
};

export default LoginForm;


