import React, {memo} from "react";
import './itemImage.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

const ItemImage = memo((props) => {
    let newWidth = props.width;
    let newHeight = props.height;

    if (props.width > 300 || props.height > 150) {
        const widthRatio = props.width / 300;
        const heightRatio = props.height / 150;

        if (widthRatio > heightRatio) {
            newWidth = 300;
            newHeight = props.height / widthRatio;
        } else {
            newHeight = 150;
            newWidth = props.width / heightRatio;
        }
    }

    return (
        <div className="item-image-wrapper">   
            <LazyLoadImage
                className="item-image"
                style={{ maxWidth: newWidth, maxHeight: newHeight }}
                src={props.image}
                alt="Not found"
            />        
        </div>
    );
});

export default ItemImage;