import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import GetIndex from '../Parent/GetIndex';
import FormatDate from '../Parent/FormatDate';
import CreateLocalDate from '../Parent/CreateLocalDate';
import CreateDate from '../Parent/CreateDate';
import { useHistory } from 'react-router-dom';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';
import FormatLocalDate from '../Parent/FormatLocalDate';
import Get from '../HTTPRequest/Get';

const UpdateAttendanceForm = (props) => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const types = ['Present', 'Leave', 'Half Day', 'Special'];
    const current = new Date();
    const [disable, setDisable] = useState({ 0: false, 1: false, 2: false, 3: false });
    const [staff_id, setStaffID] = useState([]);
    const [staff, setStaff] = useState([]);
    const [location_id, setLocationID] = useState([]);
    const [address, setAddress] = useState([]);
    const initialValues =
    {
        id: data_context.update_data['id'],
        date: CreateLocalDate(data_context.update_data['date']),
        staff_id: '',
        name: '',
        type: types[GetIndex(types, data_context.update_data['type'])],
        location_id: '',
        address: '',
        signin: data_context.update_data['signin'] || current.toLocaleTimeString("en-US", { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" }),
        signout: data_context.update_data['signout'] || current.toLocaleTimeString("en-US", { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" }),
        reason: data_context.update_data['reason'] || ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const history = useHistory();

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=work';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let staff_id = response.response_payload[0]['id'] || [];
                        let staff = response.response_payload[0]['name'] || [];
                        let location_id = response.response_payload[1]['id'] || [];
                        let address = response.response_payload[1]['address'];

                        setStaffID(staff_id);
                        setStaff(staff);
                        setLocationID(location_id);
                        setAddress(address);

                        setValues({
                            ...values, staff_id: staff_id[GetIndex(staff_id, data_context.update_data['staff_id'])],
                            name: staff[GetIndex(staff_id, data_context.update_data['staff_id'])],
                            location_id: (data_context.update_data['location_id'])?location_id[GetIndex(location_id, data_context.update_data['location_id'])]: '',
                            address: (data_context.update_data['location_id'])?address[GetIndex(location_id, data_context.update_data['location_id'])]:''
                        });
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });

        if (e.target.name === 'staff_id') {
            setValues({ ...values, staff_id: value, name: staff[GetIndex(staff_id, e.target.value)] });
        }

        if (e.target.name === 'location_id') {
            setValues({ ...values, location_id: value, address: address[GetIndex(location_id, e.target.value)] });
        }
    }


    // REGISTERING TYPE CHANGE...

    useEffect(() => {
        setErrors({});

        if (values.type === 'Present') {
            setDisable({ 0: true, 1: false, 2: false, 3: true });
        }

        if (values.type === 'Leave' || values.type === 'Special') {
            setDisable({ 0: false, 1: true, 2: true, 3: true });
        }

        if (values.type === 'Half Day') {
            setDisable({ 0: false, 1: false, 2: true, 3: true });
        }

        //eslint-disable-next-line
    }, [values.type]);


    // ENABLE OR DISABLE SIGN OUT...

    const handleToggle = () => {
        if (values.type === 'Present') {
            setDisable({ ...disable, 3: !disable[3] });
        }
    }

    // SUBMITTING FORM...

    const handleSubmit = async () => {
        let errors = validateForm(values);
        setErrors(errors);
        setMessage();

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/attendance', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage(response.message);

                if(response.status === 1){
                    if (response.response_payload['result'] === 'success') {
                        let data = data_context.view_data.table_rows;
                        let index = GetKey(data, payload);
                        payload = { ...payload, sno: data[index]['sno'] };
                        payload = { ...payload, date: FormatLocalDate(CreateDate(payload['date'])) };
                        data = ({ ...data, [index]: payload });
                        const row_values = Object.values(data);

                        let view_data = data_context.view_data;
                        view_data = ({ ...view_data, table_rows: row_values });
                        props.handleBack(view_data);
                    }
                }
            });
        }
    }


    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_attendance");
    }


    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.id) {
            errors.id = "*Attendance ID is required";
        }

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.staff_id) {
            errors.staff_id = "*Staff ID is required";
        }

        if (values.type === 'Present') {

            if (!values.location_id) {
                errors.location_id = "*Location ID is required";
            }

            if (!values.signin) {
                errors.signin = "*Sign In is required";
            }
        }

        if (values.type === 'Leave' || values.type === 'Special') {
            if (!values.reason) {
                errors.reason = "*Reason is required";
            }
        }

        if (values.type === 'Half Day') {
            if (!values.location_id) {
                errors.location_id = "*Location ID is required";
            }

            if (!values.reason) {
                errors.reason = "*Reason is required";
            }
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        let location_id = null;
        let signin = '';
        let signout = '';
        let reason = '';

        if (!disable[0]) {
            reason = values.reason;
        }

        if (!disable[1]) {
            location_id = values.location_id;
        }

        if (!disable[2]) {
            signin = values.signin;
        }

        if (!disable[3]) {
            signout = values.signout;
        }


        const data =
        {
            id: values.id,
            date: FormatDate(values.date),
            staff_id: values.staff_id,
            type: values.type,
            location_id: location_id,
            signin: signin,
            signout: signout,
            reason: reason,
            fiscal: data_context['fiscal']
        };

        return data;
    }


    // RETURNING VALUES...

    return { handleChange, handleSubmit, handleToggle, handleBack, values, errors, message, loading, staff_id, types, location_id, disable };
}

export default UpdateAttendanceForm;