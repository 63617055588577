import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import Post from '../HTTPRequest/Post';

const AddHeadForm = () =>
{
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const initialValues = 
    {
        name: '',
        address: '', 
        contact: '',        
    };

    const [values, setValues] = useState(initialValues);  
    const [errors, setErrors] = useState({}); 
    const [message, setMessage] = useState({0: ''});
    const [loading, setLoading] = useState({0: false});

    // REGISTERING INPUT VALUES...

    const handleChange = e =>
    {
        const {name, value} = e.target;
        setValues({...values, [name] : value});
    }

    // SUBMITTING FORM...

    const handleSubmit = async e =>
    {        
        e.preventDefault();
        
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});

        if (Object.keys(errors).length === 0) {
            setLoading({...loading, 0: true});

            let payload = createPayload(values);
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Post(user_context.url + '/head', payload, headers).then(response => {
                setLoading({...loading, 0: false});
                setMessage({...message, 0: response.message});
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) =>
    {
        let errors = {};
        let regex_number =/^[0-9]*$/;

        if(!values.name)
        {
            errors.name = "*Name is required";
        }

        if(values.contact)
        {
            if(!regex_number.test(values.contact))
            {
                errors.contact = "*Only digits allowed";
            }
        }
        
        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) =>
    {
        const data = 
        {
            name: values.name, 
            address: values.address,
            contact: values.contact,
        };

        return data;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, values, errors, message, loading };
}

export default AddHeadForm;