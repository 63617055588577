import React from "react";
import AddUserForm from "./AddUserForm";

const AddUser = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddUserForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR USER DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;USER</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Username:</label>
                        <input
                            type="text"
                            autoComplete='off'
                            className="input"
                            name="username"
                            value={destruct.values.username}
                            onChange={destruct.handleChange}>
                        </input>
                        {destruct.errors.username && <p className="error">{destruct.errors.username}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Password:</label>
                        <input
                            type="text"
                            autoComplete='off'
                            className="input"
                            name="password"
                            value={destruct.values.password}
                            onChange={destruct.handleChange}>
                        </input>
                        {destruct.errors.password && <p className="error">{destruct.errors.password}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Staff:</label>
                        <select className="select" name="staff" value={destruct.values.staff} onChange={destruct.handleChange} >
                            {destruct.staff && destruct.staff.id && destruct.staff.name
                                ? (destruct.staff.id.map((id, index) =>
                                    (<option key={id} value={id}>{id} - {destruct.staff.name[index]}</option>)))
                                : (<option value="">Loading...</option>)
                            }
                        </select>
                        {destruct.errors.staff && <p className="error">{destruct.errors.staff}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Role:</label>
                        <select className="select" name="role" value={destruct.values.role} onChange={destruct.handleChange} >
                            {destruct.role && destruct.role.id && destruct.role.name
                                ? (destruct.role.id.map((id, index) =>
                                    (<option key={id} value={id}>{id} - {destruct.role.name[index]}</option>)))
                                : (<option value="">Loading...</option>)
                            }
                        </select>
                        {destruct.errors.role && <p className="error">{destruct.errors.role}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading}>Save</button>
                        {destruct.message && <p className="message">{destruct.message}</p>}
                    </div>

                </form>
            </div>
        </div>
    );
}

export default AddUser;