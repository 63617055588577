import React from "react";
import AddClientForm from "./AddClientForm";

const AddClient = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddClientForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR CLIENT DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;CLIENT</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Name:</label>
                        <input type="text" autoComplete='off' className="input" name="name" value={destruct.values.name} onChange={destruct.handleChange}></input>
                        {destruct.errors.name && <p className="error">{destruct.errors.name}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Address Line 1:</label>
                        <input type="text" autoComplete='off' className="input" name="address1" value={destruct.values.address1} onChange={destruct.handleChange}></input>
                        {destruct.errors.address1 && <p className="error">{destruct.errors.address1}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Address Line 2:</label>
                        <input type="text" autoComplete='off' className="input" name="address2" value={destruct.values.address2} onChange={destruct.handleChange}></input>
                        {destruct.errors.address2 && <p className="error">{destruct.errors.address2}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">District:</label>
                        <input type="text" autoComplete='off' className="input" name="district" value={destruct.values.district} onChange={destruct.handleChange}></input>
                        {destruct.errors.district && <p className="error">{destruct.errors.district}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">State:</label>
                        <select className="select" name="state" value={destruct.values.state} onChange={destruct.handleChange} >
                            {destruct.states.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.state && <p className="error">{destruct.errors.state}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Pincode:</label>
                        <input type="text" autoComplete='off' className="input" name="pincode" value={destruct.values.pincode} onChange={destruct.handleChange}></input>
                        {destruct.errors.pincode && <p className="error">{destruct.errors.pincode}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Principal:</label>
                        <input type="text" autoComplete='off' className="input" name="principal" value={destruct.values.principal} onChange={destruct.handleChange}></input>
                        {destruct.errors.principal && <p className="error">{destruct.errors.principal}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Director:</label>
                        <input type="text" autoComplete='off' className="input" name="director" value={destruct.values.director} onChange={destruct.handleChange}></input>
                        {destruct.errors.director && <p className="error">{destruct.errors.director}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Phone:</label>
                        <input type="text" autoComplete='off' className="input" name="phone" value={destruct.values.phone} onChange={destruct.handleChange}></input>
                        {destruct.errors.phone && <p className="error">{destruct.errors.phone}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Email:</label>
                        <input type="text" autoComplete='off' className="input" name="email" value={destruct.values.email} onChange={destruct.handleChange}></input>
                        {destruct.errors.email && <p className="error">{destruct.errors.email}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading}>Save</button>
                        {destruct.message && <p className="message">{destruct.message}</p>}
                    </div>

                </form>

            </div>
        </div>
    );
}

export default AddClient;