import React, { useState, useContext, useEffect } from "react";
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Get from "../HTTPRequest/Get";

const ShowTemplate = () => {

    // STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [files, setFiles] = useState();
    const [key, setKey] = useState('');
    const [loading, setLoading] = useState(false);

    // FETCHING FILES...

    useEffect(() => {

        const fetchDirectory = async () => {
            setLoading(true);
            let endpoint = user_context.url + '/show_template';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                setLoading(false);
                if ((response.status === 1) && (response.response_payload['result'] === 'success')){
                    const data = response.response_payload[0];
                    setFiles(data);
                }
            });
        }

        fetchDirectory();

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();       
    }

    // FILTERING DATA...

    const filteredFiles = files?.filter(file =>
        file.name.toLowerCase().includes(key.toLowerCase())
    );

    // HANDLING CLICK ON FILE...

    const handleFile = (file) => {
        const baseUrl = user_context.url.replace('/smarterpweb-api', '');
        let link = baseUrl + '/templates/' + file.name;       
        window.open(link, '_blank');
    }

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="daily-wrapper">
                {/* SEARCH BOX */}

                <form className="daily-form" onSubmit={handleSubmit}>
                    <div className="daily-formGroup">
                        <h1 className="form-head">TEMPLATES</h1>
                    </div>

                    <div className="daily-formGroup">
                        <label className="daily-label">Search:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="view-input" 
                            name="key" 
                            value={key} 
                            onChange={(event) => setKey(event.target.value)}
                        />
                    </div>    
                </form>

                {/* CONTENT BODY TO DISPLAY FILES */}

                {!loading ? <div className="daily-container" style={{ overflow: 'scroll'}}>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, minmax(200px, 1fr))',
                        gap: '20px',
                        gridAutoRows: 'auto',    
                        width: '100%'                  
                    }}>
                        {filteredFiles && filteredFiles.length > 0 && filteredFiles?.map(file => (
                        <div key={file.id} style={{
                            display: 'flex',
                            flexDirection: 'column', 
                            alignItems: 'center',
                            padding: '10px',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleFile(file)}>
                            <div style={{ marginBottom: '10px' }}>
                                <InsertDriveFileIcon style={{ fontSize: '50px', color: '#4682B4' }} />
                            </div>
                            <h3 style={{ fontSize: '14px', color: '#808080' }}>{file.name}</h3> 
                        </div> ))}
                    </div>
                </div>
                :
                <div className="daily-container" style={{ 
                    overflow: 'scroll', 
                    justifyContent: 'center',
                    fontSize: '1.5em', 
                    color: '#555'
                }}>
                    Loading...
                </div>}
                
            </div>
        </div>
    );
}

export default ShowTemplate;    