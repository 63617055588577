import React from "react";
import MergePDFForm from "./MergePDFForm";
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { ToastContainer } from 'react-toastify';

const MergePDF = () => {
    // DECLARING STATES...    

    const { ...destruct } = MergePDFForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR PDF DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">MERGE&nbsp;PDF</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Existing PDF:</label>
                        <input type="text" autoComplete='off' className="input" name="pdf_name" value={destruct.values.pdf_name} onChange={destruct.handleChange} disabled></input>
                        {destruct.errors.pdf_name && <p className="error">{destruct.errors.pdf_name}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={destruct.values.docs_url} readOnly></input>
                        {destruct.errors.docs_url && <p className="error">{destruct.errors.docs_url}</p>}
                        <progress className="form-progress" value={destruct.progress} max="100" />
                        <span className="progressText">{destruct.progress + "%"}</span>
                        {destruct.docs_errors && <p className="message">{destruct.docs_errors}</p>}
                        
                        <input id="pdf_file" type="file" accept=".pdf" onChange={destruct.handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>

                        <button className="button" type="button" onClick={destruct.viewPDF} disabled={destruct.loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={destruct.handleClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>
                        <button className="button" type="submit" disabled={destruct.loading[0]}>Merge&nbsp;PDF</button>
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>
                </form>
            </div>
            <ToastContainer/>
        </div>
    );

}

export default MergePDF;