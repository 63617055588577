import React from "react";
import UpdateLocationForm from "./UpdateLocationForm";
import CustomAutocomplete from "../Components/CustomAutoComplete";
import { ToastContainer } from 'react-toastify';

const UpdateLocation = (props) => {

    // DECLARING STATES...    

    const { ...destruct } = UpdateLocationForm(props);

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR LOCATION DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;LOCATION</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Location ID:</label>
                        <input type="text" autoComplete='off' className="input" name="id" value={destruct.values.id} onChange={destruct.handleChange} disabled ></input>
                        {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Latitude:</label>
                        <input type="text" autoComplete='off' className="input" name="latitude" value={destruct.values.latitude} onChange={destruct.handleChange}></input>
                        {destruct.errors.latitude && <p className="error">{destruct.errors.latitude}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Longitude:</label>
                        <input type="text" autoComplete='off' className="input" name="longitude" value={destruct.values.longitude} onChange={destruct.handleChange}></input>
                        {destruct.errors.longitude && <p className="error">{destruct.errors.longitude}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Name:</label>
                        <input type="text" autoComplete='off' className="input" name="name" value={destruct.values.name} onChange={destruct.handleChange}></input>
                        {destruct.errors.name && <p className="error">{destruct.errors.name}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Address:</label>
                        <input type="text" autoComplete='off' className="input" name="address" value={destruct.values.address} onChange={destruct.handleChange}></input>
                        {destruct.errors.address && <p className="error">{destruct.errors.address}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Time In:</label>
                        <input type="time" autoComplete='off' className="input" name="time_in" value={destruct.values.time_in} onChange={destruct.handleChange}></input>
                        {destruct.errors.time_in && <p className="error">{destruct.errors.time_in}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Time Out:</label>
                        <input type="time" autoComplete='off' className="input" name="time_out" value={destruct.values.time_out} onChange={destruct.handleChange}></input>
                        {destruct.errors.time_out && <p className="error">{destruct.errors.time_out}</p>}
                    </div> 

                    <div className="location-formGroup">
                        <label className="label" style={{marginRight: '5px'}}>Client:</label>                        
                        <CustomAutocomplete
                            data={destruct.item}
                            placeholder="Search client..."
                            selectValue={destruct.handleSelect}
                            selected_value={destruct.values.client_id}
                        />
                        {destruct.errors.client_id && <p className="error">{destruct.errors.client_id}</p>}
                    </div> 

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading}>Update</button>
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>
                </form>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default UpdateLocation;