import React, { useState } from 'react';
import UserContext from './Pages/Parent/UserContext';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from './Pages/Parent/ProtectedRoute';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import WebPage from './Pages/Webpage/WebPage';

import './app.css';
import './Pages/StyleSheets/button.css';
import './Pages/StyleSheets/label.css';
import './Pages/StyleSheets/input.css';
import './Pages/StyleSheets/page.css';
import './Pages/StyleSheets/select.css';
import './Pages/StyleSheets/progress.css';
import './Pages/StyleSheets/checkbox.css';
import './Pages/StyleSheets/datagrid.css';
import './Pages/StyleSheets/view.css';
import './Pages/StyleSheets/dialog.css';
import './Pages/StyleSheets/datepicker.css';
import './Pages/StyleSheets/color.css';

export default function App() {

  // DECLARING STATES...

  const [log_auth, setLogAuth] = useState(false);
  const [user_data, setUserData] = useState({});
  //let url = 'http://localhost/smarterpweb-api';
  let url = 'https://softechsmartsolutions.in/smarterpweb-api';
  let user_context = { log_auth: log_auth, user_data: user_data, url: url };

  // SETTING DEFAULT VALUES...

  const handleChange = (response_data) => {    
    setLogAuth(true);
    setUserData(response_data);
  };

  // MAIN FUNCTION...

  return (
    <UserContext.Provider value={user_context}>

      <div className="app">
        <Router>
          <Switch>
            <Route exact path="/" render={() => <WebPage />} /> 
            <Route exact path="/login" render={() => <Login handleChange={handleChange} />} />
            <ProtectedRoute path="/home" component={Home} />
            <Route path="*" render={() => <WebPage />} />
          </Switch>
        </Router>
      </div>

    </UserContext.Provider>

  );

}
