import React from "react";
import ViewReceiptForm from "./ViewReceiptForm";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useMediaQuery } from 'react-responsive';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';

const ViewReceipt = (props) =>
{
    // DECLARING STATES...
     
    const { ...destruct } = ViewReceiptForm(props); 
    const defaultColumnProperties = {sortable: false, resizable: true};     
    const table_columns = [ 
        { key: 'sno', name: 'S.No' , width: '5%' },
        { key: 'id', name: 'ID' , width: '5%' },
        { key: 'ledger_id', name: 'Ledger ID' , width: '10%' },
        { key: 'party', name: 'Party' , width: '25%' },
        { key: 'type', name: 'Type' , width: '5%' },        
        { key: 'docs_url', name: 'Docs' , width: '50%' }
        ].map(c => ({ ...c, ...defaultColumnProperties }));         
        const isMobile = useMediaQuery({ query: `(max-width: 750px)` });

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className = "view-form" onSubmit={destruct.handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">VIEW&nbsp;RECEIPT</h1>
                    </div> 

                    <div className="view-formGroup">
                        <label className = "view-label">Key:</label>
                        <input type = "text" autoComplete="off" className = "view-input" placeholder = "Enter key to search..." name = "key" value = {destruct.values.key} onChange={destruct.handleChange}></input>                        
                        { !isMobile && <select className = "view-select" name = "selected_column" value = {destruct.values.selected_column} onChange={destruct.handleChange}>
                            {destruct.columns.map((item, i) => (<option key={i}>{item}</option>))}
                        </select> }
                        {!destruct.loading[0] && <button className = "view-button" type="submit">View&nbsp;All</button>} 
                        {destruct.loading[0] && <button className = "view-button" type="submit" disabled >View&nbsp;All</button>} 
                    </div> 
                    {destruct.message && <p className = "message">{destruct.message}</p>}                                                             
                </form>

                {/* DATA GRID */}

                <div className = "table-container">
                    <DataGrid 
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns} 
                        rows = {destruct.table_rows}  
                        onRowClick={destruct.onRowClick}  
                        rowRenderer={destruct.rowRenderer}                                                 
                    /> 
                </div>

                {/* FOOTER BUTTONS FOR CRUD ACTIONS... */}
                
                <div className="view-footer">   
                    <div className="progress-container"><progress className = "progress" value ={destruct.progress[0]} max ="100"/></div>                    
                    <input type="checkbox" className="checkbox" checked={destruct.checked} onChange={() => destruct.setChecked(!destruct.checked)}/>                                               
                    {destruct.permissions[3] === '1' && <button className = "view-button" type="button" onClick={destruct.handleUpdate} disabled={destruct.loading[2]}><CreateOutlinedIcon/>&nbsp;Update</button>}                   
                    {destruct.permissions[4] === '1' && <button className = "view-button" type="button" onClick={destruct.handleDelete} disabled={destruct.loading[1]}><DeleteForeverOutlinedIcon/>&nbsp;Delete</button>}                  
                </div>
                                
            </div>

            {/* DELETE DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
            closeBox={destruct.handleDialog}
            modalWidth='20%'
            headerBackgroundColor='rgb(44, 53, 68)'
            headerTextColor='white'
            headerHeight='65'
            closeButtonColor='white'
            bodyBackgroundColor='rgb(226, 218, 218)'
            bodyTextColor='black'
            bodyHeight='fit-content'
        >

            <div className="dialog-container">
                <label>Do you really want to delete?</label>
                <button className="button" type="button" onClick={destruct.handleConfirm}>Yes</button>
            </div>
        </ReactDialogBox>}
        </div>
    );
}

export default ViewReceipt;