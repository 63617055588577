import React from "react";
import "./icon.css";
import { LocationOnOutlined } from '@mui/icons-material';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Icon = (props) => {
    
    // SELECTING ICONS ACCORDING TO PROPS...

    const selectIcon = () => {
        switch (props.icon_name) {
            // dashboard
            case 'home':
                return <FiberManualRecordIcon className="icon" />;

            // location
            case 'add_location':
                return <FiberManualRecordIcon className="icon" />;
            case 'view_location':
                return <FiberManualRecordIcon className="icon" />;

            // staff
            case 'add_staff':
                return <FiberManualRecordIcon className="icon" />;
            case 'view_staff':
                return <FiberManualRecordIcon className="icon" />;
            case 'view_profile':
                return <FiberManualRecordIcon className="icon" />
            
            //partner
            case 'add_partner':
                return <FiberManualRecordIcon className="icon" />
            case 'view_partner':
                return <FiberManualRecordIcon className="icon" />

            // work
            case 'add_work':
                return <FiberManualRecordIcon className="icon" />;
            case 'view_work':
                return <FiberManualRecordIcon className="icon" />;
            case 'add_map':
                return <FiberManualRecordIcon className="icon" />;
            case 'view_map':
                return <FiberManualRecordIcon className="icon" />;

            // attendance
            case 'add_attendance':
                return <FiberManualRecordIcon className="icon" />;
            case 'view_attendance':
                return <FiberManualRecordIcon className="icon" />;
            case 'daily_attendance':
                return <FiberManualRecordIcon className="icon" />;
            case 'monthly_attendance':
                return <FiberManualRecordIcon className="icon" />
            case 'yearly_summary':
                return <FiberManualRecordIcon className="icon" />
            case 'add_holiday':
                return <FiberManualRecordIcon className="icon" />
            case 'view_holiday':
                return <FiberManualRecordIcon className="icon" />            
            case 'leave_list':
                return <FiberManualRecordIcon className="icon" />

            // expense
            case 'add_recipient':
                return <FiberManualRecordIcon className="icon" />
            case 'view_recipient':
                return <FiberManualRecordIcon className="icon" />
            case 'make_entry':
                return <FiberManualRecordIcon className="icon" />
            case 'view_entry':
                return <FiberManualRecordIcon className="icon" />
            case 'view_statement':
                return <FiberManualRecordIcon className="icon" />
            case 'balance_summary':
                return <FiberManualRecordIcon className="icon" />
            case 'add_account':
                return <FiberManualRecordIcon className="icon" />
            case 'view_account':
                return <FiberManualRecordIcon className="icon" />
            case 'mini_statement':
                return <FiberManualRecordIcon className="icon" />
            case 'expense_sheet':
                return <FiberManualRecordIcon className="icon" />

            // vendor
            case 'add_vendor':
                return <FiberManualRecordIcon className="icon" />
            case 'view_vendor':
                return <FiberManualRecordIcon className="icon" />
            case 'add_contract':
                return <FiberManualRecordIcon className="icon" />
            case 'view_contract':
                return <FiberManualRecordIcon className="icon" />
            case 'add_license':
                return <FiberManualRecordIcon className="icon" />
            case 'view_license':
                return <FiberManualRecordIcon className="icon" />

            // client
            case 'add_client':
                return <FiberManualRecordIcon className="icon" />
            case 'view_client':
                return <FiberManualRecordIcon className="icon" />
            case 'add_agreement':
                return <FiberManualRecordIcon className="icon" />
            case 'view_agreement':
                return <FiberManualRecordIcon className="icon" />
            case 'add_strength':
                return <FiberManualRecordIcon className="icon" />
            case 'view_strength':
                return <FiberManualRecordIcon className="icon" />
            case 'show_class':
                return <FiberManualRecordIcon className="icon" />

            // ticket
            case 'add_ticket':
                return <FiberManualRecordIcon className="icon" />
            case 'view_ticket':
                return <FiberManualRecordIcon className="icon" />

            // head
            case 'add_head':
                return <FiberManualRecordIcon className="icon" />
            case 'view_head':
                return <FiberManualRecordIcon className="icon" />
            case 'add_ledger':
                return <FiberManualRecordIcon className="icon" />

            // ledger
            case 'view_ledger':
                return <FiberManualRecordIcon className="icon" />
            case 'view_purchase':
                return <FiberManualRecordIcon className="icon" />
            case 'view_sales':
                return <FiberManualRecordIcon className="icon" />
            case 'show_ledger':
                return <FiberManualRecordIcon className="icon" />
            case 'view_credit_note':
                return <FiberManualRecordIcon className="icon" />
            case 'view_payment':
                return <FiberManualRecordIcon className="icon" />
            case 'view_receipt':
                return <FiberManualRecordIcon className="icon" />
            case 'view_debit_note':
                return <FiberManualRecordIcon className="icon" />
            case 'view_delivery_note':
                return <FiberManualRecordIcon className="icon" />
            case 'view_receipt_note':
                return <FiberManualRecordIcon className="icon" />
            case 'view_others':
                return <FiberManualRecordIcon className="icon" />

            // item
            case 'add_category':
                return <FiberManualRecordIcon className="icon" />
            case 'view_category':
                return <FiberManualRecordIcon className="icon" />
            case 'add_item':
                return <FiberManualRecordIcon className="icon" />
            case 'view_item':
                return <FiberManualRecordIcon className="icon" />

            // stock
            case 'add_stock':
                return <FiberManualRecordIcon className="icon" />
            case 'view_stock':
                return <FiberManualRecordIcon className="icon" />
            case 'stock_summary':
                return <FiberManualRecordIcon className="icon"/>

            // letters
            case 'add_purchase_order':
                return <FiberManualRecordIcon className="icon"/>
            case 'view_purchase_order':
                return <FiberManualRecordIcon className="icon"/>
            case 'add_proposal_template':
                return <FiberManualRecordIcon className="icon"/>
            case 'add_proposal':
                return <FiberManualRecordIcon className="icon"/>
            case 'view_proposal':
                return <FiberManualRecordIcon className="icon"/>
            case 'add_quotation':
                return <FiberManualRecordIcon className="icon"/>
            case 'view_quotation':
                return <FiberManualRecordIcon className="icon"/>
            case 'view_quotation_items':
                return <FiberManualRecordIcon className="icon"/>
            case 'add_letter_despatch':
                return <FiberManualRecordIcon className="icon"/>
            case 'view_letter_despatch':
                return <FiberManualRecordIcon className="icon"/>
            case 'add_letter_received':
                return <FiberManualRecordIcon className="icon"/>
            case 'view_letter_received':
                return <FiberManualRecordIcon className="icon"/>
            case 'show_template':
                return <FiberManualRecordIcon className="icon"/>
                                
            // cheque
            case 'view_cheque':
                return <FiberManualRecordIcon className="icon" />
            
            // task
            case 'add_task':
                return <FiberManualRecordIcon className="icon"/>
            case 'view_task':
                return <FiberManualRecordIcon className="icon"/>
            case 'sticky_note':
                return <FiberManualRecordIcon className="icon"/>
            
            // invoice
            case 'add_service_invoice':
                return <FiberManualRecordIcon className="icon"/>
            case 'view_service_invoice':
                return <FiberManualRecordIcon className="icon"/>
            case 'show_service_invoice':
                return <FiberManualRecordIcon className="icon"/>
            
            // duty
            case 'view_duty':
                return <FiberManualRecordIcon className="icon"/>
            case 'show_duty':
                return <FiberManualRecordIcon className="icon"/>
            case 'duty_summary':
                return <FiberManualRecordIcon className="icon"/>
            
            // billings
            case 'bill_goods':
                return <FiberManualRecordIcon className="icon"/>
            case 'bill_service':
                return <FiberManualRecordIcon className="icon"/>
            
            // documents
            case 'documents':
                return <FiberManualRecordIcon className="icon"/>

            // settings
            case 'add_user':
                return <FiberManualRecordIcon className="icon" />  
            case 'backup':
                return <FiberManualRecordIcon className="icon"/>     
            case 'fiscal_setup':
                return <FiberManualRecordIcon className="icon"/>     
            case 'permission':
                return <FiberManualRecordIcon className="icon"/>
            
            // salary
            case 'salary_list':
                return <FiberManualRecordIcon className="icon" />
            case 'salary_request':
                return <FiberManualRecordIcon className="icon"/>

            // main menu
            case 'Dashboard':
                return <GridViewOutlinedIcon className="menu-icon" />
            case 'Location':
                return <LocationOnOutlined className="menu-icon" />;
            case 'Staff':
                return <Person2OutlinedIcon className="menu-icon" />;
            case 'Partner':
                return <PeopleAltOutlinedIcon className="menu-icon" />;
            case 'Work':
                return <HomeWorkOutlinedIcon className="menu-icon" />;
            case 'Attendance':
                return <FingerprintOutlinedIcon className="menu-icon" />;
            case 'Expense':
                return <AttachMoneyOutlinedIcon className="menu-icon" />
            case 'Vendor':
                return <WorkOutlineOutlinedIcon className="menu-icon" />
            case 'Client':
                return <DomainOutlinedIcon className="menu-icon" />
            case 'Head':
                return <PsychologyAltOutlinedIcon className="menu-icon" />
            case 'Ticket':
                return <ConfirmationNumberOutlinedIcon className="menu-icon" />
            case 'Ledger':
                return <AutoStoriesOutlinedIcon className="menu-icon" />
            case 'Salary':
                return <CurrencyRupeeOutlinedIcon className="menu-icon" />
            case 'Item':
                return <CategoryOutlinedIcon className="menu-icon" />
            case 'Stock':
                return <Inventory2OutlinedIcon className="menu-icon" />
            case 'Letters':
                return <EmailOutlinedIcon className="menu-icon"/>
            case 'Cheque':
                return <CreditCardOutlinedIcon className="menu-icon" />
            case 'Task':
                return <WorkHistoryOutlinedIcon className="menu-icon"/>
            case 'Invoice':
                return <DescriptionOutlinedIcon className="menu-icon"/>
            case 'Duty':
                return <DirectionsWalkOutlinedIcon className="menu-icon"/>
            case 'Billings':
                return <CalculateOutlinedIcon className="menu-icon"/>
            case 'Office':
                return <MapsHomeWorkOutlinedIcon className="menu-icon"/>
            case 'Settings':
                return <SettingsOutlinedIcon className="menu-icon" />                
            default:
                return null;
        }
    }

    // MAIN FUNCTION...

    return (
        <div>
            {selectIcon()}
        </div>
    )
}

export default Icon;