import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import { Row } from 'react-data-grid';
import { useHistory } from 'react-router-dom';
import Get from '../HTTPRequest/Get';
import Delete from '../HTTPRequest/Delete';
import Download from '../HTTPRequest/Download';
import CreateBlob from '../Parent/CreateBlob';

const ViewStrengthForm = (props) => {
    // DECLARING STATES...   

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [columns] = useState(['All', ...user_context.user_data[3]['strength']]);
    const [values, setValues] = useState({ key: data_context.view_data['key'] || '', selected_column: data_context.view_data['selected_column'] || 'All' });
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false, 3: false });
    const [table_rows, setTableRows] = useState(data_context.view_data['table_rows'] || []);
    const [selected_row, setSelectedRow] = useState(data_context.view_data['selected_row']);
    const [progress, setProgress] = useState({ 0: 0 });
    const history = useHistory();
    const [checked, setChecked] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [errors, setErrors] = useState({ 0: '' });
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/view_strength');

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        setMessage();
        setTableRows([]);
        setSelectedRow();
        setLoading({ ...loading, 0: true });

        let endpoint = '';
        values.key.length === 0
            ? endpoint = user_context.url + '/strengths?fiscal=' + data_context['fiscal']
            : endpoint = user_context.url + '/strength?filter_key=' + values.key + '&field=' + values.selected_column + '&fiscal=' + data_context['fiscal'];
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

        await Get(endpoint, headers).then(response => {
            setLoading({ ...loading, 0: false });
            setMessage(response.message);
            if (response.status === 1) {
                if (response.response_payload['result'] === 'success') {
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                    }
                    setTableRows(data);
                }
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // HANDLER FOR UPDATE BUTTON...

    const handleUpdate = async () => {
        if (selected_row > -1) {
            if (checked) {
                setMessage();
                setLoading({ ...loading, 2: true });

                const options = {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` },
                    onDownloadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress({ ...progress, 0: percent });
                    }
                }

                await Download(user_context.url + '/download?file_docs=' + table_rows[selected_row]['docs_url'], options).then(response => {
                    setMessage(response['message']);
                    setLoading({ ...loading, 2: false });
                    if (response['status'] === 1) {
                        let view_data = { key: values.key, selected_column: values.selected_column, selected_row: selected_row, table_rows: table_rows };
                        props.handleBack(view_data);
                        let update_data = table_rows[selected_row];
                        update_data['docs'] = response['response_payload']['docs'];
                        props.handleUpdate(update_data);
                        history.push("/update_strength");
                    }
                });
            } else {
                let view_data = { key: values.key, selected_column: values.selected_column, selected_row: selected_row, table_rows: table_rows };
                props.handleBack(view_data);
                let update_data = table_rows[selected_row];
                update_data['docs'] = 'no_such_data';
                props.handleUpdate(update_data);
                history.push("/update_strength");
            }
        }
        else {
            setMessage("No row selected");
        }
    }

    // HANDLER FOR DELETE BUTTON...

    const handleDelete = () => {
        setMessage();
        setDialog(true);
    }

    // HANDLER FOR DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // HANDLER FOR CONFIRM DELETE...

    const handleConfirm = () => {
        setDialog(!dialog);
        setConfirm(true);
    }

    // PERFORMING DELETE ACTION...

    useEffect(() => {
        const deleteRow = async () => {
            if (confirm) {
                setConfirm(false);

                if (selected_row > -1) {
                    setLoading({ ...loading, 1: true });
                    const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };
                    await Delete(user_context.url + '/strength?id=' + table_rows[selected_row]['id'], headers).then(response => {
                        setLoading(false);
                        setMessage(response.message);
                        const new_table_rows = table_rows.filter(item => item !== table_rows[selected_row]);
                        setSelectedRow(-1);
                        setTableRows(new_table_rows);
                    });
                }
                else {
                    setMessage("No row selected");
                }
            }
        }

        deleteRow();
        //eslint-disable-next-line    
    }, [confirm]);

    // SHOWING ATTACHMENT...

    const handleAttachment = async () =>{
        if (selected_row > -1) {
            setMessage();
            setLoading({ ...loading, 3: true });

            const options = {
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${user_context.user_data[1]}`
                },
                onDownloadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress({ ...progress, 0: percent });
                }
            }

            await Download(user_context.url + '/download?file_docs=' + table_rows[selected_row]['docs_url'], options).then(response => {
                setMessage(response['message']);
                setLoading({ ...loading, 3: false });
                if (response['status'] === 1 && response.response_payload['result'] === 'success') {
                    viewPDF(response.response_payload['docs']);
                }
            });
        } else {
            setMessage("No row selected");
        }
    }

    // VIEWING PDF...

    const viewPDF = (docs) => {
        setLoading({ ...loading, 3: true });
        let errors = validateDocs(docs);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 3: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 3: false });
                }
            }
        } else {
            setLoading({ ...loading, 1: false });
        }
    }

    // // VALIDATING UPLOADS...

    const validateDocs = (docs) => {
        let errors = {};
        if (!table_rows[selected_row]['docs_url']) {
            errors[0] = "*No attachment found";
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(table_rows[selected_row]['docs_url'])[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors[0] = "*Document is not a valid PDF file";
            }
            else {
                if (docs === 'no_such_data' || !docs) {
                    errors[0] = "*No pdf preview found";
                    setLoading({ ...loading, 3: false });
                }
            }
        }
        return errors;
    }

    // RETURNING VALUES...

    return {
        handleSubmit, handleChange, onRowClick, rowRenderer, 
        handleDelete, handleUpdate, setChecked, handleDialog, 
        handleConfirm, handleAttachment, columns, message, values, 
        loading, table_rows, progress, checked, dialog, permissions,
        errors
    };


}

export default ViewStrengthForm;