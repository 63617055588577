import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import { Row } from 'react-data-grid';
import GetIndex from '../Parent/GetIndex';
import Get from '../HTTPRequest/Get';

const LeaveListForm = () => {
    // DECLARING STATES...   

    const user_context = useContext(UserContext);
    const months =
        [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
    const years = ['2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'];
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false, 1: false });
    const [table_rows, setTableRows] = useState([]);
    let initialValues = { month: months[0], year: years[0] };
    const [values, setValues] = useState(initialValues);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        setMessage();
        setTableRows([]);
        setLoading({ ...loading, 0: true });

        let month = GetIndex(months, values.month) + 1;
        let endpoint = user_context.url + '/attendance?filter_key=' + month
            + '&field=' + values.year + '&route=leave_list';
        const headers =
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${user_context.user_data[1]}`
        };

        await Get(endpoint, headers).then(response => { 
            setLoading({ ...loading, 0: false });
            setMessage(response.message);

            if (response.status === 1) {
                if (response.response_payload['result'] === 'success') {
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                    }
                    const row_values = Object.values(data);
                    setTableRows(row_values);
                }
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                <Row {...props} />
            </div>

        );
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, rowRenderer, values,
        message, loading, table_rows, months, years
    };
}

export default LeaveListForm;