import React, { useState, useContext } from "react";
import no_image from '../Images/no_image.png';
import './dailyAttendance.css';
import UserContext from "../Parent/UserContext";
import Put from "../HTTPRequest/Put";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DailyRow = (props) => {
    // STATES..

    const user_context = useContext(UserContext);
    const [disabledButtons, setDisabledButtons] = useState([]);

    // RENDER TEXT COLOR CONDITIONALLY...

    const getAttendanceTypeStyle = (type) => {
        switch (type) {
            case 'Present':
                return { color: '#008000' };
            case 'Leave':
                return { color: '#ff0000' };
            case 'Half Day':
                return { color: '#EE82EE' };
            case 'Special':
                return { color: '#000080' };
            default:
                return { color: '#ffa500' };
        }
    };

    // RENDER ACTIONS BUTTONS...

    const renderButtons = (item) => {
        return (
            <>
                {item.status === '0' && <button
                    className="green-button"
                    type="button"
                    onClick={() => handleApprove(item)}
                    disabled={disabledButtons.includes(item.id)}
                >
                    Approve
                </button>}

                {(item.status === '1' || item.status === null) && <button
                    className="green-button"
                    type="button"
                    onClick={() => handleApprove(item)}
                    disabled
                >
                    Approve
                </button>}
            </>
        );
    };

    // APPROVE LOW ACCURACY...

    const handleApprove = async (item) => {
        setDisabledButtons([...disabledButtons, item.id]);

        const request_payload = {
            id: item.id
        };

        let endpoint = user_context.url + '/attendance?route=handle_approve';
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${user_context.user_data[1]}`
        };

        try {
            const response = await Put(endpoint, request_payload, headers);

            if (response.status === 1) {
                toast.success(response.message);
                await props.destruct.fetchData();
            } else {
                toast.error(response.message);
            }

        } catch (error) {
            toast.error('Error during approval');
        } finally {
            setDisabledButtons(disabledButtons.filter(id => id !== item.id));            
        }
    };


    // MAIN FUNCTION...

    return (
        <div className="daily-row-container">
            {props.response.map((item, index) => {
                const base_url = user_context.url;
                const url = base_url.replace("/smarterpweb-api", "");

                return (
                    <div className="daily-container-wrapper" key={index} style={{ pageBreakInside: 'avoid', breakInside: 'avoid'}}>

                        <div className="daily-image-wrapper">
                            <div className="daily-image-container">
                                {<img
                                    className="daily-image"
                                    src={item['photo_url'] !== '' ? `${url}/assets/${item['photo_url']}` : no_image}
                                    alt='Not found'
                                />}
                            </div>
                        </div>

                        <div className="daily-row-wrapper">
                            <div className="daily-column-wrapper1">{item['name']}</div>
                            <div className="daily-column-wrapper">
                                <div className="daily-column-group">In: {item['signin']}</div>
                                <div className="daily-column-group">Out: {item['signout']}</div>
                            </div>
                            <div className="daily-column-wrapper" style={getAttendanceTypeStyle(item['type'])}>{item['type']}</div>
                            {item['type'] === null && <div className="daily-column-wrapper" style={{ color: '#ffa500' }} >No Information</div>}
                            <div className="daily-column-wrapper2">{item['reason']}</div>
                        </div>

                        <div style={{ display: 'flex', paddingRight: '10px' }}>
                            {renderButtons(item)}
                        </div>
                    </div>
                )
            })
            }
            <ToastContainer />
        </div>
    )
}

export default DailyRow;