import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import { Row } from 'react-data-grid';
import Get from '../HTTPRequest/Get';

const SalaryListForm = (props) =>
{
    // DECLARING STATES...   

    const user_context = useContext(UserContext);
    const [message, setMessage] = useState();    
    const [loading, setLoading] = useState({0: false});
    const [table_rows, setTableRows] = useState([]);

    // SUBMITTING FORM...

    const handleSubmit = async e =>
    {
        e.preventDefault();
        setMessage();
        setTableRows([]);
        setLoading({ ...loading, 0: true });

        let endpoint = user_context.url + '/attendance?route=salary_list';
        const headers =
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${user_context.user_data[1]}`
        };

        await Get(endpoint, headers).then(response => { 
            setLoading({ ...loading, 0: false });
            setMessage(response.message);

            if (response.status === 1) {
                if (response.response_payload['result'] === 'success') {
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                    }
                    const row_values = Object.values(data);
                    setTableRows(row_values);
                }
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => 
    {               
        return(
            <div>
                <Row {...props}/>
            </div>            
        );
    }

    // RETURNING VALUES...

    return { handleSubmit, rowRenderer, message, loading, table_rows };
}

export default SalaryListForm;