import React from "react";
import AddWorkForm from "./AddWorkForm";
import { ToastContainer } from 'react-toastify';

const AddWork = () => {
    // DECLARING STATES...    

    const { ...destruct } = AddWorkForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR WORK DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;WORK</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Staff:</label>
                        <select className="select" name="staff" value={destruct.values.staff} onChange={destruct.handleChange} >
                            {destruct.staff && destruct.staff.id && destruct.staff.name
                                ? (destruct.staff.id.map((id, index) =>
                                    (<option key={id} value={id}>{id} - {destruct.staff.name[index]}</option>)))
                                : (<option value="">Loading...</option>)
                            }
                        </select>
                        {destruct.errors.staff && <p className="error">{destruct.errors.staff}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Location:</label>
                        <select className="select" name="location" value={destruct.values.location} onChange={destruct.handleChange} >
                            {destruct.location && destruct.location.id && destruct.location.address
                                ? (destruct.location.id.map((id, index) =>
                                    (<option key={id} value={id}>{id} - {destruct.location.address[index]}</option>)))
                                : (<option value="">Loading...</option>)
                            }
                        </select>
                        {destruct.errors.location && <p className="error">{destruct.errors.location}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading}>Save</button>
                    </div>

                </form>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default AddWork;