
// MAIN FUNCTION THAT RETURNS ENCODED FILENAME...

const CreateFileName = (file) => {

    let date = new Date();

    let month = String(date.getMonth() + 1).padStart(2, '0'); 
    let day = String(date.getDate()).padStart(2, '0');
    let year = String(date.getFullYear());
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');
    let seconds = String(date.getSeconds()).padStart(2, '0');

    let formattedDate = `${month}${day}${year}${hours}${minutes}${seconds}`;

    let regex = /(?:\.([^.]+))?$/;
    let extMatch = regex.exec(file);
    let ext = extMatch ? extMatch[1] : '';

    let filename = formattedDate;
    if (ext) {
        filename += `.${ext}`;
    }

    return filename;
};

export default CreateFileName;

