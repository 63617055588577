import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Get from '../HTTPRequest/Get';
import { Row } from 'react-data-grid';
import Post from '../HTTPRequest/Post';
import Delete from '../HTTPRequest/Delete';

const AddProposalTemplateForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [item, setItem] = useState([]);
    const [proposal_types, setProposalTypes] = useState([]);
    const [proposal_templates, setProposalTemplates] = useState([]);
    const [errors, setErrors] = useState({});
    const initialValues =
    {
        item_id: '',
        proposal_type_id: '',
        rate: '',
        percentage: ''
    };
    const [values, setValues] = useState(initialValues);
    const [message, setMessage] = useState({ 0: '', 1: ''});
    const [loading, setLoading] = useState({ 0: false, 1: false });
    const [selected_row, setSelectedRow] = useState();
    const [table_rows, setTableRows] = useState([]);
    const [dialog, setDialog] = useState(false);
    const [confirm, setConfirm] = useState(false);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const fetchItem = async () => {
            let endpoint = user_context.url + '/dropdown?route=item_details';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let item = response.response_payload[0] || [];

                        const newItems = item.id.map((_, i) => ({
                            id: item.id[i],
                            name: item.name[i],
                            manufacturer: item.manufacturer[i],
                            model: item.model[i],
                            rate: item.rate[i]
                        }));

                        setItem(newItems);
                    }
                }
            });
        }

        const fetchProposalTypes = async () => {
            let endpoint = user_context.url + '/dropdown?route=proposal_types';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let proposal_types = response.response_payload[0] || [];

                        const newItems = proposal_types.id.map((_, i) => ({
                            id: proposal_types.id[i],
                            name: proposal_types.name[i],
                        }));

                        setProposalTypes(newItems);
                    }
                }
            });
        }        

        fetchItem();
        fetchProposalTypes();
        

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // FETCHING ITEMS FROM PROPOSAL TEMPLATES...

    useEffect(() => {
        const fetchProposalTemplates = async () => {
            setLoading({...loading, 0: true});
            let endpoint = user_context.url + '/proposal_templates';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                setLoading({...loading, 0: false});
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;

                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            data[i] = payload[i];
                        }
                        setProposalTemplates(data);
                    }
                }
            });
        }

        fetchProposalTemplates();
        //eslint-disable-next-line
    }, [values.proposal_type_id]);

    // FETCHING ITEMS FROM PROPOSAL TEMPLATES...

    useEffect(() => {
        if(values.proposal_type_id){            
            if (proposal_templates && proposal_templates.length) {
                let updated_rows = []; 
            
                for (let i = 0; i < proposal_templates.length; i++) {
                    if(values.proposal_type_id === proposal_templates[i]['proposal_type_id']) {
                        let item_id = proposal_templates[i]['item_id'];
                        let sno = i + 1;
                        const item_details = item_id ? item.find(data => data.id.includes(item_id)) : null;
                        let new_row_data =
                        {
                            sno: sno,
                            id: proposal_templates[i]['id'],
                            item_id: proposal_templates[i]['item_id'],
                            name: item_details ? item_details.name : null,
                            manufacturer: item_details ? item_details.manufacturer : null,
                            model: item_details ? item_details.model : null,                        
                            rate: proposal_templates[i]['rate']
                        }
                        updated_rows.push(new_row_data); 
                    }
                }
            
                setTableRows(updated_rows);
            }            
        }
        //eslint-disable-next-line
    }, [proposal_templates]);

    // ON SELECTION OF ITEM...

    const selectItem = (item) => {
        if (item) {
            setValues({ ...values, item_id: item.id });
        }
    }

    // ON SELECTION OF PROPOSAL TYPE...

    const selectProposalType = (item) => {
        if (item) {
            setValues({ ...values, proposal_type_id: item.id });
        }
    }

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);            
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            const response = await Post(user_context.url + '/proposal_template', payload, headers);            
            setLoading({ ...loading, 0: false });
            setMessage({ ...message, 0: response.message });
            if (response.status === 1 && response.response_payload['result'] === 'success') {
                const sno = table_rows.length + 1;
                const item_details = values.item_id ? item.find(data => data.id.includes(values.item_id)) : null; 
                const new_row_data =
                {
                    sno: sno,
                    id: response.response_payload['last_insert_id'],
                    item_id: values.item_id,
                    name: item_details.name,
                    manufacturer: item_details.manufacturer,
                    model: item_details.model,
                    rate: values.rate
                }
                const updated_rows = [...table_rows, new_row_data];
                setTableRows(updated_rows);
            }
        }
    }

    // VALIDATING MAIN FORM...

    const validateForm = (values) => {
        let errors = {};
        const regex_decimal = /^[-+]?\d*\.?\d+$/;

        if (!values.item_id) {
            errors.item_id = "*Item is required";
        }

        if (!values.proposal_type_id) {
            errors.proposal_type_id = "*Proposal is required";
        }

        if (!values.rate) {
            errors.rate = "*Rate is required";
        }
        else if (!regex_decimal.test(values.rate)) {
            errors.rate = "*Only decimals allowed";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            proposal_type_id: values.proposal_type_id,
            item_id: values.item_id,            
            rate: values.rate
        };

        return data;
    }

    // CALCULATING SELLING PRICE...

    const handleCalculate = async e => {
        setMessage({ ...message, 0: '' })
        if (values.percentage) {
            let regex_number = /^[0-9]*$/;
            if (regex_number.test(values.percentage)){
                const item_details = values.item_id ? item.find(data => data.id.includes(values.item_id)) : null; 
                const selling_price = ((item_details?.rate || 0) * (100 + (parseInt(values.percentage, 10))) / 100).toFixed(2);;
                setValues({...values, rate: selling_price});
            } else {
                setMessage({ ...message, 1: 'Only digits allowed' })
            }
        } else {
            setMessage({ ...message, 1: 'Percentage is required' })
        }
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // HANDLER FOR DELETE BUTTON...

    const handleDelete = () =>{
        setMessage({});
        setDialog(true);
    }

    // HANDLER FOR DELETE DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // HANDLER FOR CONFIRM DELETE...

    const handleConfirm = () => {
        setDialog(!dialog);
        setConfirm(true);
    }

    // PERFORMING DELETE ACTION...

    useEffect(() => {
        const deleteRow = async () => {
            if (confirm) {
                setConfirm(false);

                if (selected_row > -1) {
                    setLoading({ ...loading, 1: true });
                    const headers =
                    {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${user_context.user_data[1]}`
                    };
                    await Delete(user_context.url + '/proposal_template?id=' + table_rows[selected_row]['id'], headers).then(response => {
                        setLoading({ ...loading, 1: false });
                        setMessage({ ...message, 0: response.message });
                        const new_table_rows = table_rows.filter(item => item !== table_rows[selected_row]);
                        setSelectedRow(-1);
                        setTableRows(new_table_rows);
                    });
                } else {
                    setMessage({ ...message, 0: "No row selected" });
                }
            }
        }

        deleteRow();
        //eslint-disable-next-line    
    }, [confirm]);

    // RETURNING VALUES...

    return {
        selectItem,
        selectProposalType,
        handleChange,
        handleSubmit,
        rowRenderer,
        onRowClick,
        handleCalculate,
        handleDelete,
        handleDialog,
        handleConfirm,
        item,
        proposal_types,
        errors,
        values,
        message,
        loading,
        table_rows,
        dialog,
        confirm
    };
}

export default AddProposalTemplateForm;