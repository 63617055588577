import React from "react";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { CSVLink } from "react-csv";
import MiniStatementForm from "./MiniStatementForm";
import DatePicker from "react-datepicker";
import { useMediaQuery } from 'react-responsive';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const MiniStatement = () => {
    // DECLARING STATES...

    const { ...destruct } = MiniStatementForm();
    const defaultColumnProperties = { sortable: false, resizable: true };


    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'date', name: 'Date', width: '10%' },
        { key: 'particulars', name: 'Particulars', width: '40%' },
        { key: 'debit', name: 'Credit', width: '15%' },
        { key: 'credit', name: 'Debit', width: '15%' },
        { key: 'balance', name: 'Balance', width: '15%' }
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
    const headers = ['S.No', 'Date', 'Particulars', 'Credit', 'Debit', "Balance"];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form">
                    <div className="view-formGroup">
                        <h1 className="form-head">MINI&nbsp;STATEMENT</h1>
                    </div>

                    <div className="daily-formGroup">
                        <label className="daily-label">Recipient&nbsp;ID:</label>
                        <select className="ledger-select" name="recipient_id" value={destruct.values.recipient_id} onChange={destruct.handleChange}>
                            {destruct.recipient_id && destruct.recipient_id.length
                                ? (destruct.recipient_id.map((item, i) => (<option key={i}>{item}</option>)))
                                : (<option value="">Loading...</option>)}
                        </select>

                        {!isMobile && <>
                            <label className="daily-label">Name:</label>
                            <input type="text" autoComplete='off' className="view-input" name="name" value={destruct.values.name} onChange={destruct.handleChange} disabled></input>

                            <label className="daily-label">From:</label>
                            <div className="datepicker">
                                <CalendarMonthOutlinedIcon />
                                <div className='datepicker-component'>
                                    <DatePicker
                                        className='datepicker-inner-component'
                                        dateFormat="dd-MM-yyyy"
                                        selected={destruct.values.from}
                                        onChange={date => destruct.handleChange({ target: { value: date, name: 'from' } })}
                                        fixedHeight
                                        todayButton="TODAY"
                                    />
                                </div>
                            </div>

                            <label className="daily-label">To:</label>
                            <div className="datepicker">
                                <CalendarMonthOutlinedIcon />
                                <div className='datepicker-component'>
                                    <DatePicker
                                        className='datepicker-inner-component'
                                        dateFormat="dd-MM-yyyy"
                                        selected={destruct.values.to}
                                        onChange={date => destruct.handleChange({ target: { value: date, name: 'to' } })}
                                        fixedHeight
                                        todayButton="TODAY"
                                    />
                                </div>
                            </div>
                        </>}

                    </div>

                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

                {/* FOOTER BUTTONS FOR CRUD ACTIONS... */}

                <div className="view-footer">
                    <button className="view-button" type="button" onClick={destruct.handleShow} disabled={destruct.loading[0]}><RemoveRedEyeOutlinedIcon />&nbsp;Show</button>
                    <CSVLink className="view-button" data={destruct.table_rows.map(item => Object.values(item))} headers={headers} filename={destruct.values.name + '.csv'} ><TableViewOutlinedIcon />&nbsp;Export</CSVLink>
                </div>

            </div>
        </div>
    );
}

export default MiniStatement;