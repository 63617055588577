import React from "react";
import UpdateProposalForm from "./UpdateProposalForm";
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CustomAutocomplete from "../Components/CustomAutoComplete";
import { ToastContainer } from 'react-toastify';

const UpdateProposal = (props) => {
    // DECLARING STATES...    

    const { ...destruct } = UpdateProposalForm(props);

     // MAIN FUNCTION...
     return (
        <div className="page">
            <div className="location-wrapper">
                 {/* FORM FOR PROPOSAL DETAILS... */}

                 <form className="location-form" onSubmit={destruct.handleSubmit}>
                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;PROPOSAL</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Proposal ID:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="id" 
                            value={destruct.values.id} 
                            onChange={destruct.handleChange} 
                            disabled 
                        />
                        {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Reference No:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="ref_no" 
                            value={destruct.values.ref_no} 
                            onChange={destruct.handleChange} 
                            disabled 
                        />
                        {destruct.errors.ref_no && <p className="error">{destruct.errors.ref_no}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Date:</label>
                        <div className="datepicker" style={{ zIndex: 2 }}>
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Client:</label>
                        {destruct.client &&
                            <CustomAutocomplete
                                data={destruct.client}
                                placeholder="Search Client..."
                                selectValue={destruct.selectClient}
                                selected_value={destruct.values.client_id}
                            />}
                        {destruct.errors.client_id && <p className="error">{destruct.errors.client_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Proposal Type:</label>
                        {destruct.proposal_type &&
                            <CustomAutocomplete
                                data={destruct.proposal_type}
                                placeholder="Search Proposal Type..."                                
                                selected_value={destruct.values.proposal_type_id}
                                disabled={true}
                            />}
                        {destruct.errors.proposal_type_id && <p className="error">{destruct.errors.proposal_type_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Status:</label>
                        <select className="select" name="status" value={destruct.values.status} onChange={destruct.handleChange}>
                            {destruct.statuses && destruct.statuses.length
                                ? (destruct.statuses.map((item, i) => (<option key={i}>{item}</option>)))
                                : (<option value="">Loading...</option>)}
                        </select>
                        {destruct.errors.status && <p className="error">{destruct.errors.status}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading[0]}>Update</button>
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>

                </form>
            </div>
            <ToastContainer/>
        </div>
     );
}

export default UpdateProposal;