import React, { useEffect, useContext, useState, useRef } from "react";
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Get from "../HTTPRequest/Get";
import ManOutlinedIcon from '@mui/icons-material/ManOutlined';
import Woman2OutlinedIcon from '@mui/icons-material/Woman2Outlined';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import CakeIcon from '@mui/icons-material/Cake';
import Chip from '@mui/material/Chip';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import HomeIcon from '@mui/icons-material/Home';
import Divider from '@mui/material/Divider';
import { useMediaQuery } from 'react-responsive';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import epfo from '../Images/epfo.png';
import esi from '../Images/esi.png';
import RoomIcon from '@mui/icons-material/Room';
import ReactToPrint from "react-to-print";

const ViewProfile = () => {

    // STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [profile, setProfile] = useState([]);
    const [photo_url, setPhotoURL] = useState();
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: 1150px)` }); 
    const [dob, setDOB] = useState('');
    const [work, setWork] = useState([]);
    let componentRef = useRef();

    // FETCHING DATA...

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let endpoint = user_context.url + '/staff?route=view_profile';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {                 
                setLoading(false);               
                if ((response.status === 1) &&(response.response_payload['result'] === 'success')) {         
                    let data = response.response_payload[0];                     
                    const profile_data = data['profile'][0];
                    setProfile(profile_data);  
                    const work_data = data['work'];
                    setWork(work_data);
                    
                    const base_url = user_context.url;
                    const url = base_url.replace("/smarterpweb-api", "");
                    const photo = `${url}/assets/${profile_data['photo_url']}`;
                    setPhotoURL(photo);  
                    const date = FormatLocalDate(CreateDate(profile_data?.dob));
                    setDOB(date);
                }
            });
        }

        fetchData();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);



    // MAIN FUNCTION...

    return(
        <div className="page">
            {!isMobile && <>
                {loading && <div className="view-wrapper" style={{ fontSize: 25, fontWeight: 'bold', color: '#808080' }}>
                    Loading...
                </div>}
                {!loading && <div className="daily-wrapper">
                    <form className="profile-form" ref={componentRef}>
                        <div className="view-formGroup">
                            <h1 className="form-head">VIEW&nbsp;PROFILE</h1>
                        </div>

                        <div style={{ width: '90%', height: '150px', display: 'flex', flexDirection: 'row'}}>
                            <div>
                                <div style={{ display: 'flex', width: '150px', height: '150px', justifyContent: 'center', alignItems: 'center'}}>
                                    <img 
                                        src={photo_url} 
                                        alt="User Pic" 
                                        style={{ maxWidth: '95%', maxHeight: '95%', objectFit: 'contain', borderRadius: '5px' }} 
                                    />
                                </div>
                            </div>

                            <div style={{ position: 'relative', padding: '10px', display: 'flex', flexDirection: 'column', width: '100%'}}>
                                <label style={{ fontSize: 25 }}>{profile?.name}</label>
                                <label style={{ fontSize: 12, fontWeight: 'bold', color: '#808080' }}></label>
                                <label style={{ fontSize: 15, color: '#808080' }}>{`${profile?.designation}`}</label>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {profile?.gender === 'Male' && <Chip icon={<ManOutlinedIcon/>} label={profile?.gender} variant="outlined" sx={{margin: '5px'}}/>}
                                    {profile?.gender === 'Female' && <Chip icon={<Woman2OutlinedIcon/>} label={profile?.gender} variant="outlined" sx={{margin: '5px'}}/>}
                                    {profile?.gender === 'Others' && <Chip icon={<TransgenderOutlinedIcon/>} label={profile?.gender} variant="outlined" sx={{margin: '5px'}}/>}
                                    <Chip icon={<BloodtypeIcon style={{color: '#B22222'}}/>} label={profile?.bloodgroup} variant="outlined" sx={{margin: '5px' }}/>
                                    <Chip icon={<CakeIcon style={{color: '#B8860B'}}/>} label={dob} variant="outlined" sx={{margin: '5px' }}/> 
                                    <Chip icon={<PhoneInTalkOutlinedIcon style={{color: '#4CAF50'}}/>} label={profile?.phone} variant="outlined" sx={{margin: '5px' }}/> 
                                    <Chip icon={<AttachEmailOutlinedIcon style={{color: '#1E90FF'}}/>} label={profile?.email} variant="outlined" sx={{margin: '5px' }}/>                               
                                </div>  
                                <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#05b48a', borderRadius: '50%' }}>                             
                                    <ReactToPrint                                        
                                        trigger={() => 
                                        <button                                          
                                            type="button"
                                            style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
                                        >
                                            <LocalPrintshopOutlinedIcon style={{ cursor: 'pointer', color: 'white', width: 20, height: 25 }}/>
                                        </button>} 
                                        content={() => componentRef.current} 
                                    />
                                </div>                          
                            </div>                       
                        </div> 

                        <Divider sx={{ margin: '10px 0', width: '90%' }} /> 

                        <div style={{ width: '90%', height: 'fit-content', display: 'flex', flexDirection: 'row'}}>
                            <div style={{ width: '90%', height: 'fit-content', display: 'flex', flexDirection: 'column'}}>
                                <HomeIcon style={{color: '#FFA500'}}/>
                                <label style={{ fontSize: 14, marginTop:'5px' }}>{profile?.address1}</label>
                                <label style={{ fontSize: 14, marginTop:'5px' }}>{profile?.address2}</label>
                                <label style={{ fontSize: 14, marginTop:'5px' }}>{profile?.district}</label>
                                <label style={{ fontSize: 14, marginTop:'5px' }}>{profile?.state}</label>
                                <label style={{ fontSize: 14, marginTop:'5px' }}>{profile?.pincode}</label>
                            </div>
                            <div style={{ width: '90%', height: 'fit-content', display: 'flex', flexDirection: 'column'}}>
                                <FamilyRestroomOutlinedIcon style={{color: '#00BFFF'}}/>
                                <label style={{ fontSize: 14, marginTop:'5px' }}>{profile?.father}</label>
                                <label style={{ fontSize: 14, marginTop:'5px' }}>{profile?.mother}</label>
                            </div>                            
                        </div> 

                        <Divider sx={{ margin: '10px 0', width: '90%' }} />

                        <div style={{ width: '90%', height: 'fit-content', display: 'flex', flexDirection: 'row'}}>
                            <div style={{ width: '90%', height: 'fit-content', display: 'flex', flexDirection: 'column'}}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop:'10px'}}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <AccountBalanceIcon/>                                
                                        <label style={{ fontSize: 14, marginLeft: '10px', marginRight: '10px' }}>Bank Account Number</label>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginLeft: '10px' }}>: {profile?.account}</label>
                                    </div>                                    
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop:'10px' }}>

                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <img src={epfo} alt="EPFO Icon" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                                        <label style={{ fontSize: 14, marginLeft: '10px', marginRight: '20px' }}>EPFO</label>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginLeft: '10px' }}>: {profile?.uan}</label>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop:'10px' }}>

                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <img src={esi} alt="ESI Icon" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                                        <label style={{ fontSize: 14, marginLeft: '10px' }}>ESI</label>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginLeft: '10px' }}>: {profile?.esi}</label>
                                    </div>
                                </div> 
                            </div>
                            <div style={{ width: '90%', height: 'fit-content', display: 'flex', flexDirection: 'column'}}>
                                <MonetizationOnIcon style={{color: '#008000'}}/>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop:'5px'}}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginTop:'5px' }}>Basic Pay</label>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginLeft: '10px' }}>: {profile?.basic_pay}</label>
                                    </div>                                    
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop:'5px'}}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginTop:'5px' }}>HRA</label>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginLeft: '10px' }}>: {profile?.hra}</label>
                                    </div>                                    
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop:'5px'}}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginTop:'5px' }}>Earned Increment</label>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginLeft: '10px' }}>: {profile?.earned_increment}</label>
                                    </div>                                    
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop:'5px'}}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginTop:'5px' }}>TA</label>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginLeft: '10px' }}>: {profile?.ta}</label>
                                    </div>                                    
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop:'5px'}}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginTop:'5px' }}>Performance Allowance</label>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginLeft: '10px' }}>: {profile?.performance_allowance}</label>
                                    </div>                                    
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop:'5px'}}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginTop:'5px' }}>Total Salary</label>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, alignItems: 'center'}}>
                                        <label style={{ fontSize: 14, marginLeft: '10px' }}>: {profile?.salary}</label>
                                    </div>                                    
                                </div>                              
                            </div>
                        </div>                        

                        <Divider sx={{ margin: '10px 0', width: '90%' }} />

                        <div  style={{ width: '90%', height: 'fit-content', display: 'flex', flexDirection: 'column'}}>
                            <label style={{ fontSize: 15, marginLeft: '10px', marginBottom: '10px', color: '#808080' }}>Work Locations</label>
                            <div style={{ width: '90%', height: 'fit-content', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                {work.map((item, index) => (
                                    <Chip icon={<RoomIcon style={{ color: '#DC3545' }}/>} key={index} label={item.address} sx={{ margin: '5px', width: '150px' }} />
                                ))}
                            </div>
                        </div>

                        <Divider sx={{ margin: '10px 0', width: '90%' }} />
                        
                    </form>
                </div>}
            </>}
        </div>
    );
}

export default ViewProfile;