import React from "react";
import AddPurchaseOrderForm from "./AddPurchaseOrderForm";
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import DataGrid from 'react-data-grid';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import { ToastContainer } from 'react-toastify';

const AddPurchaseOrder = () => {
    
    // DECLARING STATES...    

    const { ...destruct } = AddPurchaseOrderForm();
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'ID', width: '10%' },
        { key: 'name', name: 'Name', width: '25%' },
        { key: 'manufacturer', name: 'Manufacturer', width: '25%' },
        { key: 'model', name: 'Model', width: '20%' },
        { key: 'quantity', name: 'Quantity', width: '7%' },
        { key: 'unit', name: 'Unit', width: '8%' },
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="view-wrapper">
                <div className="view-form">
                    <div className="view-formGroup">
                        <h1 className="form-head">ADD&nbsp;PURCHASE&nbsp;ORDER</h1>
                    </div>

                    <div className="attendance-body">
                        <div className="attendance-innerbox">

                            <div className="location-formGroup">
                                <label className="label">Date:</label>
                                <div className="datepicker">
                                    <CalendarMonthOutlinedIcon />
                                    <div className='datepicker-component'>
                                        <DatePicker
                                            className='datepicker-inner-component'
                                            dateFormat="dd-MM-yyyy"
                                            selected={destruct.values.date}
                                            onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                            fixedHeight
                                            todayButton="TODAY"
                                        />
                                    </div>
                                </div>
                                {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Vendor:</label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={destruct.vendor}
                                    getOptionLabel={(option) => option ? option.name : ''}
                                    isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Search Vendor..." />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <span style={{ color: 'red' }}>{`ID: ${option.id}`}</span>
                                                <br />
                                                <span>{`Name: ${option.name}`}</span>
                                            </div>
                                        </li>
                                    )}
                                    onChange={(event, value) => destruct.selectVendor(value)}
                                    sx={{
                                        width: '90%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        "& .MuiInputBase-root": {
                                            fontFamily: 'sans-serif',
                                            height: 45,
                                            fontSize: '15px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0
                                        },
                                        "&:hover fieldset": {
                                            border: 0
                                        },
                                        backgroundColor: '#FFFFFF',
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: '1px solid #9ecaed',
                                            boxShadow: '0 0 10px #9ecaed',
                                        },
                                    }}
                                />
                                {destruct.errors.vendor_id && <p className="error">{destruct.errors.vendor_id}</p>}
                            </div>

                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Bill To:</label>
                                <select className="select" name="bill_to" value={destruct.values.bill_to} onChange={destruct.handleChange}>
                                    {destruct.options && destruct.options.length
                                        ? (destruct.options.map((item, i) => (<option key={i}>{item}</option>)))
                                        : (<option value="">Loading...</option>)}
                                </select>
                                {destruct.errors.bill_to && <p className="error">{destruct.errors.bill_to}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Ship To:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="ship_to"
                                    value={destruct.values.ship_to}
                                    onChange={destruct.handleChange}>
                                </input>
                                {destruct.errors.ship_to && <p className="error">{destruct.errors.ship_to}</p>}
                            </div>

                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Deadline:</label>
                                <div className="datepicker">
                                    <CalendarMonthOutlinedIcon />
                                    <div className='datepicker-component'>
                                        <DatePicker
                                            className='datepicker-inner-component'
                                            dateFormat="dd-MM-yyyy"
                                            selected={destruct.values.deadline}
                                            onChange={date => destruct.handleChange({ target: { value: date, name: 'deadline' } })}
                                            fixedHeight
                                            todayButton="TODAY"
                                        />
                                    </div>
                                </div>
                                {destruct.errors.deadline && <p className="error">{destruct.errors.deadline}</p>}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="view-form">
                    <div className="attendance-body">
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Item:</label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={destruct.item}
                                    getOptionLabel={(option) => option ? option.name : ''}
                                    isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Search Item..." />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <span style={{ color: 'red' }}>{`ID: ${option.id}`}</span>
                                                <br />
                                                <span>{`Name: ${option.name}`}</span>
                                                <br />
                                                <span>{`Model: ${option.model}`}</span>
                                                <br />
                                                <span>{`Manufacturer: ${option.manufacturer}`}</span>
                                            </div>
                                        </li>
                                    )}
                                    onChange={(event, value) => destruct.selectItem(value)}
                                    sx={{
                                        width: '90%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        "& .MuiInputBase-root": {
                                            fontFamily: 'sans-serif',
                                            height: 45,
                                            fontSize: '15px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0
                                        },
                                        "&:hover fieldset": {
                                            border: 0
                                        },
                                        backgroundColor: '#FFFFFF',
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: '1px solid #9ecaed',
                                            boxShadow: '0 0 10px #9ecaed',
                                        },
                                    }}
                                />
                                {destruct.errors.item_id && <p className="error">{destruct.errors.item_id}</p>}
                            </div>
                        </div>
                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Quantity:</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className="input"
                                    name="quantity"
                                    value={destruct.values.quantity}
                                    onChange={destruct.handleChange}>
                                </input>
                                {destruct.errors.quantity && <p className="error">{destruct.errors.quantity}</p>}
                            </div>
                        </div>
                        <div className="attendance-innerbox">
                            <label className="label"></label>
                            <div className="location-formGroup">
                                <button
                                    className="button"
                                    type="button"
                                    onClick={destruct.addItem}><DirectionsOutlinedIcon />&nbsp;Add Item
                                </button>
                                {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                            </div>
                        </div>
                    </div>
                </div>

                {/* DATA GRID */}

                <div className="short-table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{ width: '100%', height: '100%' }}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        onRowClick={destruct.onRowClick}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

                {/* FOOTER BUTTONS */}

                <div className="view-footer">

                    <button
                        className="view-button"
                        type="button"
                        disabled={destruct.loading[0]}
                        onClick={destruct.handleSubmit}><SaveOutlinedIcon />&nbsp;Save
                    </button>
                    <button
                        className="view-button"
                        type="button"
                        onClick={destruct.handleDelete}
                    ><DeleteForeverOutlinedIcon />&nbsp;Delete
                    </button>
                </div>  
            </div>
            <ToastContainer />
        </div>
    );
}

export default AddPurchaseOrder;