import React from "react";
import MonthlyAttendanceForm from "./MonthlyAttendanceForm";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { CSVLink } from "react-csv";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';

const MonthlyAttendance = () => {
    // DECLARING STATES...

    const { ...destruct } = MonthlyAttendanceForm();
    const defaultColumnProperties = { sortable: false, resizable: true };

    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'date', name: 'Date', width: '15%' },
        { key: 'type', name: 'Type', width: '15%' },
        { key: 'signin', name: 'Sign In', width: '15%' },
        { key: 'signout', name: 'Sign Out', width: '15%' },
        { key: 'reason', name: 'Reason', width: '35%' },
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    const headers = ['S.No', 'Date', 'Type', 'Sign In', 'Sign Out', 'Reason'];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">MONTHLY&nbsp;ATTENDANCE</h1>
                    </div>

                    <div className="view-formGroup">
                        <label className="attendance-label">Staff:</label>
                        <select className="attendance-select" name="staff_id" value={destruct.values.staff_id} onChange={destruct.handleChange}>
                            {destruct.staff_id && destruct.staff_id.length
                                ? (destruct.staff_id.map((item, i) => (<option key={i}>{item}</option>)))
                                : (<option value="">Loading...</option>)}
                        </select>

                        <input type="text" autoComplete='off' className="attendance-input" name="name" value={destruct.values.name} disabled></input>

                        <label className="attendance-label">Month:</label>
                        <select className="attendance-select" name="month" value={destruct.values.month} onChange={destruct.handleChange}>
                            {destruct.months.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>

                        <label className="attendance-label">Year:</label>
                        <select className="attendance-select" name="year" value={destruct.values.year} onChange={destruct.handleChange}>
                            {destruct.years.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>

                    </div>
                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        enableCellSelect={false}
                    />
                </div>

                {/* FOOTER BUTTONS FOR ACTIONS... */}

                <div className="view-footer">
                    <button className="view-button" type="button" onClick={destruct.handleShow} disabled={destruct.loading[0]}><RemoveRedEyeOutlinedIcon />&nbsp;Show</button>
                    <CSVLink className="view-button" data={destruct.table_rows.map(item => Object.values(item))} headers={headers} filename={destruct.values.name + '_' + destruct.values.month + '_' + destruct.values.year + '.csv'} ><TableViewOutlinedIcon />&nbsp;Export</CSVLink>
                </div>

            </div>
        </div>
    );
}

export default MonthlyAttendance;