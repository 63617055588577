import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Get from '../HTTPRequest/Get';
import Delete from '../HTTPRequest/Delete';
import Post from '../HTTPRequest/Post';
import { Row } from 'react-data-grid';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const AddStockForm = () => {
    // DECLARING STATES...

    const location = useLocation();
    const from_page = location.state ? location.state.from_page : null;
    const history = useHistory();
    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const types =
        [
            'Ledger Entry',
            'Opening Stock',
            'Stock Adjustment',
            'Self Consumption',
            'Out for Repair'
        ];
    const initialValues =
    {
        ledger_id: data_context.update_data['id'] || '',
        type: data_context.update_data['id'] ? types[0] : types[1],
        item_id: '',
        name: '',
        model: '',
        manufacturer: '',
        rate: '',
        qty_in: '',
        qty_out: '',
        calculate_rate: '',
        calculate_tax: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState({ 0: false, 1: false });
    const [disable] = useState(data_context.update_data['id'] ? true : false);
    const [item, setItem] = useState([]);
    const [table_rows, setTableRows] = useState([]);
    const [selected_row, setSelectedRow] = useState
        (
            data_context.view_data['selected_row'] !== undefined &&
                data_context.view_data['selected_row'] !== null
                ? data_context.view_data['selected_row'] : -1
        );
    const [dialog, setDialog] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/add_stock');
    const [calculate, setCalculate] = useState(false);
    const [total, setTotal] = useState('');

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=item';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        const id = response.response_payload[0]['id'];
                        const name = response.response_payload[0]['name'];
                        const manufacturer = response.response_payload[0]['manufacturer'];
                        const model = response.response_payload[0]['model'];

                        const newItems = id.map((_, i) => ({
                            id: id[i],
                            label: id[i],
                            name: name[i],
                            manufacturer: manufacturer[i],
                            model: model[i]
                        }));

                        setItem(newItems);
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // FETCHING STOCK DETAILS...

    useEffect(() => {
        const fetchStock = async () => {
            let endpoint = user_context.url
                + '/stock?route=fetch_stock&filter_key='
                + values.ledger_id + '&fiscal='
                + data_context['fiscal'];
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {              
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;
                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            data[i] = payload[i];
                        }
                        setTableRows(data);
                    }
                }
            });
        }

        fetchStock();
        //eslint-disable-next-line
    }, []);

    // CALCULATING TOTAL...

    useEffect(() => {
        const length = table_rows.length;
        let total = 0;
        for(let i=0; i<length; i++){
            let rate = table_rows[i]['rate'];
            let quantity = table_rows[i]['qty_in'] || table_rows[i]['qty_out'];
            total = parseFloat(total) + (parseFloat(rate) * parseFloat(quantity));
            const rounded = total.toFixed(2);
            setTotal(rounded);
        }
        //eslint-disable-next-line
    }, [table_rows]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // ON SELECTION...

    const handleSelect = (item) => {
        if (item) {
            setValues(
                {
                    ...values,
                    item_id: item.id,
                    name: item.name,
                    manufacturer: item.manufacturer,
                    model: item.model
                });
        }
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            const response = await Post(user_context.url + '/stock', payload, headers);
            setLoading({ ...loading, 0: false });
            setMessage({ ...message, 0: response.message });
            if (response.status === 1 && response.response_payload['result'] === 'success') {
                const sno = table_rows.length + 1;
                const new_row_data =
                {
                    sno: sno,
                    id: response.response_payload['last_insert_id'],
                    ledger_id: values.ledger_id,
                    type: values.type,
                    item_id: values.item_id,
                    name: values.name,
                    model: values.model,
                    manufacturer: values.manufacturer,
                    rate: values.rate,
                    qty_in: values.qty_in,
                    qty_out: values.qty_out
                }
                const updated_rows = [...table_rows, new_row_data];
                setTableRows(updated_rows);
            }
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_decimal = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
        let regex_number = /^[0-9]*$/;

        if (!values.item_id) {
            errors.item_id = "*Item ID is required";
        }

        if (!values.rate) {
            errors.rate = "*Rate is required";
        }
        else if (!regex_decimal.test(values.rate)) {
            errors.rate = "*Only decimals allowed";
        }

        if (values.qty_in && values.qty_out) {
            errors.qty_in = "*Both Qty In and Out are not allowed";
            errors.qty_out = "*Both Qty In and Out are not allowed";
        }

        if (!values.qty_in && !values.qty_out) {
            errors.qty_in = "*Either Qty In or Out required";
            errors.qty_out = "*Either Qty In or Out required";
        }

        if (values.qty_in) {
            if (!regex_number.test(values.qty_in)) {
                errors.qty_in = "*Only digits allowed";
            }
        }

        if (values.qty_out) {
            if (!regex_number.test(values.qty_out)) {
                errors.qty_out = "*Only digits allowed";
            }
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            ledger_id: values.ledger_id || null,
            type: values.type,
            item_id: values.item_id,
            rate: values.rate,
            qty_in: values.qty_in || null,
            qty_out: values.qty_out || null,
            fiscal: data_context['fiscal']
        };

        return data;
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        if (from_page) {
            history.push(from_page);
        }
    }

    // HANDLER FOR DELETE BUTTON...

    const handleDelete = () => {
        setMessage({});
        setDialog(true);
    }

    // HANDLER FOR DELETE DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // HANDLER FOR CONFIRM DELETE...

    const handleConfirm = () => {
        setDialog(!dialog);
        setConfirm(true);
    }

    // PERFORMING DELETE ACTION...

    useEffect(() => {
        const deleteRow = async () => {
            if (confirm) {
                setConfirm(false);

                if (selected_row > -1) {
                    setLoading({ ...loading, 1: true });
                    const headers =
                    {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${user_context.user_data[1]}`
                    };
                    await Delete(user_context.url + '/stock?id=' + table_rows[selected_row]['id'], headers).then(response => {
                        setLoading({ ...loading, 1: false });
                        setMessage({ ...message, 0: response.message });
                        const new_table_rows = table_rows.filter(item => item !== table_rows[selected_row]);
                        setSelectedRow(-1);
                        setTableRows(new_table_rows);
                    });
                } else {
                    setMessage({ ...message, 0: "No row selected" });
                }
            }
        }

        deleteRow();
        //eslint-disable-next-line    
    }, [confirm]);

    // HANDLER FOR CALCULATE DIALOG BUTTON...

    const handleCalculate = () => {
        setCalculate(!calculate);
    }

    // FUNCTION TO CALCLATE RATE...

    const calculateRate = () => {
        try {
            let errors = validateCalculate(values);
            setErrors(errors);
            if (Object.keys(errors).length === 0) {
                const tax = (parseFloat(values.calculate_rate) * parseFloat(values.calculate_tax)) / 100;
                const rate = parseFloat(values.calculate_rate) + tax;
                const rounded = rate.toFixed(2);
                setValues({ ...values, rate: rounded, calculate_rate: '', calculate_tax: '' });
                setCalculate(!calculate);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    // FUNCTION TO PERFORM VALIDATION BEFORE CALCULATION...

    const validateCalculate = (values) => {
        let errors = {};
        let regex_decimal = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

        if (!values.calculate_rate) {
            errors.calculate_rate = "*Rate is required";
        } else if (!regex_decimal.test(values.calculate_rate)) {
            errors.calculate_rate = "*Only decimals allowed";
        }

        if (!values.calculate_tax) {
            errors.calculate_tax = "*Tax is required";
        } else if (!regex_decimal.test(values.calculate_tax)) {
            errors.calculate_tax = "*Only decimals allowed";
        }

        return errors;
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleSelect,
        rowRenderer, onRowClick, handleBack, handleDialog,
        handleDelete, handleConfirm, handleCalculate, calculateRate,
        values, errors, message, loading, types, disable, item,
        table_rows, from_page, dialog, permissions, calculate, total
    };

}

export default AddStockForm;