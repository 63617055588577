import React, { useState, useContext } from "react";
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import { Row } from 'react-data-grid';
import DataGrid from 'react-data-grid';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Get from '../HTTPRequest/Get';
import { CSVLink } from "react-csv";
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';

const BillService = () => {

    // STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);   
    const initialValues =
    {
        period: ''    
    };
    const [values, setValues] = useState(initialValues);
    const [selected_row, setSelectedRow] = useState();
    const [table_rows, setTableRows] = useState([]);
    const [loading, setLoading] = useState({ 0: false });
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '10%' },
        { key: 'id', name: 'ID', width: '10%' },
        { key: 'name', name: 'Name', width: '30%' },
        { key: 'address', name: 'Address', width: '20%' },
        { key: 'quantity', name: 'Quantity', width: '10%' },
        { key: 'rate', name: 'Rate', width: '10%' },
        { key: 'period', name: 'Period', width: '10%' },
    ].map(c => ({ ...c, ...defaultColumnProperties }));
    const headers = ['S.No', 'ID', 'Name', 'Address', 'Quantity', 'Rate', 'Period'];
    

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();  
        setTableRows([]);
        setSelectedRow();      
        if(values.period) {
            setLoading({ ...loading, 0: true });
            
            let endpoint = user_context.url + '/bill_service?fiscal=' + data_context['fiscal'] + '&filter_key=' + values.period;
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
    
            await Get(endpoint, headers).then(response => {
                    setLoading({ ...loading, 0: false });            
                    if ((response.status === 1) && (response.response_payload['result'] === 'success')) { 
                        toast.success(response?.message);               
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;

                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            data[i] = payload[i];
                        }
                        setTableRows(data);                
                    } else {
                        toast.error(response?.message);
                    }    
            });
        } else {
            toast.error("Period is required");
        }        
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>
        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // DELETING ITEM FROM DATAGRID...

    const handleDelete = () => {
        if (selected_row > -1) {
            const updatedRows = [...table_rows];
            updatedRows.splice(selected_row, 1);
            setTableRows(updatedRows);
            reorderArray(updatedRows);
        } else {
            toast.error("No row selected");
        }
    }

    // FUNCTION TO REARRANGE SNO AFTER DELETION...

    const reorderArray = (rows) => {
        rows.forEach((row, index) => {
            row.sno = index + 1;
        });
        setTableRows([...rows]);
    };

    // MAIN FUNCTION

    return(
        <div className="page">
            <div className="view-wrapper">
                <div className="view-form">
                    <div className="view-formGroup">
                        <h1 className="form-head">BILL&nbsp;SERVICE</h1>
                    </div>

                    <div className="view-formGroup">
                        <label className="view-label">Period:</label>
                        <input 
                            type="text" 
                            autoComplete="off" 
                            className="view-input" 
                            name="period" 
                            value={values.period} 
                            onChange={handleChange}>
                        </input>
                        <button 
                            className="view-button" 
                            type="button"
                            onClick={handleSubmit}
                            disabled={loading[0]}>View&nbsp;All
                        </button>
                    </div>
                </div>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{ width: '100%', height: '100%' }}
                        columns={table_columns}
                        rows={table_rows}
                        onRowClick={onRowClick}
                        rowRenderer={rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

                 {/* FOOTER BUTTONS */}

                 <div className="view-footer">
                    <CSVLink 
                        className="view-button" 
                        data={table_rows.map(item => Object.values(item))} 
                        headers={headers} filename={'Service Bill Request.csv'} ><TableViewOutlinedIcon />&nbsp;Generate
                    </CSVLink>

                    <button
                        className="view-button"
                        type="button"
                        onClick={handleDelete}
                    ><DeleteForeverOutlinedIcon />&nbsp;Delete
                    </button>
                </div> 

            </div>
            <ToastContainer/>
        </div>
    );
}

export default BillService;