import { useState, useEffect, useContext } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import Delete from '../HTTPRequest/Delete';
import { useHistory } from 'react-router-dom';
import Get from '../HTTPRequest/Get';
import { Row } from 'react-data-grid';
import { toast } from 'react-toastify';
import Download from '../HTTPRequest/Download';

const ViewMapForm = (props) => {

    // STATES...  

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const [columns] = useState(['All', ...user_context.user_data[3]['map']]);
    const [values, setValues] = useState({ 
        key: data_context.view_data['key'] || '', 
        selected_column: data_context.view_data['selected_column'] || 'All' 
    });
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [table_rows, setTableRows] = useState(data_context.view_data['table_rows'] || []);
    const [selected_row, setSelectedRow] = useState
    (
        data_context.view_data['selected_row'] !== undefined && 
        data_context.view_data['selected_row'] !== null 
        ? data_context.view_data['selected_row'] : -1
    );
    const history = useHistory();
    const [dialog, setDialog] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/view_map');
    const [progress, setProgress] = useState(0);
    const [checked, setChecked] = useState(false);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        setTableRows([]);
        setSelectedRow();

        let endpoint = '';
        values.key.length === 0 
            ? endpoint = user_context.url + '/maps' 
            : endpoint = user_context.url + '/map?filter_key=' + values.key + '&field=' + values.selected_column;
        setLoading({ ...loading, 0: true });
        const headers = 
        { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${user_context.user_data[1]}` 
        };

        await Get(endpoint, headers).then(response => {
            setLoading(false);
            if ((response.status === 1) && (response.response_payload['result'] === 'success')) {
                toast.success(response?.message);
                let payload = response.response_payload;
                let data_length = (Object.keys(payload).length) - 1;

                let data = ([]);
                for (let i = 0; i < data_length; i++) {
                    data[i] = payload[i];
                }
                setTableRows(data);
            } else {
                toast.error(response?.message);
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {

        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // HANDLER FOR UPDATE BUTTON...

    const handleUpdate = async () => {        
        if (selected_row > -1) {
            if (checked) {
                setLoading({ ...loading, 2: true });
                setProgress(0);
                const options = {
                    headers:
                    {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${user_context.user_data[1]}`
                    },
                    onDownloadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress(percent);
                    }
                }

                const endpoint = user_context.url + '/download?&file_docs=' + table_rows[selected_row]['docs_url'];

                await Download(endpoint, options).then(response => {
                    setLoading({ ...loading, 2: false });
                    if (response['status'] === 1) {
                        let view_data =
                        {
                            key: values.key,
                            selected_column: values.selected_column,
                            selected_row: selected_row,
                            table_rows: table_rows
                        };
                        props.handleBack(view_data);
                        let update_data = table_rows[selected_row];                   
                        update_data['docs'] = response['response_payload']['docs'];
                        props.handleUpdate(update_data);
                        history.push("/update_map");
                    }
                });
            } else {
                let view_data = { 
                    key: values.key, 
                    selected_column: values.selected_column, 
                    selected_row: selected_row, 
                    table_rows: table_rows 
                };
                props.handleBack(view_data);
                let update_data = table_rows[selected_row];
                update_data['docs'] = 'no_such_data';
                props.handleUpdate(update_data);
                history.push("/update_map");
            }            
        }
        else {
            toast.error("No row selected");
        }
    }

    // HANDLER FOR DELETE BUTTON...

    const handleDelete = () => {
        setDialog(true);
    }

    // HANDLER FOR DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // HANDLER FOR CONFIRM DELETE...

    const handleConfirm = () => {
        setDialog(!dialog);
        setConfirm(true);
    }

    // PERFORMING DELETE ACTION...

    useEffect(() => {
        const deleteRow = async () => {
            if (confirm) {
                setConfirm(false);

                if (selected_row > -1) {
                    setLoading({ ...loading, 1: true });
                    const headers = 
                    { 
                        'Accept': 'application/json', 
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${user_context.user_data[1]}` 
                    };
                    await Delete(user_context.url + '/map?id=' + table_rows[selected_row]['id'], headers).then(response => {
                        setLoading(false);
                        if(response.status === 1){
                            toast.success(response?.message)
                            const new_table_rows = table_rows.filter(item => item !== table_rows[selected_row]);
                            setSelectedRow(-1);
                            setTableRows(new_table_rows);
                        } else {
                            toast.error(response?.message);
                        }                        
                    });
                }
                else {
                    toast.error("No row selected");
                }
            }
        }

        deleteRow();

        //eslint-disable-next-line    
    }, [confirm]);

    // RETURNING VALUES...

    return { 
        handleSubmit, handleChange, onRowClick, handleDelete, 
        handleUpdate, handleDialog, handleConfirm, rowRenderer,
        setChecked, columns, values, loading, table_rows, 
        dialog, progress, permissions, checked 
    };
}

export default ViewMapForm;