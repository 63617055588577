import React from "react";
import DatePicker from "react-datepicker";
import UpdateEntryForm from "./UpdateEntryForm";
import { useMediaQuery } from 'react-responsive';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const UpdateEntry = (props) => {
    // DECLARING STATES...    

    const { ...destruct } = UpdateEntryForm(props);
    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="attendance-wrapper">

                {/* NORMAL VIEW ON A COMPUTER BROWSER... */}

                {!isMobile && <div className="attendance-form">

                    <div className="attendance-header">
                        <div className="location-formGroup">
                            <h1 className="form-head">UPDATE&nbsp;ENTRY</h1>
                        </div>
                    </div>

                    <div className="attendance-body">
                        <div className="attendance-innerbox">

                            <div className="location-formGroup">
                                <label className="label">Expense ID:</label>
                                <input type="text" autoComplete='off' className="input" name="id" value={destruct.values.id} onChange={destruct.handleChange} disabled></input>
                                {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Recipient:</label>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={destruct.item}
                                    getOptionLabel={(option) => option ? option.name : ''} 
                                    isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Search Party..." />                                       
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                                <br />
                                                <span>{`${option.name}`}</span>
                                            </div>
                                        </li>
                                    )}
                                    value={destruct.item.find(option => option.name === destruct.values.name) || null}
                                    onChange={(event, value) => destruct.handleSelect(value)}
                                    sx={{
                                        width: '90%',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        "& .MuiInputBase-root": {
                                            fontFamily: 'sans-serif',
                                            height: 45,
                                            fontSize: '15px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0
                                        },
                                        "&:hover fieldset": {
                                            border: 0
                                        },
                                        backgroundColor: '#FFFFFF',
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: '1px solid #9ecaed',
                                            boxShadow: '0 0 10px #9ecaed',
                                        },
                                    }}
                                />
                                {destruct.errors.recipient_id && <p className="error">{destruct.errors.recipient_id}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Account:</label>
                                <select className="select" name="account" value={destruct.values.account} onChange={destruct.handleChange}>
                                    {destruct.account && destruct.account.id && destruct.account.name
                                        ? (destruct.account.id.map((id, index) =>
                                            (<option key={id} value={id}>{id} - {destruct.account.name[index]}</option>)))
                                        : (<option value="">Loading...</option>)
                                    }
                                </select>
                                {destruct.errors.account && <p className="error">{destruct.errors.account}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Date:</label>
                                <div className="datepicker">
                                    <CalendarMonthOutlinedIcon />
                                    <div className='datepicker-component'>
                                        <DatePicker
                                            className='datepicker-inner-component'
                                            dateFormat="dd-MM-yyyy"
                                            selected={destruct.values.date}
                                            onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                            fixedHeight
                                            todayButton="TODAY"
                                        />
                                    </div>
                                </div>
                                {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                            </div>
                        </div>

                        <div className="attendance-innerbox">
                            <div className="location-formGroup">
                                <label className="label">Particulars:</label>
                                <input type="text" autoComplete='off' className="input" name="particulars" value={destruct.values.particulars} onChange={destruct.handleChange} ></input>
                                {destruct.errors.particulars && <p className="error">{destruct.errors.particulars}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Type:</label>
                                <select className="select" name="type" value={destruct.values.type} onChange={destruct.handleChange}>
                                    {destruct.types.map((item, i) => (<option key={i}>{item}</option>))}
                                </select>
                                {destruct.errors.type && <p className="error">{destruct.errors.type}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Credit:</label>
                                <input type="text" autoComplete='off' className="input" name="credit" value={destruct.values.credit} onChange={destruct.handleChange} disabled={destruct.disable[0]}></input>
                                {destruct.errors.credit && <p className="error">{destruct.errors.credit}</p>}
                            </div>

                            <div className="location-formGroup">
                                <label className="label">Debit:</label>
                                <input type="text" autoComplete='off' className="input" name="debit" value={destruct.values.debit} onChange={destruct.handleChange} disabled={destruct.disable[1]}></input>
                                {destruct.errors.debit && <p className="error">{destruct.errors.debit}</p>}
                            </div>                       
                        </div>                       
                    </div>

                    <div className="location-formGroup">
                        <button className="short-button" type="button" onClick={destruct.handleSubmit} disabled={destruct.loading}>Update</button>
                        {destruct.message && <p className="message">{destruct.message}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="short-button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>
                    
                </div>}

                {/* MEDIA QUERY VIEW ON MOBILE DEVICE... */}

                {isMobile && <form className="location-form" onSubmit={destruct.handleSubmit}>

                    <div className="location-formGroup">
                        <h1 className="form-head">UPDATE&nbsp;ENTRY</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Expense ID:</label>
                        <input type="text" autoComplete='off' className="input" name="id" value={destruct.values.id} onChange={destruct.handleChange} disabled></input>
                        {destruct.errors.id && <p className="error">{destruct.errors.id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Recipient:</label>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={destruct.item}
                            getOptionLabel={(option) => option ? option.name : ''} 
                            isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Search Party..." />                                       
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                        <br />
                                        <span>{`${option.name}`}</span>
                                    </div>
                                </li>
                            )}
                            value={destruct.item.find(option => option.name === destruct.values.pname) || null}
                            onChange={(event, value) => destruct.handleSelect(value)}
                            sx={{
                                width: '90%',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                "& .MuiInputBase-root": {
                                    fontFamily: 'sans-serif',
                                    height: 45,
                                    fontSize: '15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignContent: 'center'
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: 0
                                },
                                "&:hover fieldset": {
                                    border: 0
                                },
                                backgroundColor: '#FFFFFF',
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: '1px solid #9ecaed',
                                    boxShadow: '0 0 10px #9ecaed',
                                },
                            }}
                        />
                        {destruct.errors.recipient_id && <p className="error">{destruct.errors.recipient_id}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Account:</label>
                        <select className="select" name="account" value={destruct.values.account} onChange={destruct.handleChange}>
                            {destruct.account && destruct.account.id && destruct.account.name
                                ? (destruct.account.id.map((id, index) =>
                                    (<option key={id} value={id}>{id} - {destruct.account.name[index]}</option>)))
                                : (<option value="">Loading...</option>)
                            }
                        </select>
                        {destruct.errors.account && <p className="error">{destruct.errors.account}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Date:</label>
                        <div className="datepicker">
                            <CalendarMonthOutlinedIcon />
                            <div className='datepicker-component'>
                                <DatePicker
                                    className='datepicker-inner-component'
                                    dateFormat="dd-MM-yyyy"
                                    selected={destruct.values.date}
                                    onChange={date => destruct.handleChange({ target: { value: date, name: 'date' } })}
                                    fixedHeight
                                    todayButton="TODAY"
                                />
                            </div>
                        </div>
                        {destruct.errors.date && <p className="error">{destruct.errors.date}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Particulars:</label>
                        <input type="text" autoComplete='off' className="input" name="particulars" value={destruct.values.particulars} onChange={destruct.handleChange} ></input>
                        {destruct.errors.particulars && <p className="error">{destruct.errors.particulars}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Type:</label>
                        <select className="select" name="type" value={destruct.values.type} onChange={destruct.handleChange}>
                            {destruct.types.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>
                        {destruct.errors.type && <p className="error">{destruct.errors.type}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Credit:</label>
                        <input type="text" autoComplete='off' className="input" name="credit" value={destruct.values.credit} onChange={destruct.handleChange} disabled={destruct.disable[0]}></input>
                        {destruct.errors.credit && <p className="error">{destruct.errors.credit}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Debit:</label>
                        <input type="text" autoComplete='off' className="input" name="debit" value={destruct.values.debit} onChange={destruct.handleChange} disabled={destruct.disable[1]}></input>
                        {destruct.errors.debit && <p className="error">{destruct.errors.debit}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={destruct.loading}>Update</button>
                        {destruct.message && <p className="message">{destruct.message}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="button" onClick={destruct.handleBack}>Back</button>
                    </div>

                </form>}

            </div>
        </div>
    );
}

export default UpdateEntry;