import { useState, useEffect, useContext } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import { useHistory } from 'react-router-dom';
import Get from '../HTTPRequest/Get';
import Delete from '../HTTPRequest/Delete';
import { Row } from 'react-data-grid';

const ViewRecipientForm = (props) => {

    // DECLARING STATES...   

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const [columns] = useState(['All', ...user_context.user_data[3]['recipient']]);
    const [values, setValues] = useState({ key: data_context.view_data['key'] || '', selected_column: data_context.view_data['selected_column'] || 'All' });
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false, 1: false });
    const [table_rows, setTableRows] = useState(data_context.view_data['table_rows'] || []);
    const [selected_row, setSelectedRow] = useState
    (
        data_context.view_data['selected_row'] !== undefined && 
        data_context.view_data['selected_row'] !== null 
        ? data_context.view_data['selected_row'] : -1
    );
    const [refresh, setRefresh] = useState(false);
    const history = useHistory();
    const [dialog, setDialog] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/view_recipient');

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        setMessage();
        setTableRows([]);
        setSelectedRow();
        setLoading({ ...loading, 0: true });

        let endpoint = '';
        values.key.length === 0 ? endpoint = user_context.url + '/recipients' : endpoint = user_context.url + '/recipient?filter_key=' + values.key + '&field=' + values.selected_column;
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

        await Get(endpoint, headers).then(response => {
            setLoading(false);
            setMessage(response.message);
            if (response.status === 1) {
                if (response.response_payload['result'] === 'success') {
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 1;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                    }
                    setTableRows(data);
                }
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {

        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }


    // REFRESHING DATA GRID...

    useEffect(() => {
        setRefresh(false);

    }, [refresh]);

    // HANDLER FOR UPDATE BUTTON...

    const handleUpdate = () => {
        if (selected_row > -1) {
            let view_data = { key: values.key, selected_column: values.selected_column, selected_row: selected_row, table_rows: table_rows };
            props.handleBack(view_data);
            props.handleUpdate(table_rows[selected_row]);
            history.push("/update_recipient");
        }
        else {
            setMessage("No row selected");
        }
    }

    // HANDLER FOR DELETE BUTTON...

    const handleDelete = () => {
        setMessage();
        setDialog(true);
    }

    // HANDLER FOR DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // HANDLER FOR CONFIRM DELETE...

    const handleConfirm = () => {
        setDialog(!dialog);
        setConfirm(true);
    }

    // PERFORMING DELETE ACTION...

    useEffect(() => {
        const deleteRow = async () => {
            if (confirm) {
                setConfirm(false);

                if (selected_row > -1) {
                    setLoading({ ...loading, 1: true });
                    const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };
                    await Delete(user_context.url + '/location?id=' + table_rows[selected_row]['id'], headers).then(response => {
                        setLoading(false);
                        setMessage(response.message);
                        const new_table_rows = table_rows.filter(item => item !== table_rows[selected_row]);
                        setSelectedRow(-1);
                        setTableRows(new_table_rows);
                    });
                }
                else {
                    setMessage("No row selected");
                }
            }
        }

        deleteRow();
        //eslint-disable-next-line    
    }, [confirm]);

    // RETURNING VALUES...

    return { handleSubmit, handleChange, onRowClick, handleDelete, 
            handleUpdate, handleDialog, handleConfirm, rowRenderer, 
            columns, message, values, loading, table_rows, dialog,
            permissions };
}

export default ViewRecipientForm;