import { useState, useEffect, useContext } from 'react';
import UserContext from '../../Parent/UserContext';
import DataContext from '../../Parent/DataContext';
import GetIndex from '../../Parent/GetIndex';
import EncodeBase64 from '../../Parent/EncodeBase64';
import CreateBlob from '../../Parent/CreateBlob';
import CreateFileName from '../../Parent/CreateFileName';
import { useHistory } from 'react-router-dom';
import Upload from '../../HTTPRequest/Upload';
import Put from '../../HTTPRequest/Put';
import GetKey from '../../Parent/GetKey';

const UpdatePurchaseForm = (props) => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const types = ['Goods', 'Service', 'Professional Service', 'Miscellaneous'];
    const initialValues =
    {
        id: data_context.update_data['id'],
        ledger_id: data_context.update_data['ledger_id'],
        type: types[GetIndex(types, data_context.update_data['type'])],
        tds: (data_context.update_data['tds'] !== null) ? data_context.update_data['tds'] : '',
        taxable_value: data_context.update_data['taxable_value'],
        cgst: (data_context.update_data['cgst'] !== null) ? data_context.update_data['cgst'] : '',
        sgst: (data_context.update_data['sgst'] !== null) ? data_context.update_data['sgst'] : '',
        igst: (data_context.update_data['igst'] !== null) ? data_context.update_data['igst'] : '',
        round_off: (data_context.update_data['round_off'] !== null) ? data_context.update_data['round_off'] : '',
        total: data_context.update_data['total'],
        docs_url: data_context.update_data['docs_url'],
        cgst_percentage: '',
        sgst_percentage: '',
        igst_percentage: ''
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({ 0: '', 1: '' });
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [progress, setProgress] = useState(0);
    const [docs_errors, setDocsErrors] = useState();
    const [docs, setDocs] = useState(data_context.update_data['docs']);
    const [disable, setDisable] = useState(true);
    const [dialog, setDialog] = useState(false);
    const history = useHistory();

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // ENABLE OR DISABLE TDS FIELD...

    useEffect(() => {
        if (values.type === 'Service' || values.type === 'Professional Service') {
            setDisable(false);
        } else {
            setDisable(true);
        }
        //eslint-disable-next-line    
    }, [values.type]);

    // HANDLER FOR UPLOAD DIALOG...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'pdf_file') {

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setDocs(base64);
                    }

                    setValues({ ...values, docs_url: e.target.files[0].name });
                    setDocsErrors();
                    setMessage({ ...message, 1: '' });

                }).catch(() => {
                    setDocsErrors();
                    setMessage({ ...message, 1: "File conversion error" });
                });
            }
        }
    }

    // VIEWING PDF...

    const viewPDF = () => {
        setLoading({ ...loading, 1: true });
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 1: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 1: false });
                }
            }
        } else {
            setLoading({ ...loading, 1: false });
        }
    } 

    // VALIDATING UPLOADS...

    const validateDocs = () => {
        let errors = '';
        if (!values.docs_url) {
            errors = "*No pdf selected";
            setDocsErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.docs_url)[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors = "*Document is not a valid PDF file";
                setDocsErrors(errors);
            }
            else {
                if (docs === 'no_such_data' || !docs) {
                    errors = "*No pdf preview found";
                    setDocsErrors(errors);
                    setLoading({ ...loading, 1: false });
                }
            }
        }
        return errors;
    }

    // CLEARING FIELDS...

    const handleClear = () => {
        setDocs();
        setValues({ ...values, docs_url: '' });
        setDocsErrors();
        setMessage({ ...message, 1: '' });
        setProgress(0);
    }

    // UPLOADING IMAGES AND DOCS...

    const handleUpload = async () => {
        if (message[1] === '') {
            setProgress(0);
            setMessage({ ...message, 1: '' });
            let errors = validateDocs();

            if (Object.keys(errors).length === 0) {
                setLoading({ ...loading, 2: true });
                const options = {
                    headers: 
                    { 
                        'Accept': 'application/json', 
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${user_context.user_data[1]}` 
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress(percent);
                    }
                }

                if (docs !== null) {
                    let file_name = CreateFileName(values.docs_url);
                    const payload = 
                    { 
                        file_name: file_name, 
                        encoded_string: docs,
                        old_file: data_context.update_data['docs_url']
                    };

                    await Upload(user_context.url + '/upload', payload, options).then(response => {
                        setMessage({ ...message, 1: response.message });
                        setLoading({ ...loading, 2: false });
                        if (response['status'] === 1) {
                            setValues({ ...values, docs_url: file_name });
                        }
                    });
                }
            }
        } else {
            setMessage({ ...message, 1: "File already uploaded. Clear the field to upload again" });
        }
    }

    // CALCULATING CGST, SGST AND IGST VALUES...

    const handleCalculate = (props) => {
        let errors = {};
        setErrors({});
        const regex_decimal = /^[-+]?\d*\.?\d+$/;
        let regex_digit = /^[0-9]*$/;

        let percentage = '';

        if (props === 'cgst') {
            percentage = values.cgst_percentage;
        }

        if (props === 'sgst') {
            percentage = values.sgst_percentage;
        }

        if (props === 'igst') {
            percentage = values.igst_percentage;
        }

        if (values.taxable_value) {
            if (regex_decimal.test(values.taxable_value)) {
                if (percentage) {
                    if (regex_digit.test(percentage)) {
                        let tax = ((values.taxable_value * percentage) / 100).toFixed(2);

                        if (props === 'cgst') {
                            setValues({ ...values, cgst: tax });
                        }

                        if (props === 'sgst') {
                            setValues({ ...values, sgst: tax });
                        }

                        if (props === 'igst') {
                            setValues({ ...values, igst: tax });
                        }

                    }
                    else {
                        if (props === 'cgst') {
                            errors.cgst = "*Only digits allowed for CGST Percentage";
                            setErrors(errors);
                        }

                        if (props === 'sgst') {
                            errors.sgst = "*Only digits allowed for SGST Percentage";
                            setErrors(errors);
                        }

                        if (props === 'igst') {
                            errors.igst = "*Only digits allowed for IGST Percentage";
                            setErrors(errors);
                        }
                    }
                }
                else {
                    if (props === 'cgst') {
                        errors.cgst = "*CGST Percentage is required";
                        setErrors(errors);
                    }

                    if (props === 'sgst') {
                        errors.sgst = "*SGST Percentage is required";
                        setErrors(errors);
                    }

                    if (props === 'igst') {
                        errors.igst = "*IGST Percentage is required";
                        setErrors(errors);
                    }
                }
            }
            else {
                errors.taxable_value = "*Only decimals allowed";
                setErrors(errors);
            }
        }
        else {
            errors.taxable_value = "*Taxable Value is required";
            setErrors(errors);
        }
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});

        if (Object.keys(errors).length === 0) {
            setLoading({...loading, 0: true});
            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/purchase', payload, headers).then(response => {
                setLoading({...loading, 0: false});
                setMessage({...message, 0: response.message});
                
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = 
                        {
                            id: values.id,
                            ledger_id: values.ledger_id,
                            party: data_context.update_data['party'],
                            type: values.type,
                            tds: values.tds || null,
                            taxable_value: values.taxable_value,
                            cgst: values.cgst || null,
                            sgst: values.sgst || null,
                            igst: values.igst || null,
                            round_off: values.round_off || null,
                            total: values.total,
                            docs_url: values.docs_url
                        }
                        let data = data_context.view_data.table_rows;
                        let index = GetKey(data, payload);
                        payload = { ...payload, sno: data[index]['sno'] };
                        data = ({ ...data, [index]: payload });
                        const row_values = Object.values(data);

                        let view_data = data_context.view_data;
                        view_data = ({ ...view_data, table_rows: row_values });
                        props.handleBack(view_data);
                    }
                }
            });
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("/view_purchase");
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        const regex_decimal = /^[-+]?\d*\.?\d+$/;

        if (!values.id) {
            errors.id = "*Purchase ID is required";
        }

        if (!values.ledger_id) {
            errors.ledger_id = "*Ledger ID is required";
        }

        if(values.type === 'Service'){
            if(!values.tds){
                errors.tds = "*TDS is required";
            }
        }else{
            if(values.tds){
                errors.tds = "*TDS is not required";
            }
        }

        if (!values.taxable_value) {
            errors.taxable_value = "*Taxable Value is required";
        }
        else if (!regex_decimal.test(values.taxable_value)) {
            errors.taxable_value = "*Only decimals allowed";
        }

        if (values.cgst) {
            if (!regex_decimal.test(values.cgst)) {
                errors.cgst = "*Only decimals allowed";
            }
        }

        if (values.sgst) {
            if (!regex_decimal.test(values.sgst)) {
                errors.sgst = "*Only decimals allowed";
            }
        }

        if (values.igst) {
            if (!regex_decimal.test(values.igst)) {
                errors.igst = "*Only decimals allowed";
            }
        }

        if (values.round_off) {
            if (!regex_decimal.test(values.round_off)) {
                errors.round_off = "*Only decimals allowed";
            }
        }

        if (!values.total) {
            errors.total = "*Total is required";
        }
        else if (!regex_decimal.test(values.total)) {
            errors.total = "*Only decimals allowed";
        }

        if (!values.docs_url) {
            errors.docs_url = "*Copy of bill is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            ledger_id: values.ledger_id,
            type: values.type,
            tds: values.tds || null,
            taxable_value: values.taxable_value,
            cgst: values.cgst || null,
            sgst: values.sgst || null,
            igst: values.igst || null,
            round_off: values.round_off || null,
            total: values.total,
            docs_url: values.docs_url,
            fiscal: data_context['fiscal']
        };

        return data;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, handleBrowse, handleClear, viewPDF, handleUpload, 
            handleBack, handleCalculate, handleDialog, values, errors, message, loading, 
            types, progress, docs_errors, disable, dialog };

}

export default UpdatePurchaseForm;