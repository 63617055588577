import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import EncodeBase64 from '../Parent/EncodeBase64';
import Post from '../HTTPRequest/Post';
import CreateFileName from '../Parent/CreateFileName';
import FormatDate from '../Parent/FormatDate';
import no_image from '../Images/no_image.png'
import CreateBlob from '../Parent/CreateBlob';
import Get from '../HTTPRequest/Get';
import Upload from '../HTTPRequest/Upload';
import GetIndex from '../Parent/GetIndex';
import { toast } from 'react-toastify';

const AddStaffForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const states = ['Andaman and Nicobar', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh', 'Chhattisgarh', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Delhi',
        'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Lakshadweep', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya',
        'Mizoram', 'Nagaland', 'Orissa', 'Puducherry', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'];
    const bloodgroups = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];
    const genders = ['Male', 'Female', 'Others'];
    const [designation_id, setDesignationID] = useState([]);
    const [designation, setDesignation] = useState([]);
    const statuses = ['Working', 'Resigned', 'Terminated', 'Disabled'];
    const initialValues =
    {
        name: '',
        address1: '',
        address2: '',
        district: '',
        state: states[27],
        pincode: '',
        bloodgroup: bloodgroups[0],
        gender: genders[0],
        dob: '',
        doj: '',
        dor: '',
        qualification: '',
        designation_id: '',
        designation: '',
        basic_pay: '',
        hra: '',
        earned_increment: '',
        ta: '',
        performance_allowance: '',
        salary: '',
        status: statuses[0],
        phone: '',
        alternative: '',
        email: '',
        father: '',
        mother: '',
        account: '',
        uan: '',
        esi: '',
        image_url: '',
        pdf_url: ''
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [image_errors, setImageErrors] = useState();
    const [pdf_errors, setPDFErrors] = useState();
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false, 3: false, 4: false });
    let placeholder_image = no_image;
    const [image, setImage] = useState(placeholder_image);
    const [pdf, setPDF] = useState();
    const [progress, setProgress] = useState({ 0: 0, 1: 0 });

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=designation';
            const headers = { 
                                'Accept': 'application/json', 
                                'Content-Type': 'application/json;charset=UTF-8', 
                                'Authorization': `Bearer ${user_context.user_data[1]}` 
                            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {               
                    let designation_id = response.response_payload[0]['id'] || [];
                    let designation = response.response_payload[0]['name'] || [];
                    setDesignationID(response.response_payload[0]['id'] || []);
                    setDesignation(response.response_payload[0]['name'] || []);
                    setValues({ ...values, designation_id: designation_id[0], designation: designation[0] });                    
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
        if (e.target.name === 'designation') {
            setValues({
                ...values, designation_id: designation_id[GetIndex(designation, e.target.value)],
                designation: designation[GetIndex(designation, e.target.value)]
            });
        }
    }

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async e => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'image_file') {
                setProgress({ ...progress, 0: 0 });

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setImage(base64);
                    }

                    setValues({ ...values, image_url: e.target.files[0].name });
                    setImageErrors();

                }).catch(() => {
                    setImageErrors();
                    toast.error("File conversion error");
                });
            }

            if (e.target.id === 'pdf_file') {
                setProgress({ ...progress, 1: 0 });

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setPDF(base64);
                    }

                    setValues({ ...values, pdf_url: e.target.files[0].name });
                    setPDFErrors();

                }).catch(() => {
                    setPDFErrors();
                    toast.error("File conversion error");
                });
            }
        }
    }

    // UPLOADING IMAGES AND DOCS...

    const handleUpload = async (prop) => {

        if (prop === 'image_upload') {            
            setProgress({ ...progress, 0: 0 });
            let errors = validateUpload(prop);

            if (Object.keys(errors).length === 0) {
                setLoading({ ...loading, 0: true });
                const options = {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress({ ...progress, 0: percent });
                    }
                }

                if (image !== null) {
                    let file_name = CreateFileName(values.image_url);
                    const payload = { file_name: file_name, encoded_string: image };

                    await Upload(user_context.url + '/upload', payload, options).then(response => {                           
                        if(response.status === 1) {
                            toast.success(response?.message);
                            setValues({ ...values, image_url: file_name });
                        } else {
                            toast.error(response?.message);
                            setLoading({ ...loading, 0: false });
                        }                            
                    });
                }
            }            
        }
        else if (prop === 'pdf_upload') {
            setProgress({ ...progress, 1: 0 });
            let errors = validateUpload(prop);

            if (Object.keys(errors).length === 0) {
                setLoading({ ...loading, 1: true });
                const options = {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress({ ...progress, 1: percent });
                    }
                }

                if (pdf !== null) {
                    let file_name = CreateFileName(values.pdf_url);
                    const payload = { file_name: file_name, encoded_string: pdf };

                    await Upload(user_context.url + '/upload', payload, options).then(response => {
                        if(response.status === 1) {
                            toast.success(response?.message);
                            setValues({ ...values, pdf_url: file_name });
                        } else {
                            toast.error(response?.message);
                            setLoading({ ...loading, 1: false });
                        } 
                    });
                }
            }            
        }

    }

    // VALIDATING UPLOADS...

    const validateUpload = (prop) => {
        let errors = '';

        if (prop === 'image_upload') {
            if (!values.image_url) {
                errors = "*No image selected";
                setImageErrors(errors);
            }
            else {
                let regex = /(?:\.([^.]+))?$/;
                let ext = regex.exec(values.image_url)[1];

                if (ext !== "jpg" && ext !== "jpeg" && ext !== "JPG" && ext !== "JPEG") {
                    errors = "*Image is not a valid JPG file";
                    setImageErrors(errors);
                }
            }
        }

        if (prop === 'pdf_upload') {
            if (!values.pdf_url) {
                errors = "*No pdf selected";
                setPDFErrors(errors);
            }
            else {
                let regex = /(?:\.([^.]+))?$/;
                let ext = regex.exec(values.pdf_url)[1];

                if (ext !== "pdf" && ext !== "PDF") {
                    errors = "*Document is not a valid PDF file";
                    setPDFErrors(errors);
                }
            }
        }


        return errors;
    }

    // CLEARING FIELDS...

    const handleClear = (prop) => {
        if (prop === 'image_clear') {
            setImage(placeholder_image);
            setValues({ ...values, image_url: '' });
            setImageErrors();            
            setProgress({ ...progress, 0: 0 });
        }

        if (prop === 'pdf_clear') {
            setPDF();
            setValues({ ...values, pdf_url: '' });
            setPDFErrors();           
            setProgress({ ...progress, 1: 0 });
        }
    }

    // VIEWING PDF...

    const viewPDF = () => {
        setLoading({ ...loading, 4: true });
        let errors = validateUpload('pdf_upload');

        if (Object.keys(errors).length === 0) {
            if (pdf !== undefined) {
                var blob = CreateBlob(pdf.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 4: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 4: false });
                }
            }
        } else {
            setLoading({ ...loading, 4: false });
        }
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 3: true });

            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Post(user_context.url + '/staff', payload, headers).then(response => {
                setLoading({ ...loading, 3: false });                
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                    toast.success(response?.message);                        
                } else {
                    toast.error(response?.message);
                }
            });
        } else {
            toast.error("Validation failed. Page up to check for errors");
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_mail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
        let regex_number = /^[0-9]*$/;
        const regex_decimal = /^[-+]?\d*\.?\d+$/;

        if (!values.name) {
            errors.name = "*Name is required";
        }

        if (!values.address2) {
            errors.address2 = "*Address Line 2 is required";
        }

        if (!values.district) {
            errors.district = "*District is required";
        }

        if (!values.district) {
            errors.district = "*District is required";
        }

        if (!values.pincode) {
            errors.pincode = "*Pincode is required";
        }

        if (!values.dob) {
            errors.dob = "*Date of Birth is required";
        }

        if (!values.doj) {
            errors.doj = "*Date of Joining is required";
        }

        if (values.dor && values.status === 'Working') {
            errors.dor = "*Date of Resignation is not allowed";
        }

        if (!values.dor && values.status !== 'Working') {
            errors.dor = "*Date of Resignation is required";
        }

        if (values.dob && values.doj) {
            if (values.dob > values.doj) {
                errors.doj = "*DOJ cannot be less than DOB";
            }
        }

        if (values.dob && values.dor) {
            if (values.dob > values.dor) {
                errors.dor = "*DOR cannot be less than DOB";
            }
        }

        if (values.doj && values.dor) {
            if (values.doj > values.dor) {
                errors.dor = "*DOR cannot be less than DOJ";
            }
        }

        if (!values.qualification) {
            errors.qualification = "*Qualification is required";
        }

        if (!values.designation) {
            errors.designation = "*Designation is required";
        }

        if (!values.basic_pay) {
            errors.basic_pay = "*Basic Pay is required";
        }
        else if (!regex_decimal.test(values.salary)) {
            errors.basic_pay = "*Only decimal allowed";
        }

        if (!values.hra) {
            errors.hra = "*HRA is required";
        }
        else if (!regex_decimal.test(values.salary)) {
            errors.hra = "*Only decimal allowed";
        }

        if (!values.earned_increment) {
            errors.earned_increment = "*Earned Increment is required";
        }
        else if (!regex_decimal.test(values.salary)) {
            errors.earned_increment = "*Only decimal allowed";
        }

        if (!values.ta) {
            errors.ta = "*TA is required";
        }
        else if (!regex_decimal.test(values.salary)) {
            errors.ta = "*Only decimal allowed";
        }

        if (!values.performance_allowance) {
            errors.performance_allowance = "*Performance Allowance is required";
        }
        else if (!regex_decimal.test(values.salary)) {
            errors.performance_allowance = "*Only decimal allowed";
        }

        if (!values.salary) {
            errors.salary = "*Salary is required";
        }
        else if (!regex_decimal.test(values.salary)) {
            errors.salary = "*Only decimal allowed";
        }

        if (!values.phone) {
            errors.phone = "*Phone Number is required";
        }
        else if (!regex_number.test(values.phone)) {
            errors.phone = "*Only digits allowed";
        }

        if (!values.email) {
            errors.email = "*Email is required";
        }
        else if (!regex_mail.test(values.email)) {
            errors.email = "*Not a valid email id";
        }

        if (!values.father) {
            errors.father = "*Father is required";
        }

        if (!values.mother) {
            errors.mother = "*Mother is required";
        }

        if (!values.account) {
            errors.account = "*Account No is required";
        }

        if (!values.uan) {
            errors.uan = "*UAN is required";
        }

        if (!values.esi) {
            errors.esi = "*ESI is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            name: values.name,
            address1: values.address1,
            address2: values.address2,
            district: values.district,
            state: values.state,
            pincode: values.pincode,
            phone: values.phone,
            alt: values.alternative,
            email: values.email,
            father: values.father,
            mother: values.mother,
            account: values.account,
            uan: values.uan,
            esi: values.esi,
            qualification: values.qualification,
            gender: values.gender,
            bloodgroup: values.bloodgroup,
            dob: FormatDate(values.dob),
            doj: FormatDate(values.doj),
            dor: FormatDate(values.dor) || null,
            basic_pay: values.basic_pay,
            hra: values.hra,
            earned_increment: values.earned_increment,
            ta: values.ta,
            performance_allowance: values.performance_allowance,
            salary: values.salary,
            designation: values.designation_id,
            status: values.status,
            username: values.username,
            password: values.password,
            photo_url: values.image_url,
            docs_url: values.pdf_url
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleBrowse, handleClear, 
        handleUpload, viewPDF, setValues, values, errors,
        loading, states, bloodgroups, genders, designation, 
        statuses, image, placeholder_image, image_errors, pdf_errors, 
        progress
    };
}

export default AddStaffForm;
