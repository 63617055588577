import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import GetIndex from '../Parent/GetIndex';
import Get from '../HTTPRequest/Get';
import Post from '../HTTPRequest/Post';

const AddLicenseForm = () => {
    // DECLARING STATES...    

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [contract, setContract] = useState([]);
    const [client, setClient] = useState([]);
    const [agreement, setAgreement] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const initialValues =
    {
        contract_id: '',
        contract_no: '',
        client_id: '',
        client: '',
        agreement_id: '',
        agreement: '',
        license: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({ 0: '' });
    const [loading, setLoading] = useState({ 0: false });

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=license';
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let contract = response.response_payload[0] || [];
                        let client = response.response_payload[1] || [];
                        let agreement = response.response_payload[2] || [];

                        setContract(contract);
                        setClient(client);
                        setAgreement(agreement);
                        setValues({
                            ...values, contract_id: contract.id[0],
                            contract_no: contract.contract_no[0],
                            client_id: client.id[0],
                            client: client.address[0]
                        });
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // SETTING UP AGREEMENT ID AS PER CLIENT ID...

    useEffect(() => {
        if (agreement.length !== 0) { 
            let filtered_array = Object.fromEntries(
                Object.entries(agreement).map(([key, array]) => 
                [key, array.filter((_, index) => agreement.client_id[index] === values.client_id)])
            );
            setFiltered(filtered_array); 
            if(filtered_array.id.length !== 0){        
                setValues({...values, agreement_id: filtered_array.id[0], agreement: filtered_array.agreement_no[0]})  
            }       
        }
    //eslint-disable-next-line
    }, [values.client_id]);
      

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });

        if (e.target.name === 'contract_id') {
            setValues({ ...values, contract_id: value, contract_no: contract.contract_no[GetIndex(contract.id, e.target.value)] });
        }

        if (e.target.name === 'client_id') {
            setValues({ ...values, client_id: value, client: client.address[GetIndex(client.id, e.target.value)] });
        }

        if (e.target.name === 'agreement_id') {
            setValues({ ...values, agreement_id: value, agreement: agreement.agreement_no[GetIndex(agreement.id, e.target.value)] });
        }
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateForm(values);
        setErrors(errors);
        setMessage({ ...message, 0: '' });

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Post(user_context.url + '/license', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage({ 0: response.message });
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_number = /^[0-9]*$/;

        if (!values.contract_id) {
            errors.contract_id = "*Contract ID is required";
        }

        if (!values.client_id) {
            errors.client_id = "*Client ID is required";
        }

        if (!values.agreement_id) {
            errors.agreement_id = "*Agreement ID is required";
        }

        if (!values.license) {
            errors.license = "*License is required";
        }
        else if (!regex_number.test(values.license)) {
            errors.license = "*Only digits allowed";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            contract_id: values.contract_id,
            client_id: values.client_id,
            agreement_id: values.agreement_id,
            license: values.license
        };

        return data;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, values, errors, message, loading, contract, client, filtered};

}

export default AddLicenseForm;