import { useState, useEffect, useContext } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import { useHistory } from 'react-router-dom';
import Get from '../HTTPRequest/Get';
import GetIndex from '../Parent/GetIndex';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';

const UpdateStockForm = (props) => {
    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const types =
        [
            'Ledger Entry',
            'Opening Stock',
            'Stock Adjustment',
            'Self Consumption',
            'Out for Repair'
        ];
    const initialValues =
    {
        id: data_context.update_data['id'],
        ledger_id: data_context.update_data['ledger_id'] || '',
        type: data_context.update_data['type'] ? types[GetIndex(types, data_context.update_data['type'])] : types[0],
        item_id: data_context.update_data['item_id'],
        name: '',
        model: '',
        manufacturer: '',
        rate: data_context.update_data['rate'],
        qty_in: data_context.update_data['qty_in'] || '',
        qty_out: data_context.update_data['qty_out'] || ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState({ 0: false });
    const [disable] = useState(data_context.update_data['id'] ? true : false);
    const [item, setItem] = useState([]);
    const history = useHistory();

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=item';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        const id = response.response_payload[0]['id'];
                        const name = response.response_payload[0]['name'];
                        const manufacturer = response.response_payload[0]['manufacturer'];
                        const model = response.response_payload[0]['model'];

                        setValues({
                            ...values,
                            name: name[GetIndex(name, data_context.update_data['name'])],
                            model: model[GetIndex(model, data_context.update_data['model'])],
                            manufacturer: manufacturer[GetIndex(manufacturer, data_context.update_data['manufacturer'])]
                        });

                        const newItems = id.map((_, i) => ({
                            id: id[i],
                            name: name[i],
                            manufacturer: manufacturer[i],
                            model: model[i]
                        }));
                        
                        setItem(newItems);                       
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // ON SELECTION...

    const handleSelect = (item) => {
        setValues(
            {
                ...values,
                item_id: item.id,
                name: item.name,
                manufacturer: item.manufacturer,
                model: item.model
            });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });
            let payload = createPayload(values);
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Put(user_context.url + '/stock', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage({ ...message, 0: response.message });

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload =
                        {
                            id: values.id,
                            ledger_id: values.ledger_id,
                            party: data_context.update_data['party'],
                            type: values.type,
                            item_id: values.item_id,
                            name: values.name,
                            model: values.model,
                            manufacturer: values.manufacturer,
                            rate: values.rate,
                            qty_in: values.qty_in,
                            qty_out: values.qty_out
                        }
                        let data = data_context.view_data.table_rows;
                        let index = GetKey(data, payload);
                        payload = { ...payload, sno: data[index]['sno'] };
                        data = ({ ...data, [index]: payload });
                        const row_values = Object.values(data);

                        let view_data = data_context.view_data;
                        view_data = ({ ...view_data, table_rows: row_values });
                        props.handleBack(view_data);
                    }
                }
            });
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_stock");
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        let regex_decimal = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
        let regex_number = /^[0-9]*$/;

        if (!values.id) {
            errors.id = "*ID is required";
        }

        if (!values.item_id) {
            errors.item_id = "*Item ID is required";
        }

        if (!values.rate) {
            errors.rate = "*Rate is required";
        }
        else if (!regex_decimal.test(values.rate)) {
            errors.rate = "*Only decimals allowed";
        }

        if (values.qty_in && values.qty_out) {
            errors.qty_in = "*Both Qty In and Out are not allowed";
            errors.qty_out = "*Both Qty In and Out are not allowed";
        }

        if (!values.qty_in && !values.qty_out) {
            errors.qty_in = "*Either Qty In or Out required";
            errors.qty_out = "*Either Qty In or Out required";
        }

        if (values.qty_in) {
            if (!regex_number.test(values.qty_in)) {
                errors.qty_in = "*Only digits allowed";
            }
        }

        if (values.qty_out) {
            if (!regex_number.test(values.qty_out)) {
                errors.qty_out = "*Only digits allowed";
            }
        }

        if(values.type === 'Ledger Entry'){
            if(!values.ledger_id){
                errors.type = "*No Ledger ID found";
            }
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            ledger_id: values.ledger_id || null,
            type: values.type,
            item_id: values.item_id,
            rate: values.rate,
            qty_in: values.qty_in || null,
            qty_out: values.qty_out || null,
            fiscal: data_context['fiscal']
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleSelect,
        handleBack, values, errors, message, loading,
        types, disable, item
    };


}

export default UpdateStockForm;