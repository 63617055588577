import { useState, useContext, useEffect } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import { useHistory } from 'react-router-dom';
import Put from '../HTTPRequest/Put';
import GetKey from '../Parent/GetKey';
import Get from '../HTTPRequest/Get';
import { toast } from 'react-toastify';

const UpdateQuotationItemsForm = (props) => {

    // DECLARING STATES...

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    let initialValues =
    {
        id: data_context.update_data['id'],
        quotation_id: data_context.update_data['quotation_id'],
        item_id: data_context.update_data['item_id'],
        quantity: data_context.update_data['quantity'],
        rate: data_context.update_data['rate']
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [item, setItem] = useState([]);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=item';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) &&(response.response_payload['result'] === 'success')) {                    
                        
                    let data = response.response_payload[0] || [];
                    const newItems = data.id.map((_, i) => ({
                        id: data.id[i],
                        name: data.name[i],
                        manufacturer: data.manufacturer[i],
                        model: data.model[i]
                    }));  
                    
                    setItem(newItems);                                         
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }


    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Put(user_context.url + '/quotation_item', payload, headers).then(response => {
                setLoading(false); 
                
                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {
                    toast.success(response?.message);  

                    const items = item.find(item => item.id === values.item_id);

                    let payload = { 
                        id: values.id,
                        quotation_id: values.quotation_id,
                        item_id: values.item_id,
                        name: items.name,
                        manufacturer: items.manufacturer,
                        model: items.model,
                        quantity: values.quantity,
                        rate: values.rate
                    };

                    let data = data_context.view_data.table_rows;
                    let index = GetKey(data, payload); 

                    payload = { ...payload, sno: data[index]['sno'] };

                    data = ({ ...data, [index]: payload });
                    const row_values = Object.values(data);
                    let view_data = data_context.view_data;
                    view_data = ({ ...view_data, table_rows: row_values });
                    props.handleBack(view_data);

                } else {
                    toast.error(response?.message);
                }
               
            });
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_quotation_items");
    }

    // ON SELECTION...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, item_id: item.id });
        } else {
            setValues({ ...values, item_id: '' });
        }       
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};
        const regex_number = /^[0-9]*$/;
        const regex_decimal = /^[-+]?\d*\.?\d+$/;

        if (!values.id) {
            errors.id = "*ID is required";
        }

        if (!values.quotation_id) {
            errors.quotation_id = "*Quotation ID is required";
        }

        if (!values.item_id) {
            errors.item_id = "*Item is required";
        }

        if (!values.quantity) {
            errors.quantity = "*Quantity is required";
        } else if (!regex_number.test(values.quantity)) {
            errors.quantity = "*Only digits allowed";
        }

        if (!values.rate) {
            errors.rate = "*Rate is required";
        } else if (!regex_decimal.test(values.rate)) {
            errors.rate = "*Only decimals allowed";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            id: values.id,
            quotation_id: values.quotation_id,
            item_id: values.item_id,
            quantity: values.quantity,
            rate: values.rate
        };

        return data;
    }

    // RETURNING VALUES...

    return { 
        handleChange, 
        handleSubmit, 
        handleBack, 
        handleSelect, 
        values, 
        errors, 
        loading, 
        item 
    };

}

export default UpdateQuotationItemsForm;