import { useState, useEffect, useContext } from 'react';
import DataContext from '../Parent/DataContext';
import UserContext from '../Parent/UserContext';
import Get from '../HTTPRequest/Get';
import Delete from '../HTTPRequest/Delete';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-data-grid';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import letter_head from '../Images/letter_head.png';

const ViewProposalForm = (props) => {
    // DECLARING STATES...   

    const data_context = useContext(DataContext);
    const user_context = useContext(UserContext);
    const [columns] = useState(['All', ...user_context.user_data[3]['proposal']]);
    const [values, setValues] = useState(
        {
            key: data_context.view_data['key'] || '',
            selected_column: data_context.view_data['selected_column'] || 'All'
        }
    );
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [table_rows, setTableRows] = useState(data_context.view_data['table_rows'] || []);
    const [selected_row, setSelectedRow] = useState
        (
            data_context.view_data['selected_row'] !== undefined &&
                data_context.view_data['selected_row'] !== null
                ? data_context.view_data['selected_row'] : -1
        );
    const history = useHistory();
    const [dialog, setDialog] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/view_proposal');
    const [client, setClient] = useState([]);

    // SETTING UP DETAILS...

    useEffect(() => {
        const fetchClient = async () => {
            let endpoint = user_context.url + '/dropdown?route=client_details';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let data = response.response_payload[0] || [];
                        const newItems = data.id.map((_, i) => ({
                            id: data.id[i],
                            name: data.address[i],
                            institution: data.institution[i]
                        }));

                        setClient(newItems);
                    }
                }
            });
        }

        fetchClient();

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        setTableRows([]);
        setSelectedRow();
        setLoading({ ...loading, 0: true });

        let endpoint = '';
        values.key.length === 0
            ? endpoint = user_context.url + '/proposals?fiscal=' + data_context['fiscal']
            : endpoint = user_context.url + '/proposal?filter_key=' + values.key +
            '&field=' + values.selected_column + '&fiscal=' + data_context['fiscal'];
        const headers =
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${user_context.user_data[1]}`
        };

        await Get(endpoint, headers).then(response => {
            setLoading({ ...loading, 0: false });
            if ((response.status === 1) && (response.response_payload['result'] === 'success')) {
                toast.success(response?.message);
                let payload = response.response_payload;
                let data_length = (Object.keys(payload).length) - 1;

                let data = ([]);
                for (let i = 0; i < data_length; i++) {
                    let date = CreateDate(payload[i]['date']);
                    date = FormatLocalDate(date);                  
                    payload[i]['date'] = date;
                    data[i] = payload[i];
                }
                setTableRows(data);
            } else {
                toast.error(response?.message);
            }
        });
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // HANDLER FOR UPDATE BUTTON...

    const handleUpdate = () => {
        if (selected_row > -1) {
            let view_data =
            {
                key: values.key,
                selected_column: values.selected_column,
                selected_row: selected_row,
                table_rows: table_rows
            };
            props.handleBack(view_data);
            props.handleUpdate(table_rows[selected_row]);
            history.push("/update_proposal");
        }
        else {
            toast.error("No row selected");
        }
    }

    // HANDLER FOR DELETE BUTTON...

    const handleDelete = () => {
        setDialog(true);
    }

    // HANDLER FOR DIALOG BUTTON...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // HANDLER FOR CONFIRM DELETE...

    const handleConfirm = () => {
        setDialog(!dialog);
        setConfirm(true);
    }

    // PERFORMING DELETE ACTION...

    useEffect(() => {
        const deleteRow = async () => {
            if (confirm) {
                setConfirm(false);

                if (selected_row > -1) {
                    setLoading({ ...loading, 1: true });
                    const headers =
                    {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `Bearer ${user_context.user_data[1]}`
                    };
                    await Delete(user_context.url + '/proposal?id=' + table_rows[selected_row]['id'], headers).then(response => {
                        setLoading({ ...loading, 1: false });
                        if (response?.status === 1) {
                            toast.success(response?.message);
                            const new_table_rows = table_rows.filter(item => item !== table_rows[selected_row]);
                            setSelectedRow(-1);
                            setTableRows(new_table_rows);
                        } else {
                            toast.error(response?.message);
                        }
                    });
                }
                else {
                    toast.error("No row selected");
                }
            }
        }

        deleteRow();
        //eslint-disable-next-line    
    }, [confirm]);

    // DOWNLOADING PROPOSAL PDF...

    const handleDownload = async () => {
        if (selected_row > -1) {
            setLoading({ ...loading, 2: true });
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            let endpoint = user_context.url + '/proposal?route=download&id=' + table_rows[selected_row]['id'];

            await Get(endpoint, headers).then(response => {             
                setLoading({ ...loading, 0: false });
                if (response.status === 1 && response.response_payload['result'] === 'success') {
                    toast.success(response?.message);
                    let payload = response.response_payload;
                    let data_length = (Object.keys(payload).length) - 2;

                    let data = ([]);
                    for (let i = 0; i < data_length; i++) {
                        data[i] = payload[i];
                    }
                    
                    generatePDF(data);
                } else {
                    toast.error(response?.message);
                }
            });
        }
        else {
            toast.error("No row selected");
        }
    }

    // GENERATING PDF...

    const generatePDF = (items) => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'A4',
        });
        
        const imgWidth = 210; 
        const imgHeight = (imgWidth * 40) / 190; 
        doc.addImage(letter_head, 'PNG', 0, 10, imgWidth, imgHeight)

        doc.setFontSize(11);
        let yPosition = 20;  
        doc.text('Ref No: ' + table_rows[selected_row]['ref_no'], 10, yPosition + 45);
        doc.text('Date: ' + table_rows[selected_row]['date'], 160, yPosition + 45);

        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");

        const titleText = 'PROPOSAL FOR ' + (table_rows[selected_row]['proposal_type']).toUpperCase();
        const textWidth = doc.getStringUnitWidth(titleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const xPosition = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(titleText, xPosition, yPosition + 60);

        const lineHeight = 0.1;
        const lineY = yPosition + 61;
        doc.setLineWidth(lineHeight);
        doc.line(xPosition, lineY, xPosition + textWidth, lineY);

        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.text('Party:', 15, yPosition + 70);
        doc.setFontSize(11);
        doc.setFont("helvetica", "normal");
        const client_details = table_rows[selected_row]['client_id'] ? client.find(data => data.id.includes(table_rows[selected_row]['client_id'])) : null;
        doc.text('' + client_details?.institution + ', ' + client_details?.name, 17, yPosition + 75);

        yPosition = yPosition + 75;
        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.text('Note:', 115, yPosition + 10);
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.text('1) This proposal is valid for 7 days only.', 117, yPosition + 15);
        doc.text('2) All the rates are inclusive of GST.', 117, yPosition + 20);
       
        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.text('Account Details:', 15, yPosition + 10);
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.text('Beneficiary: Softech Smart Solutions', 17, yPosition + 15);
        doc.text('Account No: 1615020001936', 17, yPosition + 20);
        doc.text('Bank: The Federal Bank Limited', 17, yPosition + 25);
        doc.text('Branch: Jalandhar', 17, yPosition + 30);
        doc.text('IFSC: FDRL0001615', 17, yPosition + 35);

        const headers = ['SNo', 'Name', 'Manufacturer', 'Model', 'Unit', 'Quantity', 'Rate', 'Amount'];   
        const length = items?.length;

        let table_data = [];
        let totalAmount = 0;        
        
        for (let i = 0; i < length; i++) {
            const rate = parseFloat(items[i]['rate']).toFixed(2);
            const quantity = parseInt(items[i]['quantity']);
            let amount = parseFloat((rate * quantity).toFixed(2)) || 0;
            let amountText = amount.toLocaleString(undefined, { minimumFractionDigits: 2 });              
            
            table_data.push({
                sno: i + 1,
                item: items[i]['item'], 
                manufacturer: items[i]['manufacturer'],
                model: items[i]['model'],
                unit: items[i]['unit'],
                quantity: quantity,
                rate: rate,
                amount: amountText
            });

            totalAmount = totalAmount + parseFloat(amount);
        }

        const totalRow = {
            sno: { content: 'Total', colSpan: 7, styles: { halign: 'right' } },
            amount: totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })
        };
        table_data.push(totalRow);

        doc.autoTable({
            startY: yPosition + 40,
            head: [headers],
            body: table_data.map(row => Object.values(row)),
            rowPageBreak: 'avoid',
            margin: {bottom: 20},
            styles: {
                cellPadding: 2,
                cellWidth: 'auto',
                valign: 'middle',
                halign: 'center', 
                lineWidth: 0.1,
                lineColor: [0, 0, 0], 
            },  
            headStyles: {
                fillColor: [48, 51, 78],
                textColor: [255, 255, 255], 
            },
            bodyStyles: {
                fillColor: [255, 255, 255], 
                textColor: [0, 0, 0]
            }
        });    

        doc.save(`${client_details?.name}.pdf`);
    };

    // RETURNING VALUES...

    return {
        handleSubmit,
        handleChange,
        onRowClick,
        handleDelete,
        handleUpdate,
        handleDialog,
        handleConfirm,
        rowRenderer,
        handleDownload,
        columns,
        values,
        loading,
        table_rows,
        dialog,
        selected_row,
        permissions
    };
}

export default ViewProposalForm;