import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import { Row } from 'react-data-grid';
import FormatDate from '../Parent/FormatDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import CreateDate from '../Parent/CreateDate';
import Get from '../HTTPRequest/Get';

const ExpenseSheetForm = () => {
    const user_context = useContext(UserContext);
    const initialValues = { from: '', to: '' };
    const [values, setValues] = useState(initialValues);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false });
    const [table_rows, setTableRows] = useState([]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();
        setMessage();
        setTableRows([]);

        if (values.from && values.to) {
            setLoading({ ...loading, 0: true });
            let endpoint = user_context.url + '/expense?&field=' + FormatDate(values.from) + '&filter_key=' + FormatDate(values.to) + '&route=expense_sheet';
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Get(endpoint, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage(response.message);

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;

                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            let date = CreateDate(payload[i]['date']);
                            date = FormatLocalDate(date);
                            payload[i]['date'] = date;
                            data[i] = payload[i];
                        }

                        setTableRows(data);
                    }
                }
            });
        }
        else {
            setMessage("Mandatory fields are empty");
        }
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {

        return (
            <div>
                <Row {...props} />
            </div>

        );
    }

    // RETURNING VALUES...

    return { handleSubmit, handleChange, rowRenderer, message, values, loading, table_rows };

}

export default ExpenseSheetForm;