import React from "react";
import AddServiceInvoiceForm from "./AddServiceInvoiceForm";
import CustomAutocomplete from "../Components/CustomAutoComplete";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { ToastContainer } from 'react-toastify';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const AddServiceInvoice = () => {

    // DECLARING STATES...

    const { ...destruct } = AddServiceInvoiceForm();

    // MAIN FUNCTION...
    return (
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR INVOICE DETAILS... */}

                <form className="location-form" onSubmit={destruct.handleSubmit}>
                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;SERVICE&nbsp;INVOICE</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Type:</label>                    
                        <select className="select" name="invoice_type" value={destruct.values.invoice_type} onChange={destruct.handleChange} disabled={destruct.disable}>
                            {destruct.invoice_types.map((item, i) => (<option key={i}>{item}</option>))}
                        </select> 
                    </div>  

                    <div className="location-formGroup">
                        <label className="label" style={{marginRight: '5px'}}>Party:</label>                        
                        <CustomAutocomplete
                            data={destruct.item}
                            placeholder="Search Party..."
                            selectValue={destruct.handleSelect}
                            selected_value={destruct.values.party_id}
                        />
                        {destruct.errors.party_id && <p className="error">{destruct.errors.party_id}</p>}
                    </div>   

                    <div className="location-formGroup">
                        <label className="label" style={{marginRight: '5px'}}>Invoice:</label>      
                        <div className="tax-formGroup">                   
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={destruct.invoice}
                                getOptionLabel={(option) => option ? option.vch_no : ''} 
                                isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Search Invoice..." />                                       
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                            <br />
                                            <span>{`Invoice No: ${option.vch_no}`}</span>
                                        </div>
                                    </li>
                                )}
                                value={destruct.invoice.find(option => option.id === destruct.values.ledger_id) || null}
                                onChange={(event, value) => destruct.selectInvoice(value)}
                                sx={{
                                    width: '90%',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    "& .MuiInputBase-root": {
                                        fontFamily: 'sans-serif',
                                        height: 45,
                                        fontSize: '15px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignContent: 'center'
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: 0
                                    },
                                    "&:hover fieldset": {
                                        border: 0
                                    },
                                    backgroundColor: '#FFFFFF',
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        border: '1px solid #9ecaed',
                                        boxShadow: '0 0 10px #9ecaed',
                                    },
                                }}
                            />
                            <button className="tax-button" type="button" onClick={()=>destruct.handleAttachment(destruct.values.docs_url)} disabled={destruct.loading[0]}><AttachFileOutlinedIcon/></button>
                        </div>
                        {destruct.errors.ledger_id && <p className="error">{destruct.errors.ledger_id}</p>}                             
                    </div>   

                    <div className="location-formGroup">
                        <label className="label" style={{marginRight: '5px'}}>Agreement:</label> 
                        <div className="tax-formGroup">                        
                            <CustomAutocomplete
                                data={destruct.agreement_details}
                                placeholder="Search Agreement..."
                                selectValue={destruct.selectAgreement}
                                selected_value={destruct.values.agreement_id}
                            />
                             <button className="tax-button" type="button" onClick={()=>destruct.handleAttachment(destruct.values.agreement_url)} disabled={destruct.loading[1]}><AttachFileOutlinedIcon/></button>
                        </div>
                        {destruct.errors.agreement_id && <p className="error">{destruct.errors.agreement_id}</p>}
                    </div>   

                    <div className="location-formGroup">
                        <label className="label">Month:</label>                    
                        <select className="select" name="month" value={destruct.values.month} onChange={destruct.handleChange}>
                            {destruct.months.map((item, i) => (<option key={i}>{item}</option>))}
                        </select> 
                    </div> 
                    <div className="location-formGroup">
                        <div className="progress-container" style={{width: '90%'}}><progress className="progress" value={destruct.progress[0]} max="100" /></div>
                        {destruct.errors[0] && <p className="error">{destruct.errors[0]}</p>} 
                    </div>        
                    <button
                        className="button"
                        type="button"
                        disabled={destruct.loading[2]}
                        onClick={destruct.handleSubmit}
                    ><SaveOutlinedIcon />&nbsp;Save
                    </button>

                </form>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default AddServiceInvoice;