import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Post from '../HTTPRequest/Post';
import GetIndex from '../Parent/GetIndex';
import FormatDate from '../Parent/FormatDate';
import Get from '../HTTPRequest/Get';
import EncodeBase64 from '../Parent/EncodeBase64';
import CreateBlob from '../Parent/CreateBlob';
import Upload from '../HTTPRequest/Upload';
import CreateFileName from '../Parent/CreateFileName';

const AddTicketForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [client, setClient] = useState([]);
    const [item, setItem] = useState([]);
    const [type, setType] = useState([]);
    const [category, setCategory] = useState([]);
    const statuses = ['Pending', 'Closed'];
    const initialValues =
    {
        date: '',
        client_id: '',
        client: '',
        category_id: '',
        category: '',
        type: '',
        status: statuses[0],
        comment: '',
        staff_head: '',
        closing_date: '',
        docs_url: ''
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({ 0: '', 1: '' });
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [progress, setProgress] = useState(0);
    const [docs_errors, setDocsErrors] = useState();
    const [docs, setDocs] = useState();
    const [disable, setDisable] = useState({ 0: true, 1: true });
    const [dialog, setDialog] = useState(false);

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=ticket';
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let client = response.response_payload[0] || [];
                        let type = response.response_payload[1]['name'] || [];
                        let category = response.response_payload[2] || [];

                        setClient(client);
                        setType(type);
                        setCategory(category);

                        const newItems = client.id.map((_, i) => ({
                            id: client.id[i],
                            name: client.address[i]
                        }));
                    
                        setItem(newItems);

                        setValues({
                            ...values, category_id: category.id[0], category: category.name[0], type: type[0]
                        });
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });

        if (e.target.name === 'category') {
            setValues({ ...values, category_id: category.id[GetIndex(category.name, value)], category: value });
        }
    }

    // HANDLER FOR UPLOAD DIALOG...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // REGISTERING STATUS CHANGE...

    useEffect(() => {
        setErrors({});

        if (values.status === 'Pending') {
            setDisable({ 0: true, 1: true });
        }

        if (values.status === 'Closed') {
            setDisable({ 0: false, 1: false });
        }

        //eslint-disable-next-line
    }, [values.status]);

    // ON SELECTION...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, client_id: item.id, client: item.name });
        }        
    }

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'pdf_file') {

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setDocs(base64);
                    }

                    setValues({ ...values, docs_url: e.target.files[0].name });
                    setDocsErrors();
                    setMessage({ ...message, 1: '' });

                }).catch(() => {
                    setDocsErrors();
                    setMessage({ ...message, 1: "File conversion error" });
                });
            }
        }
    }

    // VIEWING PDF...

    const viewPDF = () => {
        setLoading({ ...loading, 1: true });
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 1: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 1: false });
                }
            }
        } else {
            setLoading({ ...loading, 1: false });
        }
    }

    // VALIDATING UPLOADS...

    const validateDocs = () => {
        let errors = '';

        if (!values.docs_url) {
            errors = "*No pdf selected";
            setDocsErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.docs_url)[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors = "*Document is not a valid PDF file";
                setDocsErrors(errors);
            }
        }
        return errors;
    }

    // CLEARING FIELDS...

    const handleClear = () => {
        setDocs();
        setValues({ ...values, docs_url: '' });
        setDocsErrors();
        setMessage({ ...message, 1: '' });
        setProgress(0);
    }

    // UPLOADING DOCS...

    const handleUpload = async () => {
        if (message[1] === '') {
            setProgress(0);
            setMessage({ ...message, 1: '' });
            let errors = validateDocs();

            if (Object.keys(errors).length === 0) {
                setLoading({ ...loading, 2: true });
                const options = {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setProgress(percent);
                    }
                }

                if (docs !== null) {
                    let file_name = CreateFileName(values.docs_url);
                    const payload = { file_name: file_name, encoded_string: docs };

                    await Upload(user_context.url + '/upload', payload, options).then(response => {
                        setMessage({ ...message, 1: response.message });
                        setLoading({ ...loading, 2: false });
                        if (response['status'] === 1) {
                            setValues({ ...values, docs_url: file_name });
                        }
                    });
                }
            }
        } else {
            setMessage({ ...message, 1: "File already uploaded. Clear the field to upload again" });
        }
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = validateForm(values);
        setErrors(errors);
        setMessage({ ...message, 0: '' });

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Post(user_context.url + '/ticket', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage({ 0: response.message });
            });
        } else {
            setMessage({ 0: "Validation failed. Page up to check for errors" });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.client_id) {
            errors.client_id = "*Client is required";
        }

        if (!values.category_id) {
            errors.category = "*Category is required";
        }

        if (!values.type) {
            errors.type = "*Type is required";
        }

        if (values.status === 'Pending') {
            if (values.staff_head) {
                errors.staff_head = "*Staff Head is not allowed";
            }

            if (values.closing_date) {
                errors.closing_date = "*Closing Date is not allowed";
            }
        }
        else {
            if (!values.staff_head) {
                errors.staff_head = "*Staff Head is required";
            }

            if (!values.closing_date) {
                errors.closing_date = "*Closing Date is required";
            }
        }

        if (!values.comment) {
            errors.comment = "*Comment is required";
        }

        if (values.type === 'Parts Request' && !values.docs_url) {
            errors.type = "*Upload Parts Request Form";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const data =
        {
            date: FormatDate(values.date),
            client_id: values.client_id,
            category_id: values.category_id,
            type: values.type,
            comment: values.comment,
            status: values.status,
            staff_head: values.staff_head,
            closing_date: FormatDate(values.closing_date) || null,
            docs_url: values.docs_url
        };

        return data;
    }

    // RETURNING VALUES...

    return {
        handleChange, handleSubmit, handleSelect, 
        handleBrowse, viewPDF, handleClear, handleUpload, handleDialog,
        values, errors, message, loading, client, category, type, statuses, 
        disable, item, progress, docs_errors, dialog
    };
}

export default AddTicketForm;