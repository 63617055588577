import React, { useState, useEffect, useContext } from "react";
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Get from "../HTTPRequest/Get";
import Post from "../HTTPRequest/Post";
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';

const StickyNote = () => {

    // STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [matter, setMatter] = useState('');
    const [loading, setLoading] = useState(false);

    // FETCHING DATA...

    useEffect(() => {
        const fetchMatter = async () => {
            let endpoint = user_context.url + '/sticky_note';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if ((response.status === 1) &&(response.response_payload['result'] === 'success')) {                   
                    const data = response?.response_payload[0]?.matter;
                    setMatter(data);                                                        
                }
            });
        }

        fetchMatter();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { value } = e.target;
        setMatter(value);
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();   

        setLoading(true);

        let payload = { matter: matter };
        const headers = 
        { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${user_context.user_data[1]}` 
        };

        await Post(user_context.url + '/sticky_note', payload, headers).then(response => {
            setLoading(false);
            if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                toast.success(response?.message);                        
            } else {
                toast.error(response?.message);
            }                
        });
    
    }

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR NOTE DETAILS... */}

                <form className="location-form" onSubmit={handleSubmit}>
                    <div className="location-formGroup">
                        <h1 className="form-head">STICKY&nbsp;NOTE</h1>
                    </div>

                    <div className="location-formGroup" style={{}}>
                        <textarea 
                            type="text" 
                            autoComplete='off' 
                            className="input" 
                            name="matter" 
                            rows={4}
                            style={{height: '600px', resize: 'none', padding: '15px'}}
                            value={matter}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="location-formGroup">
                        <button 
                            className="button" 
                            type="submit"
                            disabled={loading}
                        >Save
                        </button>                       
                    </div>

                </form>

            </div>
            <ToastContainer/>
        </div>
    );
}

export default StickyNote;