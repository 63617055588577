import styles from './Career.module.css'


const Career = () => {
    return (
    <>
      <div>
        {/* *****************************Career Home Page*********************************** */}
      <div className={styles.careerPage}>
      <section className={styles.heroSection}>
        <div className={styles.heroContent}>
          <div className={styles.heroTitle}>
            <span className={styles.titleFirstSentence}>Hey there</span>
            <div className={styles.titleContainer}>
              <hr className={styles.titleSeparator} />
              <div className={styles.titleSecondSentence}>Join Our Team</div>
            </div>
          </div>
          <p className={styles.heroParagraph}>
            Are you passionate about technology and education? Do you want to be a part of a dynamic team that is shaping the future of learning? Look no further – we're seeking dedicated individuals to join us in our mission to provide innovative software and hardware solutions to schools and businesses.
          </p>
        </div>
      </section>
    </div>

    {/* ************************************Job Post***************************************** */}
    <div className={styles.section2part2}> {/*   Section 2   Part 2  */}
                <div className={styles.jobBox}>
                    <div className={styles.jobDetails}>
                        <h1 className={styles.jobTitle}>Software Engineer</h1>
                        <p className={styles.jobDescription}>Help us develop cutting-edge software solutions that enhance the educational experience. Your coding skills can make a difference.</p>
                    </div>
                    <a href="mailto:softechsmartsolutions@gmail.com?subject=Application for Software Engineer/Programmer" className={styles.applyText}>
                Apply
                <span className={styles.arrow}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
                            <path d="M22.4908 3.16565C22.5823 2.34229 21.989 1.60066 21.1656 1.50917L7.74822 0.0183481C6.92486 -0.0731364 6.18323 0.520166 6.09174 1.34353C6.00026 2.16689 6.59356 2.90851 7.41692 3L19.3435 4.32518L18.0183 16.2518C17.9269 17.0751 18.5202 17.8168 19.3435 17.9083C20.1669 17.9997 20.9085 17.4064 21 16.5831L22.4908 3.16565ZM1.93704 20.1713L21.937 4.1713L20.063 1.8287L0.0629574 17.8287L1.93704 20.1713Z" fill="white" />
                        </svg>
                    </span></a>
                </div>

                <div className={styles.jobBox}>
                    <div className={styles.jobDetails}>
                        <h1 className={styles.jobTitle}>Network Engineer</h1>
                        <p className={styles.jobDescription}>Are you skilled in designing, implementing, and managing network infrastructure? Join us in ensuring seamless connectivity for educational institutions.</p>
                    </div>
                    <a href="mailto:softechsmartsolutions@gmail.com?subject=Application for Network Engineer" className={styles.applyText}>
                Apply
                <span className={styles.arrow}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
                            <path d="M22.4908 3.16565C22.5823 2.34229 21.989 1.60066 21.1656 1.50917L7.74822 0.0183481C6.92486 -0.0731364 6.18323 0.520166 6.09174 1.34353C6.00026 2.16689 6.59356 2.90851 7.41692 3L19.3435 4.32518L18.0183 16.2518C17.9269 17.0751 18.5202 17.8168 19.3435 17.9083C20.1669 17.9997 20.9085 17.4064 21 16.5831L22.4908 3.16565ZM1.93704 20.1713L21.937 4.1713L20.063 1.8287L0.0629574 17.8287L1.93704 20.1713Z" fill="white" />
                        </svg>
                    </span></a>
                </div>

                <div className={styles.jobBox}>
                    <div className={styles.jobDetails}>
                        <h1 className={styles.jobTitle}>Hardware Engineer</h1>
                        <p className={styles.jobDescription}>If you have a knack for working with hardware components, troubleshooting, and optimizing systems, we want to hear from you.</p>
                    </div>
                    <a href="mailto:softechsmartsolutions@gmail.com?subject=Application for Hardware Engineer" className={styles.applyText}>
                Apply
                <span className={styles.arrow}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
                            <path d="M22.4908 3.16565C22.5823 2.34229 21.989 1.60066 21.1656 1.50917L7.74822 0.0183481C6.92486 -0.0731364 6.18323 0.520166 6.09174 1.34353C6.00026 2.16689 6.59356 2.90851 7.41692 3L19.3435 4.32518L18.0183 16.2518C17.9269 17.0751 18.5202 17.8168 19.3435 17.9083C20.1669 17.9997 20.9085 17.4064 21 16.5831L22.4908 3.16565ZM1.93704 20.1713L21.937 4.1713L20.063 1.8287L0.0629574 17.8287L1.93704 20.1713Z" fill="white" />
                        </svg>
                    </span></a>
                </div>

                <div className={styles.jobBox}>
                    <div className={styles.jobDetails}>
                        <h1 className={styles.jobTitle}>Smartclass Coordinator</h1>
                        <p className={styles.jobDescription}>We need someone who can liaise with various schools, ensuring the effective implementation and maintenance of our smartclass solutions.</p>
                    </div>
                    <a href="mailto:softechsmartsolutions@gmail.com?subject=Application for Smartclass Coordinator" className={styles.applyText}>
                Apply
                <span className={styles.arrow}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
                            <path d="M22.4908 3.16565C22.5823 2.34229 21.989 1.60066 21.1656 1.50917L7.74822 0.0183481C6.92486 -0.0731364 6.18323 0.520166 6.09174 1.34353C6.00026 2.16689 6.59356 2.90851 7.41692 3L19.3435 4.32518L18.0183 16.2518C17.9269 17.0751 18.5202 17.8168 19.3435 17.9083C20.1669 17.9997 20.9085 17.4064 21 16.5831L22.4908 3.16565ZM1.93704 20.1713L21.937 4.1713L20.063 1.8287L0.0629574 17.8287L1.93704 20.1713Z" fill="white" />
                        </svg>
                    </span></a>
                </div>

                <div className={styles.jobBox}>
                    <div className={styles.jobDetails}>
                        <h1 className={styles.jobTitle}>Electrician</h1>
                        <p className={styles.jobDescription}>If you're adept at electrical installations and maintenance, contribute to creating technologically advanced learning environments.</p>
                    </div>
                    <a href="mailto:softechsmartsolutions@gmail.com?subject=Application for Electrician" className={styles.applyText}>
                Apply
                <span className={styles.arrow}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
                            <path d="M22.4908 3.16565C22.5823 2.34229 21.989 1.60066 21.1656 1.50917L7.74822 0.0183481C6.92486 -0.0731364 6.18323 0.520166 6.09174 1.34353C6.00026 2.16689 6.59356 2.90851 7.41692 3L19.3435 4.32518L18.0183 16.2518C17.9269 17.0751 18.5202 17.8168 19.3435 17.9083C20.1669 17.9997 20.9085 17.4064 21 16.5831L22.4908 3.16565ZM1.93704 20.1713L21.937 4.1713L20.063 1.8287L0.0629574 17.8287L1.93704 20.1713Z" fill="white" />
                        </svg>
                    </span></a>
                </div>

                <div className={styles.jobBox}>
                    <div className={styles.jobDetails}>
                        <h1 className={styles.jobTitle}>CCTV Expert</h1>
                        <p className={styles.jobDescription}>If you're adept at electrical installations and maintenance, contribute to creating technologically advanced learning environments.</p>
                    </div>
                    <a href="mailto:softechsmartsolutions@gmail.com?subject=Application for CCTV Expert" className={styles.applyText}>
                Apply
                <span className={styles.arrow}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
                            <path d="M22.4908 3.16565C22.5823 2.34229 21.989 1.60066 21.1656 1.50917L7.74822 0.0183481C6.92486 -0.0731364 6.18323 0.520166 6.09174 1.34353C6.00026 2.16689 6.59356 2.90851 7.41692 3L19.3435 4.32518L18.0183 16.2518C17.9269 17.0751 18.5202 17.8168 19.3435 17.9083C20.1669 17.9997 20.9085 17.4064 21 16.5831L22.4908 3.16565ZM1.93704 20.1713L21.937 4.1713L20.063 1.8287L0.0629574 17.8287L1.93704 20.1713Z" fill="white" />
                        </svg>
                    </span></a>
                </div>

                {/* ************************Email Section ************************* */}
                <div className={styles.container}>
                    <div className={styles.paragraph}>
                        <p className={styles.mainParagraph}>
                            If you're ready to join us,<br/> send your {' '}
                            <span className={styles.highlightedText}>resume</span> and{' '}
                            <span className={styles.highlightedText}>cover letter</span>{' '}to{' '}
                        </p>
                        <a href="mailto:softechdevelopersteam@gmail.com?subject=Inquiry from [YourName]: [YourSubject]&body=Dear Softech Smart Solutions Team,%0D%0A%0D%0AMy name is [YourName], and I am reaching out to inquire about [YourSubject].%0D%0A%0D%0A[Please write your questions or comments here.]%0D%0A%0D%0AThank you for your time and assistance. Looking forward to hearing back from you soon.%0D%0A%0D%0ABest regards,%0D%0A[YourName]%0D%0A[YourContactInformation, if desired]" target="_blank" rel="noopener noreferrer">
                            <p className={styles.secondaryParagraph}>
                                <span className={styles.emailLink}>softech<em className={styles.secondaryEmail}>
                                <span className={styles.developerText}>developers</span></em>team<p>@gmail.com</p></span>
                            </p>
                        </a>
                        
                        <p className={styles.thirdParagraph}>
                            Let's build a smarter future together!!!
                        </p>
                        <p className={styles.fourthParagraph}>
                            Our philosophy is simple - hire a team of diverse, passionate people and foster a culture that empowers you to do your best work.
                        </p>
                    </div>
                </div>
    </div>

      </div>
    </>
  );
}

export default Career;