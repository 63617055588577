import { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';

const BackupForm = () =>{
    // DECLARING STATES...
    
    const user_context = useContext(UserContext);
    const [message, setMessage] = useState({0: ''});
    const [loading, setLoading] = useState({0: false, 1: false});
    const [files, setFiles] = useState([]);

    // BROWSING FOR MULTIPLE FILES...

    const handleBrowse = (event) =>{
        try {
            const files = event.target.files;
            const fileNames = [];
            for (let i = 0; i < files.length; i++) {
                fileNames.push(files[i].name);                
            }
            setFiles(fileNames);
        } catch (error) {
           setMessage({...message, 0: error.message});
        }
    }

    // API REQUEST FOR INCREMENTAL BACKUP OF ASSETS...

    const handleAssetsBackup = async () => {      
        setMessage({});
        setLoading({...loading, 0: true});
    
        let payload = {files: files};
        const headers = { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${user_context.user_data[1]}` 
        };
    
        try {
            if(files && files.length !== 0){
                const response = await fetch(user_context.url + '/backup?route=file_backup', {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(payload)
                });
        
                if (response.ok) {
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'backup.zip'); 
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    const data = await response.json();
                    setMessage({...message, 0: data.message});
                }
            }else{
                setMessage({...message, 0: 'Select your local assets folder first'});
            }
        } catch (error) {
            setMessage({...message, 0: 'Failed to start download'});
        } finally {
            setLoading({...loading, 0: false});
        }          
    }   
    
    // FUNCTION TO TAKE DATABASE BACKUP...

    const handleDatabaseBackup = async () => {
        setMessage({});
        setLoading({ ...loading, 1: true });
    
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${user_context.user_data[1]}`
        };
    
        try {
            const response = await fetch(user_context.url + '/backup?route=database_backup', {
                method: 'GET',
                headers: headers
            });
    
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'backup.sql'); 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                const data = await response.json();
                setMessage({ ...message, 0: data.message });
            }
        } catch (error) {
            setMessage({ ...message, 0: 'Failed to start download' });
        } finally {
            setLoading({ ...loading, 1: false });
        }
    }
    

    // RETURNING VALUES...

    return { handleBrowse, handleAssetsBackup, handleDatabaseBackup, message, loading };
}

export default BackupForm;