import React from "react";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { useMediaQuery } from 'react-responsive';
import ViewClientForm from "./ViewClientForm";
import { CSVLink } from "react-csv";
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';

const ViewClient = (props) => {
    // DECLARING STATES...

    const { ...destruct } = ViewClientForm(props);
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'Client ID', width: '5%' },
        { key: 'name', name: 'Name', width: '20%' },
        { key: 'address1', name: 'Address Line 1', width: '15%' },
        { key: 'address2', name: 'Address Line 2', width: '15%' },
        { key: 'district', name: 'District', width: '15%' },
        { key: 'state', name: 'State', width: '10%' },
        { key: 'pincode', name: 'Pincode', width: '10%' },
        { key: 'principal', name: 'Principal', width: '15%' },
        { key: 'director', name: 'Director', width: '15%' },
        { key: 'phone', name: 'Phone', width: '10%' },
        { key: 'email', name: 'Email', width: '20%' },
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });
    const headers = ['S.No', 'Client ID', 'Name', 'Address Line 1', 'Address Line 2', 'District', 'State', 'Pincode', 'Principal', 'Director', 'Phone', 'Email'];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">VIEW&nbsp;CLIENT</h1>
                    </div>

                    <div className="view-formGroup">
                        <label className="view-label">Key:</label>
                        <input type="text" autoComplete="off" className="view-input" placeholder="Enter key to search..." name="key" value={destruct.values.key} onChange={destruct.handleChange}></input>
                        {!isMobile && <select className="view-select" name="selected_column" value={destruct.values.selected_column} onChange={destruct.handleChange}>
                            {destruct.columns.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>}
                        <button className="view-button" type="submit" disabled={destruct.loading[0]}>View&nbsp;All</button>
                    </div>
                    {destruct.message && <p className="message">{destruct.message}</p>}
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        onRowClick={destruct.onRowClick}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                    />
                </div>

                {/* FOOTER BUTTONS FOR CRUD ACTIONS... */}

                <div className="view-footer">
                    {!isMobile && destruct.permissions[5] === '1' && <CSVLink className="view-button" data={destruct.table_rows.map(item => Object.values(item))} headers={headers} filename={'CLIENT.csv'} ><TableViewOutlinedIcon />&nbsp;Export</CSVLink>}
                    {destruct.permissions[3] === '1' && <button className="view-button" type="button" onClick={destruct.handleUpdate} ><CreateOutlinedIcon />&nbsp;Update</button>}
                    {destruct.permissions[4] === '1' && <button className="view-button" type="button" onClick={destruct.handleDelete} disabled={destruct.loading[1]}><DeleteForeverOutlinedIcon />&nbsp;Delete</button>}
                </div>

            </div>

            {/* DELETE DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='20%'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'

            >

                <div className="dialog-container">
                    <label>Do you really want to delete?</label>
                    <button className="button" type="button" onClick={destruct.handleConfirm}>Yes</button>
                </div>
            </ReactDialogBox>}
        </div>
    );

}

export default ViewClient;