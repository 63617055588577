import React, { useState, useEffect, useRef } from 'react';  
import '../Webpage/webpage.css';
import { Link, Route, Switch } from 'react-router-dom';
import title from '../Images/LOGO_1.png';
import Services from './Services';
import About from './About';
import Career from './Career';
import { useMediaQuery } from 'react-responsive';

const WebPage = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const isMobile = useMediaQuery({ query: `(max-width: 750px)` });

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target) && !toggleButtonRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="webpage">

      <div className='webpage-topbar'>
        <div className='webpage-topbar-logo'>
          <img className='webpage-topbar-image' src={title} alt="Not Found"/>
        </div>
        <button ref={toggleButtonRef} className="menu-toggle" onClick={() => setMenuOpen(!isMenuOpen)}>≡</button>
        <div ref={menuRef} className={`webpage-topbar-menu ${isMenuOpen ? 'open' : ''}`}>
          <Link to="/services" className='webiste-topbar-menuitem' onClick={() => setMenuOpen(false)}>Services</Link>    
          <Link to="/about" className='webiste-topbar-menuitem' onClick={() => setMenuOpen(false)}>About</Link>    
          <Link to="/career" className='webiste-topbar-menuitem' onClick={() => setMenuOpen(false)}>Career</Link>  
          {!isMobile && <Link to="/login" className='webiste-topbar-menuitem nav-button' onClick={() => setMenuOpen(false)}>Login</Link>}
        </div>
      </div>

      <div className='webpage-body'>
        <Switch>
          <Route exact path="/" component={Services} />              
          <Route exact path="/services" component={Services} />           
          <Route exact path="/about" component={About} />
          <Route exact path="/career" component={Career} />
          <Route path="*" component={Services} />     
        </Switch>  
      </div>

      <div className='webpage-footer'>

        <div className='footer-left-section'>
          <div className='arrangement'>
            <div className="footer-title">Company</div>
            <ul className="footer-list">
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/career">Career</Link></li>
                <li><a href="mailto:softechsmartsolutions@gmail.com?subject=Subject&body=Body" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                <li><Link to="/services">Services</Link></li>
            </ul>
          </div>
          <div className='arrangement'>
            <div className="footer-title">Contact Us</div> 
            <ul className="footer-list">
                <li><a href="http://softechsmartsolutions.in" target="_blank" rel="noopener noreferrer">softechsmartsolutions.in</a></li>
                <li><a href="tel:9592134440" target="_blank" rel="noopener noreferrer">+91 95921 34440</a></li>
                <li><a href="https://www.google.com/maps?q=Guru+Gobind+Singh+Avenue,+Chogitti,+Jalandhar" target="_blank" rel="noopener noreferrer">GURU GOBIND AVENUE, 4 GF HIG, Jalandhar,<br/> Jalandhar, Punjab, 144009</a></li>
            </ul>
        </div>
        </div>

        <div className='footer-right-section'>
            <div className='footer-logo'></div>
            <div className='footer-company-name'>Softech Smart Solutions</div>
            <div className='footer-description'>Choose Softech Solutions for Seamless integration of technology, Security and innovation in your School and Business ecosystem. Experience the difference today.</div>
            <div className='footer-copyright'>© Copyright Softech Smart Solutions</div>
        </div>

      </div>
    </div>
  );
}

export default WebPage;
