import axios from 'axios';

const Upload = async (endpoint, request_payload, headers) => {
    try {        
        const response = await axios.post(endpoint, request_payload, headers);        

        if (response.status === 200) {
            return { status: 1, message: response.data.message };
        }
        else {
            return { status: 0, message: `Unexpected response status: ${response.status}` };
        }
    }
    catch (error) {
        return { status: 0, message: error.message };
    }

}

export default Upload;