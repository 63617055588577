import React, { useState, useContext, useEffect } from "react";
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Get from "../HTTPRequest/Get";
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { FilterDramaOutlined } from '@mui/icons-material';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import EncodeBase64 from "../Parent/EncodeBase64";
import CreateBlob from "../Parent/CreateBlob";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import axios from "axios";

const Documents = () => {

    // STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const [tree, setTree] = useState();
    const [cwd, setCWD] = useState();
    const [stack, setStack] = useState([]);
    const [key, setKey] = useState('');
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [dialog, setDialog] = useState(false);
    const [progress, setProgress] = useState(0);
    const [docs_errors, setDocsErrors] = useState();
    const [docs, setDocs] = useState();
    const initialValues =
    {
        docs_url: ''
    };
    const [values, setValues] = useState(initialValues);
    const permissions = user_context.user_data[4].find((innerArray) => innerArray[0] === '/documents');

    // FETCHING FILES...

    useEffect(() => {

        const fetchDirectory = async () => {
            setLoading({...loading, 0: true});
            let endpoint = user_context.url + '/document';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                setLoading({...loading, 0: false});
                if ((response.status === 1) && (response.response_payload['result'] === 'success')){
                    const data = response?.response_payload;
                    setTree(data);
                    setCWD(data?.files);  
                    push(data);                  
                }
            });
        }

        fetchDirectory();

        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // FILTERING DATA...

    const filteredFiles = cwd?.filter(file =>
        file.name.toLowerCase().includes(key.toLowerCase())
    );

    // FUNCTION TO PUSH TO STACK...

    const push = (cwd) => {
        setStack([...stack, cwd]);
    }

    // FUNCTION TO POP FROM STACK...

    const pop = () => {
        const newStack = [...stack];
        newStack.pop();
        setStack(newStack);
        return newStack;        
    }

    // FUNCTION TO PEEK STACK...

    const peek = (newStack) => {
        if (newStack.length > 0) {
            return newStack[newStack.length - 1];
        } 
    }

    // HANDLING CLICK ON FILE...

    const handleFile = (file) => {
        if(file?.type === 'file') {
            if(stack.length > 1) {
                const baseUrl = user_context.url.replace('/smarterpweb-api', '');
                let link = baseUrl + '/documents/';
                for(let i=1; i<stack.length; i++){
                    link = link + stack[i].name + '/';
                }

                link = link + file.name;
                window.open(link, '_blank');
            } else {
                const baseUrl = user_context.url.replace('/smarterpweb-api', '');
                const link = baseUrl + '/documents/' + file.name;      
                window.open(link, '_blank');
            }            
        } else {
            push(file);
            setCWD(file?.files);
        }        
    }

    // FUNCTION TO GO BACK IN TREE...

    const handleBack = () => {
        if(stack.length > 1) {
            const newStack = pop();
            const file = peek(newStack);
            setCWD(file?.files);
        }        
    }

    // FUNCTION TO MOVE TO HOME DIRECTORY...

    const home = () => {
        setStack([]);  
        setCWD(tree?.files);        
    }; 
    
    // SETTING UP STACK TO HOME...

    useEffect(() => {
        if (stack.length === 0 && tree) {
            setStack(prevStack => [...prevStack, tree]);
        }
    }, [stack, tree]);    

    // HANDLER FOR UPLOAD DIALOG...

    const handleDialog = () => {
        setDialog(!dialog);
    }

    // REGISTERING FILE INPUT VALUES...

    const handleBrowse = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.id === 'pdf_file') {

                await EncodeBase64(e.target.files[0]).then((base64) => {
                    if (base64 !== null) {
                        setDocs(base64);
                    }

                    setValues({ ...values, docs_url: e.target.files[0].name });
                    setDocsErrors();

                }).catch(() => {
                    setDocsErrors();
                    toast.error("File conversion error");
                });
            }
        }
    }

    // VIEWING PDF...

    const viewPDF = () => {
        setLoading({ ...loading, 1: true });
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 1: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 1: false });
                }
            }
        } else {
            setLoading({ ...loading, 1: false });
        }
    }

    // VALIDATING UPLOADS...

    const validateDocs = () => {
        let errors = '';

        if (!values.docs_url) {
            errors = "*No pdf selected";
            setDocsErrors(errors);
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(values.docs_url)[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors = "*Document is not a valid PDF file";
                setDocsErrors(errors);
            }
        }
        return errors;
    }

    // CLEARING FIELDS...

    const handleClear = () => {
        setDocs();
        setValues({ ...values, docs_url: '' });
        setDocsErrors();
        setProgress(0);
    }

    // UPLOADING FILES...

    const handleUpload = async () => {
        setProgress(0);
        let errors = validateDocs();

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 2: true });
            const options = {
                headers: { 
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${user_context.user_data[1]}` 
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress(percent);
                }
            }

            if (docs !== null) {
                let file_name = values.docs_url;                
                let link = '../documents';

                if(stack.length > 1) {                  
                    for(let i=1; i<stack.length; i++){
                        link = link + '/' + stack[i].name ;
                    }                   
                }            
                
                const payload = { file_name: file_name, encoded_string: docs, path: link };
                const endpoint = user_context.url + '/document';

                const response = await axios.post(endpoint, payload, options);               
                setLoading({ ...loading, 2: false });

                if ((response?.status === 200) && (response?.data['result'] === 'success')) {                        
                    toast.success(response?.data?.message);  
                    const id = getID(tree?.files);
                    const newElement = {
                        id: id+1,
                        name: values.docs_url,
                        type: 'file'
                    };
                      
                    setCWD([...cwd, newElement]);  
                    setStack(prevStack => {
                        const lastEntryIndex = prevStack.length - 1;
                        const lastEntry = prevStack[lastEntryIndex];
                    
                        if (lastEntry && lastEntry.type === 'directory' && Array.isArray(lastEntry.files)) {
                          const updatedFiles = [...lastEntry.files, newElement];
                          const updatedLastEntry = {
                            ...lastEntry,
                            files: updatedFiles
                          };
                    
                          return [
                            ...prevStack.slice(0, lastEntryIndex),
                            updatedLastEntry,
                            ...prevStack.slice(lastEntryIndex + 1)
                          ];
                        }
                    
                        return prevStack;
                    });                    
                    const lastEntry = stack[stack.length - 1]; 
                    const targetId = lastEntry.id; 
                    addElementToTree(tree, targetId, newElement);
                    setDialog(!dialog);
                    handleClear();
                } else {
                    toast.error(response?.data?.message);
                }                 
            }
        }
    }

    // FUNCTION TO FIND HIGHEST ID...

    const getID= (nodes) => {
        let maxId = 0;
    
        const traverse = (nodes) => {
          nodes.forEach(node => {
            if (node.id > maxId) {
              maxId = node.id;
            }
            if (node.type === 'directory' && Array.isArray(node.files)) {
              traverse(node.files);
            }
          });
        };
    
        traverse(nodes);
        return maxId;
    };

    // FUNCTION TO ADD NEW ELEMENT TO THE TREE...

    const addElementToTree = (tree, cwd, newElement) => {

        const traverse = (node) => {
          if (node.id === cwd && Array.isArray(node.files)) {

            node.files.push(newElement);
            return; 
          }
      
          if (Array.isArray(node.files)) {
            node.files.forEach(child => {
              traverse(child); 
            });
          }
        };
      
        traverse(tree);      
        return tree;
    };   
    
    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="daily-wrapper">
                {/* SEARCH BOX */}

                <form className="daily-form">
                    <div className="daily-formGroup">
                        <h1 className="form-head">DOCUMENTS</h1>
                    </div>

                    <div className="daily-formGroup">
                        <label className="daily-label">Search:</label>
                        <input 
                            type="text" 
                            autoComplete='off' 
                            className="view-input" 
                            name="key" 
                            value={key} 
                            onChange={(event) => setKey(event.target.value)}
                        />

                        <button 
                            className="view-button" 
                            type="button"
                            onClick={handleBack}><ReplyOutlinedIcon/>
                        </button>

                        <button 
                            className="view-button" 
                            type="button"
                            onClick={home}><HomeOutlinedIcon/>
                        </button>

                        {permissions[1] === '1' && <button 
                            className="view-button" 
                            type="button"
                            onClick={handleDialog}><BackupOutlinedIcon/>
                        </button>}

                    </div>                        
                </form>

                {/* CONTENT BODY TO DISPLAY FILES */}

                {!loading[0] ? <div className="daily-container" style={{ overflow: 'scroll'}}>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, minmax(200px, 1fr))',
                        gap: '20px',
                        gridAutoRows: 'auto',    
                        width: '100%',                
                    }}>
                        {filteredFiles && filteredFiles.length > 0 && filteredFiles?.map(file => (
                        <div key={file.id} style={{
                            display: 'flex',
                            flexDirection: 'column', 
                            alignItems: 'center',
                            padding: '10px',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                            onClick={() => handleFile(file)}>
                            <div style={{ marginBottom: '10px' }}>
                                {file?.type === 'file' && <InsertDriveFileIcon style={{ fontSize: '50px', color: '#4682B4' }}/>}
                                {file?.type === 'directory' && <FolderIcon style={{ fontSize: '50px', color: '#FBC02D' }}/>}
                            </div>
                            <h3 style={{ fontSize: '14px', color: '#808080' }}>{file.name}</h3> 
                        </div> ))}
                    </div>
                </div>
                :
                <div className="daily-container" style={{ 
                    overflow: 'scroll', 
                    justifyContent: 'center',
                    fontSize: '1.5em', 
                    color: '#555'
                }}>
                    Loading...
                </div>}
                
            </div>
            {/* UPLOAD DIALOG BOX... */}

            {dialog && <ReactDialogBox
                closeBox={handleDialog}
                modalWidth='500px'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'            >

                <div className="dialog-container">
                    <div className="location-formGroup">
                        <label className="label">Upload&nbsp;Documents:</label>
                        <input type="text" autoComplete='off' className="input" name='docs_url' value={values.docs_url} onChange={handleChange}></input>                        
                        <progress className="form-progress" value={progress} max="100" />
                        <span className="progressText">{progress + "%"}</span>
                        {docs_errors && <p className="message">{docs_errors}</p>}

                        <input id="pdf_file" type="file" accept=".pdf" onChange={handleBrowse} onClick={(event) => { event.target.value = null }} />
                        <label htmlFor="pdf_file" className="button"><ImageSearchOutlinedIcon />&nbsp;Browse</label>
                        <button className="button" type="button" onClick={viewPDF} disabled={loading[1]}><RemoveRedEyeOutlinedIcon />&nbsp;View&nbsp;PDF</button>
                        <button className="button" type="button" onClick={handleUpload} disabled={loading[2]}><FilterDramaOutlined />&nbsp;Upload</button>
                        <button className="button" type="button" onClick={handleClear}><CleaningServicesOutlinedIcon />&nbsp;Clear</button>                        
                    </div>
                </div>
            </ReactDialogBox>}
            <ToastContainer/>
        </div>
    );
}

export default Documents;    