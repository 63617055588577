import { useState, useContext, useEffect } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import FormatDate from '../Parent/FormatDate';
import Get from '../HTTPRequest/Get';
import { useHistory } from 'react-router-dom';
import CreateLocalDate from '../Parent/CreateLocalDate';
import GetIndex from '../Parent/GetIndex';
import Put from '../HTTPRequest/Put';
import CreateDate from '../Parent/CreateDate';
import FormatLocalDate from '../Parent/FormatLocalDate';
import GetKey from '../Parent/GetKey';

const UpdateTaskForm = (props) =>{
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const statuses = ['Pending', 'Closed'];
    const initialValues = 
    { 
        id: data_context.update_data['id'],
        date: CreateLocalDate(data_context.update_data['date']), 
        assignee: '', 
        task: data_context.update_data['task'], 
        status: statuses[GetIndex(statuses, data_context.update_data['status'])],
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({0: ''});
    const [loading, setLoading] = useState({0: false});    
    const [staff, setStaff] = useState();
    const history = useHistory();

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=user';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let staff = response.response_payload[0] || [];
                        setStaff(staff);
                        setValues({ ...values, assignee: staff.id[GetIndex(staff.id, data_context.update_data['assignee'])]});
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();        
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});

        if (Object.keys(errors).length === 0) {
            setLoading({...loading, 0: true});

            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            Put(user_context.url + '/task', payload, headers).then(response => {
                setLoading({...loading, 0: false});
                setMessage({...message, 0: response.message});

                if(response.status === 1){
                    if (response.response_payload['result'] === 'success') {    
                        payload['name'] = staff.name[GetIndex(staff.id, values.assignee)];                  
                        let data = data_context.view_data.table_rows;                        
                        let index = GetKey(data, payload);                        
                        payload = { ...payload, sno: data[index]['sno'] };
                        payload = { ...payload, date: FormatLocalDate(CreateDate(payload['date'])) };
                        data = ({ ...data, [index]: payload });
                        const row_values = Object.values(data);

                        let view_data = data_context.view_data;
                        view_data = ({ ...view_data, table_rows: row_values });
                        props.handleBack(view_data);
                    }
                }
            });
        }
    }

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_task");
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.id) {
            errors.id = "*ID is required";
        }

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.assignee) {
            errors.assignee = "*Assignee is required";
        }

        if (!values.task) {
            errors.task= "*Task is required";
        }

        if (!values.status) {
            errors.status = "*Status is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const payload =
        {
            id: values.id,
            date: FormatDate(values.date),
            assignee: values.assignee,
            task: values.task,
            status: values.status
        };

        return payload;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, handleBack, values, errors, message, loading, statuses, staff };
}

export default UpdateTaskForm;