import React, { useState, useContext } from "react";
import UserContext from '../Parent/UserContext';
import Post from '../HTTPRequest/Post';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';

const AddAccount = () => {
    // STATES...

    const user_context = useContext(UserContext);
    const initialValues = 
    { 
        name: '',
        particulars: '' 
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // SUBMITTING FORM...

    const handleSubmit = async e => {
        e.preventDefault();        
        let errors = validateForm(values);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);

            let payload = createPayload(values);
            const headers = 
            { 
                'Accept': 'application/json', 
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${user_context.user_data[1]}` 
            };

            await Post(user_context.url + '/account', payload, headers).then(response => {

                setLoading(false);

                if ((response.status === 1) && (response.response_payload['result'] === 'success')) {                        
                    toast.success(response?.message);                                          
                } else {
                    toast.error(response?.message);
                }
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.name) {
            errors.name = "*Name is required";
        }

        if (!values.particulars) {
            errors.particulars = "*Particulars is required";
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        const payload =
        {
            name: values.name,
            particulars: values.particulars
        };

        return payload;
    }

    // MAIN FUNCTION...

    return(
        <div className="page">
            <div className="location-wrapper">

                {/* FORM FOR ACCOUNT DETAILS... */}

                <form className="location-form" onSubmit={handleSubmit}>
                    <div className="location-formGroup">
                        <h1 className="form-head">ADD&nbsp;ACCOUNT</h1>
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Name:</label>
                        <input type="text" autoComplete='off' className="input" name="name" value={values.name} onChange={handleChange}></input>
                        {errors.name && <p className="error">{errors.name}</p>}
                    </div>

                    <div className="location-formGroup">
                        <label className="label">Particulars:</label>
                        <input type="text" autoComplete='off' className="input" name="particulars" value={values.particulars} onChange={handleChange}></input>
                        {errors.particulars && <p className="error">{errors.particulars}</p>}
                    </div>

                    <div className="location-formGroup">
                        <button className="button" type="submit" disabled={loading}>Save</button>
                    </div>

                </form>
            </div>
            <ToastContainer/>
        </div>
    );
}

export default AddAccount;