import React, { useState, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Put from '../HTTPRequest/Put';
import Get from '../HTTPRequest/Get';
import { useMediaQuery } from 'react-responsive';
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import { Row } from 'react-data-grid';
import DatePicker from "react-datepicker";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FormatDate from '../Parent/FormatDate';
import axios from 'axios';

const FiscalSetup = () => {
    // STATES...

    const user_context = useContext(UserContext);
    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false });
    
    // HANDLE SUBMISSION OF ADD FISCAL...

    const [fiscal, setFiscal] = useState('');
    const [date, setDate] = useState('');

    const handleFiscal = async () => {
        if (fiscal && date) {
            setLoading({ ...loading, 0: true });
            let payload = { fiscal_name: fiscal, date :  FormatDate(date) }

            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            try
            {
                const response = await axios.post(user_context.url + '/fiscal', payload, { headers });
                if (response.status === 200) {
                    if(response?.data?.result === 'success'){
                        toast.success(response?.data?.message);
                    } else {
                        toast.error(response?.data?.message);
                    }
                } 
            } catch(error){
                toast.error(error.message);
            } finally {
                setLoading({ ...loading, 0: false });
            }
            
        } else {
            toast.error("Required fields are empty");
        }
    }

    // ADD FISCAL COMPONENT...

    const Fiscal = (
        <>
            <div className="location-formGroup">
                <h1 className="form-head">ADD&nbsp;FISCAL</h1>
            </div>

            <div className="location-formGroup">
                <input
                    type="text"
                    placeholder='Fiscal'
                    autoComplete='off'
                    className="input"
                    name="fiscal"
                    value={fiscal}
                    onChange={(e) => setFiscal(e.target.value)}>
                </input>                
            </div>

            <div className="location-formGroup">
                <div className="datepicker">
                    <CalendarMonthOutlinedIcon />
                    <div className='datepicker-component'>
                        <DatePicker
                            className='datepicker-inner-component'
                            dateFormat="dd-MM-yyyy"
                            selected={date}
                            onChange={date => setDate(date)}
                            fixedHeight
                            todayButton="TODAY"
                        />
                    </div>
                </div>
            </div>

            <div className="location-formGroup">
                <button
                    className="button"
                    type="button"
                    disabled={loading[0]}
                    onClick={handleFiscal}>Save
                </button>
            </div>
        </>
    );  

    // HANDLE SUBMISSION OF ADEFAULT FISCAL...

    const [defaultFiscal, setDefaultFiscal] = useState(user_context?.user_data[2][0] || '');
  
    const handleDefaultFiscal = async () => {
        if (defaultFiscal) {
            setLoading({ ...loading, 1: true });
            let payload = { fiscal_name: defaultFiscal }

            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            Put(user_context.url + '/fiscal', payload, headers).then(response => {
                setLoading({ ...loading, 1: false });
                if (response?.response_payload && response.response_payload['result'] === 'success') {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            });
        } else {
            toast.error("No default fiscal provided");
        }
    }
   
    // SET DEFAULT FISCAL COMPONENT...

    const fiscals = user_context.user_data[2].map((item, index) => (
        <option key={index} value={item}>{item}</option>
    ));

    const CurrentFiscal = (
        <>
            <div className="location-formGroup">
                <h1 className="form-head">SET&nbsp;DEFAULT&nbsp;FISCAL</h1>
            </div>

            <div className="location-formGroup">
                <label className="label">Default&nbsp;Fiscal:</label>
                <select 
                    className="select" 
                    name="staff_id" 
                    value={defaultFiscal} 
                    onChange={(e) => setDefaultFiscal(e.target.value)}>
                    {fiscals && fiscals.length ? fiscals : (<option value="">Loading...</option>)}
                </select>
            </div>

            <div className="location-formGroup">
                <button
                    className="button"
                    type="button"
                    disabled={loading[1]}
                    onClick={handleDefaultFiscal}>Set&nbsp;Default
                </button>
            </div>
        </>
    );    

    // COMPONENT TO SELECT FISCAL...

    const [from, setFrom] = useState(user_context?.user_data[2][0] || '');
    const [to, setTo] = useState(user_context?.user_data[2][0] || '');

    const SelectFiscal = (
        <>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <label 
                    style={{width: 'auto', marginRight: '10px', textAlign: 'center', paddingTop: '10px'}}
                    className="view-label">
                    From:
                </label>
                <select 
                    className="select" 
                    style={{width: '150px', marginRight: '10px', marginTop: '10px'}}
                    name="staff_id" 
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}>
                    {fiscals && fiscals.length ? fiscals : (<option value="">Loading...</option>)}
                </select>  
                <label 
                    style={{width: 'auto', marginRight: '10px', textAlign: 'center', paddingTop: '10px'}}
                    className="view-label">
                    To:
                </label>
                <select 
                    className="select" 
                    style={{width: '150px', marginRight: '10px', marginTop: '10px'}}
                    name="staff_id" 
                    value={to} 
                    onChange={(e) => setTo(e.target.value)}>
                    {fiscals && fiscals.length ? fiscals : (<option value="">Loading...</option>)}
                </select>       
            </div>                 
        </>
    );

    // VIEW ALL EXPENSE...   

    const viewExpense = async () => {
        if (from && to) {
            setLoading({ ...loading, 2: true });            

            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Get(user_context.url + '/fiscal?route=view_expense&field=' + from + '&filter_key='+ to, headers).then(response => {
                setLoading({ ...loading, 2: false });
                toast.error(response.message);
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;
    
                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            data[i] = payload[i];
                        }
                        
                        if(expense_checked) {
                            let filteredTransactions = data.filter(transaction => transaction.opening_balance !== transaction.closing_balance);
                            filteredTransactions = filteredTransactions.map((transaction, index) => {
                                transaction.sno = index + 1;
                                return transaction;
                            });
                            data = filteredTransactions;
                        }
                        setExpenseRows(data);
                    }
                }
            });
        } else{
            toast.error("Required fields are empty");
        }          
    }

    // UPDATE EXPENSE...

    const updateExpense = async () => {
        if (selected_expense && selected_expense.length > 0) {
            const data = selected_expense.map(item => ({ id: item.id, closing_balance: item.closing_balance })); 
            setLoading({ ...loading, 3: true });
            let payload = { data: data, fiscal: to }
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            Put(user_context.url + '/fiscal?route=update_expense', payload, headers).then(response => {
                setLoading({ ...loading, 3: false });
                if (response?.response_payload && response.response_payload['result'] === 'success') {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            });
        } else {
            toast.error("No row selected");
        }
               
    }

    // EXPENSE COMPONENT... 
    
    const [expense_rows, setExpenseRows] = useState([]);
    const [selected_expense, setSelectedExpense] = useState([]); 
    const defaultColumnProperties = { sortable: false, resizable: true };    
    const [expense_checked, setExpenseChecked] = useState(false);

    const CheckboxSelector = ({ row }) => {
        return (
          <input
            type="checkbox"
            checked={selected_expense.some(expense => expense.id === row.id)}
            onChange={() => handleCheckboxChange(row)}
          />
        );
    };     
    
    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedExpense([...expense_rows]);
        } else {
            setSelectedExpense([]);
        }
    };

    const HeaderCheckbox = ({ onChange }) => (
        <input 
            type="checkbox" 
            checked={selected_expense.length > 0 && selected_expense.length === expense_rows.length}
            onChange={onChange} 
        />
    );

    const expense_columns = [
        {
            key: 'checkbox',
            name: <HeaderCheckbox onChange={handleSelectAll} />,
            width: 50,
            resizable: false,
            formatter: CheckboxSelector,
        },
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'Expense ID', width: '15%' },
        { key: 'name', name: 'Name', width: '40%' },
        { key: 'closing_balance', name: 'Closing Balance', width: '20%' },
        { key: 'opening_balance', name: 'Opening Balance', width: '20%' },
    ].map(c => ({ ...c, ...defaultColumnProperties }));    

    const handleCheckboxChange = (selectedRow) => {
        const isSelected = selected_expense.some((expense) => expense.id === selectedRow.id);
      
        let newSelected = [];
      
        if (!isSelected) {
          newSelected = [...selected_expense, selectedRow];
        } else {
          newSelected = selected_expense.filter((expense) => expense.id !== selectedRow.id);
        }
      
        setSelectedExpense(newSelected);
    };

    const expenseRowRenderer = (props) => {
        const isSelected = selected_expense.some(expense => expense.id === props.row.id);
        const rowClassName = isSelected ? 'selected-row' : '';

        return (
          <div className={rowClassName}>
            {(props.row['closing_balance'] === props.row['opening_balance']) && <Row {...props} isRowSelected={isSelected} className='black'/>}
            {(props.row['closing_balance'] !== props.row['opening_balance']) && <Row {...props} isRowSelected={isSelected} className='red'/>}
          </div>
        );
    };

    const Expense = (
        <>
            <div className="location-formGroup">
                <h1 className="form-head">EXPENSE</h1>
            </div>
            <div style={{height: '300px', width: '100%'}}>
                <DataGrid
                    className="rdg-light"
                    style={{width: '100%', height: '100%'}}
                    columns={expense_columns}
                    rows={expense_rows}
                    rowRenderer={expenseRowRenderer}
                    enableCellSelect={false}
                />
            </div>
           
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px'}}>      
                <input 
                    type="checkbox" 
                    className="checkbox" 
                    checked={expense_checked} 
                    onChange={() => setExpenseChecked(!expense_checked)}                   
                />
                <button
                    style={{width: '200px', marginRight: '10px'}}
                    className="view-button"
                    type="button"
                    disabled={loading[3]}
                    onClick={updateExpense}>Update                       
                </button>            
                <button
                    style={{width: '200px', marginRight: '10px'}}
                    className="view-button"
                    type="button"
                    disabled={loading[2]}
                    onClick={viewExpense}>View&nbsp;All                      
                </button>                                 
            </div>         
        </>
    );  

    // VIEW ALL LEDGER...
    
    const party_types = ['Vendor', 'Client', 'Head', 'Staff', 'Partner'];
    const parties = party_types.map((item, index) => (
        <option key={index} value={item}>{item}</option>
    ));
    const [party, setParty] = useState(party_types[0]);

    const viewLedger = async () => {
        if (from && to && party) {
            setLoading({ ...loading, 4: true });            

            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Get(user_context.url + '/fiscal?route=view_ledger&field=' + from + '&filter_key='+ to + '&id=' + party, headers).then(response => {
                setLoading({ ...loading, 4: false });
                toast.error(response.message);
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;
    
                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            data[i] = payload[i];
                        }

                        if(ledger_checked) {
                            let filteredTransactions = data.filter(transaction => transaction.opening_balance !== transaction.closing_balance);
                            filteredTransactions = filteredTransactions.map((transaction, index) => {
                                transaction.sno = index + 1;
                                return transaction;
                            });
                            data = filteredTransactions;
                        }

                        setLedgerRows(data);
                    }
                }
            });
        } else {
            toast.error("Required fields are empty");
        }          
    }

    // UPDATE LEDGER...

    const updateLedger = async () => {
        if (selected_ledger && selected_ledger.length > 0) {
            const data = selected_ledger.map(item => ({ id: item.id, closing_balance: item.closing_balance })); 
            setLoading({ ...loading, 5: true });
            let payload = { data: data, fiscal: to }
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            Put(user_context.url + '/fiscal?route=update_ledger', payload, headers).then(response => {
                setLoading({ ...loading, 5: false });
                if (response?.response_payload && response.response_payload['result'] === 'success') {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            });
        } else {
            toast.error("No row selected");
        }
               
    }
    
    // LEDGER COMPONENT...

    const [ledger_rows, setLedgerRows] = useState([]);
    const [selected_ledger, setSelectedLedger] = useState([]);    
    const [ledger_checked, setLedgerChecked] = useState(false);

    const LedgerCheckboxSelector = ({ row }) => {
        return (
          <input
            type="checkbox"
            checked={selected_ledger.some(ledger => ledger.id === row.id)}
            onChange={() => ledgerCheckboxChange(row)}
          />
        );
    };     
    
    const ledgerSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedLedger([...ledger_rows]);
        } else {
            setSelectedLedger([]);
        }
    };

    const LedgerHeaderCheckbox = ({ onChange }) => (
        <input 
            type="checkbox" 
            checked={selected_ledger.length > 0 && selected_ledger.length === ledger_rows.length}
            onChange={onChange} 
        />
    );

    const ledger_columns = [
        {
            key: 'checkbox',
            name: <LedgerHeaderCheckbox onChange={ledgerSelectAll} />,
            width: 50,
            resizable: false,
            formatter: LedgerCheckboxSelector,
        },
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'Ledger ID', width: '15%' },
        { key: 'name', name: 'Name', width: '40%' },
        { key: 'closing_balance', name: 'Closing Balance', width: '20%' },
        { key: 'opening_balance', name: 'Opening Balance', width: '20%' },
    ].map(c => ({ ...c, ...defaultColumnProperties }));    

    const ledgerCheckboxChange = (selectedRow) => {
        const isSelected = selected_ledger.some((ledger) => ledger.id === selectedRow.id);
      
        let newSelected = [];
      
        if (!isSelected) {
          newSelected = [...selected_ledger, selectedRow];
        } else {
          newSelected = selected_ledger.filter((ledger) => ledger.id !== selectedRow.id);
        }
      
        setSelectedLedger(newSelected);
    };

    const ledgerRowRenderer = (props) => {
        const isSelected = selected_ledger.some(ledger => ledger.id === props.row.id);
        const rowClassName = isSelected ? 'selected-row' : '';

        return (
          <div className={rowClassName}>
            {(props.row['closing_balance'] === props.row['opening_balance']) && <Row {...props} isRowSelected={isSelected} className='black'/>}
            {(props.row['closing_balance'] !== props.row['opening_balance']) && <Row {...props} isRowSelected={isSelected} className='red'/>}
          </div>
        );
    };

    const Ledger = (
        <>
            <div className="location-formGroup">
                <h1 className="form-head">LEDGER</h1>
            </div>            
            <div style={{height: '500px', width: '100%'}}>
                <DataGrid
                    className="rdg-light"
                    style={{width: '100%', height: '100%'}}
                    columns={ledger_columns}
                    rows={ledger_rows}
                    rowRenderer={ledgerRowRenderer}
                    enableCellSelect={false}
                />
            </div>
           
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px'}}> 
                <input 
                    type="checkbox" 
                    className="checkbox" 
                    checked={ledger_checked} 
                    onChange={() => setLedgerChecked(!ledger_checked)}                   
                />               
                <label 
                    style={{width: 'auto', marginRight: '10px', textAlign: 'center'}}
                    className="view-label">
                    Party:
                </label>
                <select 
                    className="select" 
                    style={{width: '150px', marginRight: '10px'}}
                    name="staff_id" 
                    value={party}
                    onChange={(e) => setParty(e.target.value)}>
                    {parties && parties.length ? parties : (<option value="">Loading...</option>)}
                </select>                     
                <button
                    style={{width: '200px', marginRight: '10px'}}
                    className="view-button"
                    type="button"
                    disabled={loading[5]}
                    onClick={updateLedger}>Update                       
                </button>            
                <button
                    style={{width: '200px', marginRight: '10px'}}
                    className="view-button"
                    type="button"
                    disabled={loading[4]}
                    onClick={viewLedger}>View&nbsp;All                      
                </button>                                 
            </div>         
        </>
    );

    // VIEW ALL STOCK... 

    const viewStock = async () => {
        if (from && to) {
            setLoading({ ...loading, 6: true });            

            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Get(user_context.url + '/fiscal?route=view_stock&field=' + from + '&filter_key='+ to, headers).then(response => {
                setLoading({ ...loading, 6: false });
                toast.error(response.message);
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;
    
                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            data[i] = payload[i];
                        }    
                        
                        if(stock_checked) {
                            let filteredTransactions = data.filter(transaction => transaction.opening_balance !== transaction.closing_balance);
                            filteredTransactions = filteredTransactions.map((transaction, index) => {
                                transaction.sno = index + 1;
                                return transaction;
                            });
                            data = filteredTransactions;
                        }

                        setStockRows(data);
                    }
                }
            });
        } else{
            toast.error("Required fields are empty");
        }          
    }

    // UPDATE STOCK...

    const updateStock = async () => {
        if (selected_stock && selected_stock.length > 0) {
            const data = selected_stock.map(item => ({ id: item.id, closing_balance: item.closing_balance })); 
            setLoading({ ...loading, 7: true });
            let payload = { data: data, fiscal: to }
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            Put(user_context.url + '/fiscal?route=update_stock', payload, headers).then(response => {
                setLoading({ ...loading, 7: false });
                if (response?.response_payload && response.response_payload['result'] === 'success') {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            });
        } else {
            toast.error("No row selected");
        }
               
    }

    // STOCK COMPONENT... 
    
    const [stock_rows, setStockRows] = useState([]);
    const [selected_stock, setSelectedStock] = useState([]);   
    const [stock_checked, setStockChecked] = useState(false); 

    const StockCheckboxSelector = ({ row }) => {
        return (
          <input
            type="checkbox"
            checked={selected_stock.some(stock => stock.id === row.id)}
            onChange={() => stockCheckboxChange(row)}
          />
        );
    };     
    
    const stockSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedStock([...stock_rows]);
        } else {
            setSelectedStock([]);
        }
    };

    const StockHeaderCheckbox = ({ onChange }) => (
        <input 
            type="checkbox" 
            checked={selected_stock.length > 0 && selected_stock.length === stock_rows.length}
            onChange={onChange} 
        />
    );

    const stock_columns = [
        {
            key: 'checkbox',
            name: <StockHeaderCheckbox onChange={stockSelectAll} />,
            width: 50,
            resizable: false,
            formatter: StockCheckboxSelector,
        },
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'Stock ID', width: '15%' },
        { key: 'name', name: 'Name', width: '40%' },
        { key: 'closing_balance', name: 'Closing Stock', width: '20%' },
        { key: 'opening_balance', name: 'Opening Stock', width: '20%' },
    ].map(c => ({ ...c, ...defaultColumnProperties }));    

    const stockCheckboxChange = (selectedRow) => {
        const isSelected = selected_stock.some((stock) => stock.id === selectedRow.id);
      
        let newSelected = [];
      
        if (!isSelected) {
          newSelected = [...selected_stock, selectedRow];
        } else {
          newSelected = selected_stock.filter((stock) => stock.id !== selectedRow.id);
        }
      
        setSelectedStock(newSelected);
    };

    const stockRowRenderer = (props) => {
        const isSelected = selected_stock.some(stock => stock.id === props.row.id);
        const rowClassName = isSelected ? 'selected-row' : '';

        return (
          <div className={rowClassName}>
            {(props.row['closing_balance'] === props.row['opening_balance']) && <Row {...props} isRowSelected={isSelected} className='black'/>}
            {(props.row['closing_balance'] !== props.row['opening_balance']) && <Row {...props} isRowSelected={isSelected} className='red'/>}
          </div>
        );
    };

    const Stock = (
        <>
            <div className="location-formGroup">
                <h1 className="form-head">STOCK</h1>
            </div>
            <div style={{height: '300px', width: '100%'}}>
                <DataGrid
                    className="rdg-light"
                    style={{width: '100%', height: '100%'}}
                    columns={stock_columns}
                    rows={stock_rows}
                    rowRenderer={stockRowRenderer}
                    enableCellSelect={false}
                />
            </div>
           
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px'}}>     
                <input 
                    type="checkbox" 
                    className="checkbox" 
                    checked={stock_checked} 
                    onChange={() => setStockChecked(!stock_checked)}                   
                />
 
                <button
                    style={{width: '200px', marginRight: '10px'}}
                    className="view-button"
                    type="button"
                    disabled={loading[7]}
                    onClick={updateStock}>Update                       
                </button>            
                <button
                    style={{width: '200px', marginRight: '10px'}}
                    className="view-button"
                    type="button"
                    disabled={loading[6]}
                    onClick={viewStock}>View&nbsp;All                      
                </button>                                 
            </div>         
        </>
    );

    // MAIN FUNCTION...

    return (
        <div className="page">
            {!isMobile && <>
                <div className="common-wrapper" style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="location-form" style={{margin: '5px', flex: 1, maxHeight: '280px', minHeight: '280px', overflow: 'auto'}}>
                        {Fiscal}
                    </div>
                    <div className="location-form" style={{margin: '5px', flex: 1, maxHeight: '280px', minHeight: '280px', overflow: 'auto'}}>
                        {CurrentFiscal}
                    </div>                                                  
                </div>
                <div className="common-wrapper" style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="location-form" style={{margin: '5px', flex: 1}}>
                        {SelectFiscal}
                    </div>                             
                </div>
                <div className="common-wrapper" style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="location-form" style={{margin: '5px', flex: 1}}>
                        {Expense}
                    </div>                             
                </div>
                <div className="common-wrapper" style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="location-form" style={{margin: '5px', flex: 1}}>
                        {Ledger}
                    </div>                             
                </div>
                <div className="common-wrapper" style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="location-form" style={{margin: '5px', flex: 1}}>
                        {Stock}
                    </div>                             
                </div>
            </>}

            {isMobile && <>
                <div className="common-wrapper" style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="location-form" style={{margin: '5px', flex: 1}}>
                        {Fiscal}
                    </div>                             
                </div>
                <div className="common-wrapper" style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="location-form" style={{margin: '5px', flex: 1}}>
                        {CurrentFiscal}
                    </div>                             
                </div>
                <div className="common-wrapper" style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="location-form" style={{margin: '5px', flex: 1}}>
                        {Expense}
                    </div>                             
                </div>
                <div className="common-wrapper" style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="location-form" style={{margin: '5px', flex: 1}}>
                        {Ledger}
                    </div>                             
                </div>
                <div className="common-wrapper" style={{display: 'flex', flexDirection: 'row'}}>
                    <div className="location-form" style={{margin: '5px', flex: 1}}>
                        {Stock}
                    </div>                             
                </div>
            </>}
            
            <ToastContainer />
        </div>
    );
}

export default FiscalSetup;