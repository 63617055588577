import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import DataContext from '../Parent/DataContext';

const ShowTicketForm = () => {
    // DECLARING STATES...

    const data_context = useContext(DataContext);
    const initialValues =
    {
        ticket_id: data_context.update_data['id'],
        date: data_context.update_data['date'],
        client_id: data_context.update_data['client_id'],
        client: data_context.update_data['address'],
        category: data_context.update_data['category'],
        type: data_context.update_data['type'],
        comment: data_context.update_data['comment'],
        status: data_context.update_data['status'],
        staff_head: data_context.update_data['staff_head'],
        closing_date: data_context.update_data['closing_date']
    };

    const history = useHistory();

    // BACK TO PREVIOUS PAGE...

    const handleBack = () => {
        history.push("view_ticket");
    }       

    // RETURNING VALUES...

    return { handleBack, initialValues };
}

export default ShowTicketForm;