import React from "react";
import ViewLocationForm from "./ViewLocationForm";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useMediaQuery } from 'react-responsive';
import { ReactDialogBox } from 'react-js-dialog-box';
import 'react-js-dialog-box/dist/index.css';
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import { ToastContainer } from 'react-toastify';

const ViewLocation = (props) => {
    
    // DECLARING STATES...

    const { ...destruct } = ViewLocationForm(props);
    //console.log(destruct);
    const defaultColumnProperties = { sortable: false, resizable: true };

    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'ID', width: '5%' },
        { key: 'latitude', name: 'Latitude', width: '10%' },
        { key: 'longitude', name: 'Longitude', width: '10%' },
        { key: 'name', name: 'Name', width: '35%' },
        { key: 'address', name: 'Address', width: '15%' },
        { key: 'time_in', name: 'Time In', width: '10%' },
        { key: 'time_out', name: 'Time Out', width: '10%' },
        { key: 'client_id', name: 'Client ID', width: '10%' }
    ].map(c => ({ ...c, ...defaultColumnProperties }));

    const isMobile = useMediaQuery({ query: `(max-width: 750px)` });


    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form" onSubmit={destruct.handleSubmit}>
                    <div className="view-formGroup">
                        <h1 className="form-head">VIEW&nbsp;LOCATION</h1>
                    </div>

                    <div className="view-formGroup">
                        <label className="view-label">Key:</label>
                        <input 
                            type="text" 
                            autoComplete="off" 
                            className="view-input" 
                            placeholder="Enter key to search..." 
                            name="key" 
                            value={destruct.values.key} 
                            onChange={destruct.handleChange}>
                        </input>
                        {!isMobile && 
                        <select 
                            className="view-select" 
                            name="selected_column" 
                            value={destruct.values.selected_column} 
                            onChange={destruct.handleChange}
                        >
                            {destruct.columns.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>}
                        <button className="view-button" type="submit" disabled={destruct.loading[0]} >View&nbsp;All</button>
                    </div>
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        onRowClick={destruct.onRowClick}
                        rowRenderer={destruct.rowRenderer}
                        enableCellSelect={false}
                        ref={destruct.gridRef}
                    />
                </div>

                {/* FOOTER BUTTONS FOR CRUD ACTIONS... */}

                <div className="view-footer">
                    {destruct.permissions[3] === '1' && 
                    <button 
                        className="view-button" 
                        type="button" 
                        onClick={destruct.handleUpdate} ><CreateOutlinedIcon />&nbsp;Update
                    </button>}
                    {destruct.permissions[4] === '1' && 
                    <button 
                        className="view-button" 
                        type="button" 
                        onClick={destruct.handleDelete} 
                        disabled={destruct.loading[1]} ><DeleteForeverOutlinedIcon />&nbsp;Delete
                    </button>}
                </div>

            </div>

            {/* DELETE DIALOG BOX... */}

            {destruct.dialog && <ReactDialogBox
                closeBox={destruct.handleDialog}
                modalWidth='20%'
                headerBackgroundColor='rgb(44, 53, 68)'
                headerTextColor='white'
                headerHeight='65'
                closeButtonColor='white'
                bodyBackgroundColor='rgb(226, 218, 218)'
                bodyTextColor='black'
                bodyHeight='fit-content'
            >

                <div className="dialog-container">
                    <label>Do you really want to delete?</label>
                    <button className="button" type="button" onClick={destruct.handleConfirm}>Yes</button>
                </div>
            </ReactDialogBox>}
            <ToastContainer/>
        </div>
    );
}

export default ViewLocation;