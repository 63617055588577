import { useState, useEffect, useContext } from 'react';
import UserContext from '../../Parent/UserContext';
import DataContext from '../../Parent/DataContext';
import { Row } from 'react-data-grid';
import Get from '../../HTTPRequest/Get';
import CreateDate from '../../Parent/CreateDate';
import FormatLocalDate from '../../Parent/FormatLocalDate';
import FormatDate from '../../Parent/FormatDate';
import Download from '../../HTTPRequest/Download';
import CreateBlob from '../../Parent/CreateBlob';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useHistory } from 'react-router-dom';

const ShowLedgerForm = (props) => {
    // DECLARING STATES...   

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const party_types = ['Vendor', 'Client', 'Head', 'Staff', 'Partner'];
    const initialValues =
    {
        party_id: data_context.view_data['party_id'] || '',
        party: data_context.view_data['party'] || '',
        party_type: party_types[0],
        from: '',
        to: ''
    };
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({ 0: '' });
    const [message, setMessage] = useState({ 0: '' });
    const [loading, setLoading] = useState({ 0: false, 1: false });
    const [table_rows, setTableRows] = useState(data_context.view_data['table_rows'] || []);
    const [selected_row, setSelectedRow] = useState
        (
            data_context.view_data['selected_row'] !== undefined &&
                data_context.view_data['selected_row'] !== null
                ? data_context.view_data['selected_row'] : -1
        );
    const [vendor, setVendor] = useState([]);
    const [client, setClient] = useState([]);
    const [head, setHead] = useState([]);
    const [staff, setStaff] = useState([]);
    const [partner, setPartner] = useState([]);
    const [item, setItem] = useState([]);
    const [progress, setProgress] = useState({ 0: 0 });
    const history = useHistory();
    const [disable, setDisable] = useState(true);    

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=ledger';
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {

                        let vendor = response.response_payload[0] || [];
                        let client = response.response_payload[1] || [];
                        let head = response.response_payload[2] || [];
                        let staff = response.response_payload[3] || [];
                        let partner = response.response_payload[4] || [];
                        setVendor(vendor);
                        setClient(client);
                        setHead(head);
                        setStaff(staff);
                        setPartner(partner);  
                        
                        const newItems = vendor.id.map((_, i) => ({
                            id: vendor.id[i],
                            name: vendor.name[i] 
                        }));
                    
                        setItem(newItems);
                        setDisable(false);
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);

    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    // REGISTERING TYPE CHANGE...

    useEffect(() => {
        setErrors({});
        setItem([]);
       ;
        if (values.party_type === 'Vendor' && vendor.id && vendor.id.length !== 0) {
            const newItems = vendor.id.map((_, i) => ({
                id: vendor.id[i],
                name: vendor.name[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Client' && client.id && client.id.length !== 0) {
            const newItems = client.id.map((_, i) => ({
                id: client.id[i],
                name: client.address[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Head' && head.id && head.id.length !== 0) {
            const newItems = head.id.map((_, i) => ({
                id: head.id[i],
                name: head.name[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Staff' && staff.id && staff.id.length !== 0) {
            const newItems = staff.id.map((_, i) => ({
                id: staff.id[i],
                name: staff.name[i] 
            }));
        
            setItem(newItems);
        }

        if (values.party_type === 'Partner' && partner.id && partner.id.length !== 0) {
            const newItems = partner.id.map((_, i) => ({
                id: partner.id[i],
                name: partner.name[i] 
            }));
        
            setItem(newItems);
        }
        //eslint-disable-next-line
    }, [values.party_type]);

    // ON SELECTION...

    const handleSelect = (item) => {
        if(item){
            setValues({ ...values, party_id: item.id, party: item.name });
        }        
    }

    // SUBMITTING FORM...

    const handleShow = async () => {
        let errors = validateForm(values);
        setErrors(errors);
        setMessage({});
        setTableRows([]);

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });
            let endpoint =
                user_context.url + '/ledger?id=' + values.party_id + '_' + values.party_type +
                '&field=' + FormatDate(values.from) +
                '&filter_key=' + FormatDate(values.to) +
                '&route=show_ledger' +
                '&fiscal=' + data_context['fiscal'];
            const headers =
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${user_context.user_data[1]}`
            };

            await Get(endpoint, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage({ ...message, 0: response.message });

                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let payload = response.response_payload;
                        let data_length = (Object.keys(payload).length) - 1;

                        let data = ([]);
                        for (let i = 0; i < data_length; i++) {
                            if (payload[i]['date']) {
                                let date = CreateDate(payload[i]['date']);
                                date = FormatLocalDate(date);
                                payload[i]['date'] = date;
                            }
                            data[i] = payload[i];
                        }

                        setTableRows(data);
                    }
                }
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.party_id) {
            errors[0] = "Party is required";
        } else {
            if (values.from && values.to) {
                if (values.from > values.to) {
                    errors[0] = "FROM cannot be greater than TO";
                }
            } else {
                if (values.from || values.to) {
                    errors[0] = "Both FROM and TO are required";
                }
            }
        }

        return errors;
    }

    // SUBMITTING FORM...

    const handleAttachment = async () => {
        if (selected_row > -1) {
            setMessage({});
            setLoading({ ...loading, 1: true });
            setErrors({});

            const options = {
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Authorization': `Bearer ${user_context.user_data[1]}`
                },
                onDownloadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    setProgress({ ...progress, 0: percent });
                }
            }

            await Download(user_context.url + '/download?file_docs=' + table_rows[selected_row]['docs_url'], options).then(response => {
                setMessage({ ...message, 0: response['message'] });
                setLoading({ ...loading, 1: false });
                if (response['status'] === 1 && response.response_payload['result'] === 'success') {
                    viewPDF(response.response_payload['docs']);
                }
            });
        } else {
            setMessage({ ...message, 0: "No row selected" });
        }
    }

    // VIEWING PDF...

    const viewPDF = (docs) => {
        setLoading({ ...loading, 1: true });
        let errors = validateDocs(docs);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (docs !== undefined) {
                var blob = CreateBlob(docs.split(",").pop());
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                    setLoading({ ...loading, 1: false });
                }
                else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                    setLoading({ ...loading, 1: false });
                }
            }
        } else {
            setLoading({ ...loading, 1: false });
        }
    }

    // // VALIDATING UPLOADS...

    const validateDocs = (docs) => {
        let errors = {};
        if (!table_rows[selected_row]['docs_url']) {
            errors[0] = "*No attachment found";
        }
        else {
            let regex = /(?:\.([^.]+))?$/;
            let ext = regex.exec(table_rows[selected_row]['docs_url'])[1];

            if (ext !== "pdf" && ext !== "PDF") {
                errors[0] = "*Document is not a valid PDF file";
            }
            else {
                if (docs === 'no_such_data' || !docs) {
                    errors[0] = "*No pdf preview found";
                    setLoading({ ...loading, 1: false });
                }
            }
        }
        return errors;
    }

    // ROW RENDERER...

    const rowRenderer = (props) => {
        return (
            <div>
                {(props.rowIdx === selected_row) && <Row {...props} isRowSelected={true} />}
                {(props.rowIdx !== selected_row) && <Row {...props} isRowSelected={false} />}
            </div>

        );
    }

    // HANDLER FOR ROW CLICK...

    const onRowClick = (rowIndex) => {
        setSelectedRow(rowIndex);
    }

    // GENERATING PDF FROM ARRAY OF OBJECTS...

    const generatePDF = () => {
        const doc = new jsPDF('l', 'pt', 'a4');
        const headers = ['S.No', 'Lgdr ID', 'Date', 'Particulars', 'Credit', 'Debit', 'Vch Type', 'Vch No', 'Invoice Type', 'TDS'];
        const tableData = table_rows.map(obj => Object.values(obj));
      
        tableData.forEach(innerArray => {
            const indexesToRemove = [8];
            indexesToRemove.sort((a, b) => b - a);
            indexesToRemove.forEach(index => innerArray.splice(index, 1));
        });

        const title = `${values.party}`;
        const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const centerX = (doc.internal.pageSize.width - titleWidth) / 2;
        doc.setTextColor(128, 128, 128);
        doc.text(title, centerX, 30);
        doc.setTextColor(0, 0, 0);

        doc.autoTable({
            head: [headers],
            body: tableData,
        });
        
        doc.save(`${values.party}.pdf`);
    };

    // HANDLER FOR STOCK BUTTON...

    const handleStock = () => {
        if (selected_row > -1) {
            if (
                table_rows[selected_row]['vch_type'] === 'Purchase' ||
                table_rows[selected_row]['vch_type'] === 'Sales' ||
                table_rows[selected_row]['vch_type'] === 'Credit Note' ||
                table_rows[selected_row]['vch_type'] === 'Debit Note' ||
                table_rows[selected_row]['vch_type'] === 'Delivery Note' ||
                table_rows[selected_row]['vch_type'] === 'Receipt Note') {
                let view_data =
                {
                    key: values.key,
                    selected_column: values.selected_column,
                    selected_row: selected_row,
                    table_rows: table_rows,
                    party_id: values.party_id,
                    party: values.party
                };
                props.handleBack(view_data);
                props.handleUpdate(table_rows[selected_row]);
                history.push("/add_stock", { from_page: "/show_ledger" });
            }
            else {
                setMessage({ ...message, 0: "Selected row doesn't contain the required Vch Type" });
            }
        }
        else {
            setMessage({ ...message, 0: "No row selected" });
        }
    }

    // RETURNING VALUES...

    return {
        handleShow, handleChange, rowRenderer, onRowClick,
        handleSelect, handleAttachment, handleStock, generatePDF, 
        message, values, loading, table_rows, party_types, errors, 
        progress, item, disable
    };

}

export default ShowLedgerForm;