import React from "react";
import DataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import ShowLedgerForm from "./ShowLedgerForm";
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { CSVLink } from "react-csv";
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DatePicker from "react-datepicker";
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const ShowLedger = (props) => {
    // DECLARING STATES...

    const { ...destruct } = ShowLedgerForm(props);
    const defaultColumnProperties = { sortable: false, resizable: true };
    const table_columns = [
        { key: 'sno', name: 'S.No', width: '5%' },
        { key: 'id', name: 'Lgdr ID', width: '5%' },
        { key: 'date', name: 'Date', width: '10%' },
        { key: 'particulars', name: 'Particulars', width: '29%' },
        { key: 'vch_type', name: 'Vch Type', width: '10%' },
        { key: 'invoice_type', name: 'Inv Type', width: '5%' },
        { key: 'vch_no', name: 'Vch No', width: '10%' },        
        { key: 'credit', name: 'Credit', width: '10%' },
        { key: 'debit', name: 'Debit', width: '10%' },     
        { key: 'tds', name: 'TDS', width: '5%' },   
    ].map(c => ({ ...c, ...defaultColumnProperties }));
    const headers = ['S.No', 'Lgdr ID', 'Date', 'Particulars', 'Credit', 'Debit', 'Vch Type', 'Vch No', 'Invoice Type', 'TDS'];

    // MAIN FUNCTION...

    return (
        <div className="page">
            <div className="view-wrapper">

                {/* SEARCH BOX */}

                <form className="view-form">
                    <div className="daily-formGroup">
                        <h1 className="form-head">SHOW&nbsp;LEDGER</h1>
                    </div>

                    <div className="daily-formGroup">
                        <label className="daily-label">Type:</label>
                        <select className="ledger-select" name="party_type" value={destruct.values.party_type} onChange={destruct.handleChange} disabled={destruct.disable}>
                            {destruct.party_types.map((item, i) => (<option key={i}>{item}</option>))}
                        </select>       

                        <label className="daily-label">Party:</label>
                        <Autocomplete
                            disablePortal                         
                            id="combo-box-demo"
                            options={destruct.item}
                            getOptionLabel={(option) => option ? option.name : ''} 
                            isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Search Party..." />                                       
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <span style={{color: 'red'}}>{`ID: ${option.id}`}</span>
                                        <br />
                                        <span>{`${option.name}`}</span>
                                    </div>
                                </li>
                            )}
                            onChange={(event, value) => destruct.handleSelect(value)}
                            sx={{
                                width: '90%',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                "& .MuiInputBase-root": {
                                    fontFamily: 'sans-serif',
                                    height: 45,
                                    fontSize: '15px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignContent: 'center'
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: 0
                                },
                                "&:hover fieldset": {
                                    border: 0
                                },
                                backgroundColor: '#FFFFFF',
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: '1px solid #9ecaed',
                                    boxShadow: '0 0 10px #9ecaed',
                                },
                            }}
                        />
                        {destruct.errors.party_id && <p className="error">{destruct.errors.party_id}</p>}

                        <label className="daily-label">From:</label>
                            <div className="datepicker">
                                <CalendarMonthOutlinedIcon />
                                <div className='datepicker-component'>
                                    <DatePicker
                                        className='datepicker-inner-component'
                                        dateFormat="dd-MM-yyyy"
                                        selected={destruct.values.from}
                                        onChange={date => destruct.handleChange({ target: { value: date, name: 'from' } })}
                                        fixedHeight
                                        todayButton="TODAY"
                                    />
                                </div>
                            </div>

                            <label className="daily-label">To:</label>
                            <div className="datepicker">
                                <CalendarMonthOutlinedIcon />
                                <div className='datepicker-component'>
                                    <DatePicker
                                        className='datepicker-inner-component'
                                        dateFormat="dd-MM-yyyy"
                                        selected={destruct.values.to}
                                        onChange={date => destruct.handleChange({ target: { value: date, name: 'to' } })}
                                        fixedHeight
                                        todayButton="TODAY"
                                    />
                                </div>
                            </div>
                    </div>
                    {destruct.errors[0] && <p className="message">{destruct.errors[0]}</p>}
                    {destruct.message[0] && <p className="message">{destruct.message[0]}</p>}
                </form>

                {/* DATA GRID */}

                <div className="table-container">
                    <DataGrid
                        className="rdg-light"
                        style={{width: '100%', height: '100%'}}
                        columns={table_columns}
                        rows={destruct.table_rows}
                        onRowClick={destruct.onRowClick}
                        rowRenderer={destruct.rowRenderer}
                    />
                </div>

                {/* FOOTER BUTTONS FOR CRUD ACTIONS... */}

                <div className="view-footer">
                    <div className="progress-container"><progress className="progress" value={destruct.progress[0]} max="100" /></div>
                    <button className="view-button" type="button" onClick={destruct.handleStock} ><AddShoppingCartOutlinedIcon />&nbsp;Stock</button>
                    <button className="view-button" type="button" onClick={destruct.handleAttachment} disabled={destruct.loading[1]}><AttachFileOutlinedIcon />&nbsp;Attachment</button>
                    <button className="view-button" type="button" onClick={destruct.handleShow} disabled={destruct.loading[0]}><RemoveRedEyeOutlinedIcon />&nbsp;Show</button>
                    <CSVLink className="view-button" data={destruct.table_rows.map(({ docs_url, ...rest }) => Object.values(rest))} headers={headers} filename={'LEDGER.csv'} ><TableViewOutlinedIcon />&nbsp;Export</CSVLink>
                    <button className="view-button" type="button" onClick={destruct.generatePDF}><PictureAsPdfOutlinedIcon/>&nbsp;PDF</button>
                </div>

            </div>
        </div>
    );
}

export default ShowLedger;