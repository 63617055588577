import { useState, useEffect, useContext } from 'react';
import UserContext from '../Parent/UserContext';
import DataContext from '../Parent/DataContext';
import Post from '../HTTPRequest/Post';
import GetIndex from '../Parent/GetIndex';
import FormatDate from '../Parent/FormatDate';
import Get from '../HTTPRequest/Get';

const AddAttendanceForm = () => {
    // DECLARING STATES...

    const user_context = useContext(UserContext);
    const data_context = useContext(DataContext);
    const types = ['Present', 'Leave', 'Half Day', 'Special'];
    const current = new Date();
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState({ 0: false, 1: false, 2: false });
    const [staff_id, setStaffID] = useState([]);
    const [staff, setStaff] = useState([]);
    const [location_id, setLocationID] = useState([]);
    const [address, setAddress] = useState([]);
    const initialValues =
    {
        date: '',
        staff_id: '',
        name: '',
        type: types[0],
        location_id: '',
        address: '',
        reason: '',
        signin: current.toLocaleTimeString("en-US", { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" }),
        signout: current.toLocaleTimeString("en-US", { hourCycle: 'h23', hour: "2-digit", minute: "2-digit" })
    };
    const [values, setValues] = useState(initialValues);
    const [disable, setDisable] = useState({ 0: false, 1: false, 2: false, 3: false });

    // SETTING UP DROPDOWNS...

    useEffect(() => {
        const setSelect = async () => {
            let endpoint = user_context.url + '/dropdown?route=work';
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };
            await Get(endpoint, headers).then(response => {
                if (response.status === 1) {
                    if (response.response_payload['result'] === 'success') {
                        let staff_id = response.response_payload[0]['id'] || [];
                        let staff = response.response_payload[0]['name'] || [];
                        let location_id = response.response_payload[1]['id'] || [];
                        let address = response.response_payload[1]['address'];

                        setStaffID(staff_id);
                        setStaff(staff);
                        setLocationID(location_id);
                        setAddress(address);

                        setValues({
                            ...values, staff_id: staff_id[0], name: staff[0],
                            location_id: location_id[0], address: address[0]
                        });
                    }
                }
            });
        }

        setSelect();
        //eslint-disable-next-line
    }, [data_context['fiscal']]);


    // REGISTERING INPUT VALUES...

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });

        if (e.target.name === 'staff_id') {
            setValues({ ...values, staff_id: value, name: staff[GetIndex(staff_id, e.target.value)] });
        }

        if (e.target.name === 'location_id') {
            setValues({ ...values, location_id: value, address: address[GetIndex(location_id, e.target.value)] });
        }
    }

    // REGISTERING TYPE CHANGE...

    useEffect(() => {
        setErrors({});

        if (values.type === 'Present') {
            setDisable({ 0: true, 1: false, 2: false, 3: true });
        }

        if (values.type === 'Leave' || values.type === 'Special') {
            setDisable({ 0: false, 1: true, 2: true, 3: true });
        }

        if (values.type === 'Half Day') {
            setDisable({ 0: false, 1: false, 2: true, 3: true });
        }

        //eslint-disable-next-line
    }, [values.type]);

    // ENABLE OR DISABLE SIGN OUT...

    const handleToggle = () => {
        if (values.type === 'Present') {
            setDisable({ ...disable, 3: !disable[3] });
        }
    }

    // SHOWING NO_INFO STAFF...

    const handleNoInfo = async () => {
        let errors = {};

        if (values.date) {
            setErrors({});
            setStaffID([]);
            setStaff([]);
            setValues({ ...values, staff_id: '', name: '' });
            setMessage();
            setLoading({ ...loading, 1: true });

            const endpoint = user_context.url + '/attendance?filter_key=' + FormatDate(values.date) + '&route=get_no_info';
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Get(endpoint, headers).then(response => {
                setLoading({ ...loading, 1: false });
                let response_payload = response.response_payload;
                if (response.status === 1 && response_payload['result'] === 'success') {
                    let response_payload = response.response_payload;
                    let staff_id = response_payload[0]['id'];
                    let staff = response_payload[0]['name'];
                    setStaffID(staff_id || []);
                    setStaff(staff || []);

                    setValues({ ...values, staff_id: staff_id[0], name: staff[0] });
                }
            });
        }
        else {
            errors.date = "*Date is required";
            setErrors(errors);
        }
    }

    // SHOWING ALL STAFF...

    const handleShowAll = async () => {
        setErrors({});
        setStaffID([]);
        setStaff([]);
        setValues({ ...values, staff_id: '', name: '' });
        setMessage();
        setLoading({ ...loading, 1: true });

        let endpoint = user_context.url + '/dropdown?route=staff';
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

        await Get(endpoint, headers).then(response => {
            setLoading({ ...loading, 1: false });
            let response_payload = response.response_payload;
            if (response.status === 1 && response_payload['result'] === 'success') {
                let response_payload = response.response_payload;
                let staff_id = response_payload[0]['id'];
                let staff = response_payload[0]['name'];
                setStaffID(staff_id || []);
                setStaff(staff || []);

                setValues({ ...values, staff_id: staff_id[0], name: staff[0] });
            }
        });
    }

    // SUBMITTING FORM...

    const handleSubmit = async () => {
        let errors = validateForm(values);
        setErrors(errors);
        setMessage();

        if (Object.keys(errors).length === 0) {
            setLoading({ ...loading, 0: true });

            let payload = createPayload(values);
            const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8', 'Authorization': `Bearer ${user_context.user_data[1]}` };

            await Post(user_context.url + '/attendance', payload, headers).then(response => {
                setLoading({ ...loading, 0: false });
                setMessage(response.message);
            });
        }
    }

    // VALIDATING FORM...

    const validateForm = (values) => {
        let errors = {};

        if (!values.date) {
            errors.date = "*Date is required";
        }

        if (!values.staff_id) {
            errors.staff_id = "*Staff ID is required";
        }

        if (values.type === 'Present') {
            if (!values.location_id) {
                errors.location_id = "*Location ID is required";
            }

            if (!values.signin) {
                errors.signin = "*Sign In is required";
            }
        }

        if (values.type === 'Leave' || values.type === 'Special') {
            if (!values.reason) {
                errors.reason = "*Reason is required";
            }
        }

        if (values.type === 'Half Day') {
            if (!values.location_id) {
                errors.location_id = "*Location ID is required";
            }

            if (!values.reason) {
                errors.reason = "*Reason is required";
            }
        }

        return errors;
    }

    // MAKING POST DATA...

    const createPayload = (values) => {
        let location_id = null;
        let signin = '';
        let signout = '';
        let reason = '';

        if (!disable[0]) {
            reason = values.reason;
        }

        if (!disable[1]) {
            location_id = values.location_id;
        }

        if (!disable[2]) {
            signin = values.signin;
        }

        if (!disable[3]) {
            signout = values.signout;
        }


        const data =
        {
            date: FormatDate(values.date),
            staff_id: values.staff_id,
            type: values.type,
            location_id: location_id,
            signin: signin,
            signout: signout,
            reason: reason,
            fiscal: data_context['fiscal']
        };

        return data;
    }

    // RETURNING VALUES...

    return { handleChange, handleSubmit, handleToggle, handleNoInfo, handleShowAll, 
            values, errors, message, loading, staff_id, types, location_id, disable };
}

export default AddAttendanceForm;